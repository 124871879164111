<script>
    import { onMount, onDestroy } from "svelte";
    import diagramFactory from "../diagram/diagram.js";
    import Palette from "./components/Palette.svelte";
    import Inspector from "./components/Inspector.svelte";
    import NavBar from "./components/NavBar.svelte";
    import ActionBar from "./components/ActionBar.svelte";
    import Button from "./components/Button.svelte";
    import SaveAsModal from "../modals/SaveAs.svelte";
    import EditParameterModal from "../modals/EditParameter.svelte";
    import ChangeIconModal from "../modals/ChangeIcon.svelte";
    import {
        authToken,
        isBusy,
        hasUnsavedChanges,
        visibleModal,
        graphName,
        highlight,
        visiblePage,
    } from "../stores.js";
    import { getGraph, getExampleGraph, updateGraph } from "../api.js";

    let diagram;
    export let graphId;
    export let isExample = false;
    let graphDirname;

    const loadGraph = (id) => {
        if (!!diagram) {
            diagram.div = null;
            diagram = null;
        }
        isBusy.set(true);
        let req;
        if (isExample) {
            req = getExampleGraph($authToken, id);
        } else {
            req = getGraph($authToken, id);
        }
        req.then((resp) => {
            let _diagram = diagramFactory("diagram");
            graphName.set(resp.name);
            graphDirname = resp.dirname;
            _diagram.model.load(resp.data, _diagram);
            _diagram.simulate(undefined, ["InitialLoad"]);
            hasUnsavedChanges.set(false);
            _diagram.addModelChangedListener((evt) => {
                if (!!evt.propertyName && evt.propertyName.startsWith("_")) {
                    return;
                }
                hasUnsavedChanges.set(true);
            });
            // Wait until the diagram has fully loaded before setting the prop
            // That way the components that rely on it (i.e. the Palette) get the loaded model when they first render
            diagram = _diagram;
        }).finally(() => isBusy.set(false));
    };

    $: loadGraph(graphId);

    onMount(() => {
        window.addEventListener("EditParameterRequest", handleEditParameter);
        window.addEventListener("EditTextRequest", handleEditText);
        window.addEventListener("HideParameterRequest", handleHideParameter);
        window.addEventListener("SetHighlight", handleSetHighlight);
        window.addEventListener("ShowParameterRequest", handleShowParameter);
        document.addEventListener("keydown", handleKeyDown);
        window.rfgGraphId = graphId;
    });

    onDestroy(() => {
        window.rfgGraphId = undefined;
        if (!!diagram) {
            diagram.cleanup();
            diagram.div = null;
            diagram = null;
        }
        window.removeEventListener("EditParameterRequest", handleEditParameter);
        window.removeEventListener("EditTextRequest", handleEditText);
        window.removeEventListener("HideParameterRequest", handleHideParameter);
        window.removeEventListener("SetHighlight", handleSetHighlight);
        window.removeEventListener("ShowParameterRequest", handleShowParameter);
        document.removeEventListener("keydown", handleKeyDown);
    });

    const handleSave = () => {
        isBusy.set(true);
        let data = diagram.model.toJson();
        let req = updateGraph(
            $authToken,
            graphId,
            $graphName,
            graphDirname,
            data
        );
        req.then((resp) => {
            if (!resp.error) {
                hasUnsavedChanges.set(false);
            }
        });
        req.finally(() => {
            isBusy.set(false);
        });
    };

    const handleSaveAs = () => {
        visibleModal.set({
            name: "save-as",
            params: {
                graphName: $graphName,
                graphDirname: graphDirname,
                afterConfirm: (resp) => {
                    visiblePage.set(`/graphs/${resp.id}/`);
                },
            },
        });
    };

    const handleExport = () => {
        visibleModal.set({
            name: "export-graph",
            params: {
                graphName: $graphName,
                diagram: diagram,
            },
        });
    };

    const handleBeforeUnload = (evt) => {
        if ($hasUnsavedChanges) {
            evt.preventDefault();
            evt.returnValue = "You have unsaved changes in your graph.";
            return evt.returnValue;
        }
    };

    const handleHideParameter = (evt) => {
        for (let i = diagram.model.nodeDataArray.length - 1; i >= 0; i--) {
            let component = diagram.model.nodeDataArray[i];
            if (!component.parameters) continue;
            let param = component.parameters.get(evt.detail.parameter);
            if (!!param) {
                component.change(diagram, param, [["isWatched", false]]);
            } else {
                for (let j = 0; j < component._outputs.length; j++) {
                    let output = component._outputs[j];
                    let param = output.get(evt.detail.parameter);
                    if (!!param) {
                        component.change(diagram, param, [
                            ["isWatched", false],
                        ]);
                    }
                }
                let param = component._calculations.get(evt.detail.parameter);
                if (!!param) {
                    component.change(diagram, param, [["isWatched", false]]);
                }
            }
        }
    };

    const handleShowParameter = (evt) => {
        for (let i = diagram.model.nodeDataArray.length - 1; i >= 0; i--) {
            let component = diagram.model.nodeDataArray[i];
            if (!component.parameters) continue;
            let param = component.parameters.get(evt.detail.parameter);
            if (!!param) {
                component.change(diagram, param, [["isWatched", true]]);
            } else {
                for (let j = 0; j < component._outputs.length; j++) {
                    let output = component._outputs[j];
                    let param = output.get(evt.detail.parameter);
                    if (!!param) {
                        component.change(diagram, param, [["isWatched", true]]);
                    }
                }
                let param = component._calculations.get(evt.detail.parameter);
                if (!!param) {
                    component.change(diagram, param, [["isWatched", true]]);
                }
            }
        }
    };

    const handleSetHighlight = (evt) => {
        highlight.set(evt.detail.name);
    };

    const handleEditParameter = (evt) => {
        visibleModal.set({ name: "edit-parameter", params: evt.detail });
    };

    const handleEditText = (evt) => {
        visibleModal.set({ name: "edit-text", params: evt.detail });
    };

    const handleKeyDown = (evt) => {
        if (evt.ctrlKey && evt.key === "E") {
            // i.e. Ctrl-Shift-e
            handleSecretExport();
        } else if (evt.ctrlKey && evt.key === "I") {
            // i.e. Ctrl-Shift-i
            handleSecretImport();
        }
    };

    const handleSecretExport = () => {
        let data = diagram.model.toJson();
        data = encodeURIComponent(data);
        data = window.btoa(data);
        navigator.clipboard.writeText(data).then(
            () =>
                window.rfgShowToast(
                    "Graph Exported!",
                    "The graph has been copied to your clipboard",
                    "success"
                ),
            (err) =>
                window.rfgShowToast(
                    "Graph Not Exported",
                    "There was an error copying your graph :(",
                    "error"
                )
        );
    };

    const handleSecretImport = () => {
        visibleModal.set({
            name: "secret-import",
            params: {
                onConfirm: (data) => {
                    try {
                        data = window.atob(data);
                        data = decodeURIComponent(data);
                        diagram.model.load(data, diagram);
                        diagram.simulate(undefined, ["SecretImport"]);
                    } catch (e) {
                        console.error(e);
                        window.rfgShowToast(
                            "Error!",
                            "There was an error importing, check developer console for details",
                            "error"
                        );
                    }
                },
            },
        });
    };
</script>

<svelte:window on:beforeunload={handleBeforeUnload} />

<div class="h-full flex flex-col">
    <NavBar />
    <div class="grid grid-cols-3 bg-white p-3 border-b border-black">
        <div class="text-xl mr-4">
            <span class="bg-black rounded px-[4px] py-[3px] mr-2">
                <i class="text-white fa-regular fa-fw fa-diagram-project" />
            </span>
            {$graphName}
        </div>
        <div class="justify-self-center">
            {#if diagram}
                <ActionBar {diagram} />
            {/if}
        </div>
        <div class="justify-self-end">
            <Button
                text=""
                icon="fal fa-undo"
                theme="white"
                onClick={() => diagram.commandHandler.undo()}
            />
            <Button
                text=""
                icon="fal fa-redo"
                theme="white"
                onClick={() => diagram.commandHandler.redo()}
            />
            {#if isExample}
                <Button
                    text="Save As"
                    icon="fal fa-floppy-disks"
                    theme="white"
                    onClick={handleSaveAs}
                />
            {:else}
                <span class="">
                    <Button
                        text="Save"
                        icon="fal fa-save"
                        isDisabled={!$hasUnsavedChanges}
                        onClick={handleSave}
                    />
                </span>
                <span class="">
                    <Button
                        text="Save As"
                        icon="fal fa-floppy-disks"
                        theme="white"
                        onClick={handleSaveAs}
                    />
                </span>
                <span class="">
                    <Button
                        text="Export"
                        icon="fal fa-share"
                        theme="black"
                        onClick={handleExport}
                    />
                </span>
            {/if}
        </div>
    </div>
    <div class="grow overflow-hidden">
        <div class="flex flex-row h-full">
            <div>
                {#if diagram}
                    <Palette {diagram} />
                {/if}
            </div>
            <div class="grow relative">
                <div id="diagram" class="w-full h-full bg-[#f8f8f8]" />
                <div class="absolute bottom-0 left-0 p-2 z-10">
                    &copy; 2021-2023
                    <a
                        href="https://rfgraph.com"
                        target="_blank"
                        rel="noreferrer"
                        class="text-black hover:text-brand hover:no-underline"
                    >
                        RFGraph
                    </a>
                </div>
            </div>
            <div class="border-l border-black">
                {#if diagram}
                    <Inspector {diagram} />
                {/if}
            </div>
        </div>
    </div>
</div>

{#if $visibleModal !== null}
    {#if $visibleModal.name === "save-as"}
        <SaveAsModal {diagram} {...$visibleModal.params} />
    {:else if $visibleModal.name === "edit-parameter"}
        <EditParameterModal {diagram} {...$visibleModal.params} />
    {:else if $visibleModal.name === "change-icon"}
        <ChangeIconModal {...$visibleModal.params} />
    {/if}
{/if}

<style>
    #diagram :global(canvas) {
        outline: none;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
    }
</style>
