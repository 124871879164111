<script>
    import { onMount, onDestroy } from "svelte";
    export let heading = "Heading";
    export let body = "This is a toast";
    export let theme = "info";
    export let duration = 5000;
    export let onClick = null;
    export let selfDestruct;

    let timeout;

    let bgColour, headingColour, borderColour, icon;
    if (theme === "success") {
        bgColour = "bg-emerald-500";
        headingColour = "text-emerald-500";
        borderColour = "border-emerald-500";
        icon = "fa-solid fa-check";
    } else if (theme === "warning") {
        bgColour = "bg-yellow-500";
        headingColour = "text-yellow-500";
        borderColour = "border-yellow-500";
        icon = "fa-solid fa-siren-on";
    } else if (theme === "error") {
        bgColour = "bg-red-500";
        headingColour = "text-red-500";
        borderColour = "border-red-500";
        icon = "fa-solid fa-triangle-exclamation";
    } else {
        bgColour = "bg-blue-500";
        headingColour = "text-blue-500";
        borderColour = "border-blue-500";
        icon = "fa-solid fa-info";
    }

    onMount(() => {
        if (duration) {
            timeout = setTimeout(selfDestruct, duration);
        }
    });

    onDestroy(() => {
        if (timeout) {
            clearTimeout(timeout);
        }
    });
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
    on:click={onClick}
    class:cursor-pointer={onClick}
    class="flex w-96 mx-auto overflow-hidden bg-white rounded-lg shadow-md border-2 z-50 {borderColour}"
>
    <div class="{bgColour} flex items-center justify-center w-12">
        <span><i class="{icon} text-white" /></span>
    </div>
    <div class="px-4 py-2 -mx-3">
        <div class="mx-3">
            <span class="{headingColour} font-semibold">{heading}</span>
            <p class="text-sm text-gray-600">{body}</p>
        </div>
    </div>
</div>
