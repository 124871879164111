import Decimal from 'decimal.js';
import Component from './utils/component.js';
import Context from "./utils/context.js";
import Parameters from "./utils/parameters.js";
import iconUrl from '../images/source.svg';

export default class Source extends Component {
    category = "SRC";

    constructor(args) {
        args.defaults = [
            { name: "Frequency", value: 1000, unit: "MHz", baseUnit: "Hz", isEnabled: true, isEnabledEditable: false, isWatched: true, minValue: 0.01, maxValue: 200000 },
            { name: "Temperature", value: 25, unit: "°C", isEnabled: true, isEnabledEditable: false, isWatched: true, precision: 0, minValue: -250, maxValue: 400 },
            { name: "Power", value: -20, unit: "dBm", isEnabled: true, isEnabledEditable: false, isWatched: true, minValue: -200, maxValue: 99.99 },
            { name: "Noise_Tmp (To)", value: 290, unit: "°K", isEnabled: true, isEnabledEditable: false, isWatched: false, minValue: 1, maxValue: 500 },
            { name: "BW Src", value: 1, unit: "Hz", baseUnit: "Hz", isEnabled: true, isEnabledEditable: false, isWatched: false, minValue: 1, maxValue: 10000000000 },
            { name: "Project Type", value: "TX", unit: "", choices: ["TX", "RX"], isEnabled: true, isEnabledEditable: false, isWatched: false },
        ]
        args.name = args.name || "Source";
        args.summary = args.summary || "Parameter inputs for the start of the RF chain.";
        args.icon = iconUrl;
        super(args);
        this._calculations = new Parameters([
            {
                name: "Noise Floor",
                value: 0,
                unit: "dBm",
                isWatched: (!!args.watchedCalculationNames) ? args.watchedCalculationNames.indexOf("Noise Floor") !== -1 : false
            }
        ]);
        this._showWatermark = true;
    }

    _simulate(parameters, inputs) {
        let output = new Context();

        output.isHidden = true;

        let floor = this.calculateNoiseFloor(parameters);
        output.set("Noise Floor", floor);

        output.set("C_Gain", new Decimal(0));
        output.set("C_IIP2", new Decimal(99));
        output.set("C_IIP3", new Decimal(99));
        output.set("C_IP1dB", new Decimal(99));
        output.set("C_NF", new Decimal(0));
        output.set("C_OIP2", new Decimal(99));
        output.set("C_OIP3", new Decimal(99));
        output.set("C_OP1dB", new Decimal(99));
        output.set("Frequency", parameters.get("Frequency").getValue());
        output.set("Temperature", parameters.get("Temperature").getValue());
        output.set("Noise_Pout", output.get("Noise Floor").getValue());
        output.set("Pcomp", new Decimal(0));
        output.set("Pout", parameters.get("Power").getValue());
        output.set("Project Type", parameters.get("Project Type").getValue());
        output.get("Project Type").isHidden = true;

        return [output];
    }

    _runCalculations() {
        let floor = this.calculateNoiseFloor(this.parameters);
        this._calculations.set("Noise Floor", floor);
    }

    calculateNoiseFloor(parameters) {
        let k = new Decimal("1.38e-23");
        let noiseTemp = parameters.get("Noise_Tmp (To)").getValue();
        let bw = parameters.get("BW Src").getValue();

        let noiseFloor = Decimal.log10(k.times(1000).times(noiseTemp)).times(10).add(Decimal.log10(bw).times(10));
        return noiseFloor;
    }

    inPortIds() {
        return [];
    }
}
