import Decimal from "decimal.js";

const PRECISION = parseInt(import.meta.env.VITE_DECIMAL_PRECISION || 2);

export function convertToUnit(val, fromUnit, toUnit) {
    if (fromUnit === toUnit) return val;
    if (fromUnit === "mA") {
        if (toUnit === "A") {
            return val.dividedBy(1000);
        }
    }
    if (fromUnit === "A") {
        if (toUnit === "mA") {
            return val.times(1000);
        }
    }
    if (toUnit === "linear") {
        let ten = new Decimal(10);
        return ten.pow(val.dividedBy(10));
    }
    if (fromUnit === "linear") {
        let ten = new Decimal(10);
        return ten.times(val.log(10));
    }
    // Had the wrong char for degrees for a while
    if (fromUnit === "ºC") {
        if (toUnit === "°C") {
            return val;
        }
    }
    if (fromUnit === "ºK") {
        if (toUnit === "°K") {
            return val;
        }
    }

    if (fromUnit === "mHz") {
        if (toUnit === "Hz") {
            return val.dividedBy(1000);
        }
        const hz = convertToUnit(val, "mHz", "Hz");
        return convertToUnit(hz, "Hz", toUnit);
    }

    if (fromUnit === "kHz") {
        if (toUnit === "Hz") {
            return val.times(1000);
        }
        const hz = convertToUnit(val, "kHz", "Hz");
        return convertToUnit(hz, "Hz", toUnit);
    }

    if (fromUnit === "MHz") {
        if (toUnit === "Hz") {
            return val.times(1000).times(1000);
        }
        const hz = convertToUnit(val, "MHz", "Hz");
        return convertToUnit(hz, "Hz", toUnit);
    }

    if (fromUnit === "GHz") {
        if (toUnit === "Hz") {
            return val.times(1000).times(1000).times(1000);
        }
        const hz = convertToUnit(val, "GHz", "Hz");
        return convertToUnit(hz, "Hz", toUnit);
    }

    if (fromUnit === "Hz") {
        if (toUnit === "mHz") {
            return val.times(1000);
        }
        if (toUnit === "kHz") {
            return val.dividedBy(1000);
        }
        if (toUnit === "MHz") {
            return val.dividedBy(1000).dividedBy(1000);
        }
        if (toUnit === "GHz") {
            return val.dividedBy(1000).dividedBy(1000).dividedBy(1000);
        }
    }
    throw `Cannot convert ${fromUnit} to ${toUnit}`;
}

export function loadDecimal(dumped) {
    if (!!dumped && (dumped.toStringTag === "[object Decimal]" || dumped.name === "[object Decimal]")) {
        let dValue = new Decimal(0);
        dValue.d = dumped.d;
        dValue.e = dumped.e;
        dValue.s = dumped.s;
        return dValue;
    }
    try {
        dumped = new Decimal(dumped);
    } catch {

    }
    return dumped;
}

export default class Parameter {

    constructor(args) {
        this.name = Parameter.valueOrDefault(args.name, "Unknown");
        this.value = Parameter.valueOrDefault(args.value, 0);
        this.valueGetter = Parameter.valueOrDefault(args.valueGetter, null);
        this.value = loadDecimal(this.value);

        this.precision = Parameter.valueOrDefault(args.precision, PRECISION);
        this.unit = Parameter.valueOrDefault(args.unit, "");
        this.unitChoices = Parameter.valueOrDefault(args.unitChoices, null);
        this.baseUnit = Parameter.valueOrDefault(args.baseUnit, "");

        this.isWatched = Parameter.valueOrDefault(args.isWatched, false);
        this.isHidden = Parameter.valueOrDefault(args.isHidden, false);
        this.isEnabled = Parameter.valueOrDefault(args.isEnabled, false);
        this.isEnabledEditable = Parameter.valueOrDefault(args.isEnabledEditable, true);
        this.minValue = Parameter.valueOrDefault(args.minValue, null);
        this.maxValue = Parameter.valueOrDefault(args.maxValue, null);

        this.choices = Parameter.valueOrDefault(args.choices, null);
        if (!!this.choices) this.choices = this.choices.map(loadDecimal);

        this.cascadeValue = Parameter.valueOrDefault(args.cascadeValue, []);
        this.cascadeWatched = Parameter.valueOrDefault(args.cascadeWatched, []);
        this.cascadeEnabled = Parameter.valueOrDefault(args.cascadeEnabled, []);
        this.cascadeMVEnabled = Parameter.valueOrDefault(args.cascadeMVEnabled, []);
        this.cascadeMV = Parameter.valueOrDefault(args.cascadeMV, []);

        this.isMultiValued = Parameter.valueOrDefault(args.isMultiValued, false);
        this.isMultiValuedEnabled = Parameter.valueOrDefault(args.isMultiValuedEnabled, false);
        this.multiValues = Parameter.valueOrDefault(args.multiValues, []);
        this.multiValues = this.multiValues.map(datum => {
            return {
                freq: loadDecimal(datum.freq),
                temp: loadDecimal(datum.temp),
                value: loadDecimal(datum.value)
            }
        })

        this._branch = Parameter.valueOrDefault(args._branch, null);
        this._isWarning = false;
        this._warnings = {};
        this._isDanger = false;
    }

    static valueOrDefault(value, default_) {
        if (typeof value === "undefined") {
            return default_;
        }
        return value;
    }

    isReceiverParam() {
        return ["C_IIP2", "C_IIP3", "C_IP1dB", "IIP2", "IIP3", "IP1dB", "IPsat", "IP1BackOff"].indexOf(this.name) !== -1;
    }

    isTransmitterParam() {
        return ["C_OIP2", "C_OIP3", "C_OP1dB", "OIP2", "OIP3", "OP1dB", "OPsat", "OP1BackOff"].indexOf(this.name) !== -1;
    }

    getValue(unit) {
        if (!!this.valueGetter) {
            this.set(this.valueGetter());
            this.valueGetter = null;
        }
        let val = this.value;
        if (val !== null) {
            let floatTypes = ["dB", "dBm", "Watts", "mHz", "Hz", "kHz", "MHz", "GHz", "mA", "Vdc", "°C", "°F", "°K"];
            if (floatTypes.indexOf(this.unit) !== -1) {
                val = new Decimal(val);
                if (unit) {
                    val = convertToUnit(val, this.unit, unit);
                }
            }
        }
        return val;
    }

    isScalable() {
        return this.baseUnit === "Hz";
    }

    getUnitScaleUp(unit) {
        const scales = {
            "Hz": ["mHz", "Hz", "kHz", "MHz", "GHz"]
        }
        const scale = scales[this.baseUnit];
        const current = scale.indexOf(unit);
        if (current === -1 || current === scale.length - 1) {
            return unit;
        }
        return scale[current + 1];
    }

    getUnitScaleDown(unit) {
        const scales = {
            "Hz": ["mHz", "Hz", "kHz", "MHz", "GHz"]
        }
        const scale = scales[this.baseUnit];
        const current = scale.indexOf(unit);
        if (current === -1 || current === 0) {
            return unit;
        }
        return scale[current - 1];
    }

    getShortest() {
        let unit = this.getShortestUnit();
        return this.getPretty(this.getValue(unit));
    }

    getShortestUnit() {
        if (this.isScalable()) {
            let unit = this.unit;
            let val = this.getValue(unit).abs();
            while (val.greaterThanOrEqualTo(1000)) {
                let newUnit = this.getUnitScaleUp(unit);
                if (newUnit === unit) {
                    return unit;
                }
                unit = newUnit;
                val = this.getValue(unit).abs();
            }
            while (!val.equals(0) && val.lessThan(1)) {
                let newUnit = this.getUnitScaleDown(unit);
                if (newUnit === unit) {
                    return unit;
                }
                unit = newUnit;
                val = this.getValue(unit).abs();
            }
            return unit;
        }
        return this.unit;
    }

    getPretty(val) {
        if (val === undefined) val = this.getValue();
        if (val instanceof Decimal) {
            return val.toFixed(this.precision);
        }
        return val;
    }

    prettyName() {
        if (this._branch) {
            return `${this.name}@${this._branch}`;
        }
        return this.name;
    }

    set(paramOrValue, unit) {
        // TODO: check value in choice (if given)
        if (paramOrValue instanceof Parameter) {
            let val = paramOrValue.getValue();
            if (this.unit !== paramOrValue.unit) {
                val = convertToUnit(val, paramOrValue.unit, this.unit);
            }
            this.value = val;
            this.isWatched = paramOrValue.isWatched;
            this.isEnabled = paramOrValue.isEnabled;
            this.isHidden = paramOrValue.isHidden;
            this.multiValues = paramOrValue.multiValues;
            this.isMultiValuedEnabled = paramOrValue.isMultiValuedEnabled;
            this.cascadeWatched = paramOrValue.cascadeWatched;
            this.cascadeEnabled = paramOrValue.cascadeEnabled;
            this.cascadeMVEnabled = paramOrValue.cascadeMVEnabled;
            this.cascadeMV = paramOrValue.cascadeMV;
            this.sParams = paramOrValue.sParams;
            this.isSParamsEnabled = paramOrValue.isSParamsEnabled;
        } else {
            if (!!unit && unit !== this.unit) {
                paramOrValue = convertToUnit(paramOrValue, unit, this.unit);
            }
            this.value = paramOrValue;
        }
    }

    defer(func) {
        this.valueGetter = func;
    }

    dump() {
        let v = this.getValue();

        if (v instanceof Decimal) {
            v = v.toString();
        }

        if (this.name === "Splitters") {
            v = v.map(s => {
                return {
                    key: s.key,
                    size: s.size.toString(),
                    // input: s.input.dump(),
                    // output: s.output.dump(),
                }
            })
        }
        let multiValues = this.multiValues.map(datum => {
            return {
                freq: datum.freq?.toString(),
                temp: datum.temp?.toString(),
                value: datum.value?.toString()
            }
        });

        return {
            name: this.name,
            value: v,
            precision: this.precision,
            unit: this.unit,
            unitChoices: this.unitChoices,
            isWatched: this.isWatched,
            isHidden: this.isHidden,
            isEnabled: this.isEnabled,
            isEnabledEditable: this.isEnabledEditable,
            choices: this.choices ? this.choices.map(c => c.toString()) : null,
            minValue: this.minValue,
            maxValue: this.maxValue,
            cascadeValue: (this.cascadeValue || []).map(c => { return { name: c.name, enabled: c.enabled } }),
            cascadeWatched: this.cascadeWatched,
            cascadeEnabled: this.cascadeEnabled,
            cascadeMVEnabled: this.cascadeMVEnabled,
            cascadeMV: (this.cascadeMV || []).map(c => { return { name: c.name, delta: c.delta, enabled: c.enabled } }),
            isMultiValued: this.isMultiValued,
            isMultiValuedEnabled: this.isMultiValuedEnabled,
            multiValues: multiValues,
            _branch: this._branch,
            _isWarning: this._isWarning,
            _isDanger: this._isDanger
        }
    }

    addWarning(title, msg, hideWhenSeen) {
        this._warnings[title] = { msg: msg, hideWhenSeen: hideWhenSeen };
    }

    clearWarnings() {
        this._warnings = {};
    }

    clearSeenWarnings() {
        let newWarnings = {};
        Object.entries(this._warnings).forEach(entry => {
            if (!entry[1].hideWhenSeen) {
                newWarnings[entry[0]] = entry[1];
            }
        })
        this._warnings = newWarnings;
    }

    merge(other) {
        this.set(other);
        if (!!other.cascadeValue && other.cascadeValue.length > 0) this.cascadeValue = this.mergeCascadeValue(this.cascadeValue, other.cascadeValue);
        if (!!other.cascadeMV && other.cascadeMV.length > 0) this.cascadeMV = this.mergeCascadeMV(this.cascadeMV, other.cascadeMV);
    }

    mergeCascadeValue(base, other) {
        if (!base || !base.length) {
            return other;
        }
        return base.map(b => {
            if (!b) return;
            for (let o of other) {
                if (b.name === o.name) {
                    let c = { ...b };
                    if (o.enabled !== undefined) {
                        c.enabled = o.enabled;
                    }
                    if (o.hidden !== undefined) {
                        c.hidden = o.hidden;
                    }
                    return c;
                }
                return b;
            }
        }).filter(b => !!b);
    }

    mergeCascadeMV(base, other) {
        if (!base || !base.length) {
            return other;
        }
        return base.map(b => {
            if (!b) return;
            for (let o of other) {
                if (b.name === o.name) {
                    let c = { ...b };
                    if (o.enabled !== undefined) {
                        c.enabled = o.enabled;
                    }
                    if (o.hidden !== undefined) {
                        c.hidden = o.hidden;
                    }
                    return c;
                }
            }
            return b;
        }).filter(b => !!b);
    }

    validate(comp) {
        /** Returns any errors with this parameter if it were to be used in the provided node.
         * For instance would the OP1dB multivalues cause op1db to be bigger than opsat
         * To use this function, it's probably better to create a copy of the node to be validated,
         * run change() on it, and then validate after the changes have been applied to the copy.
        */
        return {};
    }

    _validateAddError(errors, keys, value) {
        let current = errors;
        for (let idx = 0; idx < keys.length; idx++) {
            const key = keys[idx];
            if (current[key] === undefined) {
                if (idx === keys.length - 1) {
                    current[key] = [];
                } else {
                    current[key] = {};
                }
            }
            current = current[key];
        }
        current.push(value);
    }
}
