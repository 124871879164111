import { get as getStoreValue } from 'svelte/store';
import { visibleModal, user } from "./stores";

const BASE_URL = import.meta.env.VITE_API_BASE;

const request = (url, method, details) => {
    let headers = details.headers || { "Content-Type": "application/json" };
    if (!!details.authToken) {
        headers["Authorization"] = `Bearer ${details.authToken}`;
    }
    url = `${BASE_URL}${url}`;
    if (!!details.params) {
        url = `${url}?${details.params.toString()}`;
    }
    let req = fetch(url, {
        method: method,
        headers: headers,
        body: (!!details.json) ? JSON.stringify(details.json) : details.body,
    });
    req = req.catch(error => {
        console.error(error);
        window.rfgShowToast(
            "Error!",
            "There was an error talking to the API, you might need to get in touch with us so we can fix it. Sorry!",
            "error",
            5000
        );
    });
    req = req.then((resp) => {
        if (!resp || resp.status === 500) {
            window.rfgShowToast(
                "Error!",
                "There was an error talking to the API, you might need to get in touch with us so we can fix it. Sorry!",
                "error",
                5000
            );
            return { "error": "There was an error talking to the API, you might need to get in touch with us so we can fix it. Sorry!" }
        }
        if (resp.status === 401) {
            if (!getStoreValue(user)) {
                window.rfgShowToast("Unauthorised", "There was an error authorising your account. Sign out and back in, then try again.", "error");
            } else {
                window.rfgShowToast("Not Logged In?", "Log back in, and try again.", "error");
                visibleModal.set({ name: "sign-in" });
            }
        } else if (resp.status === 422) {
            window.rfgShowToast("Something's not right", "Something about the information you provided isn't right.", "error");
        }
        return resp.json();
    });
    return req;
}

export const register = (email, password, agreeTerms, agreePrivacy) => {
    return request(
        "/auth/register",
        "POST",
        { json: { email: email, password: password, agree_terms: agreeTerms, agree_privacy: agreePrivacy } }
    );
}

export const login = (email, password) => {
    let body = new FormData();
    body.append("username", email);
    body.append("password", password);
    return request(
        "/auth/jwt/login",
        "POST",
        { body: body, headers: {} }
    );
}

export const refreshToken = (authToken) => {
    return request(
        "/auth/jwt/refresh",
        "POST",
        { authToken: authToken },
    );
}

export const ping = (authToken) => {
    return request(
        "/ping",
        "GET",
        { authToken: authToken },
    );
}

export const listGraphs = (authToken, params) => {
    return request(
        "/graphs",
        "GET",
        { authToken: authToken, params: params },
    );
}

export const listFolders = (authToken) => {
    return request(
        "/graphs/folders",
        "GET",
        { authToken: authToken },
    );
}

export const listExampleGraphs = (authToken) => {
    return request(
        "/graphs/examples",
        "GET",
        { authToken: authToken },
    );
}

export const getGraph = (authToken, graphId) => {
    return request(
        `/graphs/${graphId}`,
        "GET",
        { authToken: authToken },
    );
}

export const getExampleGraph = (authToken, graphId) => {
    return request(
        `/graphs/examples/${graphId}`,
        "GET",
        { authToken: authToken },
    );
}

export const updateGraph = (authToken, graphId, graphName, graphDirname, data) => {
    return request(
        `/graphs/${graphId}`,
        "PUT",
        {
            authToken: authToken,
            json: {
                name: graphName,
                dirname: graphDirname,
                data: data,
            }
        },
    );
}

export const createGraph = (authToken, graphName, graphDirname, data) => {
    return request(
        `/graphs`,
        "POST",
        {
            authToken: authToken,
            json: {
                name: graphName,
                dirname: graphDirname,
                data: data,
            }
        },
    );
}

export const deleteGraph = (authToken, graphId) => {
    return request(
        `/graphs/${graphId}`,
        "DELETE",
        { authToken: authToken },
    );
}

export const duplicateGraph = (authToken, graphId, graphName, graphDirname) => {
    return request(
        `/graphs/${graphId}/duplicate`,
        "POST",
        {
            authToken: authToken,
            json: {
                name: graphName,
                dirname: graphDirname,
            }
        },
    );
}

export const createBillingPortal = (authToken) => {
    return request(
        `/billing/portal`,
        "POST",
        { authToken: authToken },
    );
}

export const createUpdatePaymentMethodsPage = (authToken) => {
    return request(
        `/billing/payment-methods`,
        "POST",
        { authToken: authToken },
    );
}

export const createUpdateSubscriptionPage = (authToken, tier) => {
    return request(
        `/billing/update-subscription`,
        "POST",
        { authToken: authToken, json: { tier: tier } },
    );
}

export const me = (authToken) => {
    let req = request(
        `/users/me`,
        "GET",
        { authToken: authToken },
    );
    req.then(resp => {
        if (!!resp.email) user.set(resp);
        return resp;
    });
    return req;
}

export const patchMe = (authToken, changes) => {
    let req = request(
        `/users/me`,
        "PATCH",
        { authToken: authToken, json: changes },
    );
    req.then(resp => {
        if (!!resp.email) user.set(resp);
        return resp;
    });
    return req;
}

export const requestEmailVerification = (email) => {
    return request(
        `/auth/request-verify-token`,
        "POST",
        {
            json: {
                email: email
            }
        },
    );
}

export const verifyEmail = (token) => {
    let req = request(
        `/auth/verify`,
        "POST",
        {
            json: {
                token: token
            }
        },
    );
    req.then(resp => {
        if (!!resp.email) user.set(resp);
        return resp;
    });
    return req;
}

export const requestPasswordReset = (email) => {
    return request(
        `/auth/forgot-password`,
        "POST",
        {
            json: {
                email: email
            }
        },
    );
}

export const resetPassword = (token, password) => {
    return request(
        `/auth/reset-password`,
        "POST",
        {
            json: {
                token: token,
                password: password
            }
        },
    );
}

export const createComponent = (authToken, name, category, data) => {
    return request(
        `/components`,
        "POST",
        {
            authToken: authToken,
            json: {
                name: name,
                category: category,
                data: data,
            }
        },
    );
}

export const listComponents = (authToken, params) => {
    return request(
        `/components`,
        "GET",
        {
            authToken: authToken,
            params: params,
        },
    );
}

export const deleteComponent = (authToken, compId) => {
    return request(
        `/components/${compId}`,
        "DELETE",
        {
            authToken: authToken,
        },
    );
}


export const sendGraph = (authToken, graphId, email, message) => {
    return request(
        `/graphs/${graphId}/send`,
        "POST",
        {
            authToken: authToken,
            json: {
                email: email,
                message: message
            }
        },
    );
}


export const acceptSend = (authToken, graphId, token) => {
    return request(
        `/graphs/${graphId}/send/accept`,
        "POST",
        {
            authToken: authToken,
            json: {
                token: token
            }
        },
    );
}
