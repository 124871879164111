<script>
    import { visiblePage, isValidAuthToken } from "../stores.js";
    import Button from "./components/Button.svelte";
    import Page from "./Page.svelte";

    const handleAccount = () => {
        visiblePage.set("/account/");
    };
</script>

<Page>
    <div slot="body" class="grow max-w-lg mx-auto py-4">
        <h1 class="text-xl font-bold">Reset Password</h1>
        <p class="mb-4">
            We've sent you a password reset email that contains a secure link.
        </p>
        <p class="mb-4">
            When you get the email, click the link to open up the password reset
            form.
        </p>
        {#if $isValidAuthToken}
            <p>
                If you don't get the email from us, check your
                <Button
                    text="account page"
                    theme="anchor"
                    toUpperCase={false}
                    onClick={handleAccount}
                    boldText={false}
                />
                to see that we've got the right address for you, and send another email.
            </p>
        {:else}
            <p>If you don't get an email from us, try again, checking that the email you provide is the one on your RFGraph account.</p>
        {/if}
    </div>
</Page>
