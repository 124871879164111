<script>
    export let title;
    export let rtl = false;
    export let theme = "light";
    export let isClosed = false;
    export let upsell = false;

    let directionCls = "grid grid-cols-[auto,20px] gap-0";
    if (rtl) {
        directionCls = "grid grid-cols-[20px,auto] gap-0";
    }

    let themeCls = "bg-gray-100";
    if (theme === "dark") {
        themeCls = "bg-black text-white";
    }

    const onToggle = () => {
        isClosed = !isClosed;
    };
</script>

<div>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <h3
        on:click={onToggle}
        class="mb-1 px-3 py-2 uppercase cursor-pointer {directionCls} {themeCls}"
    >
        {#if rtl}
            {#if isClosed}
                <span><i class="far fa-chevron-up" /></span>
            {:else}
                <span><i class="far fa-chevron-down" /></span>
            {/if}
            <span class="ml-[-20px] text-center">
                {title}
                {#if upsell}
                    <span class="text-black text-[0.65rem] relative top-[-0.5rem] bg-[#FFD51E] rounded-xl border border-[#D6B513] px-2" style="line-height: 1rem">
                        PRO
                    </span>
                {/if}
            </span>
        {:else}
            <span class="mr-[-20px] text-center">
                {title}
                {#if upsell}
                    <span class="text-black text-[0.65rem] relative top-[-0.5rem] bg-[#FFD51E] rounded-xl border border-[#D6B513] px-2" style="line-height: 1rem">
                        PRO
                    </span>
                {/if}
            </span>
            {#if isClosed}
                <span><i class="far fa-chevron-up" /></span>
            {:else}
                <span><i class="far fa-chevron-down" /></span>
            {/if}
        {/if}
    </h3>
    {#if !isClosed}
        <slot />
    {/if}
</div>
