import Parameter, { loadDecimal } from "../utils/parameter.js";

export default class Gain extends Parameter {
    constructor(initialValue, overrides) {
        let args = overrides || {};
        args.name = "Gain";
        args.isMultiValued = true;
        args.value = initialValue;
        args.unit = Parameter.valueOrDefault(args.unit, "dB");

        args.minValue = Parameter.valueOrDefault(args.minValue, -99.99);
        args.maxValue = Parameter.valueOrDefault(args.maxValue, 99.99);

        args.isEnabled = Parameter.valueOrDefault(args.isEnabled, true);
        args.isWatched = Parameter.valueOrDefault(args.isWatched, true);
        args.isEnabledEditable = Parameter.valueOrDefault(args.isEnabledEditable, false);

        args.cascadeValue = Parameter.valueOrDefault(args.cascadeValue, []);

        super(args);

        this.cascadeValue = this.mergeCascadeValue([
            {
                name: "NF",
                hidden: true,
                enabled: false,
                calculate: 'calculateCascadedNF'
            }
        ], this.cascadeValue);

        this.isSParamsEnabled = Parameter.valueOrDefault(args.isSParamsEnabled, false);
        this.sParams = Parameter.valueOrDefault(args.sParams, []);
        this.sParams = this.sParams.map(sParam => {
            return {
                s3Key: sParam.s3Key,
                portCount: sParam.portCount,
                name: sParam.name,
                temp: loadDecimal(sParam.temp),
                data: sParam.data.map(datum => {
                    return {
                        freq: loadDecimal(datum.freq),
                        value: loadDecimal(datum.value)
                    }
                })
            }
        })
    }

    calculateCascadedNF(component) {
        let nf = component.parameters.get("NF");
        let currentNf = nf.getValue();
        let usesMV = nf.isMultiValuedEnabled && nf.multiValues.length > 0;
        if (usesMV) return currentNf;
        return component.parameters.get("Gain").getValue().abs();
    }

    dump() {
        let d = super.dump();
        d.sParams = this.sParams.map(sParam => {
            return {
                s3Key: sParam.s3Key,
                portCount: sParam.portCount,
                name: sParam.name,
                temp: sParam.temp?.toString(),
                data: sParam.data.map(datum => {
                    return {
                        freq: datum.freq?.toString(),
                        value: datum.value?.toString()
                    }
                })
            }
        });
        d.isSParamsEnabled = this.isSParamsEnabled;
        return d
    }
}
