import Component from './utils/component.js';
import iconUrl from '../images/highpass.svg';

export default class HighPass extends Component {
    category = "HIP";

    constructor(args) {
        args.defaults = [
            { name: "Gain", value: -1, cascadeValue: [{ name: "NF", enabled: true, hidden: false }] },
            { name: "OIP2", value: 96 },
            { name: "OIP3", value: 96 },
            { name: "OP1dB", value: 94.75 },
            { name: "OPsat", value: 95 },
            { name: "IIP2", value: 99 },
            { name: "IIP3", value: 99 },
            { name: "IP1dB", value: 98.75 },
            { name: "IPsat", value: 99 },
            { name: "NF", value: 1 },
            { name: "Idc", value: 0, unit: "mA", isEnabled: false, isWatched: false, precision: 0 },
            { name: "DevSply", value: 0, unit: "Vdc", isEnabled: false, isWatched: false },
            { name: "BusSply", value: 0, unit: "Vdc", isEnabled: false, isWatched: false },
            { name: "OP1BackOff", value: 2 },
            { name: "IP1BackOff", value: 0 },
            { name: "Te", value: 290, unit: "°K", isEnabled: false, isWatched: false, isHidden: true },
            { name: "Device Type", value: "Default", unit: "", choices: ["Default", "TX", "RX"], isEnabled: true, isEnabledEditable: false, isWatched: false },
        ]
        args.name = args.name || "HighPass";
        args.summary = args.summary || "A filter that passes higher frequencies and attenuates lower frequencies.";
        args.icon = iconUrl;
        super(args);
    }

}
