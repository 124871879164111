<script>
    import { visiblePage } from "../stores.js";
    const handleHome = () => {
        visiblePage.set("/");
    };
</script>

<div id="not-found" class="text-center mt-16">
    <h1 class="text-2xl">Page not found</h1>
    <p class="mt-4">Oh dear. We can't find the page you're after. Sorry :(</p>
    <p class="mt-4">
        <button
            on:click={handleHome}
            class="text-black bg-brand px-3 py-2 rounded font-bold hover:bg-black hover:text-white uppercase"
        >
            Home
        </button>
    </p>
</div>

<style>
</style>
