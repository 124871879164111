<script>
    import { onMount, onDestroy } from "svelte";
    import {
        visiblePage,
        nextPage,
        visibleModal,
        authToken,
        toasts,
        isValidAuthToken,
        isEmailVerified,
        user,
        features,
    } from "./stores.js";
    import { me, ping, refreshToken } from "./api.js";
    import AcceptSendPage from "./pages/AcceptSend.svelte";
    import AccountPage from "./pages/Account.svelte";
    import ConfirmEmailPage from "./pages/ConfirmEmail.svelte";
    import ExamplesPage from "./pages/Examples.svelte";
    import ForgotPasswordPage from "./pages/ForgotPassword.svelte";
    import GraphPage from "./pages/Graph.svelte";
    import MyGraphsPage from "./pages/MyGraphs.svelte";
    import NotFoundPage from "./pages/NotFound.svelte";
    import PlotPage from "./pages/Plot.svelte";
    import ResetPasswordPage from "./pages/ResetPassword.svelte";
    import ResetRequestedPage from "./pages/ResetRequested.svelte";
    import SignInPage from "./pages/SignIn.svelte";
    import SignOutPage from "./pages/SignOut.svelte";
    import SignUpPage from "./pages/SignUp.svelte";
    import SubscriptionPage from "./pages/Subscription.svelte";
    import SupportIconPage from "./pages/SupportIcon.svelte";
    import VerifyNeededPage from "./pages/VerifyNeeded.svelte";
    import VerifyRequestedPage from "./pages/VerifyRequested.svelte";

    import ChangeEmailModal from "./modals/ChangeEmail.svelte";
    import ChooseFolderModal from "./modals/ChooseFolder.svelte";
    import ConfirmLoseChangesModal from "./modals/ConfirmLoseChanges.svelte";
    import DeleteGraphModal from "./modals/DeleteGraph.svelte";
    import DuplicateGraphModal from "./modals/DuplicateGraph.svelte";
    import EditTextModal from "./modals/EditText.svelte";
    import ExportGraphModal from "./modals/ExportGraph.svelte";
    import MoveGraphModal from "./modals/MoveGraph.svelte";
    import NewGraphModal from "./modals/NewGraph.svelte";
    import RenameGraphModal from "./modals/RenameGraph.svelte";
    import SecretImportModal from "./modals/SecretImport.svelte";
    import SendGraphModal from "./modals/SendGraph.svelte";
    import SignInModal from "./modals/SignIn.svelte";
    import SubscriptionProModal from "./modals/SubscriptionPro.svelte";
    import SubscriptionStandardModal from "./modals/SubscriptionStandard.svelte";
    import UpsellModal from "./modals/Upsell.svelte";
    import ViewComponentModal from "./modals/ViewComponent.svelte";

    import Toast from "./pages/components/Toast.svelte";

    const graphUrl = /^\/graphs\/(\d+)\/$/;
    const plotUrl = /^\/graphs\/(\d+)\/plot\/$/;
    const exampleUrl = /^\/examples\/(\d+)\/$/;
    const protectedPages = [
        /^\/my-graphs\/$/,
        /^\/examples\/$/,
        /^\/new-graph\/$/,
        /^\/account\/$/,
        /^\/account\/subscription\/$/,
        /^\/sign-up\/subscription\/$/,
        /^\/verify\/requested\/$/,
        /^\/verify\/needed\/$/,
        graphUrl,
        exampleUrl,
        plotUrl,
    ];

    let searchParams = {};
    let page, pageProps;
    let modal, modalProps;

    let refreshInterval;
    let pingInterval;

    onMount(() => {
        searchParams = new URLSearchParams(window.location.search);

        visiblePage.set(window.location.pathname);
        visiblePage.subscribe((newPage) => {
            let next = null;
            let current = newPage;
            if (needsUser(newPage)) {
                if ($authToken === null) {
                    next = newPage;
                    current = "/sign-up/";
                } else if (!$isValidAuthToken) {
                    next = newPage;
                    current = "/sign-in/";
                } else if (!!$user && !$isEmailVerified && needsVerifiedEmail(newPage)) {
                    next = newPage;
                    current = "/verify/needed/";
                }
                else if (!!$user && $user.subscription_tier == "pending" && needsSubscription(newPage)) {
                    next = newPage;
                    current = "/account/subscription/";
                }
            } else if (!newPage || newPage === "/") {
                current = "/my-graphs/";
            }
            if (current !== newPage) {
                nextPage.set(next);
                visiblePage.set(current);
            } else {
                history.pushState(null, "", `${newPage}${window.location.search}`);
            }
        });

        window.onpopstate = (event) => {
            searchParams = new URLSearchParams(window.location.search);
            visiblePage.set(window.location.pathname);
        };

        window.addEventListener("ShowToast", handleShowToast);

        window.rfgShowToast = (heading, body, theme, duration) => {
            const event = new CustomEvent("ShowToast", {
                detail: {
                    heading: heading,
                    body: body,
                    theme: theme,
                    duration: duration,
                    _key: "" + Date.now() + Math.floor(Math.random() * 10000),
                },
            });
            window.dispatchEvent(event);
        };

        if (!window.opener) {
            refreshInterval = setInterval(handleRefreshToken, 60 * 60 * 1000);
        }

        // Bump the interval by some time so that it's less likely to clash with the refresh interval
        setTimeout(() => {
            pingInterval = setInterval(handlePing, 60 * 1000);
            handlePing();
        }, 5000);

        window.addEventListener("visibilitychange", handleVisibilityChange);

        if ($isValidAuthToken) {
            me($authToken);
        } else {
            user.set(null);
        }
    });

    onDestroy(() => {
        if (!!refreshInterval) clearInterval(refreshInterval);
        clearInterval(pingInterval);
        window.removeEventListener("ShowToast", handleShowToast);
        window.removeEventListener("visibilitychange", handleVisibilityChange);
    });

    const needsUser = (page) => {
        if (page) {
            for (let idx = 0; idx < protectedPages.length; idx++) {
                const rex = protectedPages[idx];
                if (page.match(rex) !== null) {
                    return true;
                }
            }
        }
        return false;
    };

    const needsVerifiedEmail = (page) => {
        let doesntNeed = ["/account/", "/verify/requested/", "/verify/needed/"];
        return doesntNeed.indexOf(page) === -1;
    };

    const needsSubscription = (page) => {
        let doesntNeed = ["/verify/requested/", "/verify/needed/"];
        return doesntNeed.indexOf(page) === -1;
    };

    const handleRefreshToken = () => {
        if ($isValidAuthToken) {
            refreshToken($authToken).then((resp) => {
                if (!!resp.access_token) {
                    authToken.set(resp.access_token);
                }
            });
        }
    };

    const handlePing = () => {
        if ($isValidAuthToken) {
            ping($authToken);
        }
    };

    const removeToast = (idx) => {
        let newToasts = $toasts;
        newToasts.splice(idx, 1);
        toasts.set(newToasts);
    };

    const handleShowToast = (evt) => {
        let newToasts = $toasts;
        newToasts.push(evt.detail);
        toasts.set(newToasts);
    };

    const handleVisibilityChange = (evt) => {
        if (document.visibilityState === "visible") {
            handlePing();
        }
    };

    $: self._isMultiValuesEnabled = $features.sparams !== "upsell";
    $: self.self._isMirrorEnabled = $features.mirror !== "upsell";

    // Set the page
    $: {
        if ($visiblePage === "/sign-in/") {
            page = SignInPage;
            pageProps = {};
        } else if ($visiblePage === "/sign-up/") {
            page = SignUpPage;
            pageProps = {
                token: searchParams.get("t"),
                graphId: searchParams.get("g"),
            };
        } else if ($visiblePage === "/sign-out/") {
            page = SignOutPage;
            pageProps = {};
        } else if ($visiblePage === "/my-graphs/") {
            page = MyGraphsPage;
            pageProps = {
                s: searchParams.get("s"),
                d: searchParams.get("d"),
                l: searchParams.get("l"),
                p: searchParams.get("p"),
            };
        } else if ($visiblePage === "/examples/") {
            page = ExamplesPage;
            pageProps = {};
        } else if ($visiblePage === "/account/") {
            page = AccountPage;
            pageProps = {};
        } else if ($visiblePage === "/account/subscription/") {
            page = SubscriptionPage;
            pageProps = {};
        }  else if ($visiblePage === "/verify/requested/") {
            page = VerifyRequestedPage;
            pageProps = {};
        } else if ($visiblePage === "/verify/needed/") {
            page = VerifyNeededPage;
            pageProps = {};
        } else if ($visiblePage === "/reset/requested/") {
            page = ResetRequestedPage;
            pageProps = {};
        } else if ($visiblePage === "/confirm-email/") {
            page = ConfirmEmailPage;
            pageProps = { token: searchParams.get("t") };
        } else if ($visiblePage === "/reset-password/") {
            page = ResetPasswordPage;
            pageProps = { token: searchParams.get("t") };
        } else if ($visiblePage === "/forgot-password/") {
            page = ForgotPasswordPage;
            pageProps = {};
        } else if ($visiblePage === "/accept-graph/") {
            page = AcceptSendPage;
            pageProps = {
                token: searchParams.get("t"),
                graphId: searchParams.get("g"),
            };
        } else if ($visiblePage === "/support-icon/") {
            page = SupportIconPage;
            pageProps = {};
        } else if ($visiblePage.match(graphUrl)) {
            page = GraphPage;
            pageProps = { graphId: $visiblePage.match(graphUrl)[1] };
        } else if ($visiblePage.match(exampleUrl)) {
            page = GraphPage;
            pageProps = {
                graphId: $visiblePage.match(exampleUrl)[1],
                isExample: true,
            };
        } else if ($visiblePage.match(plotUrl)) {
            page = PlotPage;
            pageProps = {
                graphId: $visiblePage.match(plotUrl)[1],
                stopKey: parseInt(searchParams.get("stopKey")),
                isAutoTitle: searchParams.get("iat") !== "f",
                isAutoSubTitle: searchParams.get("iast") !== "f",
                title: searchParams.get("t"),
                subtitle: searchParams.get("st"),
                xAxis: searchParams.get("xa"),
                xHigh: searchParams.get("xh"),
                xLow: searchParams.get("xl"),
                xDataPoints: searchParams.get("xd") || "100",
                yTraces: searchParams.getAll("yt"),
                y2Traces: searchParams.getAll("y2t"),
                yIsFixed: searchParams.get("yif") === "t",
                y2IsFixed: searchParams.get("y2if") === "t",
                yStart: searchParams.get("ys"),
                yEnd: searchParams.get("ye"),
                y2Start: searchParams.get("y2s"),
                y2End: searchParams.get("y2e"),
            };
        } else {
            page = NotFoundPage;
            pageProps = {};
        }
    }

    // Set the modal
    $: {
        if ($visibleModal !== null) {
            if ($visibleModal.name === "confirm-lose-changes") {
                modal = ConfirmLoseChangesModal;
            } else if ($visibleModal.name === "sign-in") {
                modal = SignInModal;
            } else if ($visibleModal.name === "delete-graph") {
                modal = DeleteGraphModal;
            } else if ($visibleModal.name === "duplicate-graph") {
                modal = DuplicateGraphModal;
            } else if ($visibleModal.name === "move-graph") {
                modal = MoveGraphModal;
            } else if ($visibleModal.name === "rename-graph") {
                modal = RenameGraphModal;
            } else if ($visibleModal.name === "new-graph") {
                modal = NewGraphModal;
            } else if ($visibleModal.name === "choose-folder") {
                modal = ChooseFolderModal;
            } else if ($visibleModal.name === "export-graph") {
                modal = ExportGraphModal;
            } else if ($visibleModal.name === "change-email") {
                modal = ChangeEmailModal;
            } else if ($visibleModal.name === "view-component") {
                modal = ViewComponentModal;
            } else if ($visibleModal.name === "edit-text") {
                modal = EditTextModal;
            } else if ($visibleModal.name === "secret-import") {
                modal = SecretImportModal;
            } else if ($visibleModal.name === "send-graph") {
                modal = SendGraphModal;
            } else if ($visibleModal.name === "upsell") {
                modal = UpsellModal;
            } else if ($visibleModal.name === "subscription-pro") {
                modal = SubscriptionProModal;
            } else if ($visibleModal.name === "subscription-standard") {
                modal = SubscriptionStandardModal;
            }
            modalProps = $visibleModal.params || {};
        } else {
            modal = null;
            modalProps = {};
        }
    }
</script>

<svelte:component this={page} {...pageProps} />
<svelte:component this={modal} {...modalProps} />
<ul class="fixed left-0 bottom-0 m-2 z-10">
    {#each $toasts as toast, idx (toast._key)}
        <li class="mb-2">
            <Toast
                heading={toast.heading}
                body={toast.body}
                theme={toast.theme}
                duration={toast.duration}
                onClick={() => removeToast(idx)}
                selfDestruct={() => removeToast(idx)}
            />
        </li>
    {/each}
</ul>
