import Component from './utils/component.js';
import iconUrl from '../images/amplifier.svg';

export default class Amplifier extends Component {
    category = "AMP";

    constructor(args) {
        args.defaults = [
            { name: "Gain", value: 15 },
            { name: "OIP2", value: 99 },
            { name: "OIP3", value: 99 },
            { name: "OP1dB", value: 15, isEnabled: true, isWatched: true },
            { name: "OPsat", value: 16, isEnabled: true },
            { name: "IIP2", value: 84 },
            { name: "IIP3", value: 84 },
            { name: "IP1dB", value: 1, isEnabled: true, isWatched: true },
            { name: "IPsat", value: 2, isEnabled: true },
            { name: "NF", value: 3 },
            { name: "Idc", value: 60, unit: "mA", isEnabled: true, isWatched: false, precision: 0 },
            { name: "DevSply", value: 5, unit: "Vdc", isEnabled: true, isWatched: false },
            { name: "BusSply", value: 12, unit: "Vdc", isEnabled: false, isWatched: false },
            { name: "OP1BackOff", value: 6 },
            { name: "IP1BackOff", value: 4 },
            { name: "Te", value: 290, unit: "°K", isEnabled: false, isWatched: false, isHidden: true },
            { name: "Device Type", value: "Default", unit: "", choices: ["Default", "TX", "RX"], isEnabled: true, isEnabledEditable: false, isWatched: false },
        ];
        args.name = args.name || "Amplifier";
        args.summary = args.summary || "RF amplifier with linear, nonlinear and DC parameters.";
        args.icon = iconUrl;
        super(args);
    }

}
