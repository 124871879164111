<script>
    import { isValidAuthToken } from "../stores.js";
    import NavBar from "./components/NavBar.svelte";
    import GuestNavBar from "./components/GuestNavBar.svelte";
    import Footer from "./components/Footer.svelte";

    export let showFooter = true;
    export let bgColour = "white";
    export let padding = "4";

</script>

<div class="p-0 flex flex-col h-full">
    {#if $isValidAuthToken}
        <NavBar />    
    {:else}
        <GuestNavBar />
    {/if}
    
    <div class="grow p-{padding} bg-{bgColour}">
        <slot name="body" />
    </div>
    {#if showFooter}
        <Footer />
    {/if}
</div>
