import * as go from "gojs/release/go-module.js";


function isNode(node) {
    return node instanceof go.Node && node.category !== "NOTE";
}


export function* getAllNodesInGroup(group) {
    let it = group.memberParts.iterator;
    while (it.next()) {
        let n = it.value;
        if (isNode(n)) {
            yield n;
        }
    }
}

export function* getAllNodesToSource(node) {
    let found = new Set();
    for (const n of _getAllNodesToSource(node)) {
        if (!found.has(n.key)) {
            found.add(n.key);
            yield n;
        }
    }
}

export function* _getAllNodesToSource(node) {
    if (!node) return;
    yield node;
    let it = node.findLinksInto().iterator;
    while (it.next()) {
        let n = it.value.fromNode;
        yield* _getAllNodesToSource(n);
    }
}

export function* getAllDescendents(node, found) {
    found = found || new Set();
    yield node;
    let it = node.findLinksOutOf().iterator;
    while (it.next()) {
        let n = it.value.toNode;
        if (!!node && !found.has(n.key)) {
            found.add(n.key);
            yield* getAllDescendents(n, found);
        }
    }
}


export function* getAllNodesInSelection(selection) {
    let it = selection.iterator;
    while (it.next()) {
        let n = it.value;
        if (n instanceof go.Group) {
            yield* getAllNodesInGroup(n);
        } else if (isNode(n)) {
            yield n;
        }
    }
}

export function getSource(node) {
    for (const n of _getAllNodesToSource(node)) {
        if (n.category === "SRC") return n;
    }
}
