import Decimal from 'decimal.js';
import Component from './utils/component.js';
import Context from "./utils/context.js";
import { convertToUnit } from "./utils/parameter.js";
import iconUrl from '../images/splitter.svg';
import Parameters from './utils/parameters.js';

const LOSS = Decimal.log10(2).times(10);

export default class Splitter extends Component {
    category = "SPL";

    constructor(args) {
        args.defaults = [
            { name: "Size", value: 2, unit: "", precision: 0, choices: [2, 3, 4, 8], isEnabled: true, isEnabledEditable: false, isWatched: false },
            { name: "Gain", value: -0.25, cascadeValue: [{ name: "NF", enabled: true, hidden: false }] },
            { name: "OIP2", value: 98.75 },
            { name: "OIP3", value: 98.75 },
            { name: "OP1dB", value: 97.5 },
            { name: "OPsat", value: 97.75 },
            { name: "IIP2", value: 99 },
            { name: "IIP3", value: 99 },
            { name: "IP1dB", value: 98.75 },
            { name: "IPsat", value: 99 },
            { name: "NF", value: 0.25 },
            { name: "Idc", value: 0, unit: "mA", isEnabled: false, isWatched: false, precision: 0 },
            { name: "DevSply", value: 0, unit: "Vdc", isEnabled: false, isWatched: false },
            { name: "BusSply", value: 0, unit: "Vdc", isEnabled: false, isWatched: false },
            { name: "OP1BackOff", value: 2 },
            { name: "IP1BackOff", value: 0 },
            { name: "Te", value: 290, unit: "°K", isEnabled: false, isWatched: false, isHidden: true },
            { name: "Device Type", value: "Default", unit: "", choices: ["Default", "TX", "RX"], isEnabled: true, isEnabledEditable: false, isWatched: false },
            { name: "Loss@1", value: LOSS, unit: "dB", isEnabled: true, isWatched: false, isEnabledEditable: false },
            { name: "Loss@2", value: LOSS, unit: "dB", isEnabled: true, isWatched: false, isEnabledEditable: false },
        ]
        args.name = args.name || "Splitter";
        args.summary = args.summary || "RF splitter that defaults with theoretical losses for 2, 3, 4 or 8 ways.";
        args.icon = iconUrl;
        super(args);
    }

    _simulate(parameters, inputs) {
        let input = inputs[0];

        let outputs = [];
        let deviceLoss = parameters.get("Gain").getValue().abs();

        let size = parameters.get("Size").getValue();
        for (let idx = 1; idx <= size; idx++) {
            let output = new Context(input);

            let splittingLoss = parameters.get(`Loss@${idx}`).getValue();
            let maxLoss = splittingLoss.add(deviceLoss);

            output.get("Noise_Pout").defer(() => {
                let thisC_NF = output.get("C_NF").getValue();
                let previousC_NF = input.get("C_NF").getValue();
                let noisePout = input.get("Noise_Pout").getValue().minus(maxLoss).add(thisC_NF).minus(previousC_NF);
                let noiseFloor = input.get("Noise Floor").getValue();
                if (noisePout.lessThan(noiseFloor)) {
                    noisePout = noiseFloor;
                }
                return noisePout;
            });

            output.get("C_NF").defer(() => {
                let nf = parameters.get("NF").getValue();
                nf = nf.add(splittingLoss);
                nf = convertToUnit(nf, "*", "linear");
                let previousC_NF = input.get("C_NF").getValue("linear");
                let previousC_Gain = input.get("C_Gain").getValue("linear");
                let cNF = previousC_NF.add(nf.minus(1).dividedBy(previousC_Gain));
                return convertToUnit(cNF, "linear", "*");
            });

            output.get(`C_Gain`).defer(() => input.get("C_Gain").getValue().minus(maxLoss));

            output.get(`Pout`).defer(() => input.get("Pout").getValue().minus(maxLoss));
            // TODO: We need to calculate Pcomp
            output.get(`Pcomp`).defer(() => 0);

            output.get("C_OIP2").defer(() => input.get("C_OIP2").getValue().minus(maxLoss));
            output.get("C_OIP3").defer(() => input.get("C_OIP3").getValue().minus(maxLoss));
            output.get("C_OP1dB").defer(() => input.get("C_OP1dB").getValue().minus(maxLoss));

            output.get("C_IIP2").defer(() => input.get("C_IIP2").getValue().minus(maxLoss));
            output.get("C_IIP3").defer(() => input.get("C_IIP3").getValue().minus(maxLoss));
            output.get("C_IP1dB").defer(() => input.get("C_IP1dB").getValue().minus(maxLoss));

            output.get("Linear_Gain").defer(() => this.calculateLinearGain(parameters, input, output));
            output.get("Total_Pcomp").defer(() => this.calculateTotalPcomp(parameters, input, output));

            output.get("Splitters").defer(() => {
                let splitters = [...input.get("Splitters").getValue()];
                splitters.push({
                    key: this.key,
                    size: parameters.get("Size").getValue(),
                    input: input,
                    output: output,
                });
                return splitters;
            });

            output.all.forEach(p => {
                p._branch = idx;
            })

            outputs.push(output);
        }
        return outputs;
    }

    getValue(parameters, name) {
        let param = parameters.get(name);
        return param.getValue();
    }

    calculatePout(input) {
        return input.get("Pout");
    }

    outPortIds() {
        let ids = [];
        let size = this.parameters.get("Size").getValue();
        let idx = 1;
        while (idx <= size) {
            ids.push(`out-${idx}`);
            idx++;
        }
        return ids;
    }

    *bubbledChanges(param, field, value) {
        yield* super.bubbledChanges(param, field, value);
        if (param.name === "Size" && field === "value") {
            yield* this.bubbledLossChanges(value);
        }
    }

    *bubbledLossChanges(newSize) {
        newSize = parseInt(newSize);
        let splittingLoss = Decimal.log10(newSize).times(10);
        let name, param, idx
        for (idx = 1; idx <= newSize; idx++) {
            name = `Loss@${idx}`;
            param = this.parameters.get(name);
            if (!param) {
                param = Parameters.parameterFactory({
                    name: name,
                    value: splittingLoss,
                    unit: "dB",
                    isEnabled: true,
                    isEnabledEditable: false
                });
                yield [this.parameters.all, "ADDED", param];
            } else {
                yield [param, "value", splittingLoss];
            }
        }
        for (idx = newSize + 1; idx <= 8; idx++) {
            name = `Loss@${idx}`;
            let rank = this.parameters.indexOf(name)
            if (rank !== -1) {
                yield [this.parameters.all, "REMOVED", rank];
            }
        }
    }

}
