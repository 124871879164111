import iconUrl from '../images/group-circle.svg';

export default class Group {
    icon = iconUrl;
    category = "GRP";

    constructor(args) {
        this.key = args.key;
        this.location = args.location;
        this.group = args.group;
        this.name = args.name;
        this.isGroup = true;
    }

    copy(key) {
        return new Group({ key: key, location: this.location, name: this.name });
    }

    updateOutputs(diagram) {
        let grp = diagram.findNodeForKey(this.key);
        let subGraphParts = grp.findSubGraphParts();
        let externalLinks = grp.findExternalLinksConnected();
        let lastNode, watchedOutputs = [];
        while (externalLinks.next()) {
            let link = externalLinks.value;
            if (subGraphParts.contains(link.fromNode)) {
                lastNode = link.fromNode;
            }
        }
        if (!!lastNode) {
            watchedOutputs = lastNode.data._watchedOutputs;
        }
        diagram.model.setDataProperty(this, "_watchedOutputs", watchedOutputs);
    }

    change(diagram, param, changes) {
        diagram.startTransaction("set property");

        for (let i = 0; i < changes.length; i++) {
            let field = changes[i][0];
            let value = changes[i][1];
            diagram.model.setDataProperty(param, field, value);
        }

        diagram.commitTransaction("set property");
    }

}
