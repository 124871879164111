<script>
    export let name;
    export let label;
    export let hideLabel = false;
    export let helpText = null;
    export let placeholder = null;
    export let onChange = null;
    export let error;
    export let required = false;
    export let value;
    export let type = "text";
    export let disabled = false;
    export let labelTheme = "text-sm font-medium text-gray-700";
    export let autofocus = false;
    export let min = null;
    export let max = null;

    let cls = "";

    const focus = (el) => {
        if (autofocus) {
            el.focus();
            el.select();
        }
    }

    $: {
        cls = "rounded w-full";
        if (!!error) {
            cls = `${cls} !border-red-500`;
        }
    }

    $: {
        if (!disabled && !!min && parseFloat(value) < min) {
            error = `Value must be greater than ${min}.`;
        } else if (!disabled && !!max && parseFloat(value) > max) {
            error = `Value must be smaller than ${max}.`;
        } else {
            error = null;
        }
    }
</script>

<div>
    <label
        for={name}
        class="block mb-1 {labelTheme}"
        class:text-red-500={!!error}
        class:sr-only={hideLabel}
    >
        {label}
        {#if required}<span class="text-red-500">*</span>{/if}
    </label>
    <div class="">
        {#if type === "email"}
            <input
                on:change={onChange}
                bind:value
                type="email"
                class={cls}
                class:bg-gray-100={disabled}
                id={name}
                required={required || null}
                {placeholder}
                {disabled}
                use:focus
                {...$$restProps}
            />
        {:else if type === "password"}
            <input
                on:change={onChange}
                bind:value
                type="password"
                class={cls}
                class:bg-gray-100={disabled}
                id={name}
                required={required || null}
                {placeholder}
                {disabled}
                use:focus
                {...$$restProps}
            />
        {:else if type === "search"}
            <input
                on:change={onChange}
                bind:value
                type="search"
                class={cls}
                class:bg-gray-100={disabled}
                id={name}
                required={required || null}
                {placeholder}
                {disabled}
                use:focus
                {...$$restProps}
            />
        {:else if type === "number"}
            <input
                on:change={onChange}
                bind:value
                type="number"
                class={cls}
                class:bg-gray-100={disabled}
                id={name}
                required={required || null}
                {placeholder}
                {disabled}
                use:focus
                {...$$restProps}
            />
        {:else if type === "hidden"}
            <input
                on:change={onChange}
                bind:value
                type="hidden"
                id={name}
                {...$$restProps}
            />
        {:else}
            <input
                on:change={onChange}
                bind:value
                type="text"
                class={cls}
                class:bg-gray-100={disabled}
                id={name}
                required={required || null}
                {placeholder}
                {disabled}
                use:focus
                {...$$restProps}
            />
        {/if}
    </div>
    {#if helpText}
        <div class="text-gray-500">
            <small>{helpText}</small>
        </div>
    {/if}
    {#if error}
        <div class="text-red-500">
            <small>{error}</small>
        </div>
    {/if}
</div>
