<script>
    import { visiblePage } from "../../stores.js";
    import Button from "./Button.svelte";
    import logoUrl from "../../images/logo.png";

    const handleSignin = () => {
        visiblePage.set("/sign-in/");
    };

    const handleSignup = () => {
        visiblePage.set("/sign-up/");
    };
</script>

<ul
    class="flex flex-row justify-between items-center text-white bg-black px-3 py-1"
>
    <li class="py-2">
        <a href="/">
            <img src="{logoUrl}" alt="RFGraph logo. Black circle with a white sine wave inside, then the word R F Graph." class="h-[26px] inline-block "/>
        </a>
        {#if import.meta.env.VITE_ENV !== "production"}<span class="text-xs uppercase text-brand">{import.meta.env.VITE_ENV || ""}</span>{/if}
    </li>
    <li class="py-2">
        <Button
            text="Sign in"
            onClick={handleSignin}
            theme="anchor-white"
            toUpperCase={false}
            boldText={false}
        />
        &middot;
        <Button
            text="Create account"
            onClick={handleSignup}
            theme="anchor-white"
            toUpperCase={false}
            boldText={false}
        />
    </li>
</ul>
