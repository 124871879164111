<script>
    import { authToken, isBusy, visiblePage, accountStatus, user } from "../stores.js";
    import { createBillingPortal, createUpdatePaymentMethodsPage, requestPasswordReset } from "../api.js";
    import Button from "./components/Button.svelte";
    import Page from "./Page.svelte";
    import Section from "./components/Section.svelte";

    let email = "";
    let subscriptionTier = "";
    let subscriptionExpiry = "";
    let subscriptionStatus = "";
    let subscriptionExpiryLabel = "Renews On";
    let isVerified = true;

    const handleManageBilling = () => {
        isBusy.set(true);
        createBillingPortal($authToken)
            .then((resp) => {
                window.location = resp.url;
            })
            .finally(() => isBusy.set(false));
    };

    const handleUpdatePaymentMethod = () => {
        isBusy.set(true);
        createUpdatePaymentMethodsPage($authToken)
            .then((resp) => {
                window.location = resp.url;
            })
            .finally(() => isBusy.set(false));
    };

    const handleChangePassword = () => {
        requestPasswordReset(email).then(() => {
            visiblePage.set("/reset/requested/");
        });
    };

    const makePrettyStatus = (status) => {
        if (status === "in_trial") {
            return "(Free Trial)";
        }
        if (status === "cancelled" || status === "non_renewing") {
            return "(Cancelled)";
        }
        return "";
    }

    const makePrettyExpiryLabel = (status, expiresOn) => {
        if (status === "in_trial") {
            return "Trial Ends On";
        }
        if (status === "cancelled" || status === "non_renewing") {
            const expiresInPast = expiresOn < new Date();
            if (expiresInPast) {
                return "Expired On";
            }
            return "Expires On";
        }
        return "Renews On";
    }

    $: subscriptionTier = $accountStatus === "pro" || $accountStatus == "cancelled" || $accountStatus == "trial" ? "Pro" : "Standard";
    $: subscriptionStatus = makePrettyStatus($user?.subscription_status);
    $: subscriptionExpiry = new Date($user?.subscription_expiry).toLocaleDateString()
    $: subscriptionExpiryLabel = makePrettyExpiryLabel($user?.subscription_status, new Date($user?.subscription_expiry))

</script>

<Page bgColour="gray-100" padding="0">
    <div slot="body">
        <div
            class="bg-white p-4 shadow-b shadow-lg flex flex-row justify-between"
        >
            <h1 class="text-2xl">Account</h1>
        </div>
        <div class="max-w-3xl mx-auto mt-4">
            <Section>
                <h2 slot="header" class="text-lg font-bold">Details</h2>
                <div slot="body">
                    <div class="py-2 grid grid-cols-4 gap-4 px-6">
                        <div
                            class="text-sm font-medium text-gray-500 text-right"
                        >
                            Email address
                        </div>
                        <div class="mt-1 text-sm text-gray-900 mt-0 col-span-2">
                            {$user?.email}
                        </div>
                        <div class="text-right text-sm">
                            <Button
                                text="Change Email"
                                icon="fa-solid fa-envelope"
                                toUpperCase={false}
                                size="small"
                                modal="change-email"
                            />
                        </div>
                        <div
                            class="text-sm font-medium text-gray-500 text-right"
                        >
                            Password
                        </div>
                        <div class="mt-1 text-sm text-gray-900 mt-0 col-span-2">
                            ************
                        </div>
                        <div class="text-right text-sm">
                            <Button
                                text="Change Password"
                                icon="fa-solid fa-key"
                                toUpperCase={false}
                                size="small"
                                onClick={handleChangePassword}
                            />
                        </div>
                    </div>
                </div>
            </Section>

            <Section>
                <h2 slot="header" class="text-lg font-bold">Billing</h2>
                <div slot="body">
                    <div class="grid grid-cols-4 gap-4 px-6">
                        <div class="text-sm font-medium text-gray-500 text-right">
                            Subscription
                        </div>
                        <div class="mt-1 text-sm text-gray-900 mt-0 col-span-2">
                            {subscriptionTier}
                            {subscriptionStatus}
                        </div>
                        <div class="text-right text-sm">
                            <Button
                                text="Change Plan"
                                icon="fal fa-turn-up"
                                toUpperCase={false}
                                size="small"
                                url="/account/subscription/"
                            />
                        </div>
                        <div class="text-sm font-medium text-gray-500 text-right">
                            {subscriptionExpiryLabel}
                        </div>
                        <div class="mt-1 text-sm text-gray-900 mt-0 col-span-3">
                            {subscriptionExpiry}
                        </div>
                        <div class="text-sm font-medium text-gray-500 text-right">
                            Card
                        </div>
                        <div class="mt-1 text-sm text-gray-900 mt-0 col-span-2">
                            **** **** **** ****
                        </div>
                        <div class="text-right text-sm">
                            <Button
                                text="Change Card"
                                icon="fal fa-credit-card"
                                toUpperCase={false}
                                size="small"
                                onClick={handleUpdatePaymentMethod}
                            />
                        </div>
                    </div>
                </div>

                <p slot="footer" class="text-right">
                    {#if isVerified}
                        <Button
                            text="Manage Billing"
                            icon="fal fa-money-bill"
                            type="button"
                            onClick={handleManageBilling}
                        />
                    {/if}
                </p>
            </Section>
        </div>
    </div>
</Page>
