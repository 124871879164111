import Parameter from "../utils/parameter.js";

export default class OP1dB extends Parameter {
    constructor(initialValue, overrides) {
        let args = overrides || {};
        args.name = "OP1dB";
        args.isMultiValued = true;
        args.value = initialValue;
        args.unit = Parameter.valueOrDefault(args.unit, "dBm");

        args.minValue = Parameter.valueOrDefault(args.minValue, -99.99);
        args.maxValue = Parameter.valueOrDefault(args.maxValue, 99.99);

        args.isEnabled = Parameter.valueOrDefault(args.isEnabled, false);
        args.isWatched = Parameter.valueOrDefault(args.isWatched, false);

        args.cascadeWatched = Parameter.valueOrDefault(args.cascadeWatched, ["IP1dB"]);
        args.cascadeEnabled = Parameter.valueOrDefault(args.cascadeEnabled, ["IP1dB", "IPsat", "OPsat"]);
        args.cascadeMVEnabled = Parameter.valueOrDefault(args.cascadeMVEnabled, ["IP1dB", "IPsat", "OPsat"]);
        args.cascadeMV = Parameter.valueOrDefault(args.cascadeMV, []);

        super(args);

        this.cascadeMV = this.mergeCascadeMV([
            {
                name: "OPsat",
                direction: "below",
                delta: 0.5,
                minDelta: 0.25,
                maxDelta: 10,
                enabled: true,
                cascadeDelta: {
                    OPsat: "OP1dB",
                    IP1dB: "IPsat",
                    IPsat: "IP1dB"
                }
            },
            { name: "IP1dB", enabled: true, hidden: true, calculateDelta: 'calculateIP1dBDelta' },
            { name: "IPsat", enabled: true, hidden: true, calculateDelta: 'calculateIPsatDelta' },
        ], this.cascadeMV);
    }

    calculateIP1dBDelta(comp, multiValue) {
        let gain = comp.interpolateAtFreqTemp("Gain", multiValue.freq, multiValue.temp);
        return gain.times(-1).add(1);
    }

    calculateIPsatDelta(comp, multiValue) {
        let opsat = comp.interpolateAtFreqTemp("OPsat", multiValue.freq, multiValue.temp);
        let op1db = multiValue.value;
        let ip1dbDelta = this.calculateIP1dBDelta(comp, multiValue);
        return ip1dbDelta.plus(opsat.minus(op1db));
    }

    validate(node) {
        let errors = {};

        let pSat = node.parameters.get("OPsat");
        this.multiValues.forEach(mv => {
            const satValue = node.interpolateAtFreqTemp(pSat, mv.freq, mv.temp);
            let difference = satValue.minus(mv.value);
            if (difference.lessThan(0.25)) {
                this._validateAddError(errors, ["multiValues", mv.freq, mv.temp], `At this multi-value point, OP1dB must be ${satValue.minus(0.25).toFixed(2)}dBm or less`);
            } else if (difference.greaterThan(10)) {
                this._validateAddError(errors, ["multiValues", mv.freq, mv.temp], `At this multi-value point, OP1dB must be ${satValue.minus(10).toFixed(2)}dBm or greater`);
            }
        });

        return errors;
    }
}
