<script>
    import { authToken, isBusy, visibleModal } from "../stores.js";
    import { updateGraph } from "../api.js";
    import Modal from "./Modal.svelte";
    import ChooseFolder from "./_ChooseFolder.svelte";

    export let graphId;
    export let graphName;
    export let graphDirname;
    export let afterConfirm;

    let newFolder;
    let errors = {};

    const handleSubmit = () => {
        if ($isBusy) {
            return;
        }
        isBusy.set(true);

        let req = updateGraph($authToken, graphId, graphName, newFolder);
        req.then((resp) => {
            if (!resp.error) {
                visibleModal.set(null);
                if (!!afterConfirm) {
                    afterConfirm(resp);
                }
            } else {
                errors = resp.fields;
            }
        }).finally(() => isBusy.set(false));
    };
</script>

<Modal
    title="Move {graphName}?"
    icon="far fa-folder-arrow-up fa-2x"
    confirmIcon="far fa-folder-arrow-up"
    confirmText="Move"
    onConfirm={handleSubmit}
>
    <form on:submit|preventDefault={handleSubmit}>
        <button class="hidden" type="submit">Submit</button>
        <ChooseFolder initialFolder={graphDirname} bind:newFolder {errors} />
    </form>
</Modal>
