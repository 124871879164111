<script>
    import { onMount } from "svelte";
    import { simplePlot } from "../plot.js";

    const PRECISION = parseInt(import.meta.env.VITE_DECIMAL_PRECISION || 2);

    export let file;

    let canvas;

    onMount(() => {
        let x = [], y = [];
        file.data.forEach(d => {
            x.push(d.freq.toFixed(PRECISION));
            y.push(d.value.toFixed(PRECISION));
        });
        let trace = {
            type: "scatter",
            name: "Gain",
            mode: "lines",
            x: x,
            y: y,
            marker: {
                color: "#0ad6cc",
            },
            hovertemplate: `Frequency (MHz): %{x:.0f}<br>Gain (dB): %{y:.${PRECISION}f}<extra></extra>`,
        }
        simplePlot(canvas, trace, 470, 75);
    });
</script>

<div>
    <div bind:this={canvas}></div>
</div>
