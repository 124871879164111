<script>
    import {
        authToken,
        visibleModal,
        isBusy,
        visiblePage,
        user,
    } from "../stores.js";
    import { login, ping } from "../api.js";
    import Modal from "./Modal.svelte";
    import Input from "../pages/components/form/Input.svelte";

    let email = "";
    let password = "";
    let errors = {};

    const handleSubmit = () => {
        if ($isBusy) {
            return;
        }
        isBusy.set(true);
        let originalEmail = $user.email;
        login(email, password)
            .then((resp) => {
                if (!!resp.access_token) {
                    if (originalEmail !== email) {
                        visiblePage.set("/");
                    }
                    authToken.set(resp.access_token);
                } else {
                    errors = resp.fields;
                }
            })
            .finally(() => isBusy.set(false));
    };

    const unsubTokenChanges = authToken.subscribe(value => {
        ping(value).then(resp => {
            if (resp.msg === "pong") {
                unsubTokenChanges();
                visibleModal.set(null);
            }
        })
    });
</script>

<Modal
    title="Sign In"
    icon="far fa-sign-in-alt fa-2x"
    onConfirm={handleSubmit}
    confirmText="Sign In"
    confirmIcon="far fa-sign-in-alt"
>
    <form on:submit|preventDefault={handleSubmit}>
        <button type="submit" class="hidden">Submit</button>
        <div class="mt-2">
            <Input
                bind:value={email}
                error={errors.email}
                label="Email Address"
                name="signin-email"
                helpText="The email address you signed up with"
                required={true}
                type="email"
            />
            <div class="mt-2">
                <Input
                    bind:value={password}
                    error={errors.password}
                    label="Password"
                    name="signin-password"
                    required={true}
                    type="password"
                />
            </div>
        </div>
    </form>
</Modal>
