import * as go from "gojs/release/go-module.js";
import settings from '../settings.js';

let $ = go.GraphObject.make;

let tempLink = $(
    go.Link,
    {
        layerName: "Tool"
    },
    $(
        go.Shape,
        {
            stroke: settings.primaryColor,
            strokeWidth: 3
        }
    ),
    $(
        go.Shape,
        {
            angle: 180,
            fill: settings.primaryColor,
            stroke: "black",
            toArrow: "ToHalfEllipse"
        }
    )
);

let tempFromNode = $(
    go.Node,
    {
        layerName: "Tool"
    }
);


let tempToNode = $(
    go.Node,
    {
        layerName: "Tool"
    }
);

const init = (diagram) => {
    diagram.toolManager.linkingTool.temporaryLink = tempLink;
    diagram.toolManager.linkingTool.temporaryFromNode = tempFromNode;
    diagram.toolManager.linkingTool.temporaryFromPort = tempFromNode.port;
    diagram.toolManager.linkingTool.temporaryToNode = tempToNode;
    diagram.toolManager.linkingTool.temporaryToPort = tempToNode.port;

    diagram.toolManager.relinkingTool.temporaryLink = tempLink;
    diagram.toolManager.relinkingTool.temporaryFromNode = tempFromNode;
    diagram.toolManager.relinkingTool.temporaryFromPort = tempFromNode.port;
    diagram.toolManager.relinkingTool.temporaryToNode = tempToNode;
    diagram.toolManager.relinkingTool.temporaryToPort = tempToNode.port;
    diagram.toolManager.relinkingTool.toHandleArchetype = $(
        go.Shape,
        "HalfEllipse",
        {
            angle: 180,
            cursor: "pointer",
            fill: settings.primaryColor,
            stroke: "black",
            width: 9,
            height: 18,
            segmentIndex: -1,
        }
    );
}

export default init;
