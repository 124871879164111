<script>
    import Collapsable from "../Collapsable.svelte";
    import probeIconUrl from "../../../images/probe.svg";
    import probe from "../../../probe.js";
    import ProbePlot from "./ProbePlot.svelte";
    import Input from "../form/Input.svelte";

    export let candidates;
    export let collapsedStates;
    export let group = null;
    export let onChange;

    let calculations = [];
    let components = [];
    let source = null;
    let probedNode = null;
    let probeResults;

    $: {
        if (candidates.length !== 0) {
            probeResults = probe(candidates);
            components = probeResults.nodes;
            calculations = Array.from(probeResults.calculations.getVisible());
            source = probeResults.source;
            probedNode = probeResults.probedNode;
        }
    }
</script>

{#if !!group}
    <div class="flex flex-row m-2 items-center">
        <img
            src={group.data.icon}
            alt="Group icon"
            width="50px"
        />
        <span class="flex-grow ml-2">
            <Input
                onChange={(e) => onChange(group.data, "name", e.target.value, group.data)}
                value={group.data.name}
                error={null}
                label="Name"
                hideLabel={true}
                name="inspector-name"
                type="text"
            />
        </span>
    </div>
{:else}
    <div class="flex flex-row m-2 items-center">
        <img
            src={probeIconUrl}
            alt="Probe icon"
            style="height:2em"
        />
        <span class="flex-grow ml-2">Probe</span>
    </div>
{/if}

<Collapsable title="Calculations" bind:isClosed={collapsedStates.calculations}>
    <div class="p-2 grid grid-cols-[auto,auto,auto] gap-1 items-center">
        {#each calculations as param (param.name)}
            <span class="text-left font-semibold"
                >{param.name}</span
            >
            <span class="text-right">{param.getPretty()}</span>
            <span class="">{param.unit}</span>
        {/each}
    </div>
</Collapsable>

<Collapsable title="Components" bind:isClosed={collapsedStates.probeComponents}>
    <div class="p-2 grid grid-cols-[40px,auto] gap-1 items-center">
        {#each components as comp}
            <div class="text-center">
                <img
                    src={comp.data.icon}
                    alt="{comp.data.category} icon"
                    width="25px"
                />
            </div>
            <button class="text-left hover:text-brand" on:click={() => window.diagram.zoomToNode(comp)}>{comp.data.name} (ID: {Math.abs(comp.data.key)})</button>
        {/each}
    </div>
</Collapsable>

{#if !!source && !!probedNode}
    <Collapsable title="Plot" bind:isClosed={collapsedStates.probePlot}>
        <ProbePlot {source} node={probedNode} {components} calculations={probeResults.calculations} />
    </Collapsable>
{/if}
