    <script>
    import { onMount } from "svelte";
    import Select from "./components/form/Select.svelte";
    import Input from "./components/form/Input.svelte";
    import Plotly from "plotly.js-dist";
    import logoUrl from "../images/logo-lockup-black.png";
    import Button from "./components/Button.svelte";
    import Checkbox from "./components/form/Checkbox.svelte";
    import Collapsable from "./components/Collapsable.svelte";

    const PRECISION = parseInt(import.meta.env.VITE_DECIMAL_PRECISION || 2);

    export let graphId;
    export let stopKey;
    export let isAutoTitle = true;
    export let isAutoSubTitle = true;
    export let title;
    export let subtitle;
    export let xAxis;
    export let xHigh;
    export let xLow;
    export let yTraces;
    export let y2Traces = [];
    export let yIsFixed = false;
    export let y2IsFixed = false;
    export let yStart;
    export let yEnd;
    export let y2Start;
    export let y2End;
    export let xDataPoints = "100";

    let showSettings = true;

    let colors = [
        // https://github.com/plotly/plotly.js/blob/master/src/components/color/attributes.js
        '#1f77b4',  // muted blue
        '#ff7f0e',  // safety orange
        '#2ca02c',  // cooked asparagus green
        '#d62728',  // brick red
        '#9467bd',  // muted purple
        '#8c564b',  // chestnut brown
        '#e377c2',  // raspberry yogurt pink
        '#7f7f7f',  // middle gray
        '#bcbd22',  // curry yellow-green
        '#17becf'   // blue-teal
    ];

    let canvas;
    let done = true;
    let x = [];
    let y = [];
    let y2 = [];

    let yAxisChoices = [];

    let xAxisChoices = [];
    let xMin;
    let xMax;
    let xLowError = null;
    let xHighError = null;
    let yLowError = null;
    let yHighError = null;
    let y2LowError = null;
    let y2HighError = null;

    let queue;

    let ranges;
    let isInitialised = false;
    let needsRescale = ["y"];

    let graphName;
    let nodeName;
    let sourceTemp;
    let sourcePower;
    let sourceFreq;

    let isDisconnected = false;

    let annotations = [{
        text: subtitle,
        font: {
            size: 13,
        },
        showarrow: false,
        align: 'center',
        x: 0.5,
        y: 1,
        xref: 'paper',
        yref: 'paper',
        yshift: 45,
    }];

    const worker = new Worker(
        new URL("../worker/plot.js", import.meta.url),
        {
            type: 'module'
        }
    );

    worker.onerror = () => {
        console.error("Something went wrong simulating in the background");
    }

    worker.onmessage = (msg) => {
        if (msg.data.isDone) onDone(msg.data.data);
    }

    let bc = new BroadcastChannel(`plot-${graphId}`);
    bc.onmessage = evt => {
        if (evt.data.type === "initResponse") {
            if (isInitialised === true) return;
            isInitialised = true;
            done = false;
            x = [];
            y = [];
            y2 = [];

            let details = evt.data.details;

            graphName = evt.data.graphName;
            nodeName = evt.data.nodeName;
            sourceTemp = details.sourceTemp;
            sourcePower = details.sourcePower;
            sourceFreq = details.sourceFreq;
            yTraces = (yTraces.length === 0) ? details.yParam : yTraces;
            xAxis = (xAxis === null) ? details.xParam : xAxis;
            xHigh = (xHigh === null) ? details.xHigh : xHigh;
            xLow = (xLow === null) ? details.xLow : xLow;

            queue = details.queue;
            xAxisChoices = evt.data.xAxisChoices;
            yAxisChoices = evt.data.yAxisChoices;
            ranges = details.ranges;

            details.yParam = yTraces;
            details.y2Param = y2Traces;
            details.xParam = xAxis;
            details.xHigh = parseInt(xHigh);
            details.xLow = parseInt(xLow);

            worker.postMessage(details);
            updateData();
            updateLayout();
        } else if (evt.data.type === "changeNotification") {
            if (queue.todo.indexOf(evt.data.key) !== -1) {
                bc.postMessage({type: "queueRequest", key: stopKey});
            }
        } else if (evt.data.type === "queueUpdate") {
            if (stopKey === evt.data.key) {
                isDisconnected = false;
                queue = evt.data.queue;
                sourceTemp = evt.data.sourceTemp;
                sourcePower = evt.data.sourcePower;
                sourceFreq = evt.data.sourceFreq;
                updateData();
            }
        } else if (evt.data.type === "nodeNameUpdate") {
            if (stopKey === evt.data.key) {
                nodeName = evt.data.name;
            }
        } else if (evt.data.type === "nodeDeleted") {
            if (stopKey === evt.data.key) {
                window.close();
            }
        } else if (evt.data.type === "nodeDisconnected") {
            if (stopKey === evt.data.key) {
                isDisconnected = true;
            }
        }
    }

    const findLabel = (choices, value) => {
        for (let idx = 0; idx < choices.length; idx++) {
            const elem = choices[idx];
            if (elem[0] === value) return elem[1];
        }
    }

    const findLabelIndex = (choices, value) => {
        for (let idx = 0; idx < choices.length; idx++) {
            const elem = choices[idx];
            if (elem[0] === value) return idx;
        }
    }

    const getUnit = label => {
        const match = label.match(/\((.*)\)/);
        if (match === null) return "";
        return match[1];
    }

    const updateData = () => {
        if (done === true && !!queue) {
            done = false;
            x = [];
            y = [];
            y2 = [];
            worker.postMessage({
                xHigh: parseFloat(xHigh),
                xLow: parseFloat(xLow),
                xDataPoints: parseInt(xDataPoints),
                xParam: xAxis,
                yParam: yTraces,
                y2Param: y2Traces,
                stopKey: stopKey,
                queue: queue,
                spread: xAxis === "Frequency" ? "octave" : "even"
            });
        }
    }

    const onDone = (rawData) => {
        const xLabel = findLabel(xAxisChoices, xAxis);
        x = [];
        y = rawData[0].y.map(() => []);
        y2 = rawData[0].y2.map(() => []);
        rawData.forEach(d => {
            x.push(d.x);
            d.y.forEach((dy, i) => y[i].push(dy));
            d.y2.forEach((dy, i) => y2[i].push(dy));
        });
        let data = y.map((yData, idx) => {
            const yLabel = findLabel(yAxisChoices, yTraces[idx]);
            let lineColor = colors[findLabelIndex(yAxisChoices, yTraces[idx]) % colors.length];
            return {
                type: "scatter",
                name: yLabel,
                mode: "lines+markers",
                x: x,
                y: yData,
                marker: {
                    symbol: idx,
                },
                line: {
                    color: lineColor
                },
                hovertemplate: `${xLabel}: %{x:.${PRECISION}f}<br>${yLabel}: %{y:.${PRECISION}f}<extra></extra>`,
            }
        });
        y2.forEach((y2Data, idx) => {
            const y2Label = findLabel(yAxisChoices, y2Traces[idx]);
            let lineColor = colors[findLabelIndex(yAxisChoices, y2Traces[idx]) % colors.length];
            data.push({
                type: "scatter",
                name: y2Label,
                mode: "lines+markers",
                x: x,
                y: y2Data,
                yaxis: "y2",
                marker: {
                    symbol: idx + 100,
                },
                line: {
                    color: lineColor
                },
                hovertemplate: `${xLabel}: %{x:.${PRECISION}f}<br>${y2Label}: %{y:.${PRECISION}f}<extra></extra>`,
            })
        });
        restoreAnnotations();
        Plotly.react(canvas, data, makeLayout());
        if (needsRescale.length > 0) {
            if (shouldRescaleY) {
                yStart = Math.floor(canvas.layout.yaxis.range[0]).toFixed(PRECISION);
                yEnd = Math.ceil(canvas.layout.yaxis.range[1]).toFixed(PRECISION);
            }
            if (!!canvas.layout.yaxis2.range) {
                if (shouldRescaleY2) {
                    y2Start = Math.floor(canvas.layout.yaxis2.range[0]).toFixed(PRECISION);
                    y2End = Math.ceil(canvas.layout.yaxis2.range[1]).toFixed(PRECISION);
                }
            } else {
                y2Start = null;
                y2End = null;
            }

            needsRescale = [];
        }
        done = true;
    }

    const restoreAnnotations = () => {
        let newAnnotations = [annotations[0]];

        annotations.slice(1).forEach(a => {
            let closestX = {
                asF: parseFloat(x[0]),
                asS: x[0],
                delta: Math.abs(parseFloat(x[0]) - a._origX)
            };
            for (let idx = 1; idx < x.length; idx++) {
                // Linear search through sorted list, stop when the next item isn't closer.
                // TODO: Could improve with better search algorithm?
                const asS = x[idx];
                const asF = parseFloat(asS);
                const delta = Math.abs(asF - a._origX);
                if (delta < closestX.delta) {
                    closestX = {
                        asF: asF,
                        asS: asS,
                        delta: delta
                    }
                } else {
                    break
                }
            }

            let ySide = y;
            let yIdx = yTraces.indexOf(a._traceName);
            if (a.yref === "y2") {
                yIdx = y2Traces.indexOf(a._traceName);
                ySide = y2;
            }
            if (yIdx === -1) {
                // Trace removed from plot, so delete annotation
                return;
            }

            const yAsS = ySide[yIdx][x.indexOf(closestX.asS)];
            a.x = a._x_d2l(closestX.asF);
            a.y = a._y_d2l(yAsS);

            let xVal = closestX.asF.toFixed(PRECISION);
            let yVal = parseFloat(yAsS).toFixed(PRECISION);
            a.text = `${xVal} ${a._xUnit}<br>${yVal} ${a._yUnit}`;

            newAnnotations.push(a);
        })
        annotations = newAnnotations;
    }

    const makeAutoTitle = (currentTitle, isAuto) => {
        if (isAuto) {
            const xLabel = findLabel(xAxisChoices, xAxis);
            if (yTraces.length === 0 && y2Traces.length === 0) {
                return `Nothing plotted over ${xLabel}`;
            }
            const yLabel = yTraces.map(t => findLabel(yAxisChoices, t)).join(", ");
            if (y2Traces.length === 0) {
                return `${yLabel} over ${xLabel}`;
            }
            const y2Label = y2Traces.map(t => findLabel(yAxisChoices, t)).join(", ");
            if (yTraces.length === 0) {
                return `${y2Label} over ${xLabel}`;
            }
            return `${yLabel}, ${y2Label} over ${xLabel}`;
        }
        return currentTitle;
    }

    const makeAutoSubtitle = (currentSubtitle, isAuto) => {
        if (isAuto) {
            let newSubtitle = `${nodeName} node with`;
            if (xAxis === "Frequency") {
                return `${newSubtitle} source temperature at ${sourceTemp}°C and source power at ${sourcePower}dBm`;
            }
            if (xAxis === "Temperature") {
                return `${newSubtitle} source frequency at ${sourceFreq}MHz and source power at ${sourcePower}dBm`;
            }
            return `${newSubtitle} source temperature at ${sourceTemp}°C and source frequency at ${sourceFreq}MHz`;
        }
        return currentSubtitle;
    }

    const makeLayout = () => {
        const xLabel = findLabel(xAxisChoices, xAxis);
        const yLabel = yTraces.map(t => findLabel(yAxisChoices, t)).join(", ");
        const y2Label = y2Traces.map(t => findLabel(yAxisChoices, t)).join(", ");

        let yRange = [yStart, yEnd];
        if (shouldRescaleY) {
            yRange = autoScale(y, 0.1);
            yStart = yRange[0];
            yEnd = yRange[1];
        }

        let y2Range = [y2Start, y2End];
        if (y2Traces.length !== 0 && shouldRescaleY2) {
            y2Range = autoScale(y2, 0.1);
            y2Start = y2Range[0];
            y2End = y2Range[1];
        }

        return {
            dragmode: false,
            title: {
                text: title,
            },
            annotations: annotations,
            legend: {
                orientation: "h",
                x: 0,
                y: 1,
                yanchor: "bottom",
            },
            xaxis: {
                title: `<b>${xLabel}</b>`,
                linecolor: "black",
                gridcolor: "#aaa",
                zeroline: false,
                minor: {
                    showgrid: true,
                    gridcolor: "#ddd",
                    tickcolor: "black"
                },
                range: [xLow, xHigh]
            },
            yaxis: {
                title: `<b>${yLabel}</b>`,
                side: "left",
                yaxis: "y2",
                linecolor: "black",
                zeroline: false,
                gridcolor: "#888",
                tickformat : `.${PRECISION}f`,
                minor: {
                    showgrid: true,
                    gridcolor: "#ddd",
                    tickcolor: "black"
                },
                range: yRange,
                fixedrange: yIsFixed,
            },
            yaxis2: {
                title: `<b>${y2Label}</b>`,
                side: "right",
                overlaying: "y",
                linecolor: "black",
                zeroline: false,
                tickformat : `.${PRECISION}f`,
                range: y2Range,
                fixedrange: y2IsFixed,
                showgrid: false,
            }
        }
    }

    const updateLayout = () => {
        if (!!canvas) Plotly.relayout(canvas, makeLayout());
    }

    const handleAutoScaleX = () => {
        xHigh = ranges[xAxis].high.toFixed(PRECISION);
        xLow = ranges[xAxis].low.toFixed(PRECISION);
        needsRescale = ["y", "y2"];
        updateData();
    }

    const handleAutoScaleXMax = () => {
        xHigh = ranges[xAxis].maxHigh.toFixed(PRECISION);
        xLow = ranges[xAxis].maxLow.toFixed(PRECISION);
        needsRescale = ["y", "y2"];
        updateData();
    }

    const handleManualScaleX = () => {
        needsRescale = ["y", "y2"];
        updateData();
    }

    const updateYAxes = () => {
        let updates = {
            'yaxis.range[0]': yStart,
            'yaxis.range[1]': yEnd,
        }
        if (y2Traces.length !== 0) {
            updates['yaxis2.range[0]'] = y2Start;
            updates['yaxis2.range[1]'] = y2End;
        }
        Plotly.relayout(canvas, updates);
    }

    const toggleSettings = () => {
        showSettings = !showSettings;
        Plotly.Plots.resize(canvas);
    }

    const autoScale = (data, paddingPercentage) => {
        if (data.length === 0) {
            return [-1, 1];
        }
        let start = Math.min(...data.flat());
        let end = Math.max(...data.flat());

        const range = Math.abs(end - start);
        const padding = range * paddingPercentage;

        start = start - padding;

        end = end + padding;

        start = Math.floor(start);
        end = Math.ceil(end);

        if (start === end) {
            start -= 1;
            end += 1;
        }

        return [start.toFixed(PRECISION), end.toFixed(PRECISION)];
    }

    const handleAutoScaleY = () => {
        const range = autoScale(y, 0.1);
        yStart = range[0];
        yEnd = range[1];
        updateYAxes();
    }

    const handleAutoScaleY2 = () => {
        const range = autoScale(y2, 0.1);
        y2Start = range[0];
        y2End = range[1];
        updateYAxes();
    }


    onMount(() => {
        Plotly.newPlot(canvas, [], {},
        {
            responsive: true,
            displayModeBar: true,
            displaylogo: false,
            modeBarButtonsToRemove: ["select2d", "lasso2d", "autoScale2d", "resetScale2d"],
            doubleClick: false,
            editable: true,
            edits: {
                annotationPosition: false,
                annotationTail: true,
                annotationText: false,
                axisTitleText: false,
                colorBarPosition: false,
                colorbarTitleText: false,
                legendPosition: false,
                legendText: false,
                shapePosition: false,
                titleText: false
            }
        });
        canvas.on("plotly_relayout", evt => {
            if (evt["xaxis.range[0]"]) {
                xHigh = Math.ceil(evt["xaxis.range[1]"]).toFixed(PRECISION);
                xLow = Math.floor(evt["xaxis.range[0]"]).toFixed(PRECISION);

                if (shouldRescaleY) {
                    yEnd = Math.floor(evt["yaxis.range[1]"]);
                    yStart = Math.ceil(evt["yaxis.range[0]"]);
                    if (yEnd === yStart) {
                        yEnd += 0.5;
                        yStart -= 0.5;
                    }
                    yEnd = yEnd.toFixed(PRECISION);
                    yStart = yStart.toFixed(PRECISION);
                }
                if (shouldRescaleY2) {
                    if (evt["yaxis2.range[1]"]) {
                        y2End = Math.floor(evt["yaxis2.range[1]"]).toFixed(PRECISION);
                    } else {
                        y2End = null;
                    }

                    if (evt["yaxis2.range[0]"]) {
                        y2Start = Math.ceil(evt["yaxis2.range[0]"]).toFixed(PRECISION);
                    } else {
                        y2Start = null;
                    }
                }
                updateData();
            }
        });

        canvas.on("plotly_click", evt => {
            let point = evt.points[0];
            let newAnnotation = {
                _x_d2l: point.xaxis.d2l,
                _y_d2l: point.yaxis.d2l,
                x: point.xaxis.d2l(point.x),
                y: point.yaxis.d2l(point.y),
                arrowhead: 6,
                align: "left",
                ax: -80,
                ay: -80,
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                arrowcolor: point.fullData.marker.color,
                bordercolor: point.fullData.marker.color,
                borderwidth: 1,
                borderpad: 4,
                _origX: point.x
            };
            const xLabel = findLabel(xAxisChoices, xAxis);
            newAnnotation._xUnit = getUnit(xLabel);
            newAnnotation._yUnit = getUnit(point.data.name);
            newAnnotation._traceName = point.data.name.split(" (")[0];
            if (point.data.yaxis === "y2") {
                newAnnotation.yref = "y2";
            }
            let x = point.x.toFixed(PRECISION);
            let y = point.y.toFixed(PRECISION);
            newAnnotation.text = `${x} ${newAnnotation._xUnit}<br>${y} ${newAnnotation._yUnit}`;
            // delete instead if clicked twice
            // The first annotation is the subtitle
            for (let idx = 1; idx < canvas.layout.annotations.length; idx++) {
                const ann = canvas.layout.annotations[idx];
                if (ann.text === newAnnotation.text ) {
                    annotations.splice(idx, 1);
                    updateLayout()
                    return;
                }
            }
            annotations.push(newAnnotation);
            updateLayout()
        });
        canvas.on('plotly_clickannotation', function(event) {
            for (let idx = 1; idx < canvas.layout.annotations.length; idx++) {
                const ann = canvas.layout.annotations[idx];
                if (ann.text === event.annotation.text ) {
                    annotations.splice(idx, 1);
                    updateLayout()
                    return;
                }
            }
        });

        if (!stopKey) {
            alert("Something unexpected went wrong (code 112). Please close this tab and try again.");
        } else {
            bc.postMessage({type: "initRequest", key: stopKey});
        }
    });

    $: {
        if (xAxis === "Frequency") {
            xMin = "0.01";
            xMax = "200000";
        } else if (!!ranges) {
            xMin = ranges[xAxis].maxLow;
            xMax = ranges[xAxis].maxHigh;
        }
    }

    $: {
        needsRescale = ["y"];
        updateData(yTraces);
    }

    $: {
        needsRescale = ["y2"];
        updateData(y2Traces);
    }

    $: shouldRescaleY = !yIsFixed && needsRescale.indexOf("y") !== -1;
    $: shouldRescaleY2 = !y2IsFixed && needsRescale.indexOf("y2") !== -1;

    $: title = makeAutoTitle(title, isAutoTitle, xAxis, yTraces, y2Traces);
    $: subtitle = makeAutoSubtitle(subtitle, isAutoSubTitle, xAxis, nodeName, sourcePower, sourceFreq, sourceTemp, nodeName);

    $: if (!!canvas) Plotly.relayout(canvas, {title: title});
    $: if (!!canvas) Plotly.relayout(canvas, {'annotations[0].text': subtitle});

    $: {
        let searchParams = new URLSearchParams();
        searchParams.set("stopKey", stopKey);

        if (!isAutoTitle) {
            searchParams.set("iat", "f");
            searchParams.set("t", title);
        }

        if (!isAutoSubTitle) {
            searchParams.set("iast", "f");
            searchParams.set("st", subtitle);
        }

        searchParams.set("xa", xAxis);
        searchParams.set("xl", xLow);
        searchParams.set("xh", xHigh);
        searchParams.set("xd", xDataPoints);

        yTraces.forEach(yt => searchParams.append("yt", yt));
        searchParams.set("ys", yStart);
        searchParams.set("ye", yEnd);
        if (yIsFixed) searchParams.set("yt", "t");

        y2Traces.forEach(yt => searchParams.append("y2t", yt));
        searchParams.set("y2s", y2Start);
        searchParams.set("y2e", y2End);
        if (y2IsFixed) searchParams.set("y2t", "t");

        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        history.replaceState(null, '', newRelativePathQuery);
    }
</script>

<div class="h-full flex flex-col">
    <div class="flex flex-row bg-white p-3 border-b border-black justify-between gap-2">
        <div class="text-xl">
            <span class="bg-black rounded px-[4px] py-[3px] mr-2">
                <i class="text-white fa-regular fa-fw fa-diagram-project" />
            </span>
            {graphName}
        </div>
        {#if isDisconnected}
            <div class="">
                <div class="text-red-500 font-semibold">
                    Updates paused: The node being plotted has been disconnected.
                </div>
            </div>
        {/if}
        <div class="">
            <a href="/">
                <img src="{logoUrl}" alt="RFGraph logo. Black circle with a white sine wave inside, then the word R F Graph." class="h-[26px] inline-block "/>
            </a>
        </div>
    </div>
    <div class="grow overflow-hidden">
        <div class="flex flex-row h-full">
            <div class="grow relative">
                <div id="chart-container" class="grow w-full h-full px-4" style="">
                    <div bind:this={canvas} class="h-full w-full"></div>
                </div>
            </div>
            <div
                style="width: {showSettings ? "350px" : "50px"}"
                class="h-full flex flex-col border-l border-black"
                class:bg-black={!showSettings}
            >
                {#if !showSettings}
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <span
                        on:click={toggleSettings}
                        class="px-2 py-2 text-white text-lg cursor-pointer"
                    >
                        <i class="far fa-arrow-from-right mx-2" />
                        <h2
                            class="transform rotate-90 whitespace-nowrap uppercase relative top-[10px] font-semibold"
                        >
                            Settings
                        </h2>
                    </span>
                {:else}
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <h2
                        on:click={toggleSettings}
                        class="px-2 py-2 bg-black text-white uppercase text-lg font-semibold flex flex-row justify-between cursor-pointer"
                    >
                        <span><i class="far fa-arrow-from-left ml-2" /></span>
                        <span class="mr-2">Settings</span>
                    </h2>
                    <div class="grow overflow-y-scroll">
                        <div class="px-2 mt-2 mb-4">
                            <h3>Title</h3>
                            <div class="mb-2"><Checkbox name="autoTitle" label="Auto generate title" bind:value={isAutoTitle} error={null} /></div>
                            <Input name="title" label="Title" hideLabel={true} bind:value={title} error={null} disabled={isAutoTitle} />

                            <h3 class="mt-4">Subtitle</h3>
                            <div class="mb-2"><Checkbox name="autoSubTitle" label="Auto generate subtitle" bind:value={isAutoSubTitle} error={null} /></div>
                            <Input name="subtitle" label="Subtitle" hideLabel={true} bind:value={subtitle} error={null} disabled={isAutoSubTitle} />
                        </div>
                        <Collapsable title="X Axis">
                            <div class="px-2 flex flex-row gap-4 mt-2 mb-2">
                                <Select name="x-axis"
                                        label="Parameter"
                                        error={null}
                                        choices={xAxisChoices}
                                        disabled={!done}
                                        bind:value={xAxis}
                                        onChange={handleAutoScaleX} />
                            </div>
                            <div class="px-2 mt-2 mb-4">
                                <form action="" class="grid grid-cols-3 gap-2 items-end" on:submit|preventDefault={handleManualScaleX}>
                                    <Input name="x-min"
                                        label="Min"
                                        type="number"
                                        bind:value={xLow}
                                        bind:error={xLowError}
                                        max={+xHigh - 0.01}
                                        step="any"
                                        disabled={!done}
                                        onChange={() => xLow = parseFloat(xLow).toFixed(2)} />
                                    <Input name="x-max"
                                        label="Max"
                                        type="number"
                                        bind:value={xHigh}
                                        bind:error={xHighError}
                                        min={+xLow + 0.01}
                                        step="any"
                                        disabled={!done}
                                        onChange={() => xHigh = parseFloat(xHigh).toFixed(2)} />
                                    <div class="">
                                        <Button text="Set Scale" type="submit" isDisabled={!done || !!xLowError || !!xHighError} />
                                    </div>
                                </form>
                            </div>
                            <div class="px-2 text-right mb-4">
                                <Button text="Max Scale" onClick={handleAutoScaleXMax} isDisabled={!done} />
                                <Button text="Default Scale" onClick={handleAutoScaleX} isDisabled={!done} />
                            </div>
                        </Collapsable>

                        <Collapsable title="Left Y Axis">
                            <div class="px-2 grid grid-cols-2 gap-2 flex-wrap mt-2 mb-2">
                                {#each yAxisChoices as choice (choice[0])}
                                    <label class="whitespace-nowrap text-sm">
                                        <input type="checkbox" bind:group={yTraces} name="yTraces" value={choice[0]} class="accent-brand" disabled={!done} />
                                        {choice[1]}
                                    </label>
                                {/each}
                            </div>
                            <div class="px-2 mt-2 mb-4">
                                <form action="" on:submit|preventDefault={updateYAxes}>
                                    <div class="grid grid-cols-3 gap-4 items-end">
                                        <Input name="y-start"
                                            label="Min"
                                            type="number"
                                            bind:value={yStart}
                                            bind:error={yLowError}
                                            max={+yEnd - 0.01}
                                            step="any"
                                            disabled={!done || yIsFixed}
                                            onChange={() => yStart = parseFloat(yStart).toFixed(2)} />
                                        <Input name="y-end"
                                            label="Max"
                                            type="number"
                                            bind:value={yEnd}
                                            bind:error={yHighError}
                                            min={+yStart + 0.01}
                                            step="any"
                                            disabled={!done || yIsFixed}
                                            onChange={() => yEnd = parseFloat(yEnd).toFixed(2)} />
                                        <div class="">
                                            <Button text="Set Scale" type="submit" isDisabled={!done || yIsFixed} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="px-2 text-right mb-4 flex flex-row items-center gap-2 justify-between">
                                <Checkbox name="fixed-y" label="Fix Range" error={null} bind:value={yIsFixed} />
                                <Button text="Auto Scale" onClick={handleAutoScaleY} isDisabled={!done || yIsFixed} />
                            </div>
                        </Collapsable>

                        <Collapsable title="Right Y Axis">
                            <div class="px-2 grid grid-cols-2 gap-2 flex-wrap mt-2 mb-2">
                                {#each yAxisChoices as choice (choice[0])}
                                    <label class="whitespace-nowrap text-sm">
                                        <input type="checkbox" bind:group={y2Traces} name="y2Traces" value={choice[0]} class="accent-brand" disabled={!done} />
                                        {choice[1]}
                                    </label>
                                {/each}
                            </div>
                            <div class="px-2 mt-2 mb-4">
                                <form action="" on:submit|preventDefault={updateYAxes}>
                                    <div class="grid grid-cols-3 gap-4 items-end">
                                        <Input name="y2-start"
                                                label="Min"
                                                type="number"
                                                bind:value={y2Start}
                                                bind:error={y2LowError}
                                                max={+y2End - 0.01}
                                                step="any"
                                                disabled={!done || y2Traces.length === 0 || y2IsFixed}
                                                onChange={() => y2Start = parseFloat(y2Start).toFixed(2)} />
                                        <Input name="y-end"
                                                label="Max"
                                                type="number"
                                                bind:value={y2End}
                                                bind:error={y2HighError}
                                                min={+y2Start + 0.01}
                                                step="any"
                                                disabled={!done || y2Traces.length === 0 || y2IsFixed}
                                                onChange={() => y2End = parseFloat(y2End).toFixed(2)} />
                                        <div class="">
                                            <Button text="Set Scale" type="submit" isDisabled={!done || y2Traces.length === 0 || y2IsFixed} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="px-2 text-right mb-4 flex flex-row items-center gap-2 justify-between">
                                <Checkbox name="fixed-y2" label="Fix Range" error={null} bind:value={y2IsFixed} />
                                <Button text="Auto Scale" onClick={handleAutoScaleY2} isDisabled={!done || y2Traces.length === 0 || y2IsFixed} />
                            </div>
                        </Collapsable>

                        <Collapsable title="Simulation" isClosed={true}>
                            <div class="px-2 flex flex-col gap-4 mt-2 mb-4">
                                <Select name="x-data-points"
                                        label="Number of Data Points"
                                        error={null}
                                        choices={[
                                            ["100", "100 data points"],
                                            ["200", "200 data points"],
                                            ["500", "500 data points"],
                                            ["1000", "1000 data points"],
                                            ["5000", "5000 data points"],
                                        ]}
                                        disabled={!done}
                                        bind:value={xDataPoints}
                                        onChange={updateData} />
                            </div>
                        </Collapsable>
                    </div>
                {/if}
            </div>
        </div>
    </div>
</div>

<style>
    #chart-container :global(.modebar-btn svg) {
        display: inline
    }
    :global(div.plotly-notifier) {
        visibility: hidden;
    }

</style>
