<script>
    import { authToken, isBusy, visibleModal } from "../stores.js";
    import { updateGraph } from "../api.js";
    import Modal from "./Modal.svelte";
    import Input from "../pages/components/form/Input.svelte";

    export let graphId;
    export let graphName;
    export let graphDirname;
    export let afterConfirm;

    let name = graphName;
    let errors = {};

    const handleSubmit = () => {
        if ($isBusy) {
            return;
        }
        isBusy.set(true);

        let req = updateGraph($authToken, graphId, name, graphDirname);
        req.then((resp) => {
            if (!resp.error) {
                visibleModal.set(null);
                if (!!afterConfirm) {
                    afterConfirm(resp);
                }
            } else {
                errors = resp.fields;
            }
        }).finally(() => isBusy.set(false));
    };
</script>

<Modal
    title="Rename {graphName}?"
    icon="far fa-pen-to-square fa-2x"
    confirmIcon="far fa-pen-to-square"
    confirmText="Rename"
    onConfirm={handleSubmit}
>
    <form on:submit|preventDefault={handleSubmit}>
        <button class="hidden" type="submit">Submit</button>
        <div class="mt-2">
            <Input
                bind:value={name}
                error={errors.name}
                label="Name"
                name="rename-name"
                helpText="The new name for this graph"
                required={true}
            />
        </div>
    </form>
</Modal>
