<script>
    import Button from "./Button.svelte";

    import {
        visiblePage,
        hasUnsavedChanges,
        visibleModal,
        nextPage,
        isEmailVerified,
        user,
        accountStatus,
    } from "../../stores.js";
    import { requestEmailVerification } from "../../api";
    import logoUrl from "../../images/logo.png";

    let activePage = "";

    const handleNavigation = (page) => {
        if ($hasUnsavedChanges) {
            visibleModal.set({ name: "confirm-lose-changes" });
            nextPage.set(page);
        } else {
            visiblePage.set(page);
            if (!!window._plotTabs) {
                window._plotTabs.forEach(tab => {
                    if (!!tab && !tab.closed) tab.close();
                });
                window._plotTabs = [];
            }
        }
    };

    const handleMyGraphs = () => {
        handleNavigation("/my-graphs/");
    };

    const handleExamples = () => {
        handleNavigation("/examples/");
    };

    const handleSignout = () => {
        handleNavigation("/sign-out/");
    };

    const handleAccount = () => {
        handleNavigation("/account/");
    };

    const handleVerifyEmail = () => {
        requestEmailVerification($user.email);
        visiblePage.set("/verify/requested/");
    };

    $: {
        if ($visiblePage === "/my-graphs/" || $visiblePage.startsWith("/graphs/")) {
            activePage = "graphs";
        } else if ($visiblePage.startsWith("/examples/")) {
            activePage = "examples";
        }
    };
</script>

<div class="grid grid-cols-3 items-center bg-black px-3 pt-3 text-white">
    <div class="pb-3">
        <a href="/">
            <img src="{logoUrl}" alt="The RFGraph logo" class="h-[26px] inline-block "/>
        </a>
        {#if import.meta.env.VITE_ENV !== "production"}<span class="text-xs uppercase text-brand">{import.meta.env.VITE_ENV || ""}</span>{/if}
    </div>
    <div class="justify-self-center">
        <ul class="flex flex-row gap-5">
            <li class="border-b-4 pb-3 {activePage === 'graphs' ? 'border-brand' : 'border-black'}">
                {#if activePage === 'graphs'}
                    <Button
                        text="My Graphs"
                        onClick={handleMyGraphs}
                        theme="anchor-brand"
                        toUpperCase={false}
                    />
                {:else}
                    <Button
                        text="My Graphs"
                        onClick={handleMyGraphs}
                        theme="anchor-white"
                        toUpperCase={false}
                    />
                {/if}
            </li>
            <li class="border-b-4 pb-3 {activePage === 'examples' ? 'border-brand' : 'border-black'}">
                {#if activePage === 'examples'}
                    <Button
                        text="Examples"
                        onClick={handleExamples}
                        theme="anchor-brand"
                        toUpperCase={false}
                    />
                {:else}
                    <Button
                        text="Examples"
                        onClick={handleExamples}
                        theme="anchor-white"
                        toUpperCase={false}
                    />
                {/if}
            </li>
            <li class="border-b-4 border-black">
                <a
                    href="https://rfgraph.com/support/"
                    target="_blank"
                    rel="noreferrer"
                    class="text-white hover:text-brand hover:no-underline font-semibold"
                >
                    Support
                </a>
            </li>
        </ul>
    </div>
    <div class="justify-self-end pb-3">
        <ul class="flex flex-row items-center gap-2">
            <li class="">
                <Button
                    text="Account"
                    onClick={handleAccount}
                    theme="anchor-white"
                    toUpperCase={false}
                    boldText={false}
                />
            </li>
            <li class="">&middot;</li>
            <li class="">
                <Button
                    text="Sign out"
                    onClick={handleSignout}
                    theme="anchor-white"
                    toUpperCase={false}
                    boldText={false}
                />
            </li>
        </ul>
    </div>
</div>
{#if !$isEmailVerified}
    <div class="bg-red-200 border-b-2 border-red-600 p-2 text-center">
        <span class="mr-2"> Your email address hasn't been verified! </span>
        <Button
            text="Resend Email"
            type="button"
            theme="danger"
            size="small"
            onClick={handleVerifyEmail}
        />
    </div>
{/if}
{#if $accountStatus === "standard"}
    <div class="bg-[#FFEA8E] p-2 flex flex-row items-center gap-2">
        <Button
            text="Upgrade"
            type="button"
            url="/account/subscription/"
            theme="pro"
            size="small"
        />
        <p>
            <strong>Your subscription has expired.</strong>
            Upgrade to PRO for enhanced features.
            <a href="/account/subscription/">Upgrade now</a>.
        </p>
    </div>
{/if}
