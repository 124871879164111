<script>
    import { visiblePage } from "../stores.js";
    import { requestPasswordReset } from "../api.js";
    import Button from "./components/Button.svelte";
    import Page from "./Page.svelte";
    import Input from "./components/form/Input.svelte";

    let email = "";
    let isBusy = false;
    let errors = {};

    const handleSubmit = () => {
        isBusy = true;
        requestPasswordReset(email)
            .then(() => visiblePage.set("/reset/requested/"))
            .finally(() => isBusy = false);
    };
</script>

<Page>
    <div slot="body" class="grow">
        <form
            on:submit|preventDefault={handleSubmit}
            class="p-4 max-w-lg mx-auto"
        >
            <h1 class="text-xl font-bold">Reset your Password</h1>
            <p>In order to change your password we need to send a secure link to your email address.</p>
            <p class="mt-2 mb-4">Please enter the email address that you have registered on your RFGraph account below.</p>
            {#if errors.token}
                <p class="text-red-500">{errors.token}</p>
            {/if}
            <div class="mt-2">
                <Input
                    bind:value={email}
                    error={errors.email}
                    label="Email Address"
                    name="forgot-password-email"
                    helpText="Your email address, so we can send you a secure link"
                    required={true}
                    type="email"
                />
            </div>
            <div class="mt-4 flex flex-row justify-between items-end">
                <Button text="Send Password Reset Email" icon="fal fa-envelope" {isBusy} />
            </div>
        </form>
    </div>
</Page>
