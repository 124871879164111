<script>
    export let name;
    export let label;
    export let helpText = null;
    export let error;
    export let required = false;
    export let value;
</script>

<div class="">
    <div class="flex flex-row items-center gap-1">
        <input 
            bind:checked={value} 
            type="checkbox"
            id={name}
            required={required || null}
            class="accent-brand"
            {...$$restProps}
        />
        <label
            for={name}
            class="text-sm text-gray-700"
            class:text-red-500={!!error}
        >
            {label}
            {#if required}<span class="text-red-500">*</span>{/if}
        </label>
    </div>
    {#if helpText}
        <div class="text-gray-500">
            <small>{helpText}</small>
        </div>
    {/if}
    {#if error}
        <div class="text-red-500">
            <small>{error}</small>
        </div>
    {/if}
</div>
