import * as go from "gojs/release/go-module.js";
import settings from '../settings.js';
import "./shapes.js";

let $ = go.GraphObject.make;

const MIRROR_COLOURS = [
    // https://github.com/plotly/plotly.js/blob/master/src/components/color/attributes.js
    '#1f77b4',  // muted blue
    '#ff7f0e',  // safety orange
    '#2ca02c',  // cooked asparagus green
    '#9467bd',  // muted purple
    '#8c564b',  // chestnut brown
    '#e377c2',  // raspberry yogurt pink
    '#7f7f7f',  // middle gray
    '#bcbd22',  // curry yellow-green
    '#17becf'   // blue-teal
];

function stateColor(obj) {
    if (obj._isDanger) {
        return settings.dangerColor;
    }
    if (obj._isWarning) {
        return settings.warningColor;
    }
    return settings.textColor;
}

export let parameterItemTemplate = (canEdit) => $(
    go.Panel,
    "TableRow",
    {
        cursor: "pointer",
        mouseEnter: function (evt, row) {
            window.diagram.model.setDataProperty(window.diagram.model.modelData, "_highlight", row.data.name);
            const event = new CustomEvent(
                'SetHighlight',
                {
                    detail: {
                        name: row.data.name
                    }
                }
            );
            window.dispatchEvent(event);
        },
        mouseLeave: function (evt, row) {
            window.diagram.model.setDataProperty(window.diagram.model.modelData, "_highlight", null);
            const event = new CustomEvent(
                'SetHighlight',
                {
                    detail: {
                        name: null
                    }
                }
            );
            window.dispatchEvent(event);
        },
        click: function (evt, row) {
            let component = row.panel;
            while (component.panel !== null) {
                component = component.panel;
            }
            const event = new CustomEvent(
                'EditParameterRequest',
                {
                    detail: {
                        component: component.data,
                        parameter: row.data,
                        canEdit: canEdit,
                    }
                }
            );
            window.dispatchEvent(event);
        }
    },
    $(go.TextBlock,
        {
            font: settings.font("13px", "600"),
            margin: new go.Margin(0, 5, 3, 0),
            column: 0,
            alignment: go.Spot.Left
        },
        new go.Binding("text", "name", function (val, obj) {
            let component = obj.panel;
            while (component.panel !== null) {
                component = component.panel;
            }
            if (component.data.category === "SPL") {
                return obj.panel.data.prettyName();
            }
            return obj.panel.data.name;
        }),
        new go.Binding(
            "stroke",
            "_isWarning",
            (val, obj) => stateColor(obj.panel.data),
        ),
        new go.Binding(
            "stroke",
            "_isDanger",
            (val, obj) => stateColor(obj.panel.data),
        ),
        new go.Binding(
            "stroke",
            "_highlight",
            function (val, obj) {
                if (obj.panel.data.name === val) {
                    return settings.primaryColor;
                }
                return stateColor(obj.panel.data);
            }
        ).ofModel()
    ),
    $(go.TextBlock,
        {
            font: settings.font("13px"),
            editable: false,
            isMultiline: false,
            margin: new go.Margin(0, 5, 0, 0),
            column: 1,
            alignment: go.Spot.Right
        },
        new go.Binding(
            "text",
            "value",
            function (val, obj) {
                return obj.panel.data.getShortest();
            }
        ),
        new go.Binding(
            "stroke",
            "_isWarning",
            (val, obj) => stateColor(obj.panel.data),
        ),
        new go.Binding(
            "stroke",
            "_isDanger",
            (val, obj) => stateColor(obj.panel.data),
        ),
        new go.Binding(
            "stroke",
            "_highlight",
            function (val, obj) {
                if (obj.panel.data.name === val) {
                    return settings.primaryColor;
                }
                return stateColor(obj.panel.data);
            }
        ).ofModel()
    ),
    $(go.TextBlock,
        {
            font: settings.font("13px"),
            column: 2,
            alignment: go.Spot.Left,
            margin: new go.Margin(0, 0, 0, 0),
        },
        new go.Binding(
            "text",
            "value",
            function (val, obj) {
                return obj.panel.data.getShortestUnit();
            }
        ),
        new go.Binding(
            "stroke",
            "_isWarning",
            (val, obj) => stateColor(obj.panel.data),
        ),
        new go.Binding(
            "stroke",
            "_isDanger",
            (val, obj) => stateColor(obj.panel.data),
        ),
        new go.Binding(
            "stroke",
            "_highlight",
            function (val, obj) {
                if (obj.panel.data.name === val) {
                    return settings.primaryColor;
                }
                return stateColor(obj.panel.data);
            }
        ).ofModel()
    )
);

let outPortTemplate = $(
    go.Panel,
    "TableRow",
    {},
    $(
        go.Shape,
        "HalfEllipse",
        {
            fromLinkable: true,
            toLinkable: false,
            toMaxLinks: 1,
            fromMaxLinks: 1,
            fromSpot: go.Spot.Right,
            cursor: "pointer",
            margin: new go.Margin(5, 0, 5, -1),
        },
        new go.Binding("portId", ""),
        new go.Binding("fill", "", function (obj, other) {
            let node = other.part;
            let isLinked = node.findLinksOutOf(obj).count > 0;
            if (!isLinked) {
                return settings.primaryColor;
            }
            return "black";
        }),
        new go.Binding("desiredSize", "", function (obj, other) {
            let node = other.part;
            let isLinked = node.findLinksOutOf(obj).count > 0;
            if (!isLinked) {
                return new go.Size(9, 18);
            }
            return new go.Size(5, 10);
        })
    )
);

let inPortTemplate = $(
    go.Panel,
    "TableRow",
    {},
    $(
        go.Shape,
        "HalfEllipse",
        {
            angle: 180,
            cursor: "pointer",
            fromMaxLinks: 1,
            toSpot: go.Spot.Right,
            margin: new go.Margin(5, -1, 5, 0),
            toLinkable: true,
            toMaxLinks: 1,
        },
        new go.Binding("portId", ""),
        new go.Binding("fill", "", function (obj, other) {
            let node = other.part;
            let isLinked = node.findLinksInto(obj).count > 0;
            if (!isLinked) {
                return settings.primaryColor;
            }
            return "black";
        }),
        new go.Binding("desiredSize", "", function (obj, other) {
            let node = other.part;
            let isLinked = node.findLinksInto(obj).count > 0;
            if (!isLinked) {
                return new go.Size(9, 18);
            }
            return new go.Size(5, 10);
        })
    )
);

let nodeTemplate = $(
    go.Node,
    "Horizontal",
    {
        linkValidation: function (fromNode, fromPort, toNode, toPort, link) {
            if (toNode.data.category === "CMB") {
                let incomingContext = fromNode.data.getFirstOutput();
                if (typeof incomingContext === "undefined") {
                    // fromNode hasn't been simulated yet, we don't know what's it's connected to
                    // so we don't know if it's a valid connection
                    return false;
                }
                let incomingSplitters = incomingContext.get("Splitters").getValue();
                if (incomingSplitters.length === 0) {
                    // The chain hasn't been split
                    return false;
                }
                let connectedSplitters = toNode.data._inputs.map(
                    input => input.get("Splitters").getValue().map(spl => spl.key)
                ).flat();
                if (connectedSplitters.length !== 0) {
                    // If there are no matching splitters then this must be a chain from a different source
                    return incomingSplitters.some(spl => connectedSplitters.indexOf(spl.key) !== -1)
                }

            }
            if (fromNode.data.category === "SRC" && toNode.data.category === "TRM") {
                return false;
            }
            return true;
        }
    },
    new go.Binding("location", "location", go.Point.parse).makeTwoWay(go.Point.stringify),
    {
        selectionAdorned: false,
        locationSpot: go.Spot.Center,
        stretch: go.GraphObject.Fill,
    },
    $(
        go.Panel,
        "Table",
        {
            itemTemplate: inPortTemplate,
        },
        new go.Binding("itemArray", "_inPortIds")
    ),
    $(
        go.Panel,
        "Table",
        {
            minSize: new go.Size(200, 0),
            // TODO: Work out how to stretch the table to account for more
            //       input/output ports whilst not collapsing the nodes with only one
            // stretch: go.GraphObject.Fill,
        },
        $(
            go.Panel,
            "Auto",
            {
                stretch: go.GraphObject.Horizontal,
                row: 0,
                column: 0,
            },
            $(
                go.Shape,
                "RoundedTopRectangle",
                {
                    strokeWidth: 2,
                    stroke: "black",
                    margin: new go.Margin(0, 0, -1.5, 0),
                },
                new go.Binding("fill", "", function (obj) {
                    if (obj._isDanger) {
                        return settings.dangerColor;
                    }
                    if (obj._isWarning) {
                        return settings.warningColor;
                    }
                    return settings.primaryColor;
                }),
            ),
            $(
                go.Panel,
                "Vertical",
                {
                    padding: 12,
                    stretch: go.GraphObject.Fill
                },
                $(
                    go.TextBlock,
                    "Default Text",
                    {
                        stroke: settings.textColor,
                        font: settings.font("16px", "bold"),
                        isMultiline: false,
                        alignment: go.Spot.Center,
                        wrap: go.TextBlock.None,
                        overflow: go.TextBlock.OverflowEllipsis,
                        maxSize: new go.Size(175, Infinity),
                    },
                    new go.Binding("text", "name")
                ),
            ),
        ),
        $(
            go.Panel,
            "Auto",
            {
                stretch: go.GraphObject.Fill,
                row: 1,
                column: 0,
            },
            $(
                go.Shape,
                "RoundedBottomRectangle",
                {
                    strokeWidth: 2,
                    stroke: "black",
                },
                new go.Binding("fill", "", function (obj) {
                    if (obj.isSelected) return "#e2fdfc";
                    return "white";
                }).ofObject()
            ),
            $(
                go.Panel,
                "Vertical",
                {
                    stretch: go.GraphObject.Horizontal,
                },
                $(
                    go.Panel,
                    "Table",
                    {
                        itemTemplate: parameterItemTemplate(true),
                        margin: new go.Margin(5, 5, 2, 5),
                        stretch: go.GraphObject.Horizontal,
                    },
                    new go.Binding("itemArray", "_watchedParameters")
                ),
                $(
                    go.Panel,
                    "Table",
                    {
                        alignment: go.Spot.Center,
                        margin: new go.Margin(0, 5, 5, 5),
                        stretch: go.GraphObject.Horizontal,

                    },
                    $(
                        go.Panel,
                        "Vertical",
                        {
                            alignment: go.Spot.Top,
                            column: 0,
                            width: 16
                        },
                        $(
                            go.Shape,
                            { figure: "RoundedRectangle", width: 14, height: 14, margin: new go.Margin(0, 0, 5, 0), },
                            new go.Binding("fill", "", function (obj) {
                                if (!!obj.mirrorKey) {
                                    const idx = Math.abs(obj.mirrorKey) % MIRROR_COLOURS.length;
                                    return MIRROR_COLOURS[idx];
                                }
                                return "black";
                            }),
                            new go.Binding("figure", "", function (obj) {
                                const shapes = ["Square", "Circle", "TriangleRight", "TriangleDown", "TriangleLeft", "TriangleUp", "Diamond"];
                                if (!!obj.mirrorKey) {
                                    const idx = Math.abs(obj.mirrorKey) % shapes.length;
                                    return shapes[idx];
                                }
                                return "Rectangle";
                            }),
                            new go.Binding("visible", "", function (obj) {
                                return !!obj.mirrorKey;
                            }),
                        ),
                        $(
                            go.Picture,
                            {
                                width: 16,
                                height: 16,
                                desiredSize: new go.Size(16, 16),
                                source: "/people-pants-regular.png",
                            },
                            new go.Binding("visible", "", function (obj) {
                                return !!obj.mirrorKey;
                            }),
                        ),
                    ),
                    $(
                        go.Panel,
                        "Auto",
                        {
                            name: "icon",
                            margin: new go.Margin(0, 10, 0, 10),
                            column: 1,
                        },
                        $(
                            go.Picture,
                            {
                                width: 100,
                                height: 100,
                                desiredSize: new go.Size(100, 100)
                            },
                            new go.Binding("source", "icon")
                        ),
                    ),
                    $(
                        go.Panel,
                        "Vertical",
                        {
                            alignment: go.Spot.Top,
                            column: 2,
                            width: 16
                        },
                    ),
                ),
                $(
                    go.Panel,
                    "Table",
                    {
                        itemTemplate: parameterItemTemplate(false),
                        margin: new go.Margin(5, 5, 2, 5),
                        stretch: go.GraphObject.Horizontal,
                    },
                    new go.Binding("itemArray", "_watchedOutputs")
                ),
                $(
                    go.Panel,
                    "Table",
                    {
                        itemTemplate: parameterItemTemplate(false),
                        margin: new go.Margin(5, 5, 2, 5),
                        stretch: go.GraphObject.Horizontal,
                    },
                    new go.Binding("itemArray", "_watchedCalculations"),
                    new go.Binding("visible", "", function (obj) {
                        return obj._watchedCalculations.length > 0;
                    }),
                ),
                $(
                    go.Panel,
                    "Vertical",
                    $(
                        go.TextBlock,
                        "Default Text",
                        {
                            stroke: settings.textColor,
                            font: settings.font("16px", "bold"),
                            isMultiline: false,
                            alignment: go.Spot.Center,
                            text: "rfgraph.com",
                        }
                    ),
                    new go.Binding("visible", "", function (obj) {
                        return obj._showWatermark || false;
                    })
                ),
            )
        ),
    ),
    $(
        go.Panel,
        "Table",
        {
            itemTemplate: outPortTemplate,
        },
        new go.Binding("itemArray", "_outPortIds")
    ),
);

export default nodeTemplate;
