<script>
    import { saveAs } from "file-saver";
    import Modal from "./Modal.svelte";
    import { isBusy, visibleModal } from "../stores";
    import { onMount } from "svelte";
    import Select from "../pages/components/form/Select.svelte";
    import Input from "../pages/components/form/Input.svelte";
    import makeImage from "../camera.js";

    export let graphName;
    export let diagram;

    let dataUri;
    let format = "image/png";
    let background = "white";
    let name = "Export";
    let whichNodes = "all";
    let scale = "1";
    let noSelection = false;
    let whichNodeChoices = [];

    const updatePreview = () => {
        makeImage(
            diagram,
            {
                background: background,
                format: format,
                scale: parseInt(scale),
                whichNodes: whichNodes,
                callback: (blob) => {
                    if (dataUri) {
                        URL.revokeObjectURL(dataUri);
                    }
                    dataUri = URL.createObjectURL(blob);
                }
            }
        );
    };

    const handleConfirm = () => {
        isBusy.set(true);
        makeImage(
            diagram,
            {
                background: background,
                format: format,
                scale: parseInt(scale),
                whichNodes: whichNodes,
                callback: (blob) => {
                    let ext = "png";
                    if (format === "image/jpeg") {
                        ext = "jpg";
                    }
                    saveAs(blob, `${name}.${ext}`);
                    visibleModal.set(null);
                    isBusy.set(false);
                }
            }
        );
    };

    onMount(() => {
        name = `${graphName} Export`;
        noSelection = diagram.selection.size === 0;
        whichNodeChoices = [
            ["all", "Entire graph"],
            ["selected", "Only the selected nodes", noSelection],
            ["chain", "The selected chain", noSelection],
        ];
        updatePreview();
    });
</script>

<Modal
    title="Export"
    icon="fal fa-share fa-2x"
    confirmIcon="fal fa-download"
    confirmText="Download"
    onConfirm={handleConfirm}
>
    <div class="mt-2">
        <Input
            bind:value={name}
            label="Name"
            error={null}
            name="export-name"
            type="text"
        />
    </div>

    <div class="mt-2">
        <Select
            choices={whichNodeChoices}
            bind:value={whichNodes}
            onChange={updatePreview}
            error={null}
            label="Which Nodes"
            name="export-which-nodes"
        />
    </div>

    <div class="mt-2">
        <div class="flex flex-row">
            <div class="grow">
                <Select
                    choices={[
                        ["transparent", "Transparent"],
                        ["white", "White"],
                    ]}
                    bind:value={background}
                    onChange={updatePreview}
                    error={null}
                    label="Background Color"
                    name="export-background-color"
                />
            </div>
            <div class="ml-2">
                <Select
                    choices={[
                        ["1", "1x"],
                        ["2", "2x"],
                        ["5", "5x"],
                    ]}
                    bind:value={scale}
                    onChange={updatePreview}
                    error={null}
                    label="Size"
                    name="export-scale"
                />
            </div>
        </div>
    </div>

    <div class="mt-2">
        <Select
            choices={[
                ["image/jpeg", "JPEG"],
                ["image/png", "PNG"],
            ]}
            bind:value={format}
            onChange={updatePreview}
            error={null}
            label="Format"
            name="export-format"
        />
    </div>

    {#if dataUri}
        <div class="mt-4">
            <p>Preview</p>
            <div class="bg-gray-200 p-2 shadow-inner rounded">
                <img
                    src={dataUri}
                    alt="Preview of the exported graph"
                    class="border mx-auto"
                />
            </div>
        </div>
    {/if}
</Modal>
