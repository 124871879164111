<script>
    import Page from "./Page.svelte";

    let icon = "chart-line";
    let style = "fal";
    let fg = "#151619";
    let bg = "#0ad6cc";

    $: icons = [`fa-${icon} ${style}`]
</script>

<Page>
    <div slot="body">
        <h1>Support Icon</h1>
        <div class="">
            <input type="text" bind:value={icon}>
        </div>
        <div class="">
            <input type="text" bind:value={style}>
        </div>
        <div class="">
            <input type="color" bind:value={fg}>
        </div>
        <div class="mb-8">
            <input type="color" bind:value={bg}>
        </div>
        <div class="py-8" style="background-color: {fg}">
            {#each icons as icon (icon) }
                <div class="ml-8 inline-block rounded-full px-4 py-6" style="background-color: {bg}; color: {fg}"><i class="fa-fw fa-5x {icon}"></i></div>
            {/each}
        </div>
    </div>
</Page>
