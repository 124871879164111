<script>
    import { visibleModal } from "../stores.js";
    import Modal from "./Modal.svelte";
    import { onMount } from "svelte";

    export let onConfirm;

    let elem;
    let text;

    const handleSubmit = () => {
        onConfirm(text);
        visibleModal.set(null);
    };

    onMount(() => {
        elem.focus();
    });
</script>

<Modal
    title="Import"
    icon="fal fa-file-import fa-2x"
    confirmText="Import and Replace"
    confirmIcon="fal fa-file-import"
    onConfirm={handleSubmit}
>
    <p>
        Are you sure? Importing a graph will replace everything in the current
        graph with the imported one.
    </p>
    <form on:submit|preventDefault={handleSubmit}>
        <textarea
            bind:value={text}
            bind:this={elem}
            name="text"
            id="SecretImport-text"
            cols="30"
            rows="10"
        />
        <button class="hidden" type="submit">Import and Replace</button>
    </form>
</Modal>
