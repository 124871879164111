import Parameters from "./parameters.js";


const DEFAULTS = [
    { name: "Total_Pdc_Dev", value: 0, unit: "Watts", isWatched: false, isEnabled: true },
    { name: "Total_Pdc_Sply", value: 0, unit: "Watts", isWatched: false, isEnabled: true },
    { name: "Total_Pcomp", value: 0, unit: "dB", isWatched: false, isEnabled: true },
    { name: "Linear_Gain", value: 0, unit: "dB", isWatched: false, isEnabled: true },
];

export default class ProbedParameters extends Parameters {


    constructor(data) {
        if (data === undefined) {
            data = DEFAULTS;
        }
        super(data);
    }

    addParam(name, param, unit) {
        if (!!param) {
            let val = param.getValue(unit);
            val = val.add(this.get(name).getValue(unit));
            this.set(name, val);
        }
    }

    addValue(name, value, unit) {
        value = value.add(this.get(name).getValue(unit));
        this.set(name, value);
    }

    reset() {
        for (const default_ of DEFAULTS) {
            this.set(default_.name, default_.value);
        }
    }

}
