<script>
    import { features } from "../../stores.js";
    import * as go from "gojs/release/go-module.js";
    import { onMount } from "svelte";
    import Button from "./Button.svelte";

    export let diagram;
    let buttons = [];

    let isClipboardEmpty = true;

    const handlePaste = () => {
        diagram.commandHandler.pasteSelection();
    };

    const handleDelete = () => {
        diagram.commandHandler.deleteSelection();
    };

    const handleCopy = () => {
        diagram.commandHandler.copySelection();
    };

    const handleExpandGroup = () => {
        diagram.commandHandler.expandSubGraph();
        updateButtons();
    };

    const handleCollapseGroup = () => {
        diagram.commandHandler.collapseSubGraph();
        updateButtons();
    };

    const handleUnGroup = () => {
        diagram.commandHandler.ungroupSelection();
    };

    const handleGroup = () => {
        diagram.commandHandler.groupSelection();
    };

    const handleMirror = () => {
        let orig = diagram.selection.first();
        diagram.startTransaction("Add mirrored node");
        let comp = orig.data.copy();
        comp.mirrorKey = orig.data.mirrorKey || Date.now();
        let location = go.Point.parse(orig.data.location);
        location.offset(20, 20);
        comp.location = go.Point.stringify(location);
        diagram.model.addNodeData(comp);
        if (!orig.data.mirrorKey) {
            diagram.model.setDataProperty(orig.data, "mirrorKey", comp.mirrorKey);
        }
        diagram.commitTransaction("Add mirrored node");
    };

    const handleUnmirror = () => {
        diagram.startTransaction("Unmirror node");
        let it = diagram.selection.iterator;
        while (it.next()) {
            if (!!it.value.data.mirrorKey) {
                diagram.model.setDataProperty(it.value.data, "mirrorKey", undefined);
            }
        }
        diagram.commitTransaction("Unmirror node");
        updateButtons();
    };

    const buttonProps = {
        copy: {
            text: "Copy",
            icon: "fal fa-copy",
            onClick: handleCopy,
            upsell: false
        },
        paste: {
            text: "Paste",
            icon: "fal fa-paste",
            onClick: handlePaste,
            upsell: false
        },
        delete: {
            text: "",
            icon: "fal fa-fw fa-trash",
            onClick: handleDelete,
            upsell: false
        },
        expandGroup: {
            text: "Expand",
            icon: "fal fa-fw fa-expand-arrows-alt",
            onClick: handleExpandGroup,
            upsell: false
        },
        collapseGroup: {
            text: "Collapse",
            icon: "fal fa-fw fa-compress-arrows-alt",
            onClick: handleCollapseGroup,
            upsell: false
        },
        unGroup: {
            text: "Ungroup",
            icon: "fal fa-fw fa-object-ungroup",
            onClick: handleUnGroup,
            upsell: false
        },
        group: {
            text: "Group",
            icon: "fak fa-fw fa-rf-group",
            onClick: handleGroup,
            upsell: false
        },
        mirror: {
            text: "Mirror",
            icon: "fal fa-fw fa-people-pants",
            onClick: handleMirror,
            upsell: $features.mirror === "upsell"
        },
        unmirror: {
            text: "Unmirror",
            icon: "fa-kit fa-fw fa-light-people-pants-slash",
            onClick: handleUnmirror,
            upsell: $features.mirror === "upsell"
        }
    };

    onMount(() => {
        updateButtons();
        diagram.addDiagramListener("ChangedSelection", (e) => {
            updateButtons();
        });
        diagram.addDiagramListener("ClipboardChanged", (e) => {
            isClipboardEmpty = false;
            updateButtons();
        });
    });

    const updateButtons = () => {
        let newButtons = [];
        if (!isClipboardEmpty && diagram.commandHandler.canPasteSelection) {
            newButtons.push("paste");
        }
        if (diagram.selection.size === 1) {
            let node = diagram.selection.first();
            if (node instanceof go.Link) {
                newButtons.push("delete");
            } else if (node instanceof go.Group) {
                newButtons.push("copy");
                if (node.isSubGraphExpanded) {
                    newButtons.push("collapseGroup");
                } else {
                    newButtons.push("expandGroup");
                }
                newButtons = newButtons.concat(["unGroup", "delete"]);
            } else {
                newButtons.push("copy");
                const notMirrorable = ["NOTE", "GRP", "TRM"];
                if (notMirrorable.indexOf(node.data.category) === -1) {
                    newButtons.push("mirror");
                    if (!!node.data.mirrorKey) {
                        newButtons.push("unmirror");
                    }
                }
                newButtons.push("delete");
            }
        } else if (diagram.selection.size > 1) {
            newButtons = newButtons.concat(["copy", "group"]);
            let it = diagram.selection.iterator;
            while (it.next()) {
                if (!!it.value.data.mirrorKey) {
                    newButtons.push("unmirror");
                    break;
                }
            }
            newButtons.push("delete");
        }
        buttons = newButtons.map((type) => {
            return { type: type, key: "" + Math.random() };
        });
    };

    const buttonPosition = (rank, rankOf) => {
        if (rankOf === 1) {
            return null;
        }
        if (rank === 1) {
            return "left";
        }
        if (rank === rankOf) {
            return "right";
        }
        return "middle";
    };
</script>

<div class="flex flex-row items-center h-full">
    {#each buttons as b, idx (b.key)}
        <Button
            {...buttonProps[b.type]}
            theme="white"
            size="small"
            barPosition={buttonPosition(idx + 1, buttons.length)}
        />
    {/each}
</div>
