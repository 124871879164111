<script>
    import { isBusy, visiblePage } from "../stores.js";
    import { resetPassword } from "../api.js";
    import Button from "./components/Button.svelte";
    import Page from "./Page.svelte";
    import Input from "./components/form/Input.svelte";

    export let token;
    let password = "";
    let errors = {};

    const handleSubmit = () => {
        isBusy.set(true);
        resetPassword(token, password)
            .then((resp) => {
                if (!resp || !resp.error) {
                    // The response after changing password is null on success
                    visiblePage.set("/my-graphs/");
                } else {
                    errors = resp.fields;
                }
            })
            .finally(() => isBusy.set(false));
    };
</script>

<Page>
    <div slot="body" class="grow">
        <form
            on:submit|preventDefault={handleSubmit}
            class="p-4 max-w-lg mx-auto"
        >
            <h1 class="text-xl font-bold">Reset your Password</h1>
            {#if errors.token}
                <p class="text-red-500">{errors.token}</p>
            {/if}
            <div class="mt-2">
                <Input
                    bind:value={password}
                    error={errors.password}
                    label="Password"
                    name="reset-password-password"
                    helpText="Your new password (make it a good one)"
                    required={true}
                    type="password"
                />
            </div>
            <div class="mt-4 flex flex-row justify-between items-end">
                <Button text="Set Password" icon="fal fa-key" />
            </div>
        </form>
    </div>
</Page>
