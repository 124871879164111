<script>
    import {
        nextPage,
        visiblePage,
        hasUnsavedChanges,
        visibleModal,
    } from "../stores.js";
    import Modal from "./Modal.svelte";

    const handleClose = () => {
        nextPage.set(null);
    };

    const handleSubmit = () => {
        hasUnsavedChanges.set(false);
        visiblePage.set($nextPage);
        nextPage.set(null);
        visibleModal.set(null);
        if (!!window._plotTabs) {
            window._plotTabs.forEach(tab => {
                if (!!tab && !tab.closed) tab.close();
            });
            window._plotTabs = [];
        }
    };
</script>

<Modal
    title="You have unsaved changes!"
    icon="fal fa-house-person-leave fa-2x"
    confirmText="Leave and lose changes"
    confirmIcon="fal fa-house-person-leave"
    onConfirm={handleSubmit}
    onClose={handleClose}
>
    <div class="mt-2">
        <p>
            Are you sure you want to leave this page? You will lose your unsaved
            changes.
        </p>
    </div>
</Modal>
