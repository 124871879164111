import * as go from "gojs/release/go-module.js";
import Decimal from 'decimal.js';

import Amplifier from './components/amplifier.js';
import Attenuator from './components/attenuator.js';
import BandPass from "./components/bandpass.js";
import BandStop from "./components/bandstop.js";
import Combiner from './components/combiner.js';
import Generic from "./components/generic.js";
import Group from "./components/group.js";
import HighPass from "./components/highpass.js";
import LowPass from "./components/lowpass.js";
import Note from "./components/note.js";
import Source from './components/source.js';
import Splitter from './components/splitter.js';
import Termination from "./components/termination.js";
import Parameters from "./components/utils/parameters.js";

let COMPONENTS = {
    "AMP": Amplifier,
    "ATT": Attenuator,
    "BNP": BandPass,
    "BST": BandStop,
    "CMB": Combiner,
    "GEN": Generic,
    "HIP": HighPass,
    "LWP": LowPass,
    "SPL": Splitter,
    "SRC": Source,
    "TRM": Termination,
}

let model = new go.GraphLinksModel([], []);
model.linkFromPortIdProperty = "fromPort";
model.linkToPortIdProperty = "toPort";

model.copyNodeDataFunction = (data, model) => {
    if (!!data.copy) return data.copy();
    return Object.assign({}, data);
}

model.load = (data, diagram) => {
    model.undoManager.clear();
    model.commit(() => {
        diagram.clear();
        model.modelData = {};
        if (!data) {
            // When a new graph is created it doesn't have any data
            model.modelData.nodeVersion = 10;
            model.modelData.linkVersion = 2;
            return;
        }
        go.Model.fromJson(data, model);
        model.nodeDataArray = model.nodeDataArray.map(d => {
            if (d.parameters) migrateNode(model.modelData.nodeVersion, d);
            let Comp = COMPONENTS[d.category];
            if (!!Comp) {
                d.parameters = d.parameters.all;
                return new Comp(d);
            }
            if (d.category === "NOTE") {
                return new Note(d);
            }
            if (d.category === "GRP") {
                return new Group(d);
            }
            d.notes = "There was an error loading this node.\n Please get in touch.";
            return new Note(d);
        });
        model.modelData.nodeVersion = 10;

        model.linkDataArray = model.linkDataArray.map(d => {
            migrateLink(model.modelData.linkVersion, d);
            return d;
        });
        model.modelData.linkVersion = 2;

        if (!!diagram && model.modelData.position) {
            diagram.initialPosition = go.Point.parse(model.modelData.position);
        }
        if (!!diagram && model.modelData.scale) {
            diagram.initialScale = model.modelData.scale;
        }

        diagram.updateModelConnectionData();

    }, null);
}


const migrateNode = (version, data) => {
    if (!version) {
        version = 0;
    }
    if (version < 1) {
        migrateNodeRemoveQuestionMarkUnits(data);
    }
    if (version < 2) {
        migrateNodeToggleSourceParameterWatched(data);
    }
    if (version < 3) {
        migrateNodeChangeProjectTypeNames(data);
    }
    if (version < 4) {
        migrateNodeDisabledAttenuatorNF(data);
    }
    if (version < 5) {
        migrateNodeRenameTypeToCategory(data);
    }
    if (version < 6) {
        migrateNodeRenameProjectType(data);
    }
    if (version < 7) {
        migrateNodeRenameProjectTypeAgain(data);
    }
    if (version < 8) {
        migrateNodeAddMoreBackOff(data);
    }
    if (version < 9) {
        migrateNodeAddCascadeValueToPassiveCompGain(data);
    }
    if (version < 10) {
        migrateNodeMoveExtraDataToGainSparam(data);
    }
}

const migrateNodeRemoveQuestionMarkUnits = (data) => {
    data.parameters.all.forEach(element => {
        if (element.unit === "?") element.unit = "";
    });
}

const migrateNodeToggleSourceParameterWatched = (data) => {
    if (data.type === "SRC") {
        data.parameters.all.forEach(element => {
            if (element.name === "Frequency") element.isWatched = true;
            if (element.name === "Power") element.isWatched = true;
        });
    }
}

const migrateNodeChangeProjectTypeNames = (data) => {
    if (data.type === "SRC") {
        data.parameters.all.forEach(element => {
            if (element.name === "Type") element.value = (element.value === "IIP") ? "RX" : "TX";
        });
    }
}

const migrateNodeDisabledAttenuatorNF = (data) => {
    if (data.type === "ATT") {
        data.parameters.all.forEach(element => {
            if (element.name === "NF") element.isEnabled = false;
        });
    }
}

const migrateNodeRenameTypeToCategory = (data) => {
    data.category = data.type;
    delete data.type;
}

const migrateNodeRenameProjectType = (data) => {
    if (data.category === "SRC") {
        for (let index = 0; index < data.parameters.all.length; index++) {
            const param = data.parameters.all[index];
            if (param.name === "Project Type") {
                param.value = (param.value === "Receiver") ? "RX" : "TX";
                param.name = "Type";
                break;
            }
        }
    }
}

const migrateNodeRenameProjectTypeAgain = (data) => {
    if (data.category === "SRC") {
        for (let index = 0; index < data.parameters.all.length; index++) {
            const param = data.parameters.all[index];
            if (param.name === "Type") {
                param.value = (param.value === "Receiver") ? "RX" : "TX";
                param.name = "Project Type";
                break;
            }
        }
    }
}

const migrateNodeAddMoreBackOff = (data) => {
    let op1backoff, deviceType, projectType;
    data.parameters.all.forEach(param => {
        if (param.name === "P1BackOff") {
            op1backoff = Parameters.parameterFactory(param);
            param.name = "OP1BackOff";
        } else if (param.name === "Device Type") {
            deviceType = param.value;
        } else if (param.name === "Project Type") {
            projectType = param.value;
        }
    });
    if (!!op1backoff) {
        data.parameters.all.push(
            {
                name: "IP1BackOff",
                value: op1backoff.getValue().minus(2),
                unit: "dB",
                isEnabled: !!op1backoff.isEnabled,
                isWatched: !!op1backoff.isWatched,
            }
        )
    }
}

const migrateNodeAddCascadeValueToPassiveCompGain = (data) => {
    const passiveComps = ["ATT", "BNP", "BST", "CMB", "HIP", "LWP", "SPL"];
    if (passiveComps.indexOf(data.category) !== -1) {
        data.parameters.all.forEach(param => {
            if (param.name === "Gain") {
                param.cascadeValue = [{ name: "NF", enabled: true, hidden: false }];
            }
        });
    }
}

const migrateNodeMoveExtraDataToGainSparam = (data) => {
    if (data.extras?.sparams?.length > 0) {
        data.parameters.all.forEach(param => {
            if (param.name === "Gain") {
                param.isSParamsEnabled = true;
                param.sParams = data.extras.sparams.map(sParam => {
                    let data = [];
                    Object.entries(sParam.data.dataAtFreq).forEach(([freq, pairs]) => {
                        let val = pairs[1][0];

                        let dVal = new Decimal(0);
                        dVal.d = val.d;
                        dVal.e = val.e;
                        dVal.s = val.s;
                        data.push({ freq: freq, value: dVal.toString() })
                    })
                    return {
                        s3Key: null,
                        portCount: sParam.data.portCount,
                        name: sParam.name,
                        temp: sParam.temp,
                        data: data
                    }
                });
            }
        });
    }

}


const migrateLink = (version, data) => {
    if (!version) {
        version = 0;
    }
    if (version < 2) {
        migrateLinkAddPortRanks(data);
    }
}

const migrateLinkAddPortRanks = (data) => {
    if (!data.fromPort) {
        data.fromPort = "out";
    }
    if (!data.toPort) {
        data.toPort = "in";
    }
}

export default model;
