<script>
    import { authToken, isBusy, visibleModal } from "../stores.js";
    import { duplicateGraph } from "../api.js";
    import Modal from "./Modal.svelte";
    import Input from "../pages/components/form/Input.svelte";

    export let graphId;
    export let graphName;
    export let graphDirname;
    export let afterConfirm;

    let name = graphName + " Copy";
    let errors = {};

    const handleSubmit = () => {
        if ($isBusy) {
            return;
        }
        isBusy.set(true);

        let req = duplicateGraph($authToken, graphId, name, graphDirname);
        req.then((resp) => {
            if (!resp.error) {
                visibleModal.set(null);
                if (!!afterConfirm) {
                    afterConfirm(resp);
                }
            } else {
                errors = resp.fields;
            }
        }).finally(() => isBusy.set(false));
    };
</script>

<Modal
    title="Duplicate {graphName}?"
    icon="far fa-clone fa-2x"
    confirmIcon="far fa-clone"
    confirmText="Duplicate"
    onConfirm={handleSubmit}
>
    <form on:submit|preventDefault={handleSubmit}>
        <button class="hidden" type="submit">Submit</button>
        <div class="mt-2">
            <Input
                bind:value={name}
                error={errors.name}
                label="Name"
                name="duplicate-name"
                helpText="What should the copied graph be called?"
                required={true}
            />
        </div>
    </form>
</Modal>
