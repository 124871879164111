import * as go from "gojs/release/go-module.js";
import settings from "../settings.js";
import { parameterItemTemplate } from "./nodeTemplate.js";

let $ = go.GraphObject.make;

let groupTemplate = $(
    go.Group,
    "Table",
    {
        minSize: new go.Size(200, 0),
        isSubGraphExpanded: false,
        ungroupable: true,
        selectionAdorned: false,
        locationSpot: go.Spot.Center,
        toSpot: go.Spot.Left,
        fromSpot: go.Spot.Right,

    },
    new go.Binding("location", "location", go.Point.parse).makeTwoWay(go.Point.stringify),
    $(
        go.Panel,
        "Auto",
        {
            stretch: go.GraphObject.Horizontal,
            row: 0,
            column: 0,
        },
        $(
            go.Shape,
            "RoundedTopRectangle",
            {
                strokeWidth: 2,
                stroke: "black",
                margin: new go.Margin(0, 0, -1.5, 0),
            },
            new go.Binding("fill", "", function (obj) {
                if (obj._isDanger) {
                    return settings.dangerColor;
                }
                if (obj._isWarning) {
                    return settings.warningColor;
                }
                return settings.primaryColor;
            }),
        ),
        $(
            go.Panel,
            "Vertical",
            {
                padding: 12,
                stretch: go.GraphObject.Fill
            },
            $(
                go.TextBlock,
                "Default Text",
                {
                    stroke: settings.textColor,
                    font: settings.font("16px", "bold"),
                    isMultiline: false,
                    alignment: go.Spot.Center,
                    wrap: go.TextBlock.None,
                    overflow: go.TextBlock.OverflowEllipsis,
                    maxSize: new go.Size(175, Infinity),
                },
                new go.Binding("text", "name")
            ),
        ),
    ),
    $(
        go.Panel,
        "Auto",
        {
            stretch: go.GraphObject.Fill,
            row: 1,
            column: 0,
        },
        $(
            go.Shape,
            "RoundedBottomRectangle",
            {
                strokeWidth: 2,
                stroke: "black",
            },
            new go.Binding("fill", "", function (obj) {
                if (obj.isSelected) return "#e2fdfc";
                return "white";
            }).ofObject()
        ),
        $(
            go.Panel,
            "Vertical",
            {
                stretch: go.GraphObject.Horizontal,
                padding: new go.Margin(8, 0, 5, 0),
            },
            new go.Binding("visible", "isSubGraphExpanded", (isExpanded) => !isExpanded).ofObject(),
            $(
                go.Panel,
                "Auto",
                {
                    name: "icon",
                    margin: new go.Margin(0, 10, 0, 10),
                },
                $(
                    go.Picture,
                    {
                        width: 100,
                        height: 100,
                        desiredSize: new go.Size(100, 100),
                    },
                    new go.Binding("source", "icon")
                ),
            ),
            $(
                go.Panel,
                "Table",
                {
                    itemTemplate: parameterItemTemplate(false),
                    margin: new go.Margin(5, 5, 2, 10),
                    stretch: go.GraphObject.Horizontal
                },
                new go.Binding("itemArray", "_watchedOutputs")
            ),
        ),
        $(go.Placeholder, { padding: 20 }, new go.Binding("visible", "isSubGraphExpanded", (isExpanded) => isExpanded).ofObject())
    ),
);

export default groupTemplate;
