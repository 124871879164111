<script>
    import Button from "./components/Button.svelte";
    import Page from "./Page.svelte";
</script>

<Page>
    <div slot="body" class="max-w-3xl mx-auto">
        <h1 class="text-xl font-bold mb-4">Verify Email</h1>
        <p class="mb-4">
            We've sent you a verification email that contains a secure link.
        </p>
        <p class="mb-4">
            When you get the email, click the link and you'll be verified.
        </p>
        <p>
            If you don't get the email from us, check your
            <Button
                text="account page"
                theme="anchor"
                toUpperCase={false}
                url="/account/"
                boldText={false}
            />
            to see that we've got the right address for you, and send another email.
        </p>
    </div>
</Page>
