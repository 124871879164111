import * as go from "gojs/release/go-module.js";

import simulate, { unsimulate } from '../simulator.js';
import model from '../model.js';
import nodeTemplate from './nodeTemplate.js';
import linkTemplate from './linkTemplate.js';
import linkingToolInit from './linkingTool.js';
import dragSelectingToolInit from './dragSelectingTool.js';
import groupTemplate from './groupTemplate.js';
import nodeNoteTemplate from "./nodeNoteTemplate.js";
import Note from "../components/note.js";
import Group from "../components/group.js";
import { getAllDescendents, getAllNodesInSelection } from "./utils.js";

go.Diagram.licenseKey = "73f944e4b06e31b700ca0d2b113f69ee1bb37a3a9ed01ef05d5041f7ee0f681670c9ed7958d68fc3c0e848fa4a75c08e8e923b2b944c0538ee38d68b4ae182adb46373ba1301448bf7072393caa829f6fb2d24f290b472a7c8688aa7bbaec3ce0ce9e1c44bcb0db836711934";

export default (elemId) => {
    let $ = go.GraphObject.make;

    let diagram = $(
        go.Diagram,
        elemId,
        {
            "undoManager.isEnabled": true,
            "draggingTool.isGridSnapEnabled": true,
            layout: $(
                go.LayeredDigraphLayout,
                { isInitial: false, isOngoing: false }
            )
        }
    );


    diagram.simulate = (node, reasons) => { simulate(diagram, node, false, reasons) }

    let nodeTemplates = new go.Map();
    nodeTemplates.add("", nodeTemplate);
    nodeTemplates.add("NOTE", nodeNoteTemplate);
    diagram.nodeTemplateMap = nodeTemplates;

    diagram.linkTemplate = linkTemplate;
    diagram.groupTemplate = groupTemplate;
    linkingToolInit(diagram);
    dragSelectingToolInit(diagram);

    diagram.scrollMode = go.Diagram.InfiniteScroll;

    model.clear();
    diagram.model = model;

    diagram.grid =
        $(go.Panel, "Grid",
            { visible: true, gridCellSize: new go.Size(30, 30) },
            $(go.Shape, "LineH", { stroke: "#E8E8E8" }),
            $(go.Shape, "LineV", { stroke: "#E8E8E8" }),
        );

    diagram.validCycle = go.Diagram.CycleNotDirected;

    diagram.commandHandler.archetypeGroupData = new Group({ name: "Group" });

    diagram.toolManager.resizingTool.handleArchetype = $(go.Shape, "Square", { width: 10, height: 10, fill: "#0ad6cc", cursor: "ns-resize" });

    const onModelChanged = (evt) => {
        if (!evt.isTransactionFinished) return;
        let txn = evt.object;
        if (txn === null) return;
        let nodes = [];
        let ceItr = txn.changes.iterator;
        while (ceItr.next()) {
            let ce = ceItr.value;
            if (!!ce.model && ce.model.skipsUndoManager) continue;
            // TODO: Make this smarter, it doesn't need to run on every change, or update every node
            diagram.updateModelConnectionData();
            if (ce.modelChange === "linkDataArray") {
                if (ce.change === go.ChangedEvent.Insert) {
                    if (!!ce.newValue.from) {
                        // from is undefined if the user pasted a link by itself
                        let n = diagram.findNodeForKey(ce.newValue.from);
                        if (nodes.indexOf(n) === -1) nodes.push(n);
                        n.data.broadcastChangeNotification();
                    }
                    if (!!ce.newValue.to) {
                        let n = diagram.findNodeForKey(ce.newValue.to);
                        n.data.broadcastChangeNotification();
                    }
                }
                else if (ce.change === go.ChangedEvent.Remove) {
                    let n = diagram.findNodeForKey(ce.oldValue.to);
                    if (!!n) {
                        unsimulate(diagram, n);
                        let disconnectedNodes = Array.from(getAllDescendents(n));
                        disconnectedNodes.forEach(node => node.data.broadcastDisconnectedNotification());
                    }
                }
            } else if (ce.modelChange === "linkToKey") {
                let n = diagram.findNodeForKey(ce.object.from);
                if (!!n) {
                    if (nodes.indexOf(n) === -1) nodes.push(n);
                    n.data.broadcastChangeNotification();
                }
            } else if (ce.modelChange === "linkFromKey") {
                let n = diagram.findNodeForKey(ce.object.from);
                if (!!n) {
                    if (nodes.indexOf(n) === -1) nodes.push(n);
                    n.data.broadcastChangeNotification();
                }
            }
        }

        if (txn.name === "Paste") {
            let sources = [];
            for (let i = 0; i < nodes.length; i++) {
                let n = nodes[i];
                if (!n) continue;
                let s = n.findTreeRoot();
                if (s.data.category === "SRC" && sources.indexOf(s) === -1) sources.push(s);
            }
            for (let i = 0; i < sources.length; i++) {
                // Set timeout here to run the simulation outside of the paste transaction
                // For some reason the paste transaction was being run twice without this
                setTimeout(() => simulate(diagram, sources[i], false, ["Pasted"]), 10);
            }
        } else {
            for (let i = 0; i < nodes.length; i++) {
                let n = nodes[i];
                if (!!n && n.findTreeRoot().data.category === "SRC") {
                    simulate(diagram, n, false, ["ChangedLink"]);
                }
            }
        }
    }

    diagram.addDiagramListener("ClipboardPasted", (evt) => {
        evt.subject.each(function (part) {
            if (part instanceof go.Node) {
                part.location.offset(20, 20);
                part.data.location = go.Point.stringify(part.location);
            } else if (part instanceof go.Group) {
                part.location.offset(20, 20);
                part.memberParts.each((n) => {
                    if (n instanceof go.Node) {
                        n.data.location = go.Point.stringify(n.location);
                    }
                })
            }
        });
    });

    diagram.addDiagramListener("SelectionGrouped", (evt) => {
        const bounds = evt.diagram.computePartsBounds(evt.subject.memberParts);
        let center = bounds.center;
        center.snapToGridPoint(new go.Point(0, 0), new go.Size(30, 30));
        evt.subject.location = center;
        evt.subject.findExternalLinksConnected().each(l => l.invalidateRoute());
        evt.subject.data.updateOutputs(diagram);
    });

    diagram.addDiagramListener("ViewportBoundsChanged", (evt) => {
        diagram.model.modelData.position = go.Point.stringify(diagram.position);
        diagram.model.modelData.scale = diagram.scale;
    });

    diagram.addDiagramListener("BackgroundDoubleClicked", (evt) => {
        const event = new CustomEvent(
            'EditTextRequest',
            {
                detail: {
                    title: "Notes",
                    helpText: "A sticky note, for whatever extra information you need.",
                    text: "",
                    onConfirm: text => {
                        evt.diagram.startTransaction("Add note after dblclick");
                        let comp = new Note({});
                        comp.location = go.Point.stringify(evt.diagram.lastInput.documentPoint);
                        evt.diagram.model.addNodeData(comp);
                        diagram.model.setDataProperty(comp, "notes", text);
                        evt.diagram.commitTransaction("Add note after dblclick");
                    }
                }
            }
        );
        window.dispatchEvent(event);
    });

    diagram.addDiagramListener("ObjectDoubleClicked", (evt) => {
        let comp = evt.subject.part.data;
        if (comp.category === "NOTE") {
            const event = new CustomEvent(
                'EditTextRequest',
                {
                    detail: {
                        title: "Notes",
                        helpText: "A sticky note, for whatever extra information you need.",
                        text: comp.notes,
                        onConfirm: text => {
                            diagram.startTransaction("Edit note");
                            diagram.model.setDataProperty(comp, "notes", text);
                            diagram.commitTransaction("Edit note");
                        }
                    }
                }
            );
            window.dispatchEvent(event);
        }
    });

    diagram.addDiagramListener("SubGraphCollapsed", (evt) => {
        let itr = evt.subject.iterator;
        while (itr.next()) {
            itr.value.data.updateOutputs(diagram);
        }

    });

    diagram.addDiagramListener("SelectionDeleted", (evt) => {
        let itr = evt.subject.iterator;
        while (itr.next()) {
            if (!!itr.value.data.broadcastDeleteNotification) {
                itr.value.data.broadcastDeleteNotification();
            }
        }
    });

    diagram.addDiagramListener("ChangedSelection", (e) => {
        let nodes = Array.from(getAllNodesInSelection(diagram.selection));
        nodes.forEach(n => {
            n.zOrder = 100;
        });
    });

    diagram.updateModelConnectionData = (node, done) => {
        if (!node) {
            done = [];
            let rootItr = diagram.findTreeRoots();
            while (rootItr.next()) {
                diagram.updateModelConnectionData(rootItr.value, done);
            }
        } else {
            if (done.indexOf(node.data.key) === -1) {
                done.push(node.data.key);
                node.data._connectedComponentsIn = [];
                node.data._connectedComponentsOut = [];

                const linkItr = node.findLinksOutOf();
                while (linkItr.next()) {
                    const link = linkItr.value;

                    let outIndex = link.fromNode.data.outPortIds().indexOf(link.fromPortId);
                    node.data._connectedComponentsOut[outIndex] = link.toNode.data;

                    diagram.updateModelConnectionData(link.toNode, done);

                    let inIndex = link.toNode.data.inPortIds().indexOf(link.toPortId);
                    link.toNode.data._connectedComponentsIn[inIndex] = node.data;
                }
            }
        }
    }

    diagram.addModelChangedListener(onModelChanged);

    diagram.cleanup = () => {
        diagram.removeModelChangedListener(onModelChanged);
    }

    diagram.zoomToNode = node => {
        if (!!node.containingGroup && !node.containingGroup.isSubGraphExpanded) {
            diagram.commandHandler.expandSubGraph(node.containingGroup);
        }
        diagram.centerRect(node.actualBounds);
    }

    window.diagram = diagram;

    return diagram;
}
