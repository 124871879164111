import * as go from "gojs/release/go-module.js";

const addAllPartsToRoot = (allParts, part, skipSelf) => {
    if (!allParts.contains(part) || skipSelf) {
        allParts.add(part);
        if (part instanceof go.Link) {
            addAllPartsToRoot(allParts, part.fromNode);
        } else {
            let it = part.findLinksInto().iterator;
            while (it.next()) {
                addAllPartsToRoot(allParts, it.value);
            }
            if (part.containingGroup !== null) {
                allParts.add(part.containingGroup);
            }
        }
    }
};

const addAllPartsToLeaves = (allParts, part, skipSelf) => {
    if (!allParts.contains(part) || skipSelf) {
        allParts.add(part);
        if (part instanceof go.Link) {
            addAllPartsToLeaves(allParts, part.toNode);
        } else {
            let it = part.findLinksOutOf().iterator;
            while (it.next()) {
                addAllPartsToLeaves(allParts, it.value);
            }
            if (part.containingGroup !== null) {
                allParts.add(part.containingGroup);
            }
        }
    }
};

const findRoot = (part) => {
    if (part instanceof go.Link) {
        return findRoot(part.fromNode);
    } else if (part instanceof go.Group) {
        let node = part.findExternalNodesConnected().first();
        if (!node) return part;
        return findRoot(node);
    } else {
        let link = part.findLinksInto().first();
        if (!link) return part;
        return findRoot(link.fromNode);
    }
};


export default (diagram, options) => {
    let parts,
        first,
        bounds = diagram.documentBounds,
        originalSelection = diagram.selection.copy();
    if (options.whichNodes === "selected") {
        parts = diagram.selection.copy();
        first = parts.first();
        bounds = diagram.computePartsBounds(parts);
    } else if (options.whichNodes === "chain") {
        parts = new go.Set();
        if (!options.chainNode) {
            let it = diagram.selection.iterator;
            while (it.next()) {
                addAllPartsToRoot(parts, it.value);
                addAllPartsToLeaves(parts, it.value, true);
            }
        } else {
            addAllPartsToRoot(parts, options.chainNode);
            addAllPartsToLeaves(parts, options.chainNode, true);
        }

        first = findRoot(parts.first());
        bounds = diagram.computePartsBounds(parts);
    } else {
        first = diagram.findTreeRoots().first();
    }
    let size = bounds.size,
        position = bounds.position;

    size = new go.Size(size.width * options.scale + 40, size.height * options.scale + 40);

    diagram.selectCollection([]);
    if (!!first) {
        diagram.model.setDataProperty(first.data, "_showWatermark", true);
    }

    diagram.makeImageData({
        background: options.background,
        type: options.format,
        details: 1,
        size: size,
        maxSize: size,
        position: position,
        padding: 20,
        parts: parts,
        scale: options.scale,
        returnType: options.returnType || "blob",
        callback: (type) => {
            options.callback(type);
            if (!!first && first.data.category != "SRC") {
                diagram.model.setDataProperty(
                    first.data,
                    "_showWatermark",
                    false
                );
            }
            diagram.selectCollection(originalSelection);
        },
    });
};