<script>
    export let name;
    export let label;
    export let hideLabel = false;
    export let helpText = null;
    export let placeholder = null;
    // error hasn't been tested properly yet, we don't have a textarea that could error
    export let error;
    export let required = false;
    export let value;
    export let rows = 10;
    export let disabled = false;
    export let labelTheme = "text-sm font-medium text-gray-700";
    export let autofocus = false;

    const focus = (el) => {
        if (autofocus) {
            el.focus();
            el.select();
        }
    };
</script>

<div class="mt-1">
    {#if label}
        <label
            for={name}
            class="block mb-1 {labelTheme}"
            class:text-red-500={!!error}
            class:sr-only={hideLabel}
        >
            {label}
            {#if required}<span class="text-red-500">*</span>{/if}
        </label>
    {/if}
    <div class="">
        <textarea
            on:change
            bind:value
            id={name}
            required={required || null}
            {placeholder}
            {disabled}
            {rows}
            use:focus
        />
    </div>
    {#if helpText}
        <div class="text-gray-500">
            <small>{helpText}</small>
        </div>
    {/if}
    {#if error}
        <div class="text-red-500">
            <small>{error}</small>
        </div>
    {/if}
</div>
