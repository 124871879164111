import Parameter from "../utils/parameter.js";

export default class IP1dB extends Parameter {
    constructor(initialValue, overrides) {
        let args = overrides || {};
        args.name = "IP1dB";
        args.isMultiValued = true;
        args.value = initialValue;
        args.unit = Parameter.valueOrDefault(args.unit, "dBm");

        args.minValue = Parameter.valueOrDefault(args.minValue, -99.99);
        args.maxValue = Parameter.valueOrDefault(args.maxValue, 99.99);

        args.isEnabled = Parameter.valueOrDefault(args.isEnabled, false);
        args.isWatched = Parameter.valueOrDefault(args.isWatched, false);

        args.cascadeWatched = Parameter.valueOrDefault(args.cascadeWatched, ["OP1dB"]);
        args.cascadeEnabled = Parameter.valueOrDefault(args.cascadeEnabled, ["OP1dB", "IPsat", "OPsat"]);
        args.cascadeMVEnabled = Parameter.valueOrDefault(args.cascadeMVEnabled, ["OP1dB", "IPsat", "OPsat"]);
        args.cascadeMV = Parameter.valueOrDefault(args.cascadeMV, []);

        super(args);

        this.cascadeMV = this.mergeCascadeMV([
            {
                name: "IPsat",
                direction: "below",
                delta: 0.5,
                minDelta: 0.25,
                maxDelta: 10,
                enabled: true,
                cascadeDelta: {
                    IPsat: "IP1dB",
                    OP1dB: "OPsat",
                    OPsat: "OP1dB"
                }
            },
            { name: "OP1dB", enabled: true, hidden: true, calculateDelta: 'calculateOP1dBDelta' },
            { name: "OPsat", enabled: true, hidden: true, calculateDelta: 'calculateOPsatDelta' },

        ], this.cascadeMV);
    }

    calculateOP1dBDelta(comp, multiValue) {
        let gain = comp.interpolateAtFreqTemp("Gain", multiValue.freq, multiValue.temp);
        return gain.minus(1);
    }

    calculateOPsatDelta(comp, multiValue) {
        let ipsat = comp.interpolateAtFreqTemp("IPsat", multiValue.freq, multiValue.temp);
        let ip1db = multiValue.value;
        let op1dbDelta = this.calculateOP1dBDelta(comp, multiValue);
        return op1dbDelta.add(ipsat.minus(ip1db));
    }

    validate(node) {
        let errors = {};

        let pSat = node.parameters.get("IPsat");
        this.multiValues.forEach(mv => {
            const satValue = node.interpolateAtFreqTemp(pSat, mv.freq, mv.temp);
            let difference = satValue.minus(mv.value);
            if (difference.lessThan(0.25)) {
                this._validateAddError(errors, ["multiValues", mv.freq, mv.temp], `At this multi-value point, IP1dB must be ${satValue.minus(0.25).toFixed(2)}dBm or less`);
            } else if (difference.greaterThan(10)) {
                this._validateAddError(errors, ["multiValues", mv.freq, mv.temp], `At this multi-value point, IP1dB must be ${satValue.minus(10).toFixed(2)}dBm or greater`);
            }
        });

        return errors;
    }
}
