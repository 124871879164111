import * as Sentry from "@sentry/browser";
import Parameters from "./components/utils/parameters.js";
import { convertToMultiValue } from "./sparams.js";

function findInputs(node, results) {
    /**
     * Return an array of the inputs for this node in rank order of the incoming ports.
     *
     * Find all the parents for the node and fetch the output it generated for this node
     */
    let inputs = [];
    let linksInto = node.findLinksInto();
    while (linksInto.next()) {
        let link = linksInto.value;
        let parent = link.fromNode;
        let rankOut = link.fromPortId.split("-")[1];
        if (!!rankOut) {
            rankOut = parseInt(rankOut);
        } else {
            rankOut = 1;
        }
        let rankIn = link.toPortId.split("-")[1];
        if (!!rankIn) {
            rankIn = parseInt(rankIn);
        } else {
            rankIn = 1;
        }
        inputs[rankIn - 1] = results[parent.data.key][rankOut - 1];
    }

    return inputs;
}

function buildQueue(node, queue) {
    /**
     * Builds a queue of nodes that need simulating and lists their dependencies
     */
    if (!queue) queue = { dependencies: {}, todo: {}, done: {}, groups: {}, results: {} };
    if (!queue.todo[node.data.key]) {
        queue.todo[node.data.key] = node;
        if (node.containingGroup) {
            queue.groups[node.containingGroup.data.key] = node.containingGroup;
        }
        let linksOut = node.findLinksOutOf();
        while (linksOut.next()) {
            let link = linksOut.value;
            let child = link.toNode;
            if (!queue.dependencies[child.data.key]) queue.dependencies[child.data.key] = [];
            queue.dependencies[child.data.key].push(node.data.key);
            queue = buildQueue(child, queue);
        }
        if (node.data.category === "CMB") {
            let linksInto = node.findLinksInto();
            while (linksInto.next()) {
                let link = linksInto.value;
                let parent = link.fromNode;
                queue.results[parent.data.key] = parent.data._outputs;
            }
        }
    }
    return queue;
}

function simNode(model, node, results, reasons) {
    let previousOutputs = node.data._outputs;

    let inputs = findInputs(node, results);
    let outputs = node.data.simulate(inputs, reasons);

    if (previousOutputs.length === 0) {
        // Device either hasn't been simulated yet, or has no outputs
        outputs.forEach(output => {
            for (let p of output.all) {
                p.isWatched = (!!node.data.watchedOutputNames) ? node.data.watchedOutputNames.indexOf(p.name) !== -1 : false
            }
        });
    } else {
        // Node is connected and has already been simulated
        // So copy the watched and enabled flags over from previous run
        outputs.forEach((output, idx) => {
            let previous = previousOutputs[idx];
            if (!!previous) {
                for (let c of previous.all) {
                    let o = output.get(c.name);
                    if (o !== null) {
                        o.isWatched = c.isWatched;
                        o.isEnabled = c.isEnabled;
                    }
                }
            }
        });
    }

    let watched = Array.from(node.data.combineOutputs(outputs));
    model.setDataProperty(node.data, "_watchedOutputs", watched);
    model.setDataProperty(node.data, "_isWarning", node.data._isWarning);
    model.setDataProperty(node.data, "_isDanger", node.data._isDanger);

    // Update the watched params because the simulation might have been
    // triggered by changing the Source project type and so now we need
    // to show the OIP etc. values or IIP etc. values.
    watched = Array.from(node.data.parameters.getWatched(node.data.getDeviceType()));
    diagram.model.setDataProperty(node.data, "_watchedParameters", watched);

    for (let p of node.data._calculations.all) {
        p.isWatched = (!!node.data.watchedCalculationNames) ? node.data.watchedCalculationNames.indexOf(p.name) !== -1 : false
    }
    watched = Array.from(node.data._calculations.getWatched(node.data.getDeviceType()));
    diagram.model.setDataProperty(node.data, "_watchedCalculations", watched);

    return outputs;
}

function simulateNodes(model, queue, reasons) {
    let loopMax = Object.keys(queue.todo).length;
    while (Object.keys(queue.todo).length > 0) {
        if (loopMax === 0) throw "Infinite loop when trying to simulate??";
        loopMax--;

        let todo = {};
        for (let key of Object.keys(queue.todo)) {
            let node = queue.todo[key];
            let depsDone = true;
            if (!!queue.dependencies[key]) {
                for (let depKey of queue.dependencies[key]) {
                    if (!queue.done[depKey]) {
                        todo[key] = node;
                        depsDone = false;
                        break;
                    }
                }
            }
            if (depsDone) {
                queue.results[node.data.key] = simNode(model, node, queue.results, reasons);
                queue.done[key] = node;
            }
        }
        queue.todo = todo;
    }
}

function unsimNode(model, node) {
    model.setDataProperty(node, "_inputs", [])
    model.setDataProperty(node, "_outputs", []);
    model.setDataProperty(node, "_watchedOutputs", []);
    model.setDataProperty(node, "_isWarning", false);
    model.setDataProperty(node, "_isDanger", false);
}

function unsimulateNodes(diagram, startingNode) {
    let queue = [startingNode.data.key], data = {};
    data[startingNode.data.key] = startingNode;

    for (let i = 0; i < queue.length; i++) {
        let key = queue[i], node = data[key];
        let shouldSimulate = false;
        if (node.data.category === "CMB") {
            let incomingLinks = node.findLinksInto();
            while (incomingLinks.next()) {
                let link = incomingLinks.value;
                let port = link.fromPortId;
                let parent = link.fromNode;
                let input = parent.data.outputOnPort(port);
                if (!!input) {
                    shouldSimulate = true;
                    break;
                }
            }
        }
        if (shouldSimulate) {
            simulate(diagram, node, true);
        } else {
            unsimNode(diagram.model, node.data);
            let children = node.findNodesOutOf();
            while (children.next()) {
                let child = children.value;
                data[child.data.key] = child;
                queue.push(child.data.key);
            }
        }
    }
}

let IS_SIMULATING = false;

export function unsimulate(diagram, startingNode) {
    /** Remove cached simulation values from nodes, and resimulate other nodes if needed
     *
     * For instance, when you remove a link you want the downstream nodes to "forget" about
     * the previous simulation data. You also need any connected combiners to re-simulate
     * with the missing connection.
     *
     */
    if (IS_SIMULATING) return;

    IS_SIMULATING = true;
    let model = diagram.model;
    model.skipsUndoManager = true;
    model.startTransaction("unsimulate");
    try {
        unsimulateNodes(diagram, startingNode);
    } catch (e) {
        console.error("Couldn't unsimulate?", e);
        Sentry.captureException(e);
    }
    model.commitTransaction("unsimulate");
    model.skipsUndoManager = false;
    IS_SIMULATING = false;
}

export default function simulate(diagram, startingNode, force = false, reasons = []) {
    if (IS_SIMULATING && !force) return;

    IS_SIMULATING = true;
    let model = diagram.model;
    model.skipsUndoManager = true;
    model.startTransaction("simulate");
    let queue;
    if (!startingNode) {
        let roots = diagram.findTreeRoots();
        while (roots.next()) {
            let root = roots.value;
            if (root.data.category === "SRC") {
                queue = buildQueue(root, queue);
            }
        }
    } else {
        queue = { dependencies: {}, todo: {}, done: {}, groups: {}, results: {} };
        let linksInto = startingNode.findLinksInto();
        while (linksInto.next()) {
            let link = linksInto.value;
            let parent = link.fromNode;
            queue.results[parent.data.key] = parent.data._outputs;
        }
        queue = buildQueue(startingNode, queue);
    }

    if (!queue || Object.keys(queue.todo).length === 0) {
        return;
    }

    try {
        simulateNodes(model, queue, reasons);
    } catch (e) {
        console.error("Couldn't simulate!", e);
        window.rfgShowToast("Couldn't Simulate!", "There was an error simulating your graph", "error");
    }

    for (const grp of Object.values(queue.groups)) {
        grp.data.updateOutputs(diagram);
    }


    model.commitTransaction("simulate");
    model.skipsUndoManager = false;
    IS_SIMULATING = force;
}

export function changeInBackground(node, param, changes) {
    for (const change of node._change(node, param, changes)) {
        const type = change[0];

        if (type === "set") {
            change[1][change[2]] = change[3];
        }
    }
}

export function simulateInBackground(startingNode, startingParams, stopKey) {
    // TODO: This is messy
    let queue = buildQueue(startingNode);
    if (!queue) {
        return;
    }
    let results = queue.results;
    try {
        let loopMax = Object.keys(queue.todo).length;
        while (Object.keys(queue.todo).length > 0) {
            if (loopMax === 0) throw "Infinite loop when trying to simulate??";
            loopMax--;

            let todo = {};
            for (let key of Object.keys(queue.todo)) {
                let node = queue.todo[key];
                let depsDone = true;
                if (!!queue.dependencies[key]) {
                    for (let depKey of queue.dependencies[key]) {
                        if (!queue.done[depKey]) {
                            todo[key] = node;
                            depsDone = false;
                            break;
                        }
                    }
                }
                if (depsDone) {
                    let inputs = findInputs(node, results);

                    let firstInput = null;
                    for (let idx = 0; idx < inputs.length; idx++) {
                        if (!!inputs[idx]) {
                            firstInput = inputs[idx];
                            break;
                        }
                    }

                    let params = node.data.parameters;
                    if (node === startingNode) params = startingParams;

                    for (let idx = 0; idx < params.all.length; idx++) {
                        const param = params.all[idx];
                        if (param.isMultiValued && param.isMultiValuedEnabled && param.multiValues.length > 0) {
                            changeInBackground(node.data, param, [["value", node.data.interpolate(firstInput, param, param.multiValues)]]);
                        }
                        if (param.isSParamsEnabled && param.sParams.length > 0) {
                            changeInBackground(node.data, param, [["value", node.data.calculateUsingSParams(firstInput, param, param.sParams)]]);
                        }
                    }

                    results[node.data.key] = node.data._simulate(params, inputs);
                    queue.done[key] = node;
                    if (!!stopKey && node.data.key === stopKey) {
                        return results;
                    }
                }
            }
            queue.todo = todo;
        }
        return results;
    } catch (e) {
        console.error("Couldn't simulate!", e);
    }
}

export function Queue() {
    // Silly little fake class to make building queues a little easier
    return { dependencies: {}, todo: [], done: {}, results: {}, parameters: {}, categories: {}, nodes: {}, inputsFor: {} };
}

export function addComponentToQueue(queue, component, params, multiValuesEnabled) {
    queue.todo.push(component.key);
    queue.categories[component.key] = component.category;
    queue.nodes[component.key] = component;
    queue.dependencies[component.key] = [];
    queue.inputsFor[component.key] = [];

    if (!params) {
        params = new Parameters(component.parameters);
    }
    if (!multiValuesEnabled) {
        params.all.forEach(p => {
            if (p.isMultiValuedEnabled) {
                p.isMultiValuedEnabled = false;
            }
        })
    }

    let gain = params.get("Gain");
    if (!!gain && gain.isSParamsEnabled && gain.sParams.length > 0 && multiValuesEnabled) {
        gain.multiValues = convertToMultiValue(gain.sParams);
        gain.isMultiValuedEnabled = true;
    }
    queue.parameters[component.key] = params;
    return queue;
}

export function buildDisconnectedQueue(component, queue) {
    /**
     * Builds a queue of nodes that need simulating and lists their dependencies
     * This queue contains all the information needed to simulate, without access to the diagram
     */
    if (!queue) queue = Queue();
    if (queue.todo.indexOf(component.key) === -1) {
        queue = addComponentToQueue(queue, component, null, self._isMultiValuesEnabled);
        for (let idx = 0; idx < component._connectedComponentsIn.length; idx++) {
            const c = component._connectedComponentsIn[idx];
            if (!c) continue;
            queue.dependencies[component.key].push(c.key);
            let outIndex = c._connectedComponentsOut.map(d => d.key).indexOf(component.key);
            queue.inputsFor[component.key][idx] = [c.key, outIndex];
            queue = buildDisconnectedQueue(c, queue);
        }
    }
    return queue;
}


export function simulateInBackgroundDisconnected(queue) {
    let loopMax = queue.todo.length;
    while (queue.todo.length > 0) {
        if (loopMax === 0) throw "Infinite loop when trying to simulate??";
        loopMax--;

        let todo = [];
        for (let key of queue.todo) {
            let depsDone = true;
            if (!!queue.dependencies[key]) {
                for (let depKey of queue.dependencies[key]) {
                    if (!queue.done[depKey]) {
                        todo.push(key);
                        depsDone = false;
                        break;
                    }
                }
            }
            if (depsDone) {
                let inputs = [];
                for (let idx in queue.inputsFor[key]) {
                    let [parentKey, resultIndex] = queue.inputsFor[key][idx];
                    inputs[idx] = queue.results[parentKey][resultIndex];
                }
                queue.results[key] = queue.nodes[key]._simulate(new Parameters(queue.parameters[key]), inputs);
                queue.done[key] = true;
            }
        }
        queue.todo = todo;
    }
    return queue.results;
}
