<script>
    import { visibleModal } from "../stores.js";
    import Modal from "./Modal.svelte";
    import TextArea from "../pages/components/form/TextArea.svelte";

    export let title;
    export let helpText;
    export let text;
    export let onConfirm;

    const handleSubmit = () => {
        onConfirm(text);
        visibleModal.set(null);
    };
</script>

<Modal
    {title}
    icon="fal fa-edit fa-2x"
    confirmText="Save"
    confirmIcon="fal fa-floppy-disk"
    onConfirm={handleSubmit}
>
    <form on:submit|preventDefault={handleSubmit}>
        <TextArea
            bind:value={text}
            error={null}
            label={null}
            name="EditText-text"
            {helpText}
            required={true}
            autofocus={true}
            rows={10}
        />
        <button class="hidden" type="submit">Submit</button>
    </form>
</Modal>
