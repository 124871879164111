import pdfMake from "pdfmake/build/pdfmake";
import makeImage from "../../../camera.js";

pdfMake.fonts = {
    "IBM Plex Sans": {
        normal: `${import.meta.env.VITE_WEB_BASE}/fonts/IBMPlexSans-Regular.ttf`,
        bold: `${import.meta.env.VITE_WEB_BASE}/fonts/IBMPlexSans-SemiBold.ttf`,
    }
}

function table(data) {
    if (!data.length) {
        return {
            text: "None"
        }
    }
    return {
        table: {
            widths: ["*", "auto", "auto"],
            body: data.map(p => {
                return [
                    { text: p.name, style: "tableHeader", fontSize: 10 },
                    { text: p.getPretty(), alignment: 'right', fontSize: 10 },
                    { text: p.unit, fontSize: 10 }
                ]
            })
        },
        style: "table",
        layout: "noBorders",
    }
}

function addDefaults(content) {
    return {
        pageSize: "LETTER",
        content: content,
        styles: {
            header: {
                fontSize: 16,
            },
            subheader: {
                fontSize: 14,
            },
            table: {
                margin: [0, 5, 0, 10]
            },
            tableHeader: {
                bold: true,
                fontSize: 10,
            }
        },
        defaultStyle: {
            font: 'IBM Plex Sans',
            columnGap: 16,
        },
        images: {
            logo: `${import.meta.env.VITE_WEB_BASE}/logo-lockup-black.png`
        }
    }
}

export function downloadReport(diagram, source, termination, calculations, output, graphName, userEmail, callback) {
    makeImage(
        diagram,
        {
            background: "transparent",
            format: "png",
            scale: 1,
            whichNodes: "chain",
            chainNode: diagram.findNodeForKey(termination.key),
            returnType: "string",
            callback: (imageUri) => {
                let content = [
                    {
                        columns: [
                            {
                                image: "logo",
                                width: 200
                            },
                            [
                                { text: graphName, alignment: "right", fontSize: 16 },
                                { text: userEmail, alignment: "right" },
                                { text: (new Date()).toLocaleString(), alignment: "right", fontSize: 10 },
                            ]
                        ]
                    },
                    {
                        image: imageUri,
                        fit: [595.28 - 40 - 40, 180],
                        margin: [0, 50],
                        alignment: "center"
                    },
                    {
                        text: `Parameters at ${source.name}`,
                        style: "header",
                    },
                    {
                        columns: [
                            [
                                table(source.parameters.all),
                            ],
                            {
                                width: "50%",
                                stack: [
                                    (!!source.summary) ? { text: source.summary, margin: [0, 5, 0, 10] } : null,
                                    (!!source.notes) ? { text: source.notes, margin: [0, 5, 0, 10] } : null
                                ]
                            }
                        ]
                    },
                    {
                        text: `Output at ${termination.name}`,
                        style: "header",
                    },
                    {
                        columns: [
                            [
                                table(output.context),
                                table(calculations)
                            ],
                            {
                                width: "50%",
                                stack: [
                                    (!!termination.summary) ? { text: termination.summary, margin: [0, 5, 0, 10] } : null,
                                    (!!termination.notes) ? { text: termination.notes, margin: [0, 5, 0, 10] } : null
                                ]
                            }
                        ]
                    }
                ];
                pdfMake.createPdf(addDefaults(content)).download("RFGraph Report", callback);
            }
        });
}
