import { derived, writable } from 'svelte/store';
import jwt_decode from "jwt-decode";

const persistentWritable = (key, startValue) => {
    const { subscribe, set } = writable(startValue);
    const json = localStorage.getItem(key);
    if (!!json) {
        try {
            set(JSON.parse(json));
        } catch {
            set(startValue);
        }
    }

    window.addEventListener("storage", evt => {
        if (evt.key === key) {
            set(JSON.parse(evt.newValue));
        }
    });

    subscribe(current => {
        localStorage.setItem(key, JSON.stringify(current));
    });
    return {
        subscribe,
        set
    };
}

export const authToken = persistentWritable("authToken", null);

export const parsedAuthToken = derived(
    authToken,
    $authToken => {
        if (!!$authToken) {
            return jwt_decode($authToken);
        }
        return {};
    }
);

export const isValidAuthToken = derived(
    parsedAuthToken,
    $parsedAuthToken => {
        if (!!$parsedAuthToken.exp) {
            return new Date($parsedAuthToken.exp * 1000) > new Date();
        }
        return false;
    }
);

export const user = writable(null);

export const isEmailVerified = derived(
    user,
    $user => {
        if (!$user) return true;
        return !!$user.is_verified;
    }
);

export const isExpired = derived(
    user,
    $user => {
        if (!$user || !$user.subscription_expiry) return false;
        let exp = new Date($user.subscription_expiry);
        return exp - new Date() < 0;
    }
);

export const accountStatus = derived(
    [user, isExpired],
    ([$user, $isExpired]) => {
        if (!$user) return
        if ($isExpired) return "standard"
        if ($user.subscription_status === "in_trial") return "trial"
        if ($user.subscription_status === "cancelled") return "cancelled"
        return "pro"
    }
);

export const features = derived(
    isExpired,
    $isExpired => {
        let state = "available";
        if ($isExpired) {
            state = "upsell"
        }
        return {
            mirror: state,
            sparams: state,
            multivalues: state,
            customcomponents: state,
        }
    }
);

export const isBusy = writable(false);
export const modalConfirmEnabled = writable(true);
export const hasUnsavedChanges = writable(false);
export const graphName = writable(null);
export const highlight = writable(null);

export const nextPage = writable(null);
export const visiblePage = writable("/");
export const visibleModal = writable(null);
export const toasts = writable([]);

export const probePlotSettings = writable({ plotYAxis: "", plotXAxis: "Frequency" });
