<script>
    import { createBillingPortal } from "../api.js";
    import { isBusy, authToken } from "../stores.js";
    import Modal from "./Modal.svelte";

    const handleConfirm = () => {
        isBusy.set(true);
        createBillingPortal($authToken)
            .then((resp) => {
                window.location = resp.url;
            })
            .finally(() => isBusy.set(false));
    }
</script>

<Modal
    title="Downgrade to Standard"
    confirmText="Open Billing Portal"
    cancelText="Cancel"
    onConfirm={handleConfirm}
>
    <p class="mb-2">When you downgrade, your current subscription (if any) will be cancelled. You will still have access to all of the PRO features until your subscription expires.</p>
    <p class="mb-2">Your credit card will not be charged.</p>
    <p class="mb-2 font-bold">To downgrade you must open the billing portal and choose the option to cancel your subscription.</p>
</Modal>
