let settings = {
    primaryColor: "#0ad6cc",
    dangerColor: "rgb(239, 68, 68)",
    warningColor: "rgb(245, 158, 11)",
    textColor: "black",
    darkColor: "#46484d",
    brandColor: "#0ad6cc",
    brandColorLighter: "#9efaf6",
    fontFamily: "'IBM Plex Sans', sans-serif",
}
settings.font = function (size = "14px", weight = "normal") {
    return `${weight} ${size} ${settings.fontFamily}`
}

export default settings;
