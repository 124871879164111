import Parameter from "../utils/parameter.js";

export default class IPsat extends Parameter {
    constructor(initialValue, overrides) {
        let args = overrides || {};
        args.name = "IPsat";
        args.isMultiValued = true;
        args.value = initialValue;
        args.unit = Parameter.valueOrDefault(args.unit, "dBm");

        args.minValue = Parameter.valueOrDefault(args.minValue, -99.99);
        args.maxValue = Parameter.valueOrDefault(args.maxValue, 99.99);

        args.isEnabled = Parameter.valueOrDefault(args.isEnabled, false);
        args.isWatched = Parameter.valueOrDefault(args.isWatched, false);

        args.cascadeWatched = Parameter.valueOrDefault(args.cascadeWatched, ["OPsat"]);
        args.cascadeEnabled = Parameter.valueOrDefault(args.cascadeEnabled, ["OPsat", "IP1dB", "OP1dB"]);
        args.cascadeMVEnabled = Parameter.valueOrDefault(args.cascadeMVEnabled, ["OPsat", "IP1dB", "OP1dB"]);
        args.cascadeMV = Parameter.valueOrDefault(args.cascadeMV, []);

        super(args);

        this.cascadeMV = this.mergeCascadeMV([
            {
                name: "IP1dB",
                direction: "above",
                delta: 0.5,
                maxDelta: 10,
                minDelta: 0.25,
                enabled: true,
                cascadeDelta: {
                    IP1dB: "IPsat",
                    OP1dB: "OPsat",
                    OPsat: "OP1dB"
                }
            },
            { name: "OPsat", enabled: true, hidden: true, calculateDelta: 'calculateOPsatDelta' },
            { name: "OP1dB", enabled: true, hidden: true, calculateDelta: 'calculateOP1dBDelta' },
        ], this.cascadeMV);
    }

    calculateOP1dBDelta(comp, multiValue) {
        let ip1db = comp.interpolateAtFreqTemp("IP1dB", multiValue.freq, multiValue.temp);
        let ipsat = multiValue.value;
        let opsatDelta = this.calculateOPsatDelta(comp, multiValue);
        return opsatDelta.minus(ipsat.minus(ip1db));
    }

    calculateOPsatDelta(comp, multiValue) {
        let gain = comp.interpolateAtFreqTemp("Gain", multiValue.freq, multiValue.temp);
        return gain.minus(1);
    }

    validate(node) {
        let errors = {};

        let p1db = node.parameters.get("IP1dB");
        this.multiValues.forEach(mv => {
            const p1dbValue = node.interpolateAtFreqTemp(p1db, mv.freq, mv.temp);
            let difference = mv.value.minus(p1dbValue);
            if (difference.lessThan(0.25)) {
                this._validateAddError(errors, ["multiValues", mv.freq, mv.temp], `At this multi-value point, IPsat must be ${p1dbValue.plus(0.25).toFixed(2)}dBm or greater`);
            } else if (difference.greaterThan(10)) {
                this._validateAddError(errors, ["multiValues", mv.freq, mv.temp], `At this multi-value point, IPsat must be ${p1dbValue.plus(10).toFixed(2)}dBm or less`);
            }
        });

        return errors;
    }
}
