<script>
    import { authToken, visiblePage, nextPage, isBusy } from "../stores.js";
    import { login, me } from "../api.js";
    import Button from "./components/Button.svelte";
    import Page from "./Page.svelte";
    import Input from "./components/form/Input.svelte";

    let email = "";
    let password = "";
    let errors = {};

    const handleSubmit = (evt) => {
        evt.preventDefault();
        isBusy.set(true);
        login(email, password)
            .then((resp) => {
                if (!resp.error) {
                    authToken.set(resp.access_token);
                    return me(resp.access_token).then(() => {
                        visiblePage.set($nextPage);
                        nextPage.set(null);
                    });
                } else {
                    errors = resp.fields || {};
                }
            })
            .finally(() => isBusy.set(false));
    };
</script>

<Page>
    <form slot="body" on:submit={handleSubmit} class="p-4 max-w-lg mx-auto">
        <h1 class="text-xl font-bold">Sign In</h1>
        <div class="mt-2">
            <Input
                bind:value={email}
                error={errors.username}
                label="Email Address"
                name="signin-email"
                helpText="The email address you signed up with"
                required={true}
                type="email"
            />
            <Input
                bind:value={password}
                error={errors.password}
                label="Password"
                name="signin-password"
                required={true}
                type="password"
            />
        </div>
        <div class="mt-4 flex flex-row justify-between items-start">
            <Button text="Sign In" icon="far fa-sign-in-alt" />
            <div class="text-right">
                <div>
                    <Button
                        text="Need to create an account?"
                        type="button"
                        url="/sign-up/"
                        theme="anchor"
                        toUpperCase={false}
                        boldText={false}
                    />
                </div>
                <div>
                    <Button
                        text="Forgot your password?"
                        type="button"
                        url="/forgot-password/"
                        theme="anchor"
                        toUpperCase={false}
                        boldText={false}
                    />
                </div>
            </div>
        </div>
    </form>
</Page>
