<script>
    import { visibleModal } from "../stores.js";
    import { createEventDispatcher } from 'svelte';

    export let title;
    export let isEnabled = false;
    export let upsell = false;

    const dispatch = createEventDispatcher();

    const onToggleEnabled = () => {
        if (upsell) {
            visibleModal.set({ name: "upsell" });
            return;
        }
        isEnabled = !isEnabled;
        dispatch("toggle-enabled", {isEnabled: isEnabled});
    };
</script>


<div class="mt-2">
    <div
        class="flex flex-row items-center gap-2 mb-1 px-3 py-1 uppercase bg-gray-200"
    >
        {#if !upsell && isEnabled}
            <span class="text-2xl">
                <button on:click={onToggleEnabled} class="relative">
                    <i
                        class="fa-duotone fa-toggle-on"
                        style="--fa-primary-color: white; --fa-secondary-color: var(--color-brand); --fa-secondary-opacity: 1"
                    />
                </button>
            </span>
        {:else}
            <span class="text-2xl">
                <button on:click={onToggleEnabled} class="relative">
                    <i
                        class="fa-duotone fa-toggle-off"
                        style="--fa-primary-color: black; --fa-secondary-color: black; --fa-primary-opacity: .4"
                    />
                </button>
            </span>
        {/if}
        <h4 class="">{title}</h4>
        {#if upsell}
            <span class="text-black ml-auto text-[0.65rem] bg-[#FFD51E] rounded-xl border border-[#D6B513] px-2" style="line-height: 1rem">
                PRO
            </span>
        {/if}
    </div>
    <div class:hidden={upsell || !isEnabled}>
        <slot />
    </div>
</div>
