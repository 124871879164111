<script>
    import { authToken, isBusy, visibleModal } from "../stores.js";
    import { deleteGraph } from "../api.js";
    import Modal from "./Modal.svelte";

    export let graphId;
    export let graphName;
    export let afterConfirm;
    let error = null;

    const handleSubmit = () => {
        if ($isBusy) {
            return;
        }
        error = null;
        isBusy.set(true);

        let req = deleteGraph($authToken, graphId);
        req.then((resp) => {
            if (!resp.error) {
                visibleModal.set(null);
                if (!!afterConfirm) {
                    afterConfirm(resp);
                }
            } else {
                error = resp.error;
            }
        }).finally(() => isBusy.set(false));
    };
</script>

<Modal
    title="Delete {graphName}?"
    icon="far fa-trash-alt fa-2x"
    confirmIcon="far fa-trash-alt"
    confirmText="Delete"
    onConfirm={handleSubmit}
>
    {#if error}
        <p class="mt-1 text-red-500">
            {error}
        </p>
    {/if}
    <div class="mt-2">
        <p>Are you sure you want to delete this graph?</p>
    </div>
</Modal>
