<script>
    import { onMount } from "svelte";
    import { isBusy, authToken, visiblePage } from "../stores.js";
    import { acceptSend } from "../api.js";
    import Page from "./Page.svelte";

    export let token;
    export let graphId;

    onMount(() => {
        isBusy.set(true);
        acceptSend($authToken, graphId, token)
            .then((resp) => {
                if (resp.error) {
                    window.rfgShowToast(
                        "Error",
                        "There was an error accessing the graph, please get in touch.",
                        "error"
                    );
                    visiblePage.set(`/my-graphs/`);
                } else {
                    visiblePage.set(`/graphs/${resp.id}/`);
                }
            })
            .finally(() => isBusy.set(false));
    });
</script>

<Page>
    <div slot="body" class="">
        <p class="text-xl text-center mt-4">Loading...</p>
    </div>
</Page>
