import Parameter from "./parameter.js";
import IIP2 from "../parameters/iip2.js";
import IIP3 from "../parameters/iip3.js";
import IP1BackOff from "../parameters/ip1backoff.js";
import IP1dB from "../parameters/ip1db.js";
import IPsat from "../parameters/ipsat.js";
import OIP2 from "../parameters/oip2.js";
import OIP3 from "../parameters/oip3.js";
import OP1BackOff from "../parameters/op1backoff.js";
import OP1dB from "../parameters/op1db.js";
import OPsat from "../parameters/opsat.js";
import Gain from "../parameters/gain.js";
import NF from "../parameters/nf.js";


export default class Parameters {

    constructor(data) {
        this.all = [];
        this.isHidden = false;
        if (data !== undefined) {
            if (data instanceof Parameters) {
                this.all = data.all.map(p => Parameters.parameterFactory(p));
            } else {
                this.all = data.map(p => Parameters.parameterFactory(p));
            }

        }
    }

    static parameterFactory(args) {
        if (args.name === "IIP2") {
            return new IIP2(args.value, args);
        }
        if (args.name === "IIP3") {
            return new IIP3(args.value, args);
        }
        if (args.name === "IP1BackOff") {
            return new IP1BackOff(args.value, args);
        }
        if (args.name === "IP1dB") {
            return new IP1dB(args.value, args);
        }
        if (args.name === "IPsat") {
            return new IPsat(args.value, args);
        }
        if (args.name === "OIP2") {
            return new OIP2(args.value, args);
        }
        if (args.name === "OIP3") {
            return new OIP3(args.value, args);
        }
        if (args.name === "OP1BackOff") {
            return new OP1BackOff(args.value, args);
        }
        if (args.name === "OP1dB") {
            return new OP1dB(args.value, args);
        }
        if (args.name === "OPsat") {
            return new OPsat(args.value, args);
        }
        if (args.name === "Gain") {
            return new Gain(args.value, args);
        }
        if (args.name === "NF") {
            return new NF(args.value, args);
        }
        return new Parameter(args);
    }

    indexOf(name) {
        for (let idx = 0; idx < this.all.length; idx++) {
            if (this.all[idx].name === name) {
                return idx;
            }
        }
        return -1;
    }

    add(param) {
        this.all.push(Parameters.parameterFactory(param));
    }

    get(name) {
        for (const p of this.all) {
            if (p.name === name) {
                return p;
            }
        }
        return null;
    }

    set(name, newParam) {
        let p = this.get(name);
        if (p !== null) {
            p.set(newParam);
        }
        return p
    }

    merge(other) {
        if (other !== undefined) {
            for (let o of other) {
                o = Parameters.parameterFactory(o);
                let p = this.get(o.name);
                if (p !== null) {
                    p.merge(o);
                } else {
                    this.add(o);
                }
            }
        }
    }

    dump() {
        return this.all.map(p => p.dump())
    }

    *getVisible(deviceType) {
        if (!this.isHidden) {
            let isTransmitter = deviceType === "TX";
            for (const p of this.all) {
                if (!p.isHidden) {
                    if (p.isTransmitterParam()) {
                        if (isTransmitter) yield p;
                    } else if (p.isReceiverParam()) {
                        if (!isTransmitter) yield p;
                    } else {
                        yield p;
                    }
                }
            }
        }
    }

    *getWatched(deviceType) {
        for (const p of this.getVisible(deviceType)) {
            if (p.isWatched) {
                yield p;
            }
        }
    }
}
