<script>
    import Button from "./components/Button.svelte";
    import Page from "./Page.svelte";
    import Section from "./components/Section.svelte";
    import { accountStatus, visibleModal } from "../stores.js";
    import logoUrl from "../images/icon-black.png";

    const handleUpgradeToPro = () => {
        visibleModal.set({name: "subscription-pro"});
    };

    const handleDowngradeToStandard = () => {
        visibleModal.set({name: "subscription-standard"});
    };

    const getPrettyTier = status => {
        if (status === "trial") return "Free Trial"
        if (status === "pro") return "Pro plan"
        if (status === "cancelled" || status === "non_renewing") return "Pro plan (cancelled)"
        return "Standard plan"
    }
</script>

<Page bgColour="gray-100" padding="0">
    <div slot="body">
        <div class="text-center mt-8">
            <h1 class="text-3xl font-bold mb-2">RFGraph Plans</h1>
            <p>You are currently on the <strong>{getPrettyTier($accountStatus)}</strong>.</p>
        </div>
        <div class="flex flex-row gap-4 justify-center mt-8">
            <Section>
                <div class="w-[250px]" slot="header">
                    <div class="-mt-8">
                        <div class="h-10 w-10 bg-brand rounded border flex flex-row items-center justify-center text-lg mx-auto">
                            <img src="{logoUrl}" alt="The RFGraph logo" class="h-[26px] inline-block "/>
                        </div>
                    </div>
                    <h2 class="font-bold text-center">STANDARD plan</h2>
                </div>
                <div class="-mx-4 h-full flex flex-col" slot="body">
                    <div class="border-b text-brand text-center py-4 text-2xl">
                        Free
                    </div>
                    <ul class="text-center py-4 grow">
                        <li>Standard components</li>
                        <li>Generic components</li>
                        <li>Export reports</li>
                        <li>Share graphs</li>
                    </ul>
                    <div class="px-4">
                        <Button
                            text={$accountStatus === "standard" ? "Selected" : "Downgrade"}
                            theme="black"
                            width="full"
                            onClick={handleDowngradeToStandard}
                            isDisabled={$accountStatus === "standard" || $accountStatus === "cancelled" || $accountStatus === "non_renewing"}
                        />
                    </div>
                </div>
            </Section>
            <Section>
                <div class="w-[250px]" slot="header">
                    <div class="-mt-8">
                        <div class="h-10 w-10 bg-[#FFD51E] rounded border flex flex-row items-center justify-center text-lg mx-auto">
                            <i class="fas fa-fw fa-star"></i>
                        </div>
                    </div>
                    <h2 class="font-bold text-center">PRO plan</h2>
                </div>
                <div class="-mt-2 -mx-4 flex flex-col" slot="body">
                    <div class="border-b text-2xl grid grid-cols-2">
                        <div class="flex flex-col items-center border-r pb-4 pt-2">
                            <span class="text-brand">$9.99</span>
                            <span class="text-xs">Monthly</span>
                        </div>
                        <div class="flex flex-col items-center pb-4 pt-2">
                            <span class="text-brand">$99</span>
                            <span class="text-xs">Annual</span>
                        </div>
                    </div>
                    <ul class="text-center py-4 grow">
                        <li>Everything in Standard</li>
                        <li>S-Parameters</li>
                        <li>Multiple values</li>
                        <li>Custom components</li>
                        <li>Mirror components</li>
                    </ul>
                    <div class="px-4">
                        <Button
                            text={$accountStatus === "pro" ? "Change Billing" : "Upgrade to Pro"}
                            theme="pro"
                            width="full"
                            onClick={handleUpgradeToPro}
                        />
                    </div>
                </div>
            </Section>
        </div>
        <h2 class="text-center font-bold text-2xl mt-8">Go PRO for enhanced features</h2>
        <p class="text-center text-sm mt-4">Unlock all of these enhanced features for $9.99 per month.</p>
        <div class="grid grid-cols-2 gap-6 text-sm mt-8 max-w-2xl mx-auto mb-8">
            <div class="flex flex-col items-center gap-2 bg-gray-200 rounded p-4">
                <div class="flex flex-row items-center justify-center">
                    <div class="bg-white rounded border border-gray-300 w-12 h-12 text-2xl flex flex-row items-center justify-center">
                        <i class="h-[45px] fad fa-fw fa-chart-scatter" style="--fa-secondary-color: #FFD51E; --fa-primary-opacity: 1; --fa-secondary-opacity: 1"></i>
                    </div>
                </div>
                <div class="text-center">
                    <p class="font-bold">S-Parameters</p>
                    <p class="text-xs">Make beautiful plots that sweep over frequency and reduce the number of single frequency components with s-parameters. Great time saver.</p>
                </div>
            </div>
            <div class="flex flex-col items-center gap-2 bg-gray-200 rounded p-4">
                <div class="flex flex-row items-center justify-center">
                    <div class="bg-white rounded border border-gray-300 w-12 h-12 text-2xl flex flex-row items-center justify-center">
                        <i class="fad fa-fw fa-rectangle-vertical-history" style="--fa-primary-color: #FFD51E; --fa-primary-opacity: 1; --fa-secondary-opacity: 1"></i>
                    </div>
                </div>
                <div class="text-center">
                    <p class="font-bold">Multiple values</p>
                    <p class="text-xs">Nonlinear parameters can be modeled by generating a table of values from a component's datasheet.  Input these over frequency and temperature for great plots.</p>
                </div>
            </div>
            <div class="flex flex-col items-center gap-2 bg-gray-200 rounded p-4">
                <div class="flex flex-row items-center justify-center">
                    <div class="bg-white rounded border border-gray-300 w-12 h-12 text-2xl flex flex-row items-center justify-center">
                        <i class="h-[45px] fad fa-fw fa-square-bolt" style="--fa-secondary-color: #FFD51E; --fa-primary-opacity: 1; --fa-secondary-opacity: 1"></i>
                    </div>
                </div>
                <div class="text-center">
                    <p class="font-bold">Save custom components</p>
                    <p class="text-xs">Grow your own internal library for parts that you use often and reuse these parts over all your designs with ease.</p>
                </div>
            </div>
            <div class="flex flex-col items-center gap-2 bg-gray-200 rounded p-4">
                <div class="flex flex-row items-center justify-center">
                    <div class="bg-white rounded border border-gray-300 w-12 h-12 text-2xl flex flex-row items-center justify-center">
                        <i class="h-[45px] fad fa-fw fa-people-pants" style="--fa-primary-color: #FFD51E; --fa-primary-opacity: 1; --fa-secondary-opacity: 1"></i>
                    </div>
                </div>
                <div class="text-center">
                    <p class="font-bold">Mirror components</p>
                    <p class="text-xs">Great for developing a new cascaded lineup with many of the same device. Change one device and it changes all the others.</p>
                </div>
            </div>
        </div>
        <div class="text-center mb-8">
            <Button
                text="Upgrade to Pro"
                theme="pro"
                onClick={handleUpgradeToPro}
            />
        </div>
    </div>
</Page>
