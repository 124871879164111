<script>
    import Decimal from "decimal.js";
    import Button from "../pages/components/Button.svelte";
    import Checkbox from "../pages/components/form/Checkbox.svelte";
    import Collapsable from "./_Collapsable.svelte";
    import { createEventDispatcher } from 'svelte';
    import Input from "../pages/components/form/Input.svelte";
    import { modalConfirmEnabled, features } from "../stores.js";

    const PRECISION = parseInt(import.meta.env.VITE_DECIMAL_PRECISION || 2);

    export let parameter;
    export let valueUnit;
    export let freqUnit;
    export let multiValues = [];
    export let isEnabled;
    export let isEditable;
    export let errors;

    const dispatch = createEventDispatcher();
    let newFreq = "";
    let newTemp = "25";
    let newValue = "";

    const handleAdd = () => {
        let freq = new Decimal(newFreq);
        let temp = new Decimal(newTemp);
        let mvs = [{freq: freq, temp: temp, value: new Decimal(newValue)}, ...multiValues];
        mvs.sort((a, b) => {
            if (a.temp.equals(b.temp)) {
                return a.freq.comparedTo(b.freq);
            }
            return a.temp.comparedTo(b.temp);
        });
        multiValues = mvs;
        newFreq = "";
        newTemp = "25";
        newValue = "";
        dispatch("change");
    }

    const handleRemove = (idx) => {
        multiValues.splice(idx, 1);
        multiValues = multiValues;
        dispatch("change");
    }

    const handleChange = (mv, field) => {
        if (!!mv.freq_error || !!mv.temp_error || !!mv.value_error) {
            modalConfirmEnabled.set(false);
        } else {
            let v = mv[field];
            if (v !== null) v = new Decimal(mv[field]);
            mv[field] = v
            modalConfirmEnabled.set(true);
            dispatch("change");
        }
    }

    const handleCascadeDeltaChange = cascade => {
        if (!!cascade.error) {
            modalConfirmEnabled.set(false);
        } else {
            modalConfirmEnabled.set(true);
            dispatch("change");
        }
    }
</script>

<Collapsable title="Multiple Values" bind:isEnabled={isEnabled} on:toggle-enabled upsell={$features.multivalues === "upsell"}>
    <p class="text-sm">If you have multiple values for the parameter over frequency, add them all here. We'll use linear interpolation to provide values at any frequency.</p>
    <div class="grid grid-cols-[1fr,1fr,1fr,80px] items-start gap-2 mt-2">
        <strong class="font-medium text-gray-900">Freq. ({freqUnit})</strong>
        <strong class="font-medium text-gray-900">Temp. (°C)</strong>
        <strong class="font-medium text-gray-900">Value ({valueUnit})</strong>
        <span></span>
        {#each multiValues as mv, idx}
            <div>
                <Input label="Frequency"
                       hideLabel={true}
                       name="mv-freq-{idx}"
                       bind:value={mv.freq}
                       bind:error={mv.freq_error}
                       min={0.01}
                       max={200000}
                       type="number"
                       step="any"
                       disabled={!isEditable}
                       onChange={() => handleChange(mv, "freq")} />
            </div>
            <div>
                <Input label="Temperature"
                       hideLabel={true}
                       name="mv-temp-{idx}"
                       bind:value={mv.temp}
                       bind:error={mv.temp_error}
                       min={-200}
                       max={200}
                       type="number"
                       step="any"
                       disabled={!isEditable}
                       onChange={() => handleChange(mv, "temp")} />
            </div>
            <div>
                <Input label="Value"
                       hideLabel={true}
                       name="mv-value-{idx}"
                       bind:value={mv.value}
                       bind:error={mv.value_error}
                       min={parameter.minValue}
                       max={parameter.maxValue}
                       type="number"
                       step="any"
                       disabled={!isEditable}
                       onChange={() => handleChange(mv, "value")} />
            </div>
            <div class="text-center">
                <Button text="" type="button" icon="far fa-trash" theme="anchor-danger" onClick={() => handleRemove(idx)} isDisabled={!isEditable} />
            </div>
            {#each errors?.multiValues?.[mv.freq]?.[mv.temp] || [] as err}
                <div class="col-span-4 text-red-500 text-xs -mt-2">{err}</div>
            {/each}
        {/each}
        <div>
            <Input label="Frequency"
                       hideLabel={true}
                       name="mv-freq-new"
                       bind:value={newFreq}
                       error={null}
                       min={0.01}
                       max={200000}
                       type="number"
                       step="any"
                       disabled={!isEditable} />
        </div>
        <div>
            <Input label="Temperature"
                       hideLabel={true}
                       name="mv-temp-new"
                       bind:value={newTemp}
                       error={null}
                       min={-200}
                       max={200}
                       type="number"
                       step="any"
                       disabled={!isEditable} />
        </div>
        <div>
            <Input label="Value"
                       hideLabel={true}
                       name="mv-value-new"
                       bind:value={newValue}
                       error={null}
                       min={parameter.minValue}
                       max={parameter.maxValue}
                       type="number"
                       step="any"
                       disabled={!isEditable} />
        </div>
        <div class="text-center">
            <Button text="Add" type="button" icon="far fa-plus" onClick={handleAdd} isDisabled={!isEditable || !newFreq || !newValue} />
        </div>
        {#each errors?.multiValues?.other || [] as err}
            <div class="col-span-4 text-red-500 text-xs">{err}</div>
        {/each}
    </div>
    {#if !!parameter.cascadeMV}
        {#each parameter.cascadeMV as cascade (cascade.name)}
            {#if !cascade.hidden}
                <div class="mt-4">
                    <Checkbox label="Auto update {cascade.name}'s multiple values" name="cascade-enabled-{cascade.name}" bind:value={cascade.enabled} error={null} />
                    <div class="mt-2">
                        <Input label="Fixed Offset {cascade.direction} {cascade.name}"
                                name="cascade-delta-{cascade.name}"
                                bind:value={cascade.delta}
                                bind:error={cascade.error}
                                min={cascade.minDelta}
                                max={cascade.maxDelta}
                                step="any"
                                type="number"
                                onChange={() => handleCascadeDeltaChange(cascade)}
                                disabled={!cascade.enabled} />
                    </div>
                </div>
            {/if}
        {/each}
    {/if}

</Collapsable>
