<script>
    import { visibleModal, visiblePage } from "../stores";
    import Modal from "./Modal.svelte";

    const handleConfirm = () => {
        visiblePage.set("/account/subscription/");
        visibleModal.set(null);
    }
</script>

<Modal
    title="Upgrade to Pro"
    icon="fad fa-circle-star fa-2x"
    iconStyle="--fa-secondary-color: #FFD51E; --fa-primary-opacity: 1; --fa-secondary-opacity: 1"
    confirmText="Upgrade Now"
    confirmIcon="fas fa-star"
    confirmTheme="pro"
    cancelText="Maybe later"
    onConfirm={handleConfirm}
>
    <p class="text-sm">Unlock all of these enhanced features for $9.99 per month.</p>
    <div class="grid grid-cols-2 gap-6 text-sm mt-8">
        <div class="flex flex-row gap-4">
            <div class="">
                <div class="border w-12 h-12 text-2xl flex flex-row items-center justify-center">
                    <i class="fad fa-fw fa-chart-scatter" style="--fa-secondary-color: #FFD51E; --fa-primary-opacity: 1; --fa-secondary-opacity: 1"></i>
                </div>
            </div>
            <div class="">
                <p class="font-bold">S-Parameters</p>
                <p>Make beautiful plots that sweep over frequency.</p>
            </div>
        </div>
        <div class="flex flex-row gap-4">
            <div class="">
                <div class="border w-12 h-12 text-2xl flex flex-row items-center justify-center">
                    <i class="fad fa-fw fa-rectangle-vertical-history" style="--fa-primary-color: #FFD51E; --fa-primary-opacity: 1; --fa-secondary-opacity: 1"></i>
                </div>
            </div>
            <div class="">
                <p class="font-bold">Multiple values</p>
                <p>Model nonlinear parameters using a table of values.</p>
            </div>
        </div>
        <div class="flex flex-row gap-4">
            <div class="">
                <div class="border w-12 h-12 text-2xl flex flex-row items-center justify-center">
                    <i class="fad fa-fw fa-square-bolt" style="--fa-secondary-color: #FFD51E; --fa-primary-opacity: 1; --fa-secondary-opacity: 1"></i>
                </div>
            </div>
            <div class="">
                <p class="font-bold">Save custom components</p>
                <p>Grow your own internal library of parts.</p>
            </div>
        </div>
        <div class="flex flex-row gap-4">
            <div class="">
                <div class="border w-12 h-12 text-2xl flex flex-row items-center justify-center">
                    <i class="fad fa-fw fa-people-pants" style="--fa-primary-color: #FFD51E; --fa-primary-opacity: 1; --fa-secondary-opacity: 1"></i>
                </div>
            </div>
            <div class="">
                <p class="font-bold">Mirror components</p>
                <p>Change one device and it changes all the others.</p>
            </div>
        </div>
    </div>
</Modal>
