<div
    class="bg-white shadow-lg rounded-lg border border-gray-300 mb-4 flex flex-col"
>
    {#if $$slots.header}
        <div class="px-4 py-3 border-b border-gray-300">
            <slot name="header" />
        </div>
    {/if}
    <div class="bg-white px-4 py-2 grow">
        <slot name="body" />
    </div>
    {#if $$slots.footer}
        <div class="px-4 py-3 border-t border-gray-300">
            <slot name="footer" />
        </div>
    {/if}
</div>
