import Parameter from "../utils/parameter.js";

export default class OIP3 extends Parameter {
    constructor(initialValue, overrides) {
        let args = overrides || {};
        args.name = "OIP3";
        args.isMultiValued = true;
        args.value = initialValue;
        args.unit = Parameter.valueOrDefault(args.unit, "dBm");

        args.minValue = Parameter.valueOrDefault(args.minValue, -99.99);
        args.maxValue = Parameter.valueOrDefault(args.maxValue, 99.99);

        args.isEnabled = Parameter.valueOrDefault(args.isEnabled, false);
        args.isWatched = Parameter.valueOrDefault(args.isWatched, false);

        args.cascadeWatched = Parameter.valueOrDefault(args.cascadeWatched, ["IIP3"]);
        args.cascadeEnabled = Parameter.valueOrDefault(args.cascadeEnabled, ["IIP3"]);

        super(args);
    }
}