import * as go from "gojs/release/go-module.js";
import settings from '../settings.js';
import "./shapes.js";

let $ = go.GraphObject.make;

let nodeNoteTemplate = $(
    go.Node,
    "Auto",
    {
        resizable: true,
        selectionAdorned: false,
        locationSpot: go.Spot.Center,
        stretch: go.GraphObject.Fill,
        desiredSize: new go.Size(300, 200),
        layerName: "Foreground"
    },
    new go.Binding("location", "location", go.Point.parse).makeTwoWay(go.Point.stringify),
    new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(go.Size.stringify),
    $(
        go.Shape,
        "RoundedRectangle",
        {
            strokeWidth: 2,
        },
        new go.Binding("stroke", "", function (obj) {
            if (obj.isSelected) return "black";
            return settings.brandColor;
        }).ofObject(),
        new go.Binding("fill", "", function (obj) {
            if (obj.isSelected) return settings.brandColor;
            return settings.brandColorLighter;
        }).ofObject()
    ),
    $(
        go.TextBlock,
        "",
        {
            stroke: settings.textColor,
            isMultiline: true,
            textAlign: "left",
            stretch: go.GraphObject.Fill,
            margin: new go.Margin(5),
            font: settings.font("28px"),
            overflow: go.TextBlock.OverflowEllipsis,

        },
        new go.Binding("text", "notes")
    ),
);

export default nodeNoteTemplate;
