<script>
    import { authToken, isBusy, visibleModal, visiblePage } from "../stores.js";
    import { patchMe } from "../api.js";
    import Modal from "./Modal.svelte";
    import Input from "../pages/components/form/Input.svelte";

    let email;
    let errors = {};

    const handleSubmit = () => {
        if ($isBusy) {
            return;
        }
        if (!email) {
            errors = { email: "You must provide a new email address" };
            return;
        }
        isBusy.set(true);

        let req = patchMe($authToken, { email: email });
        req.then((resp) => {
            if (!resp.error) {
                visibleModal.set(null);
                visiblePage.set("/verify/requested/");
            } else {
                errors = resp.fields;
            }
        }).finally(() => isBusy.set(false));
    };
</script>

<Modal
    title="Change Email Address?"
    icon="far fa-pen-to-square fa-2x"
    confirmIcon="far fa-pen-to-square"
    confirmText="Change"
    onConfirm={handleSubmit}
>
    <form on:submit|preventDefault={handleSubmit}>
        <button class="hidden" type="submit">Submit</button>
        <div class="mt-2">
            <Input
                bind:value={email}
                error={errors.email}
                label="New Email"
                name="change-email-email"
                helpText="The new email address to use"
                required={true}
                type="email"
            />
        </div>
    </form>
</Modal>
