<script>
    import { highlight } from "../../../stores";

    const doNothing = () => {};

    export let param;
    export let onChange = doNothing;
    export let handleHighlight = doNothing;
    export let handleEditRequest = doNothing;
</script>

<div>
    {#if param.isEnabledEditable}
        <label for="{param.name}-enabled" class="cursor-pointer text-lg">
            {#if param.isEnabled}
                <span>
                    <i
                        class="fa-duotone fa-toggle-on"
                        style="--fa-primary-color: white; --fa-secondary-color: var(--color-brand); --fa-secondary-opacity: 1"
                    />
                </span>
            {:else}
                <span>
                    <i
                        class="fa-duotone fa-toggle-off"
                        style="--fa-primary-color: black; --fa-secondary-color: black; --fa-primary-opacity: .4"
                    />
                </span>
            {/if}
        </label>
        <input
            on:change={(e) => onChange(param, "isEnabled", e.target.checked)}
            checked={param.isEnabled}
            type="checkbox"
            id="{param.name}-enabled"
            class="hidden"
        />
    {/if}
</div>
<!-- svelte-ignore a11y-click-events-have-key-events -->
<span
    on:mouseenter={() => handleHighlight(param.name, true)}
    on:mouseleave={() => handleHighlight(null)}
    on:click={() => handleEditRequest(param)}
    class:text-brand={$highlight === param.name}
    class:text-yellow-500={$highlight !== param.name && param._isWarning}
    class:text-red-500={$highlight !== param.name && param._isDanger}
    class="cursor-pointer text-left font-semibold">{param.name}</span
>
<!-- svelte-ignore a11y-click-events-have-key-events -->
<span
    on:mouseenter={() => handleHighlight(param.name, true)}
    on:mouseleave={() => handleHighlight(null)}
    on:click={() => handleEditRequest(param)}
    class:text-brand={$highlight === param.name}
    class:text-yellow-500={$highlight !== param.name && param._isWarning}
    class:text-red-500={$highlight !== param.name && param._isDanger}
    class="cursor-pointer text-right">{param.getPretty()}</span
>
<!-- svelte-ignore a11y-click-events-have-key-events -->
<span
    on:mouseenter={() => handleHighlight(param.name, true)}
    on:mouseleave={() => handleHighlight(null)}
    on:click={() => handleEditRequest(param)}
    class:text-brand={$highlight === param.name}
    class:text-yellow-500={$highlight !== param.name && param._isWarning}
    class:text-red-500={$highlight !== param.name && param._isDanger}
    class="cursor-pointer ">{param.unit}</span
>
<div>
    {#if param.isEnabled}
        <label for="{param.name}-isWatched" class="cursor-pointer">
            {#if param.isWatched}
                <span>
                    <i class="far fa-eye" />
                </span>
            {:else}
                <span>
                    <i class="far fa-eye-slash text-[#c3c3c3]" />
                </span>
            {/if}
        </label>
        <input
            on:change={(e) => onChange(param, "isWatched", e.target.checked)}
            checked={param.isWatched}
            type="checkbox"
            class="hidden"
            id="{param.name}-isWatched"
        />
    {/if}
</div>
