import * as go from "gojs/release/go-module.js";
import settings from '../settings.js';
import probeIconUrl from "../images/probe-white.svg";

let $ = go.GraphObject.make;

let linkTemplate = $(
    go.Link,
    {
        selectionAdorned: false,
        routing: go.Link.Normal,
        relinkableTo: true,
        fromEndSegmentLength: 10,
        toEndSegmentLength: 10,
        // fromSpot: go.Spot.Right,
        // toSpot: go.Spot.Right,
    },
    $(
        go.Shape,
        {
            strokeWidth: 3,
        },
        new go.Binding("stroke", "isSelected", function (sel) {
            if (sel) {
                return settings.primaryColor;
            }
            return settings.textColor;
        }).ofObject("")
    ),
    $(
        go.Panel,
        "Auto",
        $(
            go.Shape,
            "Circle",
            {
                strokeWidth: 0,
                width: 30,
                height: 30
            },
            new go.Binding("fill", "isSelected", function (sel) {
                if (sel) {
                    return settings.primaryColor;
                }
                return settings.textColor;
            }).ofObject("")
        ),
        $(
            go.Picture,
            {
                width: 15,
                height: 15,
                desiredSize: new go.Size(15, 15),
                source: probeIconUrl
            },
        ),
    )
);

export default linkTemplate;
