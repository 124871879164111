<script>
    import {
        visibleModal,
        authToken,
    } from "../stores.js";
    import Modal from "./Modal.svelte";
    import Parameters from "../components/utils/parameters.js";
    import { deleteComponent } from "../api";

    export let comp;
    export let onDeleted;

    const handleDelete = () => {
        deleteComponent($authToken, comp.id).then(() => {
            onDeleted();
            visibleModal.set(null);
        });
    };

    $: parameters =
        !!comp && !!comp.parameters
            ? new Parameters(comp.parameters.filter((c) => c.isEnabled)).all
            : [];

    $: dangerText = !!comp && !!comp.id ? "Delete component" : null;
    $: onDanger = !!comp && !!comp.id ? handleDelete : null;
</script>

<Modal
    title={comp.name}
    iconUrl={comp.icon}
    confirmText={null}
    cancelText="OK"
    cancelIcon="fal fa-check"
    {dangerText}
    {onDanger}
>
    {#if comp.summary}
        <p>{comp.summary}</p>
    {/if}

    {#if parameters.length}
        <p><strong>Default Parameters</strong></p>
        <div class="p-2 grid grid-cols-3 gap-1 items-center border rounded">
            {#each parameters as param}
                <span class="text-left font-semibold">{param.name}</span>
                <span class="text-right">{param.getPretty()}</span>
                <span class="">{param.unit}</span>
            {/each}
        </div>
    {/if}

    {#if comp.notes}
        <p><strong>Notes</strong></p>
        <p>{comp.notes}</p>
    {/if}
</Modal>
