import Plotly from "plotly.js-dist";

export function simplePlot(canvas, trace, width, height) {
    Plotly.newPlot(
        canvas,
        [trace],
        {
            xaxis: {
                showgrid: false,
                linecolor: "black",
                showticklabels: false,
                fixedrange: true,
                zeroline: false,
            },
            yaxis: {
                showgrid: false,
                linecolor: "black",
                zeroline: false,
                showticklabels: false,
                fixedrange: true
            },
            height: height,
            width: width,
            margin: {
                autoexapnd: false,
                b: 5,
                t: 0,
                l: 5,
                r: 0,
            }
        },
        {
            responsive: true,
            displayModeBar: false,
            doubleClick: false,
        }
    );
}
