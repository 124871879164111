<script>
    import Page from "./Page.svelte";
</script>

<Page>
    <svelte:fragment slot="body">
        <h1 class="text-xl font-bold mb-4 text-center">
            You need to verify your email address
        </h1>
        <p class="mb-4 text-center">
            Before you can use RFGraph you'll need to verify your email address.
        </p>
    </svelte:fragment>
</Page>
