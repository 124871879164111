<script>
    import {
        authToken,
        visiblePage,
        isBusy,
        visibleModal,
    } from "../stores.js";
    import { listGraphs } from "../api.js";
    import Button from "./components/Button.svelte";
    import Page from "./Page.svelte";
    import Dropdown from "./components/Dropdown.svelte";
    import { onMount } from "svelte";
    import Section from "./components/Section.svelte";
    import Input from "./components/form/Input.svelte";
    import Select from "./components/form/Select.svelte";

    export let s;
    export let d;
    export let l;
    export let p;

    let choices = [];
    let breadcrumbs = [];
    let search = s || "";
    let dirname = d || "/";
    let limit = l || "10";
    let page = p || "1";
    let errors = {};
    let showingSearchResults = false;

    const limitChoices = [
        ["10", "Show 10 results"],
        ["25", "Show 25 results"],
        ["50", "Show 50 results"],
    ];

    $: countStart = (parseInt(page) - 1) * parseInt(limit) + 1;
    $: countEnd = Math.max(countStart, countStart - 1 + choices.length);
    $: previousEnabled = parseInt(page) > 1;
    $: nextEnabled = parseInt(limit) == choices.length;

    onMount(() => {
        fetchGraphs();
    });

    const fetchGraphs = () => {
        isBusy.set(true);
        choices = [];
        errors = {};
        showingSearchResults = false;

        let params = new URLSearchParams();
        params.set("d", dirname);
        params.set("l", limit);
        params.set("p", page);
        if (!!search) {
            params.set("s", search);
        }
        window.history.replaceState(
            {},
            "",
            `${window.location.pathname}?${params}`
        );

        params = new URLSearchParams();
        params.set("dirname", dirname);
        params.set("limit", limit);
        params.set("page", page);
        if (!!search) {
            params.set("search", search);
        }
        listGraphs($authToken, params)
            .then((resp) => {
                if (resp.error) {
                    errors = resp.fields || {};
                } else {
                    choices = resp;
                    let newBreadcrumbs = [];
                    let parent = "/";
                    for (let dn of dirname.split("/")) {
                        if (!dn) continue;
                        let newParent = `${parent}${dn}/`;
                        newBreadcrumbs.push({
                            name: dn,
                            dirname: parent,
                            key: "" + Math.random(),
                        });
                        parent = newParent;
                    }
                    breadcrumbs = newBreadcrumbs;
                    showingSearchResults = !!search;
                }
            })
            .finally(() => isBusy.set(false));
    };

    const handleNew = () => {
        visibleModal.set({
            name: "new-graph",
            params: {
                graphDirname: dirname,
                afterConfirm: (resp) => {
                    visiblePage.set(`/graphs/${resp.id}/`);
                },
            },
        });
    };

    const handleSearch = () => {
        fetchGraphs();
    };

    const handlePrevious = () => {
        let p = parseInt(page);
        page = p - 1;
        fetchGraphs();
    };

    const handleNext = () => {
        let p = parseInt(page);
        page = p + 1;
        fetchGraphs();
    };

    const handleReset = () => {
        search = "";
        dirname = "/";
        fetchGraphs();
    };

    const handleChooseFolder = () => {
        visibleModal.set({
            name: "choose-folder",
            params: {
                dirname: dirname,
                afterConfirm: (dn) => {
                    dirname = dn;
                },
            },
        });
    };

    const handleOpen = (item) => {
        if (!item.id) {
            dirname = `${item.dirname}${item.name}`;
            fetchGraphs();
        } else {
            visiblePage.set(`/graphs/${item.id}/`);
        }
    };

    const handleRename = (item) => {
        visibleModal.set({
            name: "rename-graph",
            params: {
                graphId: item.id,
                graphName: item.name,
                graphDirname: item.dirname,
                afterConfirm: fetchGraphs,
            },
        });
    };

    const handleDuplicate = (item) => {
        visibleModal.set({
            name: "duplicate-graph",
            params: {
                graphId: item.id,
                graphName: item.name,
                graphDirname: item.dirname,
                afterConfirm: fetchGraphs,
            },
        });
    };

    const handleMove = (item) => {
        visibleModal.set({
            name: "move-graph",
            params: {
                graphId: item.id,
                graphName: item.name,
                graphDirname: item.dirname,
                afterConfirm: fetchGraphs,
            },
        });
    };

    const handleSend = (item) => {
        visibleModal.set({
            name: "send-graph",
            params: {
                graphId: item.id,
                graphName: item.name,
                afterConfirm: () => {
                    window.rfgShowToast(
                        "Graph Sent!",
                        "We've sent that person an email inviting them to RFGraph",
                        "success"
                    );
                },
            },
        });
    };

    const handleDelete = (item) => {
        visibleModal.set({
            name: "delete-graph",
            params: {
                graphId: item.id,
                graphName: item.name,
                afterConfirm: fetchGraphs,
            },
        });
    };

    const formatDatetime = (dt) => {
        if (!!dt) {
            dt = new Date(`${dt}Z`);
            return dt.toLocaleString();
        }
        return "";
    };

    $: dirnameLabel = dirname === "/" ? "All folders" : dirname;
</script>

<Page bgColour="gray-100" padding="0">
    <div slot="body">
        <div
            class="bg-white p-4 shadow-b shadow-lg flex flex-row justify-between"
        >
            <h1 class="text-2xl">My Graphs</h1>
            <Button
                text="New Graph"
                onClick={handleNew}
            />
        </div>

        <div class="max-w-3xl mx-auto mt-4">
            <Section>
                <form
                    slot="body"
                    action=""
                    method="GET"
                    class=""
                    on:submit|preventDefault={handleSearch}
                >
                    <button class="hidden">Submit</button>
                    <div class="flex flex-row items-end mb-1">
                        <div class="grow">
                            <Input
                                bind:value={search}
                                error={null}
                                label="Search"
                                name="mygraphs-search"
                                placeholder="Search for graphs by name..."
                                type="search"
                            />
                        </div>
                        <div class="mx-2 min-w-[200px]">
                            <Input
                                bind:value={dirname}
                                error={null}
                                label="Search in"
                                name="mygraphs-search-in"
                                type="hidden"
                            />
                            <Button
                                text={dirnameLabel}
                                icon="fa-solid fa-folder"
                                theme="white"
                                toUpperCase={false}
                                onClick={handleChooseFolder}
                                boldText={false}
                                customClass="w-full text-left"
                            />
                        </div>
                        <div class="">
                            <Button text="Search" theme="white" type="submit" />
                        </div>
                    </div>
                </form>
            </Section>

            <Section>
                <div slot="header">
                    <div class="flex flex-row items-center gap-2">
                        <button
                            on:click={handleReset}
                            class="hover:text-brand text-lg font-bold"
                            >My Graphs</button
                        >
                        {#if showingSearchResults}
                            <span><i class="fal fa-chevron-right" /></span>
                            <span>Search Results</span>
                        {:else}
                            {#each breadcrumbs as b (b.key)}
                                <span><i class="fal fa-chevron-right" /></span>
                                <button
                                    on:click={() => handleOpen(b)}
                                    class="hover:text-brand">{b.name}</button
                                >
                            {/each}
                        {/if}
                    </div>
                </div>

                <div slot="body">
                    {#if $isBusy}
                        <div class="text-center my-2">Loading...</div>
                    {:else}
                        <div class="">
                            {#if choices.length}
                                <div
                                    class="grid grid-cols-[auto,1fr,auto] items-center gap-x-2"
                                >
                                    {#each choices as c, idx}
                                        <button
                                            class="text-black hover:text-brand flex flex-row items-center"
                                            on:click={() => handleOpen(c)}
                                        >
                                            {#if !c.id}
                                                <span
                                                    class="bg-black rounded px-[5px] py-[3px]"
                                                >
                                                    <i
                                                        class="text-white fa-solid fa-fw fa-folder"
                                                    />
                                                </span>
                                            {:else}
                                                <span
                                                    class="bg-black rounded px-[5px] py-[3px]"
                                                >
                                                    <i
                                                        class="text-white fa-regular fa-fw fa-diagram-project"
                                                    />
                                                </span>
                                            {/if}
                                        </button>
                                        <div class="ml-2 text-left">
                                            <button
                                                class="text-black hover:text-brand flex flex-row items-center"
                                                on:click={() => handleOpen(c)}
                                            >
                                                {c.name}
                                            </button>
                                            <div class="text-xs text-gray-500">
                                                <div
                                                    class="flex flex-row justify-between"
                                                >
                                                    <div>
                                                        {#if !!c.id}
                                                            Updated: {formatDatetime(
                                                                c.updated_on
                                                            )}
                                                        {/if}
                                                    </div>
                                                    {#if c.dirname !== "/"}
                                                        <div class="">
                                                            <i
                                                                class="fa-solid fa-fw fa-folder"
                                                            />
                                                            {c.dirname}
                                                        </div>
                                                    {/if}
                                                </div>
                                                {#if !!c.description}
                                                    {c.description}
                                                {/if}
                                            </div>
                                        </div>
                                        <div class="text-right">
                                            {#if !!c.id}
                                                <Dropdown
                                                    buttonTheme="white"
                                                    options={[
                                                        {
                                                            text: "Rename",
                                                            icon: "far fa-pen-to-square",
                                                            action: () => handleRename(c),
                                                        },
                                                        {
                                                            text: "Duplicate",
                                                            icon: "far fa-clone",
                                                            action: () => handleDuplicate(c),
                                                        },
                                                        {
                                                            text: "Move",
                                                            icon: "far fa-folder-arrow-up",
                                                            action: () => handleMove(c),
                                                        },
                                                        {
                                                            text: "Send",
                                                            icon: "far fa-share",
                                                            action: () => handleSend(c),
                                                        },
                                                        {
                                                            text: "Delete",
                                                            icon: "far fa-trash-alt",
                                                            action: () => handleDelete(c),
                                                        },
                                                    ]}
                                                />
                                            {/if}
                                            <Button
                                                icon="far fa-chevron-right mx-[3px] mt-[2px]"
                                                text=""
                                                onClick={() => handleOpen(c)}
                                            />
                                        </div>
                                        {#if idx < choices.length - 1}
                                            <div
                                                class="border-b border-gray-200 col-span-full mt-2 mb-2"
                                            />
                                        {/if}
                                    {/each}
                                </div>
                            {:else}
                                <div class="text-center my-2">
                                    No matching graphs :(
                                </div>
                            {/if}
                        </div>
                    {/if}
                </div>

                <div slot="footer" class="flex flex-row items-center">
                    <span class="mr-auto">
                        <Select
                            choices={limitChoices}
                            bind:value={limit}
                            onChange={handleSearch}
                            error={errors.limit}
                            label="Number of results to show"
                            hideLabel={true}
                            name="mygraphs-limit"
                        />
                    </span>
                    <span>
                        <Button
                            text="Previous"
                            icon="fa-solid fa-chevrons-left"
                            theme="white"
                            onClick={handlePrevious}
                            isDisabled={!previousEnabled}
                        />
                        <span class="mx-2">{countStart} - {countEnd}</span>
                        <Button
                            text="Next"
                            icon="fa-solid fa-chevrons-right"
                            iconRight={true}
                            theme="white"
                            onClick={handleNext}
                            isDisabled={!nextEnabled}
                        />
                    </span>
                </div>
            </Section>
        </div>
    </div>
</Page>
