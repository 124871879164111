<script>
    import * as go from "gojs/release/go-module.js";
    import { onDestroy, onMount } from "svelte";
    import Collapsable from "./Collapsable.svelte";
    import Button from "./Button.svelte";
    import { features } from "../../stores.js";

    import Amplifier from "../../components/amplifier.js";
    import Attenuator from "../../components/attenuator.js";
    import BandPass from "../../components/bandpass.js";
    import BandStop from "../../components/bandstop.js";
    import Combiner from "../../components/combiner.js";
    import Generic from "../../components/generic.js";
    import HighPass from "../../components/highpass.js";
    import LowPass from "../../components/lowpass.js";
    import Note from "../../components/note";
    import Source from "../../components/source.js";
    import Splitter from "../../components/splitter.js";
    import Termination from "../../components/termination";

    import { authToken, isBusy, visibleModal } from "../../stores";
    import { createComponent, listComponents } from "../../api";
    import plusIconUrl from "../../images/plus.svg";
    import Select from "./form/Select.svelte";
    import Input from "./form/Input.svelte";

    export let diagram;

    const simplify = (comp) => {
        return {
            icon: comp.icon,
            id: null,
            name: comp.name,
            notes: comp.notes,
            parameters: comp.parameters.all.map((p) => {
                return {
                    name: p.name,
                    value: p.getPretty(),
                    unit: p.unit,
                    isEnabled: p.isEnabled,
                };
            }),
            summary: comp.summary,
            category: comp.category,
        };
    };

    let standard = [];
    const _standard = [
        simplify(new Source({})),
        simplify(new Termination({})),
        simplify(new Amplifier({})),
        simplify(new Attenuator({})),
        simplify(new Splitter({})),
        simplify(new Combiner({})),
        simplify(new Generic({})),
        {
            category: "NOTE",
            name: "Note",
            summary: "A sticky note that can be placed anywhere.",
        },
    ];
    let filters = [];
    const _filters = [
        simplify(new LowPass({})),
        simplify(new HighPass({})),
        simplify(new BandPass({})),
        simplify(new BandStop({})),
    ];
    let custom = [];
    let _custom = [];

    let icons = {};
    let typeChoices = [["", "Any"]];

    _standard.concat(_filters).forEach((c) => {
        icons[c.category] = c.icon;
        typeChoices.push([c.category, c.name]);
    });

    let width;
    let isClosed = false;
    let customCandidate = null;

    let searchQuery = "";
    let categoryFilter = "";
    let customPage = 1;
    const customLimit = 10;
    let showLoadMore = false;

    const filterComponent = (comp, search, category) => {
        let isMatch = true;
        if (category !== "") {
            isMatch = comp.category === category;
        }
        if (isMatch && search !== "") {
            isMatch =
                comp.name.toLowerCase().search(search.toLowerCase()) !== -1;
        }
        return isMatch;
    };

    function debounce(func, timeout = 300) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    }

    const fetchCustom = debounce((search, category, extend = false) => {
        isBusy.set(true);
        let params = new URLSearchParams();
        params.set("page", customPage.toString());
        if (!!search) {
            params.set("search", search);
        }
        if (!!category) {
            params.set("category", category);
        }
        let req = listComponents($authToken, params);
        req.then((resp) => {
            let newCustom = resp.map((c) => {
                let comp = JSON.parse(c.data);
                comp.id = c.id;
                if (!comp.icon) comp.icon = icons[c.category];
                return comp;
            });
            if (extend) {
                _custom = [..._custom, ...newCustom];
            } else {
                _custom = newCustom;
            }
            showLoadMore = resp.length === customLimit;
        }).finally(() => isBusy.set(false));
    });

    const handleDragstart = (event, comp) => {
        event.dataTransfer.setData(
            "text/plain",
            JSON.stringify({ category: comp.category, id: comp.id, icon: comp.icon })
        );
        event.dataTransfer.dropEffect = "copy";
        if (!!comp.id) {
            event.dataTransfer.setDragImage(
                document.getElementById(`custom-${comp.id}`),
                50,
                50
            );
        } else {
            event.dataTransfer.setDragImage(
                document.getElementById(`standard-${comp.category}`),
                50,
                50
            );
        }
    };

    const handleDragOver = (evt) => {
        evt.preventDefault();
        evt.dataTransfer.dropEffect = "copy";
    };

    const handleDrop = (evt) => {
        evt.preventDefault();
        let can = evt.target;
        // if the target is not the canvas, we may have trouble, so just quit:
        if (!(can instanceof HTMLCanvasElement)) {
            return;
        }

        diagram.startTransaction("Add node from palette");
        let comp,
            details,
            name = "",
            parameters,
            summary = "",
            notes = "";
        try {
            details = JSON.parse(evt.dataTransfer.getData("text/plain"));
        } catch {
            // Bail early if we can't read data from the dropped object.
            // Not sure how this happens, but we saw a bug report on sentry about it.
            return;
        }

        let category = details.category;
        if (!!details.id) {
            if ($features.customcomponents === "upsell") {
                visibleModal.set({ name: "upsell" });
                return;
            }
            for (let i = custom.length - 1; i >= 0; i--) {
                let c = custom[i];
                if (c.id === details.id) {
                    name = c.name;
                    parameters = c.parameters;
                    summary = c.summary;
                    notes = c.notes;
                    break;
                }
            }
        }

        if (category === "NOTE") {
            comp = new Note({notes:notes, size: go.Size.stringify(new go.Size(300, 200))})
        } else {
            let categories = {
                AMP: Amplifier,
                ATT: Attenuator,
                BNP: BandPass,
                BST: BandStop,
                CMB: Combiner,
                GEN: Generic,
                HIP: HighPass,
                LWP: LowPass,
                SPL: Splitter,
                SRC: Source,
                TRM: Termination,
            };

            comp = categories[category];
            if (!comp) {
                return;
            }
            comp = new comp({
                name: name,
                parameters: parameters,
                summary: summary,
                notes: notes,
                watchedOutputNames: ["Pout", "C_Gain"],
                icon: details.icon,
            });
        }

        let pixelratio = diagram.computePixelRatio();
        let bbox = can.getBoundingClientRect();
        let bbw = bbox.width;
        if (bbw === 0) bbw = 0.001;
        let bbh = bbox.height;
        if (bbh === 0) bbh = 0.001;
        let mx = evt.clientX - bbox.left * (can.width / pixelratio / bbw);
        let my = evt.clientY - bbox.top * (can.height / pixelratio / bbh);
        let point = diagram.transformViewToDoc(new go.Point(mx, my));
        point.snapToGridPoint(new go.Point(0, 0), new go.Size(30, 30));
        comp.location = go.Point.stringify(point);
        diagram.model.addNodeData(comp);
        diagram.commitTransaction("Add node from palette");
    };

    const handleToggle = () => {
        isClosed = !isClosed;
    };

    const handleSaveAsCustom = () => {
        if ($features.customcomponents === "upsell") {
            visibleModal.set({ name: "upsell" });
            return;
        }
        isBusy.set(true);
        let data = {
            name: customCandidate.data.name,
            category: customCandidate.data.category,
            icon: customCandidate.data.icon,
            parameters: customCandidate.data.parameters.dump(),
            summary: customCandidate.data.summary,
            notes: customCandidate.data.notes,
        };
        let req = createComponent(
            $authToken,
            data.name,
            data.category,
            JSON.stringify(data)
        );
        req.then((resp) => {
            if (resp.error) {
                window.rfgShowToast(resp.error, resp.fields.name, "error");
            } else {
                let comp = JSON.parse(resp.data);
                comp.id = resp.id;
                custom = [...custom, comp];
                customCandidate = null;
            }
        }).finally(() => isBusy.set(false));
    };

    const handleLoadMore = () => {
        customPage++;
        fetchCustom(searchQuery, categoryFilter, true);
    };

    const handleClick = (comp) => {
        visibleModal.set({
            name: "view-component",
            params: {
                comp: comp,
                onDeleted: () => fetchCustom(searchQuery, categoryFilter),
            },
        });
    };

    onMount(() => {
        diagram.div.removeEventListener("dragover", handleDragOver);
        diagram.div.removeEventListener("drop", handleDrop);
        diagram.div.addEventListener("dragover", handleDragOver);
        diagram.div.addEventListener("drop", handleDrop);

        diagram.addDiagramListener("ChangedSelection", (e) => {
            customCandidate = null;
            let node = diagram.selection.first();
            if (node && node instanceof go.Node) {
                customCandidate = node;
            }
        });
    });

    onDestroy(() => {
        // diagram.div.removeEventListener("dragover", handleDragOver);
        // diagram.div.removeEventListener("drop", handleDrop);
    });

    $: width = isClosed ? "50px" : "250px";

    $: standard = _standard.filter((c) =>
        filterComponent(c, searchQuery, categoryFilter)
    );
    $: filters = _filters.filter((c) =>
        filterComponent(c, searchQuery, categoryFilter)
    );

    $: {
        customPage = 1;
        fetchCustom(searchQuery, categoryFilter);
    }
    $: custom = _custom.filter((c) =>
        filterComponent(c, searchQuery, categoryFilter)
    );
</script>

<div
    style="width: {width}"
    class="h-full flex flex-col"
    class:bg-black={isClosed}
>
    {#if isClosed}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <span
            on:click={handleToggle}
            class="px-2 py-2 text-white text-lg cursor-pointer"
        >
            <i class="far fa-arrow-from-left mx-2" />
            <h2
                class="transform -rotate-90 whitespace-nowrap uppercase relative top-[140px] font-semibold"
            >
                Add Components
            </h2>
        </span>
    {:else}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <h2
            on:click={handleToggle}
            class="px-2 py-2 bg-black text-white uppercase text-lg font-semibold flex flex-row justify-between cursor-pointer"
        >
            <span class="ml-2">Add Components</span>
            <span><i class="far fa-arrow-from-right mr-2" /></span>
        </h2>
        <div class="bg-[#46484d] grow py-2 overflow-y-scroll">
            <div class="mb-4 px-4">
                <Input
                    bind:value={searchQuery}
                    error={null}
                    label="Search"
                    name="palette-search"
                    type="search"
                    labelTheme="text-sm font-medium text-white"
                />
                <Select
                    choices={typeChoices}
                    bind:value={categoryFilter}
                    error={null}
                    label="Type"
                    name="palette-type"
                    labelTheme="text-sm font-medium text-white mt-2"
                />
            </div>
            {#each [["Standard", standard], ["Filters", filters]] as [title, list]}
                <Collapsable {title} rtl={true} theme="dark">
                    <div class="grid grid-cols-2 gap-4 px-4 my-4">
                        {#each list as comp (comp.category)}
                            <!-- svelte-ignore a11y-click-events-have-key-events -->
                            <div
                                on:dragstart={(e) => handleDragstart(e, comp)}
                                on:click={(e) => handleClick(comp)}
                                class="cursor-default rounded text-center bg-white pb-2"
                                class:bg-brand-lighter={comp.category === "NOTE"}
                                draggable="true"
                                id="standard-{comp.category}"
                            >
                                <div
                                    class="bg-brand text-sm font-bold py-1 rounded-t"
                                >
                                    {comp.name}
                                </div>
                                <div style="min-height: 50px">
                                    {#if comp.icon}
                                        <img
                                            src={comp.icon}
                                            alt="{comp.name} icon"
                                            width="50px"
                                            height="50px"
                                            class="m-auto mt-2"
                                        />
                                    {/if}
                                </div>
                            </div>
                        {/each}
                    </div>
                </Collapsable>
            {/each}

            <Collapsable title="Custom" rtl={true} theme="dark" upsell={$features.customcomponents === "upsell"}>
                {#if custom.length === 0}
                    <p class="text-white text-center mb-4 px-4">
                        Save your own components to easily reuse them.
                    </p>
                {/if}
                <div class="grid grid-cols-2 gap-4 px-4 mt-4">
                    {#each custom as comp (comp.id)}
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <div
                            on:dragstart={(e) => handleDragstart(e, comp)}
                            on:click={(e) => handleClick(comp)}
                            class="cursor-default rounded text-center bg-white pb-2"
                            draggable="true"
                            id="custom-{comp.id}"
                        >
                            <div
                                class="bg-brand text-sm font-bold py-1 px-1 rounded-t truncate"
                            >
                                {comp.name}
                            </div>
                            <img
                                src={comp.icon}
                                alt="{comp.name} icon"
                                width="50px"
                                height="50px"
                                class="m-auto mt-2"
                            />
                        </div>
                    {/each}
                    {#if customCandidate}
                        <div class="text-center text-white">
                            <button
                                class="rounded text-center border-dashed border-2 border-white px-2 hover:bg-white hover:bg-opacity-10 w-full relative"
                                on:click={handleSaveAsCustom}
                            >
                                <img
                                    src={plusIconUrl}
                                    alt="Add icon"
                                    width="40px"
                                    height="40px"
                                    class="m-auto text-white mt-1"
                                />
                                <div class="text-sm">Save</div>
                                <div class="text-sm pb-1 truncate">
                                    {customCandidate.data.name}
                                </div>
                            </button>
                        </div>
                    {/if}
                </div>
                {#if showLoadMore}
                    <div class="text-center mt-2">
                        <Button text="Load More" onClick={handleLoadMore} />
                    </div>
                {/if}
            </Collapsable>
        </div>
    {/if}
</div>
