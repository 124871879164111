
export default class Note {
    category = "NOTE";

    constructor(args) {
        this.key = args.key;
        this.location = args.location;
        this.size = args.size;
        this.notes = args.notes;
    }

    copy(key) {
        return new Note({ key: key, location: this.location, notes: this.notes, size: this.size });
    }

    change(diagram, param, changes) {
        diagram.startTransaction("set property");

        for (let i = 0; i < changes.length; i++) {
            let field = changes[i][0];
            let value = changes[i][1];
            diagram.model.setDataProperty(param, field, value);
        }
        diagram.commitTransaction("set property");
    }

}
