<script>
    import { isBusy, visibleModal } from "../stores.js";
    import Modal from "./Modal.svelte";
    import ChooseFolder from "./_ChooseFolder.svelte";

    export let afterConfirm;
    export let dirname;

    const handleSubmit = () => {
        if ($isBusy) {
            return;
        }
        visibleModal.set(null);
        if (!!afterConfirm && !!dirname) {
            afterConfirm(dirname);
        }
    };
</script>

<Modal
    title="Choose Folder"
    icon="far fa-folder fa-2x"
    confirmIcon="far fa-folder"
    confirmText="Choose Folder"
    onConfirm={handleSubmit}
>
    <form on:submit|preventDefault={handleSubmit}>
        <button class="hidden" type="submit">Submit</button>
        <ChooseFolder initialFolder={dirname} bind:newFolder={dirname} allowNew={false} hideLabel={true} errors={null} />
    </form>
</Modal>
