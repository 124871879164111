import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [new Integrations.BrowserTracing()],
	release: import.meta.env.VITE_VERSION,
	environment: import.meta.env.VITE_ENV,
	tracesSampleRate: 1.0,
});

import "./index.css";

import App from "./App.svelte";

export const app = new App({
	target: document.getElementById("app"),
	props: {},
});
