<script>
    import { authToken, isBusy, visibleModal } from "../stores.js";
    import { sendGraph } from "../api.js";
    import Modal from "./Modal.svelte";
    import Input from "../pages/components/form/Input.svelte";

    export let graphId;
    export let graphName;
    export let afterConfirm;

    let email;
    let message =
        "Hi there!\n\nI've made this graph on rfgraph.com, take a look!";
    let errors = {};

    const handleSubmit = () => {
        if ($isBusy) {
            return;
        }
        isBusy.set(true);

        let req = sendGraph($authToken, graphId, email, message);
        req.then((resp) => {
            if (!resp.error) {
                visibleModal.set(null);
                if (!!afterConfirm) {
                    afterConfirm(resp);
                }
            } else {
                errors = resp.fields;
            }
        }).finally(() => isBusy.set(false));
    };
</script>

<Modal
    title="Send {graphName}?"
    icon="far fa-share fa-2x"
    confirmIcon="far fa-share"
    confirmText="Send"
    onConfirm={handleSubmit}
>
    <p class="mt-2 text-sm">
        Send a graph to another user makes a copy of your graph and sends it to
        them.
    </p>
    <p class="mt-2 text-sm">
        Any future edits you make to this graph won't be seen by the other
        person. Changes they make won't be seen by you.
    </p>
    <p class="mt-2 text-sm">
        We'll send an email to the below address with a link that will let the
        person accept the invitation to access the graph. If they don't already
        have an account with us then they'll be invited to create one.
    </p>
    <form on:submit|preventDefault={handleSubmit}>
        <div class="mt-4">
            <Input
                bind:value={email}
                error={errors.email}
                label="Email Address"
                name="send-email"
                helpText="The email address of the person receiving the graph"
                required={true}
                type="email"
            />
        </div>
        <div class="mt-2">
            <label
                for="send-message"
                class="block text-sm font-medium text-gray-700"
            >
                Message
            </label>
            <div class="mt-1">
                <textarea
                    bind:value={message}
                    name="send-message"
                    id="send-message"
                    cols="30"
                    rows="5"
                />
            </div>
        </div>
        <button class="hidden" type="submit">Submit</button>
    </form>
</Modal>
