<script>
    import * as go from "gojs/release/go-module.js";
    import { onMount } from "svelte";
    import Collapsable from "./Collapsable.svelte";
    import Input from "./form/Input.svelte";
    import probe from "../../probe.js";
    import { graphName, highlight, isBusy, user, visibleModal } from "../../stores.js";
    import { getAllNodesInSelection } from "../../diagram/utils.js";
    import Button from "./Button.svelte";
    import { downloadReport } from "./inspector/report";
    import InspectedParameter from "./inspector/InspectedParameter.svelte";
    import Context from "./inspector/Context.svelte";
    import Probe from "./inspector/Probe.svelte";

    export let diagram;
    let isClosed = false;
    let collapsedStates = {};
    let node = null;
    let data = null;
    let parameters = [];
    let outputs = [];
    let calculations = [];
    let mirroredNodes = [];

    let probeCandidates = [];
    let group = null;

    $: width = isClosed ? "50px" : "350px";

    const getData = () => {
        let newProbeCandidates = [];
        let inspectCandidate = null;
        group = null;
        if (diagram.selection.size === 1 && diagram.selection.first() instanceof go.Group) {
            group = diagram.selection.first();
        }
        let nodes = Array.from(getAllNodesInSelection(diagram.selection));
        if (nodes.length === 0) {
            let first = diagram.selection.first();
            if (first instanceof go.Link) {
                newProbeCandidates = [first.fromNode];
            }
        } else if (nodes.length === 1) {
            inspectCandidate = nodes[0];
        } else {
            newProbeCandidates = nodes;
        }

        data = null;
        parameters = [];
        outputs = [];
        calculations = [];
        mirroredNodes = [];
        probeCandidates = newProbeCandidates;

        if (probeCandidates.length === 0) {
            getDataNode(inspectCandidate);
        }
    };

    const getDataNode = (newNode) => {
        if (!newNode && !!node) newNode = node;
        if (!newNode) return;
        node = newNode;
        data = newNode.data;
        let dType = data.getDeviceType();
        if (data._outputs && data._outputs.length > 0) {
            let defaultParams = data.getFirstOutput();
            if (data._outputs.length === 1) {
                if (!defaultParams.isHidden) {
                    outputs = [
                        {
                            title: "Output",
                            context: Array.from(
                                data.getFirstOutput().getVisible(dType)
                            ),
                            id: `${data.key}-0`,
                        },
                    ];
                }
            } else {
                outputs = data._outputs.map((o, idx) => {
                    return {
                        title: `Output@${idx + 1}`,
                        context: Array.from(o.getVisible(dType)),
                        id: `${data.key}-${idx}`,
                    };
                });
            }
        }
        if (data.parameters) {
            parameters = Array.from(data.parameters.getVisible(dType));
        }
        if (data.category === "TRM") {
            let connectedNode = newNode.findNodesInto().first();
            if (!!connectedNode) {
                probe([connectedNode], 0, data._calculations);
                calculations = Array.from(data._calculations.getVisible(dType));
            }
        } else if (data._calculations) {
            calculations = Array.from(data._calculations.getVisible(dType));
        }
        if (!!data.mirrorKey) {
            mirroredNodes = new go.List(data.findMirrors(diagram)).toArray();
        }
    };

    onMount(() => {
        diagram.addModelChangedListener((e) => {
            if (e.isTransactionFinished) {
                getData();
            }
        });
        diagram.addDiagramListener("ChangedSelection", (e) => {
            getData();
        });
    });

    const onChange = (obj, name, value, node) => {
        node = node || data;
        node.change(diagram, obj, [[name, value]]);
    };

    const handleToggle = () => {
        isClosed = !isClosed;
    };

    const handleEditRequest = (param) => {
        const event = new CustomEvent("EditParameterRequest", {
            detail: {
                component: data,
                parameter: param,
                canEdit: true,
            },
        });
        window.dispatchEvent(event);
    };

    const handleViewRequest = (param) => {
        const event = new CustomEvent("EditParameterRequest", {
            detail: {
                component: data,
                parameter: param,
                canEdit: false,
            },
        });
        window.dispatchEvent(event);
    };

    const handleHighlight = (name) => {
        diagram.model.setDataProperty(
            diagram.model.modelData,
            "_highlight",
            name
        );
        highlight.set(name);
    };

    const handleDownloadReport = () => {
        if (!!data) {
            isBusy.set(true);
            const source = data.findSource();
            downloadReport(diagram, source, data, calculations, outputs[0], $graphName, $user.email, () => {
                isBusy.set(false);
            });
        }
    };

    const handleEditNotes = () => {
        const event = new CustomEvent("EditTextRequest", {
            detail: {
                title: "Notes",
                helpText: "Notes specific to this node in the graph.",
                text: data.notes,
                onConfirm: (newText) => onChange(data, "notes", newText),
            },
        });
        window.dispatchEvent(event);
    };

    const handleEditSummary = () => {
        const event = new CustomEvent("EditTextRequest", {
            detail: {
                title: "Summary",
                helpText: "A brief description of this type of component.",
                text: data.summary,
                onConfirm: (newText) => onChange(data, "summary", newText),
            },
        });
        window.dispatchEvent(event);
    };

    const handleChangeIcon = () => {
        visibleModal.set({
            name: "change-icon",
            params: {
                currentIcon: data.icon,
                iconChoices: data._iconChoices,
                onConfirm: (newIcon) => {
                    onChange(data, "icon", newIcon)

                    const names = ["Generic", ...Object.values(data._iconNames)];
                    if (names.indexOf(data.name) !== -1) {
                        onChange(data, "name", data._iconNames[newIcon] || "Generic");
                    }

                },
            }
        });
    }
</script>

<div
    style="width: {width}"
    class="h-full flex flex-col"
    class:bg-black={isClosed}
>
    {#if isClosed}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <span
            on:click={handleToggle}
            class="px-2 py-2 text-white text-lg cursor-pointer"
        >
            <i class="far fa-arrow-from-right mx-2" />
            <h2
                class="transform rotate-90 whitespace-nowrap uppercase relative top-[10px] font-semibold"
            >
                Inspector
            </h2>
        </span>
    {:else}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <h2
            on:click={handleToggle}
            class="px-2 py-2 bg-black text-white uppercase text-lg font-semibold flex flex-row justify-between cursor-pointer"
        >
            <span><i class="far fa-arrow-from-left ml-2" /></span>
            <span class="mr-2">Inspector</span>
        </h2>
        {#if data}
            <div class="grow pt-2 overflow-y-scroll">
                {#if !!data.icon}
                    <div class="flex flex-row m-2 items-center">
                        <img
                            src={data.icon}
                            alt="{data.category} icon"
                            width="50px"
                        />
                        <div class="grow ml-2 flex flex-col gap-2">
                            <Input
                                onChange={(e) => {
                                    onChange(data, "name", e.target.value);
                                    data.broadcastNameChange();
                                }}
                                value={data.name}
                                error={null}
                                label="Name"
                                hideLabel={true}
                                name="inspector-name"
                                type="text"
                            />
                            <span class="-mt-2 text-sm text-right">ID: {Math.abs(data.key)}</span>
                        </div>
                    </div>

                    {#if data._iconChoices.length > 0}
                        <div class="m-2 text-right">
                            <Button
                                text="Change Icon"
                                icon="fas fa-circle"
                                onClick={handleChangeIcon}
                            />
                        </div>
                    {/if}
                {/if}

                {#if data.summary !== undefined}
                    <Collapsable
                        title="Summary"
                        bind:isClosed={collapsedStates.summary}
                    >
                        <div class="p-2">
                            <div class="whitespace-pre-wrap break-words">{data.summary}</div>
                            <div class="text-right mt-2">
                                <Button
                                    text="Edit Summary"
                                    icon="fal fa-edit"
                                    onClick={handleEditSummary}
                                />
                            </div>
                        </div>
                    </Collapsable>
                {/if}

                {#if !!parameters.length}
                    <Collapsable
                        title="Parameters"
                        bind:isClosed={collapsedStates.parameters}
                    >
                        <div
                            class="p-2 grid grid-cols-[40px,auto,auto,auto,20px] gap-1 items-center"
                        >
                            {#each parameters as param}
                                <InspectedParameter
                                    {param}
                                    {onChange}
                                    {handleHighlight}
                                    {handleEditRequest}
                                />
                            {/each}
                        </div>
                    </Collapsable>
                {/if}

                {#each outputs as output (output.id)}
                    <Collapsable
                        title={output.title}
                        bind:isClosed={collapsedStates[output.title]}
                    >
                        <Context
                            context={output.context}
                            {handleHighlight}
                            {handleViewRequest}
                            {onChange}
                        />
                    </Collapsable>
                {/each}

                {#if !!calculations.length}
                    <Collapsable
                        title="Calculations"
                        bind:isClosed={collapsedStates.calculations}
                    >
                        <div
                            class="p-2 grid grid-cols-[auto,auto,auto,20px] gap-1 items-center"
                        >
                            {#each calculations as param (param.name)}
                                <!-- svelte-ignore a11y-click-events-have-key-events -->
                                <span
                                    on:mouseenter={() =>
                                        handleHighlight(param.name, true)}
                                    on:mouseleave={() => handleHighlight(null)}
                                    on:click={() => handleViewRequest(param)}
                                    class:text-brand={$highlight === param.name}
                                    class:text-yellow-500={$highlight !==
                                        param.name && param._isWarning}
                                    class:text-red-500={$highlight !==
                                        param.name && param._isDanger}
                                    class="cursor-pointer text-left font-semibold"
                                    >{param.name}</span
                                >
                                <!-- svelte-ignore a11y-click-events-have-key-events -->
                                <span
                                    on:mouseenter={() =>
                                        handleHighlight(param.name, true)}
                                    on:mouseleave={() => handleHighlight(null)}
                                    on:click={() => handleViewRequest(param)}
                                    class:text-brand={$highlight === param.name}
                                    class:text-yellow-500={$highlight !==
                                        param.name && param._isWarning}
                                    class:text-red-500={$highlight !==
                                        param.name && param._isDanger}
                                    class="cursor-pointer text-right"
                                    >{param.getPretty()}</span
                                >
                                <!-- svelte-ignore a11y-click-events-have-key-events -->
                                <span
                                    on:mouseenter={() =>
                                        handleHighlight(param.name, true)}
                                    on:mouseleave={() => handleHighlight(null)}
                                    on:click={() => handleViewRequest(param)}
                                    class:text-brand={$highlight === param.name}
                                    class:text-yellow-500={$highlight !==
                                        param.name && param._isWarning}
                                    class:text-red-500={$highlight !==
                                        param.name && param._isDanger}
                                    class="cursor-pointer ">{param.unit}</span
                                >
                                <div>
                                    <label
                                        for="calculations-{param.name}-isWatched"
                                        class="cursor-pointer"
                                    >
                                        {#if param.isWatched}
                                            <span>
                                                <i class="far fa-eye" />
                                            </span>
                                        {:else}
                                            <span>
                                                <i
                                                    class="far fa-eye-slash text-[#c3c3c3]"
                                                />
                                            </span>
                                        {/if}
                                    </label>
                                    <input
                                        on:change={(e) =>
                                            onChange(
                                                param,
                                                "isWatched",
                                                e.target.checked
                                            )}
                                        checked={param.isWatched}
                                        type="checkbox"
                                        class="hidden"
                                        id="calculations-{param.name}-isWatched"
                                    />
                                </div>
                            {/each}
                        </div>
                    </Collapsable>
                {/if}

                {#if !!mirroredNodes.length}
                    <Collapsable
                        title="Mirrors"
                        bind:isClosed={collapsedStates.mirroredNodes}
                        upsell={!self._isMirrorEnabled}
                    >
                        <div class="p-2 grid grid-cols-[40px,auto] gap-1 items-center">
                            {#each mirroredNodes as comp}
                                <div class="text-center">
                                    <img
                                        src={comp.data.icon}
                                        alt="{comp.data.category} icon"
                                        width="25px"
                                    />
                                </div>
                                <button class="text-left hover:text-brand" on:click={() => diagram.zoomToNode(comp)}>{comp.data.name} (ID: {Math.abs(comp.data.key)})</button>
                            {/each}
                        </div>
                    </Collapsable>
                {/if}

                <Collapsable
                    title="Notes"
                    bind:isClosed={collapsedStates.notes}
                >
                    <div class="p-2">
                        <div class="whitespace-pre-wrap break-words">{data.notes}</div>
                        <div class="text-right mt-2">
                            <Button
                                text="Edit Notes"
                                icon="fal fa-edit"
                                onClick={handleEditNotes}
                            />
                        </div>
                    </div>
                </Collapsable>

                {#if data.category === "TRM"}
                    <div class="mt-4 text-center p-2 bg-[#46484d]">
                        <Button
                            text="Download Report"
                            icon="far fa-download"
                            onClick={handleDownloadReport}
                        />
                    </div>
                {/if}
            </div>
        {/if}

        {#if !!probeCandidates.length}
            <div class="grow pt-2 overflow-y-scroll">
                <Probe candidates={probeCandidates} {collapsedStates} {group} {onChange} />
            </div>
        {/if}
    {/if}
</div>
