import Component from './utils/component.js';
import iconUrl from '../images/generic.svg';

export default class Generic extends Component {
    _iconChoices = [
        iconUrl,
        // These icons have to be loaded from the public folder so they
        // don't get hashed and changed over time.
        // If we change a generic icon choice we have to keep the old version around still
        "/generic/isolator-clockwise.1.svg",
        "/generic/isolator-anticlockwise.1.svg",
        "/generic/phase-shifter.1.svg",
        "/generic/variable-attenuator.1.svg",
        "/generic/mixer-if.1.svg",
        "/generic/mixer-rf.1.svg",
        "/generic/directional-coupler.1.svg",
        "/generic/switch.1.svg",
        "/generic/transmission.1.svg",
        "/generic/splitter.1.svg",
        "/generic/combiner.1.svg",
        "/generic/source.1.svg",
    ];
    _iconNames = {
        "/generic/isolator-clockwise.1.svg": "Isolator",
        "/generic/isolator-anticlockwise.1.svg": "Isolator",
        "/generic/phase-shifter.1.svg": "Phase Shifter",
        "/generic/variable-attenuator.1.svg": "Variable Attenuator",
        "/generic/mixer-if.1.svg": "Mixer",
        "/generic/mixer-rf.1.svg": "Mixer",
        "/generic/directional-coupler.1.svg": "Directional Coupler",
        "/generic/switch.1.svg": "Switch",
        "/generic/transmission.1.svg": "Transmission Line",
        "/generic/splitter.1.svg": "Splitter",
        "/generic/combiner.1.svg": "Combiner",
        "/generic/source.1.svg": "Generator",
    }
    category = "GEN";

    constructor(args) {
        args.defaults = [
            { name: "Gain", value: -3, cascadeValue: [{ name: "NF", enabled: true, hidden: false }] },
            { name: "OIP2", value: 96 },
            { name: "OIP3", value: 96 },
            { name: "OP1dB", value: 94.75 },
            { name: "OPsat", value: 95 },
            { name: "IIP2", value: 99 },
            { name: "IIP3", value: 99 },
            { name: "IP1dB", value: 98.75 },
            { name: "IPsat", value: 99 },
            { name: "NF", value: 3 },
            { name: "Idc", value: 0, unit: "mA", isEnabled: false, isWatched: false, precision: 0 },
            { name: "DevSply", value: 0, unit: "Vdc", isEnabled: false, isWatched: false },
            { name: "BusSply", value: 0, unit: "Vdc", isEnabled: false, isWatched: false },
            { name: "OP1BackOff", value: 2 },
            { name: "IP1BackOff", value: 0 },
            { name: "Te", value: 290, unit: "°K", isEnabled: false, isWatched: false, isHidden: true },
            { name: "Device Type", value: "Default", unit: "", choices: ["Default", "TX", "RX"], isEnabled: true, isEnabledEditable: false, isWatched: false },
        ]
        args.name = args.name || "Generic";
        args.summary = args.summary || "Generic RF Component that defaults to linear lossy 2 port device.  Icon is user selectable to enhance presentations.";
        args.icon = args.icon || iconUrl;
        super(args);
    }
}
