<script>
    import { authToken, visiblePage, isBusy } from "../stores.js";
    import { register, login, me, acceptSend } from "../api.js";
    import Button from "./components/Button.svelte";
    import Page from "./Page.svelte";
    import Input from "./components/form/Input.svelte";
    import Checkbox from "./components/form/Checkbox.svelte";

    export let token;
    export let graphId;

    let email = "";
    let password = "";
    let agreeTerms = false;
    let agreePrivacy = false;
    let errors = {};

    const handleSubmit = () => {
        if ($isBusy) {
            return;
        }
        isBusy.set(true);
        register(email, password, agreeTerms, agreePrivacy)
            .then((resp) => {
                if (!resp.error) {
                    return login(resp.email, password).then((resp) => {
                        authToken.set(resp.access_token);
                        return me(resp.access_token).then(() => {
                            if (!!token && !!graphId) {
                                acceptSend(resp.access_token, graphId, token).then(
                                    (resp) => {
                                        if (resp.error) {
                                            window.rfgShowToast(
                                                "Error",
                                                "There was an error accessing the graph, please get in touch.",
                                                "error"
                                            );
                                        } else {
                                            window.rfgShowToast(
                                                "Graph Shared",
                                                "The graph that was sent to you is available in your account.",
                                                "success"
                                            );
                                        }
                                        visiblePage.set("/verify/requested/");
                                    }
                                );
                            } else {
                                visiblePage.set("/verify/requested/");
                            }
                        });
                    });
                } else {
                    errors = resp.fields;
                }
            })
            .finally(() => isBusy.set(false));
    };

</script>

<Page>
    <form
        slot="body"
        on:submit|preventDefault={handleSubmit}
        class="p-4 max-w-lg mx-auto"
    >
        <h1 class="text-xl font-bold">Sign Up</h1>
        <div class="mt-2">
            <Input
                bind:value={email}
                error={errors.email}
                label="Email Address"
                name="signup-email"
                helpText="Used to sign in, and to contact you if necessary"
                required={true}
                type="email"
            />
            <div class="mt-2">
                <Input
                    bind:value={password}
                    error={errors.password}
                    label="Password"
                    name="signup-password"
                    required={true}
                    type="password"
                />
            </div>
            <p class="my-2">
                Before you create an account please take some time to read our <a href="https://rfgraph.com/terms/" target="_blank" rel="noreferrer">terms and conditions</a> and <a href="https://rfgraph.com/privacy/" target="_blank" rel="noreferrer">privacy policy</a>.
            </p>
            <Checkbox
                bind:value={agreeTerms}
                error={errors.agree_terms}
                label="I have read and accept the terms and conditions"
                name="agree-terms"
                required={true}
            />
            <Checkbox
                bind:value={agreePrivacy}
                error={errors.agree_privacy}
                label="I have read and accept the privacy policy"
                name="agree-privacy"
                required={true}
            />
        </div>
        <div class="mt-4 flex flex-row justify-between items-start">
            <Button text="Sign Up" icon="fal fa-user-plus" />
            <div class="text-right">
                <div>
                    <Button
                        text="Already have an account?"
                        type="button"
                        url="/sign-in/"
                        theme="anchor"
                        toUpperCase={false}
                        boldText={false}
                    />
                </div>
                <div>
                    <Button
                        text="Forgot your password?"
                        type="button"
                        url="/forgot-password/"
                        theme="anchor"
                        toUpperCase={false}
                        boldText={false}
                    />
                </div>
            </div>
        </div>
    </form>
</Page>
