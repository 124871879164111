

function extractData(data, field, value) {
    return data.filter(d => d[field].equals(value));
}

function findClosestData(data, field, value) {
    data = data.slice();
    data.sort((a, b) => a[field].minus(b[field]).toNumber());

    // If requested below or equal to smallest value, clip to smallest
    if (data[0][field].greaterThanOrEqualTo(value)) {
        return [extractData(data, field, data[0][field])];
    }
    // If requested higher than biggest value, clip to biggest
    if (data[data.length - 1][field].lessThanOrEqualTo(value)) {
        return [extractData(data, field, data[data.length - 1][field])];
    }

    for (let index = 1; index < data.length; index++) {
        const current = data[index][field];
        if (current.equals(value)) {
            return [extractData(data, field, current)];
        }
        const previous = data[index - 1][field];
        if (value.greaterThan(previous) && value.lessThan(current)) {
            return [extractData(data, field, previous), extractData(data, field, current)];
        }
    }
}

function i(x, x0, x1, y0, y1) {
    let a = y1.minus(y0);
    let b = x1.minus(x0);
    let c = x.minus(x0);
    let d = c.times(a.dividedBy(b));
    return y0.add(d);
}

function bi(f11, f12, f21, f22, x, y, x1, x2, y1, y2) {
    let value = f11.times(x2.minus(x)).times(y2.minus(y));
    value = value.add(f21.times(x.minus(x1)).times(y2.minus(y)));
    value = value.add(f12.times(x2.minus(x)).times(y.minus(y1)));
    value = value.add(f22.times(x.minus(x1)).times(y.minus(y1)));
    value = value.dividedBy(x2.minus(x1).times(y2.minus(y1)));
    return value;
}

export function interpolateValue(data, temp, freq) {
    if (data.length === 1) {
        return data[0].value;
    }

    let byTemp = findClosestData(data, "temp", temp);
    if (byTemp.length === 1) {
        let byFreq = findClosestData(byTemp[0], "freq", freq);
        if (byFreq.length === 1) {
            return byFreq[0][0].value;
        } else {
            return i(freq, byFreq[0][0].freq, byFreq[1][0].freq, byFreq[0][0].value, byFreq[1][0].value);
        }
    } else {
        let byFreq = findClosestData(data, "freq", freq);
        if (byFreq.length === 1) {
            byTemp = findClosestData(byFreq[0], "temp", temp);
            if (byTemp.length === 1) {
                return byTemp[0][0].value;
            } else {
                return i(temp, byTemp[0][0].temp, byTemp[1][0].temp, byTemp[0][0].value, byTemp[1][0].value);
            }
        } else {
            let points = [
                findClosestData(byTemp[0], "freq", freq),
                findClosestData(byTemp[1], "freq", freq),
            ];
            if (points[0].length === 1 || points[1].length === 1) {
                return i(freq, byFreq[0][0].freq, byFreq[1][0].freq, byFreq[0][0].value, byFreq[1][0].value);
            }
            return bi(
                points[0][0][0].value,
                points[0][1][0].value,
                points[1][0][0].value,
                points[1][1][0].value,
                freq,
                temp,
                points[0][0][0].freq,
                points[0][1][0].freq,
                points[0][0][0].temp,
                points[1][0][0].temp,
            )
        }
    }
}
