import Decimal from 'decimal.js';

function lineToList(line) {
    let parts = line.split("!");
    return parts[0].trim().split(/\s+/);
}

function cleanRawData(rawData) {
    let result = { options: null, lines: [] };
    rawData.split(/\r?\n/).forEach(line => {
        line = line.trim();
        if (!!line) {
            if (line[0] === "#") {
                result.options = lineToList(line.toUpperCase());
            } else if (line[0] !== "!") {
                result.lines.push(lineToList(line));
            }
        }
    });
    return result;
}

function parseOptions(line) {
    let options = { frequencyUnit: "GHZ", format: "MA", R: 50 };
    if (line !== null) {
        line = line.slice(1);
        for (let index = 0; index < line.length; index++) {
            const opt = line[index];
            if (["GHZ", "MHZ", "KHZ", "HZ"].indexOf(opt) !== -1) {
                options.frequencyUnit = opt;
            } else if (["DB", "MA", "RI"].indexOf(opt) !== -1) {
                options.format = opt;
            } else if (["S", "Y", "Z", "H", "G"].indexOf(opt) !== -1) {
                if (opt !== "S") throw "File must contain S-parameter (scattering) data";
            } else if (opt === "R") {
                index++;
                let ohms = parseInt(line[index]);
                if (Number.isNaN(ohms)) throw `Bad R value '${line[index]}'`;
                if (ohms !== 50) throw "Only 50 ohms reference resistance supported";
                options.R = ohms;
            } else {
                throw `Unknown option '${opt}'`
            }
        }
    }
    return options;
}

function convertFrequency(providedValue, providedUnit) {
    if (providedUnit === "HZ") {
        return providedValue.dividedBy(1000 * 1000);
    } else if (providedUnit === "KHZ") {
        return providedValue.dividedBy(1000);
    } else if (providedUnit === "MHZ") {
        return providedValue;
    } else if (providedUnit === "GHZ") {
        return providedValue.times(1000);
    }
}

function convertFormat(providedPair, providedFormat) {
    if (providedFormat === "DB") {
        return providedPair;
    } else if (providedFormat === "MA") {
        return [providedPair[0].logarithm(10).times(20), providedPair[1]];
    } else if (providedFormat === "RI") {
        let real = providedPair[0];
        let imag = providedPair[1];
        let mag = Decimal.log10(Decimal.sqrt(real.toPower(2).plus(imag.toPower(2)))).times(20);
        let angle = Decimal.atan2(imag, real).times(180).dividedBy(Math.PI);
        return [mag, angle];
    }
}

export function parseSParameterData(rawData) {
    let { options, lines } = cleanRawData(rawData);
    options = parseOptions(options);
    let dataAtFreq = {};
    let freq = new Decimal(0);
    lines.forEach(line => {
        if (line.length % 2 === 1) {
            freq = new Decimal(line[0]);
            freq = convertFrequency(freq, options.frequencyUnit);
            line = line.slice(1);
            freq = freq.toString();
        }
        line = line.map(datum => new Decimal(datum));
        if (!dataAtFreq[freq]) {
            dataAtFreq[freq] = [];
        }
        while (line.length > 0) {
            let pair = line.splice(0, 2);
            pair = convertFormat([new Decimal(pair[0]), new Decimal(pair[1])], options.format);
            dataAtFreq[freq].push(pair);
        }
    });

    let data = [];
    Object.entries(dataAtFreq).forEach(([freq, pairs]) => {
        let value;
        // We don't really cope with sparams that aren't 2-port ATM
        if (pairs.length === 1) value = pairs[0][0];
        else if (pairs.length === 4) value = pairs[1][0];
        else if (pairs.length === 9) value = [pairs[1][0], pairs[4][0]];
        data.push({ freq: new Decimal(freq), value: value });
    })

    return {
        portCount: parseInt(Math.sqrt(dataAtFreq[freq].length)),
        data: data,
    }
}

export function convertToMultiValue(files) {
    let multiValues = [];
    files.forEach(spf => {
        const temp = new Decimal(spf.temp);
        spf.data.forEach(d => {
            multiValues.push({
                temp: temp,
                freq: d.freq,
                value: d.value,
            });
        });
    });
    return multiValues;
}
