<script>
    import { visibleModal } from "../stores.js";
    import Modal from "./Modal.svelte";

    export let currentIcon;
    export let iconChoices;
    export let onConfirm;

    const handleSubmit = (evt) => {
        onConfirm(evt.submitter.value);
        visibleModal.set(null);
    };
</script>

<Modal
    title="Change Icon"
    icon="fas fa-circle fa-2x"
    confirmText={null}
    onConfirm={handleSubmit}
>
    <p class="my-2">Please select the icon you would like to use.</p>
    <h4 class="font-bold mb-2">Current Icon</h4>
    <img src="{currentIcon}" alt="The current icon" width="72">
    <h4 class="font-bold mb-2 mt-4">Icon Choices</h4>
    <form on:submit|preventDefault={handleSubmit}>
        <div class="flex flex-row flex-wrap gap-2">
            {#each iconChoices as icon}
                <button name="icon" value={icon}>
                    <img src="{icon}" alt="A new icon" width="72">
                </button>
            {/each}
        </div>
    </form>
</Modal>
