<script>
    import Decimal from 'decimal.js';
    import { visibleModal, modalConfirmEnabled, features } from "../stores.js";
    import Modal from "./Modal.svelte";
    import MultiValue from "./_MultiValue.svelte";
    import SParamPlot from "./_SParamPlot.svelte";
    import Button from "../pages/components/Button.svelte";
    import Select from "../pages/components/form/Select.svelte";
    import Input from "../pages/components/form/Input.svelte";
    import Checkbox from "../pages/components/form/Checkbox.svelte";
    import { parseSParameterData } from '../sparams.js';
    import { onMount, tick } from "svelte";
    import Collapsable from './_Collapsable.svelte';
    import { changeInBackground } from "../simulator.js";
    import Parameters from '../components/utils/parameters.js';

    export let diagram;
    export let component;
    export let parameter;
    export let canEdit = true;

    const PRECISION = parseInt(import.meta.env.VITE_DECIMAL_PRECISION || 2);

    let value = parameter.getPretty();
    let valueError = null;
    let unit = parameter.unit;
    let origUnit = unit;
    let isEnabled = parameter.isEnabled;
    let origIsEnabled = isEnabled;
    let isWatched = parameter.isWatched;
    let origIsWatched = isWatched;
    let errors = {};

    let showMultiValue = parameter.isMultiValued && component.category !== "SPL" && component.category !== "CMB";
    let multiValues = [];
    let mvEnabled = false;
    let frequencyUnit = "MHz";

    let showSParamUpload = parameter.name === "Gain" && component.category !== "SPL" && component.category !== "CMB";
    let sParamError = null;
    let sParamFiles = [];
    let sParamEnabled = false;
    let graphedSParamFiles = [];

    let mirrorCount = component.findMirrors(diagram).count;

    const handleSubmit = () => {
        if (!$modalConfirmEnabled) {
            return;
        }
        if (!value) {
            valueError = "You must provide a value";
            return;
        }
        let changes = [];
        if (canEdit) {
            // cascadeValue has to go before value so that mirrored cascadeValue values are updated
            // before the value change is processed.
            changes.push(["cascadeValue", parameter.cascadeValue.filter(cv => !cv.hidden).map(cv => [cv.name, cv.enabled])])
            changes.push(["value", value]);
            if (origUnit !== unit) {
                changes.push(["unit", unit]);
            }
            if (origIsEnabled !== isEnabled) {
                changes.push(["isEnabled", isEnabled]);
            }
            if (!isEnabled) {
                isWatched = false;
            }
            if (mvEnabled) {
                changes.push(["cascadeMV", parameter.cascadeMV.filter(cmv => !cmv.hidden).map(cmv => [cmv.name, cmv.enabled, cmv.delta])])
                changes.push(["multiValues", cleanMVs]);
            }
            changes.push(["isMultiValuedEnabled", mvEnabled]);

            if (showSParamUpload) {
                changes.push(["sParams", sParamFiles]);
            }
            changes.push(["isSParamsEnabled", sParamEnabled]);
        }
        if (origIsWatched !== isWatched) {
            changes.push(["isWatched", isWatched]);
        }

        let closeModal = true;

        if (changes.length !== 0) {
            const copyComp = component.copy();
            const copyParam = Parameters.parameterFactory(parameter);
            changeInBackground(copyComp, copyParam, changes);
            errors = copyParam.validate(copyComp);
            closeModal = Object.keys(errors).length === 0;
            if (closeModal) {
                component.change(diagram, parameter, changes);
                component.broadcastChangeNotification();
            }
        }

        parameter.clearSeenWarnings();
        if (closeModal) {
            visibleModal.set(null);
        }
    };

    const handleVisibilityEverywhere = (newWatched) => {
        isWatched = newWatched;
        for (const comp of diagram.model.nodeDataArray) {
            if (!!comp.change) {
                let params = [];
                if (comp.parameters) {
                    params.push(comp.parameters.get(parameter.name));
                }
                if (!!comp._outputs) {
                    for (const out of comp._outputs) {
                        params.push(out.get(parameter.name));
                    }
                }
                for (let idx = 0; idx < params.length; idx++) {
                    if (params[idx]) {
                        comp.change(diagram, params[idx], [["isWatched", newWatched]]);
                    }
                }
            }
        }
        visibleModal.set(null);
    };

    const handleWatchEverywhere = () => handleVisibilityEverywhere(true);
    const handleHideEverywhere = () => handleVisibilityEverywhere(false);

    const getDroppedFile = (evt) => {
        if (evt.dataTransfer.items) {
            const items = [...evt.dataTransfer.items];
            for (let index = 0; index < items.length; index++) {
                const item = items[index];
                if (item.kind === 'file') {
                    return item.getAsFile();
                }
            }
        } else {
            return evt.dataTransfer.files[0];
        }
    }

    const handleSParamDragOver = (evt) => {
        evt.preventDefault();
        evt.dataTransfer.dropEffect = "copy";
        sParamError = null;
    }

    const handleSParamFile = (file) => {
        const ext = file.name.slice(-4).toLowerCase();
        if ([".s2p", ".s3p", ".s4p", ".s5p", ".s9p"].indexOf(ext) === -1) {
            sParamError = "Only .s2p, .s3p, .s4p, .s5p, and .s9p files are accepted.";
            return
        }
        if (!!sParamFiles.find(other => other.name === file.name)) {
            sParamError = "That file has already been uploaded.";
            return
        }
        if (!isEditable) {
            sParamError = "Your account is in read-only mode.";
            return
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                let parsed = parseSParameterData(reader.result);
                let newSParamFile = {
                    name: file.name,
                    s3Key: null,
                    data: parsed.data,
                    temp: 25,
                    portCount: parsed.portCount
                };
                if (sParamFiles.length > 0) {
                    if (sParamFiles[0].data.portCount !== newSParamFile.data.portCount) {
                        sParamError = `This component is using s${sParamFiles[0].data.portCount}p files, this appears to be an s${newSParamFile.data.portCount}p file`;
                        return
                    }
                }
                sParamFiles = [newSParamFile, ...sParamFiles];
                value = component.calculateUsingSParams(component.getFirstInput(), parameter, sParamFiles).toFixed(PRECISION);
            } catch (e) {
                sParamError = e;
                throw e
            }
        }
        reader.readAsText(file);
    }

    const handleSParamDrop = (evt) => {
        evt.preventDefault();
        let file = getDroppedFile(evt);
        handleSParamFile(file);
    }

    const showSParamPicker = () => {
        if (!isEditable) {
            sParamError = "Your account is in read-only mode.";
            return
        }
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = ".s2p,.s3p,.s4p,.s5p,.s9p";
        input.onchange = e => {
            handleSParamFile(e.target.files[0]);
        }
        input.click();
        sParamError = null;
    }

    const handleRemoveSParam = (name) => {
        sParamFiles = sParamFiles.filter(sparam => sparam.name !== name);
        value = component.calculateUsingSParams(component.getFirstInput(), parameter, sParamFiles).toFixed(PRECISION);
    }

    const handleSParamTempChange = (file, newValue) => {
        newValue = parseFloat(newValue);
        if (newValue < -250) {
            newValue = -250;
            window.rfgShowToast("Changed Value!", `Temperature must be greater than or equal to -250`, "warning");
        }
        if (newValue > 400) {
            newValue = 400;
            window.rfgShowToast("Changed Value!", `Temperature must be less than or equal to 400`, "warning");
        }
        if (!Number.isNaN(newValue)) {
            for (let index = 0; index < sParamFiles.length; index++) {
                const sparam = sParamFiles[index];
                if (sparam.name === file.name) sparam.temp = newValue;
            }
            sParamFiles = sParamFiles;
            value = component.calculateUsingSParams(component.getFirstInput(), parameter, sParamFiles).toFixed(PRECISION);
        }
    }

    const handleTogglePlotSParam = (name) => {
        let idx = graphedSParamFiles.indexOf(name);
        if (idx === -1) {
            graphedSParamFiles.push(name);
        } else {
            graphedSParamFiles.splice(idx, 1);
        }
        graphedSParamFiles = graphedSParamFiles;
    }

    const handleMultiValueChange = async () => {
        await tick();
        value = component.interpolate(component.getFirstInput(), parameter, cleanMVs).toFixed(PRECISION);
    }

    const handleToggleMV = (evt) => {
        if (evt.detail.isEnabled) {
            sParamEnabled = false;
            value = component.interpolate(component.getFirstInput(), parameter, cleanMVs).toFixed(PRECISION);
        }
    }

    const handleToggleSP = (evt) => {
        if (evt.detail.isEnabled) {
            mvEnabled = false;
            value = component.calculateUsingSParams(component.getFirstInput(), parameter, sParamFiles).toFixed(PRECISION);
        }
    }

    const handleClose = () => {
        parameter.clearSeenWarnings();
    }

    const isCleanMV = mv => {
        if (mv.freq === null || mv.freq === undefined) return false;
        if (mv.temp === null || mv.temp === undefined) return false;
        if (mv.value === null || mv.value === undefined) return false;
        return true;
    }

    onMount(async () => {
        if (showSParamUpload) {
            sParamFiles = (parameter.sParams || []).map(old => {
                return {
                    name: old.name,
                    portCount: old.portCount,
                    s3Key: old.s3Key,
                    data: old.data.map(o => o),
                    temp: old.temp,
                }
            });
            sParamEnabled = parameter.isSParamsEnabled && sParamFiles.length > 0;
        }
        if (showMultiValue) {
            let mvs = [];
            let existing = [];
            for (let idx = 0; idx < parameter.multiValues.length; idx++) {
                const old = parameter.multiValues[idx];
                const mv = {
                    freq: new Decimal(old.freq),
                    temp: new Decimal(old.temp || "25"),
                    value: new Decimal(old.value),
                }
                mvs.push(mv);
                let key = `${mv.freq.toFixed(PRECISION)}|${mv.temp.toFixed(0)}`;
                existing.push(key);
            }
            for (let idx = 0; idx < component.parameters.all.length; idx++) {
                const param = component.parameters.all[idx];
                for (let jdx = 0; jdx < param.multiValues.length; jdx++) {
                    const mv = param.multiValues[jdx];
                    let temp = mv.temp || new Decimal(25);
                    let key = `${mv.freq.toFixed(PRECISION)}|${temp.toFixed(0)}`;
                    if (existing.indexOf(key) === -1) {
                        mvs.push({freq: mv.freq, temp: temp});
                        existing.push(key);
                    }
                }
            }
            mvs.sort((a, b) => {
                if (a.temp.equals(b.temp)) {
                    return a.freq.comparedTo(b.freq);
                }
                return a.temp.comparedTo(b.temp);
            });
            multiValues = mvs;
            await tick();
            mvEnabled = parameter.isMultiValuedEnabled && cleanMVs.length > 0 && !sParamEnabled;

            let firstInput = component.getFirstInput();
            if (!!firstInput) {
                frequencyUnit = firstInput.get("Frequency").unit;
            }
        }
    })

    $: {
        if (!!valueError) {
            modalConfirmEnabled.set(false);
        } else {
            modalConfirmEnabled.set(true);
        }
    }
    $: cleanMVs = multiValues.filter(isCleanMV);
    $: isEditable = isEnabled && canEdit;
    $: inputDisabled = !isEditable || ($features.sparams !== "upsell" && sParamFiles.length > 0 && sParamEnabled) || ($features.multivalues !== "upsell" && cleanMVs.length > 0 && mvEnabled);
</script>

<Modal
    title="{component.name}"
    iconUrl={component.icon}
    confirmText="Save"
    confirmIcon="fal fa-floppy-disk"
    onConfirm={handleSubmit}
    onClose={handleClose}
>
    <form on:submit|preventDefault={handleSubmit}>
        <input type="submit" hidden>
        <div class="mb-4">
            <div class="mt-2 flex flex-row items-center mb-2">
                <div class="grow">
                    {#if !!parameter.choices}
                        <Select
                            choices={parameter.choices.map((c) => [parameter.getPretty(c), parameter.getPretty(c)])}
                            bind:value
                            bind:error={valueError}
                            label={parameter.name}
                            name="editparam-value"
                            disabled={inputDisabled}
                            required={true}
                        />
                    {:else}
                        <Input
                            bind:value
                            disabled={inputDisabled}
                            label={parameter.name}
                            bind:error={valueError}
                            name="editparam-value"
                            type="number"
                            min={parameter.minValue}
                            max={parameter.maxValue}
                            step="any"
                            autofocus={true}
                            required={true}
                        />
                    {/if}
                </div>
                <div class="ml-2 mt-6">
                    {parameter.unit}
                </div>
            </div>
            {#each Object.values(parameter._warnings) as warn}
                <p class="text-yellow-500 text-xs mb-2">{warn.msg}</p>
            {/each}
            {#each parameter.cascadeValue as cascade (cascade.name)}
                {#if !cascade.hidden}
                    <div class="mb-4">
                        <Checkbox label="Auto update the {cascade.name} value" name="cascade-value-enabled-{cascade.name}" bind:value={cascade.enabled} error={null} />
                    </div>
                {/if}
            {/each}
        </div>
        {#if canEdit && parameter.isEnabledEditable}
            <div class="border-t pt-2 mt-2">
                <div class="flex flex-row items-center gap-2">
                    {#if isEnabled}
                        <span class="align-middle text-2xl">
                            <label for="edit-enabled" class="cursor-pointer">
                                <i
                                    class="fa-duotone fa-toggle-on"
                                    style="--fa-primary-color: white; --fa-secondary-color: var(--color-brand); --fa-secondary-opacity: 1"
                                />
                            </label>
                        </span>
                        <label for="edit-enabled" class="cursor-pointer font-semibold">
                            Enabled
                        </label>
                    {:else}
                        <span class="align-middle text-2xl">
                            <label for="edit-enabled" class="cursor-pointer">
                                <i
                                    class="fa-duotone fa-toggle-off"
                                    style="--fa-primary-color: black; --fa-secondary-color: black; --fa-primary-opacity: .4"
                                />
                            </label>
                        </span>
                        <label for="edit-enabled" class="cursor-pointer font-semibold">
                            Disabled
                        </label>
                    {/if}
                </div>
                <input
                    bind:checked={isEnabled}
                    type="checkbox"
                    id="edit-enabled"
                    class="hidden"
                />
            </div>
        {/if}
        {#if isEnabled}
            <div class="border-t pt-2 mt-2">
                <div class="flex flex-row items-center gap-2">
                    {#if isWatched}
                        <span class="align-middle text-2xl">
                            <label for="edit-watched" class="cursor-pointer">
                                <i
                                    class="fa-duotone fa-toggle-on"
                                    style="--fa-primary-color: white; --fa-secondary-color: var(--color-brand); --fa-secondary-opacity: 1"
                                />
                            </label>
                        </span>
                        <label for="edit-watched" class="cursor-pointer font-semibold">
                            Watched
                        </label>
                    {:else}
                        <span class="align-middle text-2xl">
                            <label for="edit-watched" class="cursor-pointer">
                                <i
                                    class="fa-duotone fa-toggle-off"
                                    style="--fa-primary-color: black; --fa-secondary-color: black; --fa-primary-opacity: .4"
                                />
                            </label>
                        </span>
                        <label for="edit-watched" class="cursor-pointer font-semibold">
                            Hidden
                        </label>
                    {/if}
                        <div class="ml-auto">
                            <Button
                                text="Watch All"
                                theme="white"
                                icon="far fa-fw fa-eye"
                                size="small"
                                type="button"
                                onClick={handleWatchEverywhere}
                            />
                            <Button
                                text="Hide All"
                                theme="white"
                                icon="far fa-fw fa-eye-slash"
                                size="small"
                                type="button"
                                onClick={handleHideEverywhere}
                            />
                        </div>
                </div>
                <input
                    bind:checked={isWatched}
                    type="checkbox"
                    id="edit-watched"
                    class="hidden"
                />
            </div>
        {/if}
        {#if showSParamUpload || showMultiValue}
            <div class="border-t pt-2 mt-2">
                {#if $features.sparams === "upsell" || $features.multivalues === "upsell"}
                    <div class="flex flex-row items-center gap-2 text-sm my-2">
                        <Button
                            text="Upgrade"
                            type="button"
                            url="/account/subscription/"
                            theme="pro"
                            size="small"
                        />
                        <p><a href="/account/subscription/">Upgrade to use S-Parameters and Multi Values.</a></p>
                    </div>
                {/if}
                {#if showSParamUpload}
                    <Collapsable title="S-Parameters" bind:isEnabled={sParamEnabled} on:toggle-enabled={handleToggleSP} upsell={$features.sparams === "upsell"}>
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <div class="py-4 px-2 mt-2 rounded bg-gray-100 border border-gray-300 border-dashed text-center cursor-pointer" on:dragover={handleSParamDragOver} on:drop={handleSParamDrop} class:border-red-500={!!sParamError} on:click={showSParamPicker}>
                            <p class="text-gray-500 text-lg">S-Parameter File</p>
                            <p class="text-gray-500">Drag and drop, or click to choose</p>
                            {#if sParamError}
                                <p class="text-red-500">{sParamError}</p>
                            {/if}
                        </div>
                        {#if sParamFiles.length > 0}
                            <div class="grid grid-cols-[25px,1fr,100px,50px] items-center gap-y-2 mt-2">
                                <span></span>
                                <strong class="font-medium text-gray-900">Filename</strong>
                                <strong class="font-medium text-gray-900">Temp. (&#176;C)</strong>
                                <span></span>
                                {#each sParamFiles as file (file.name) }
                                    <div class="text-center">
                                        <Button text="" type="button" theme="anchor" icon="far fa-chart-line" onClick={() => handleTogglePlotSParam(file.name)} />
                                    </div>
                                    <div class="truncate" title="{file.name}">{file.name}</div>
                                    <div>
                                        <input
                                            value={file.temp}
                                            on:change={evt => handleSParamTempChange(file, evt.target.value)}
                                            type="number"
                                            step="any"
                                            class="rounded w-full"
                                            disabled={!isEditable}
                                        />
                                    </div>
                                    <div class="text-center">
                                        <Button text="" type="button" theme="anchor-danger" icon="far fa-trash" onClick={() => handleRemoveSParam(file.name)} isDisabled={!isEditable} />
                                    </div>
                                    {#if graphedSParamFiles.indexOf(file.name) !== -1}
                                        <div class="mt-2 col-span-4">
                                            <SParamPlot file={file} />
                                        </div>
                                    {/if}
                                {/each}
                            </div>

                        {/if}
                    </Collapsable>
                {/if}
                {#if showMultiValue}
                    <MultiValue bind:multiValues={multiValues}
                                on:change={handleMultiValueChange}
                                bind:isEnabled={mvEnabled}
                                bind:isEditable={isEditable}
                                freqUnit={frequencyUnit}
                                valueUnit={parameter.unit}
                                on:toggle-enabled={handleToggleMV}
                                parameter={parameter}
                                bind:errors={errors}
                    />
                {/if}
            </div>
        {/if}
        {#if mirrorCount > 0}
            <p class="text-right -mb-2 text-sm mt-4" class:line-through={$features.mirror === "upsell"}>Changing these values will also update {mirrorCount} mirrored components.</p>
            {#if $features.mirror === "upsell"}
                <p class="text-right -mb-2 text-sm mt-2"><a href="/account/subscription/">Upgrade to get access to mirrored components.</a></p>
            {/if}
        {/if}
    </form>
</Modal>
