<script>
    import { isBusy, visibleModal, modalConfirmEnabled } from "../stores.js";
    import Button from "../pages/components/Button.svelte";
    import { onDestroy, onMount } from "svelte";

    export let icon = null;
    export let iconUrl = null;
    export let iconStyle = null;
    export let title;
    export let cancelText = "Cancel";
    export let cancelIcon = "fal fa-times";
    export let confirmText = "Confirm";
    export let confirmIcon = "fal fa-check";
    export let confirmTheme = "default";
    export let onClose = null;
    export let onConfirm = null;
    export let dangerText = null;
    export let onDanger = null;

    let showDangerConfirm = false;

    onMount(() => {
        document.addEventListener("keydown", closeOnEscape, { capture: true });
    });

    onDestroy(() => {
        document.removeEventListener("keydown", closeOnEscape);
    });

    const handleStartDanger = () => {
        if ($isBusy) return;
        showDangerConfirm = true;
    };

    const handleCancelDanger = () => {
        if ($isBusy) return;
        showDangerConfirm = false;
    };

    const handleConfirmDanger = () => {
        if ($isBusy) return;
        if (!!onDanger) onDanger();
    };

    const handleClose = () => {
        if ($isBusy) return;
        if (!!onClose) {
            onClose();
        }
        visibleModal.set(null);
    };

    const handleConfirm = () => {
        if ($isBusy) return;
        if (!!onConfirm) {
            onConfirm();
        } else {
            visibleModal.set(null);
        }
    };

    const closeOnEscape = (evt) => {
        let isEscape = evt.keyCode === 27;
        if ("key" in evt) {
            isEscape = evt.key === "Escape" || evt.key === "Esc";
        }
        if (isEscape) {
            if ($visibleModal !== null && !$isBusy) {
                handleClose();
            }
        }
    };
</script>

<div class="fixed z-50 inset-0 overflow-y-auto">
    <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
        <div
            on:click={handleClose}
            class="fixed inset-0 transition-opacity"
            aria-hidden="true"
        >
            <div class="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
        >
            &#8203;
        </span>
        <div
            class="inline-block bg-white rounded-lg text-left overflow-hidden transform transition-all my-8 align-top max-w-xl w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
        >
            <div class="bg-white">
                <div class="flex flex-row items-center gap-4 border-b p-4 mb-4">
                    {#if !!iconUrl}
                        <img
                            src={iconUrl}
                            alt="Icon representing {title}"
                            class="w-10"
                        />
                    {:else if !!icon}
                        <span><i class={icon} style={iconStyle} /></span>
                    {/if}
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                        {title}
                    </h3>
                </div>
                <div class="px-4 pb-4">
                    <slot />
                </div>
            </div>
            <div class="bg-gray-200 px-4 py-3 flex flex-row items-center">
                {#if !!dangerText && !!onDanger}
                    {#if showDangerConfirm}
                        <p class="mr-2">Are you sure?</p>
                        <span class="mr-2">
                            <Button
                                text="Cancel"
                                theme="white"
                                onClick={handleCancelDanger}
                            />
                        </span>
                        <Button
                            text="Yes, I'm sure"
                            theme="danger"
                            isDisabled={!$modalConfirmEnabled}
                            onClick={handleConfirmDanger}
                        />
                    {:else}
                        <Button
                            text={dangerText}
                            theme="anchor-danger"
                            onClick={handleStartDanger}
                            boldText={false}
                        />
                    {/if}
                {/if}
                {#if !showDangerConfirm}
                    <span class="mr-2 ml-auto">
                        <Button
                            text={cancelText}
                            icon={cancelIcon}
                            theme="white"
                            onClick={handleClose}
                            type="button"
                        />
                    </span>
                    {#if confirmText}
                        <Button
                            text={confirmText}
                            icon={confirmIcon}
                            theme={confirmTheme}
                            isDisabled={!$modalConfirmEnabled}
                            onClick={handleConfirm}
                        />
                    {/if}
                {/if}
            </div>
        </div>
    </div>
</div>
