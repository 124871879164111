<script>
    import { authToken, isBusy, visibleModal } from "../stores.js";
    import { createGraph } from "../api.js";
    import Modal from "./Modal.svelte";
    import ChooseFolder from "./_ChooseFolder.svelte";
    import Input from "../pages/components/form/Input.svelte";

    export let diagram;
    export let graphName;
    export let graphDirname;
    export let afterConfirm;

    let name = graphName + " Copy";
    let newFolder;
    let errors = {};

    const handleSubmit = () => {
        if ($isBusy) {
            return;
        }
        isBusy.set(true);

        let data = diagram.model.toJson();

        let req = createGraph($authToken, name, newFolder, data);
        req.then((resp) => {
            if (!resp.error) {
                visibleModal.set(null);
                if (!!afterConfirm) {
                    afterConfirm(resp);
                }
            } else {
                errors = resp.fields;
            }
        }).finally(() => isBusy.set(false));
    };
</script>

<Modal
    title="Save As"
    icon="fal fa-copy fa-2x"
    confirmText="Save As"
    confirmIcon="fal fa-copy"
    onConfirm={handleSubmit}
>
    <form on:submit|preventDefault={handleSubmit}>
        <button class="hidden" type="submit">Submit</button>
        <ChooseFolder initialFolder={graphDirname} bind:newFolder {errors} />
        <div class="mt-2">
            <Input
                bind:value={name}
                error={errors.name}
                label="Name"
                name="saveas-name"
                helpText="The new name for this graph"
                required={true}
            />
        </div>
    </form>
</Modal>
