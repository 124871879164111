<script>
    import { createPopper } from "@popperjs/core";
    import Button from "../components/Button.svelte";
    import { onDestroy, onMount, afterUpdate } from "svelte";

    export let buttonIcon = "fas fa-ellipsis";
    export let buttonText = null;
    export let buttonTheme = "default";
    export let options = [];

    let isShowing = false;

    let popper, popperElement, popperTooltip;

    function initialisePopper() {
        if (popperElement && popperTooltip) {
            popper = createPopper(popperElement, popperTooltip, {
                placement: "bottom-end",
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 10],
                        },
                    },
                ],
            });
        }
    }

    function destroyPopper() {
        if (popper) {
            popper.destroy();
            popper = null;
        }
    }

    function trigger(element) {
        popperElement = element;
        initialisePopper();
        return {
            destroy() {
                popperElement = null;
                destroyPopper();
            },
        };
    }

    function tooltip(element) {
        popperTooltip = element;
        initialisePopper();

        return {
            destroy() {
                popperTooltip = null;
                destroyPopper();
            },
        };
    }

    const handleClose = () => {
        isShowing = false;
    };

    const handleOpen = () => {
        isShowing = true;
    };

    const closeOnEscape = (evt) => {
        let isEscape = evt.keyCode === 27;
        if ("key" in evt) {
            isEscape = evt.key === "Escape" || evt.key === "Esc";
        }
        if (isEscape) {
            handleClose();
        }
    };

    afterUpdate(async () => {
        if (!!popper) {
            await popper.update();
        }
    });

    onMount(() => {
        document.addEventListener("keydown", closeOnEscape, { capture: true });
    });

    onDestroy(() => {
        document.removeEventListener("keydown", closeOnEscape);
    });

    const handleAction = (action) => {
        handleClose();
        if (!!action) {
            action();
        }
    };
</script>

<span use:trigger>
    <Button
        text={buttonText}
        icon={buttonIcon}
        theme={buttonTheme}
        onClick={handleOpen}
    />
</span>
{#if isShowing}
    <div class="fixed z-10 inset-0 overflow-y-auto">
        <div
            on:click={handleClose}
            class="fixed inset-0 transition-opacity"
            aria-hidden="true"
        >
            <div class="absolute inset-0" />
        </div>
        <ul class="bg-white border rounded shadow" use:tooltip>
            {#each options as opt (opt.text)}
                <li class="border-b">
                    <Button
                        onClick={() => handleAction(opt.action)}
                        icon={opt.icon}
                        text={opt.text}
                        theme={opt.theme || "dropdown-white"}
                        isDisabled={opt.isDisabled}
                    />
                </li>
            {/each}
        </ul>
    </div>
{/if}
