<script>
    import Parameter from "../../../components/utils/parameter.js";
import { highlight } from "../../../stores.js";

    export let context;
    export let handleHighlight;
    export let handleViewRequest;
    export let onChange;
</script>

<div class="p-2 grid grid-cols-[auto,auto,auto,20px] gap-1 items-center">
    {#each context as param (param.name)}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <span
            on:mouseenter={() => handleHighlight(param.name, true)}
            on:mouseleave={() => handleHighlight(null)}
            class:text-brand={$highlight === param.name}
            class:text-yellow-500={$highlight !== param.name && !param._isDanger && param._isWarning}
            class:text-red-500={$highlight !== param.name && param._isDanger}
            on:click={() => handleViewRequest(param)}
            class="cursor-pointer text-left font-semibold">{param.name}</span
        >
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <span
            on:mouseenter={() => handleHighlight(param.name, true)}
            on:mouseleave={() => handleHighlight(null)}
            class:text-brand={$highlight === param.name}
            class:text-yellow-500={$highlight !== param.name && !param._isDanger && param._isWarning}
            class:text-red-500={$highlight !== param.name && param._isDanger}
            on:click={() => handleViewRequest(param)}
            class="cursor-pointer text-right">{param.getPretty()}</span
        >
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <span
            on:mouseenter={() => handleHighlight(param.name, true)}
            on:mouseleave={() => handleHighlight(null)}
            class:text-brand={$highlight === param.name}
            class:text-yellow-500={$highlight !== param.name && !param._isDanger && param._isWarning}
            class:text-red-500={$highlight !== param.name && param._isDanger}
            on:click={() => handleViewRequest(param)}
            class="cursor-pointer ">{param.unit}</span
        >
        <div>
            <label
                for="inspector-context-{param.name}-{param._branch}-isWatched"
                class="cursor-pointer"
            >
                {#if param.isWatched}
                    <span>
                        <i class="far fa-eye" />
                    </span>
                {:else}
                    <span>
                        <i class="far fa-eye-slash text-[#c3c3c3]" />
                    </span>
                {/if}
            </label>
            <input
                on:change={(e) =>
                    onChange(param, "isWatched", e.target.checked)}
                checked={param.isWatched}
                type="checkbox"
                class="hidden"
                id="inspector-context-{param.name}-{param._branch}-isWatched"
            />
        </div>
    {/each}
</div>
