<script>
    import { onMount } from "svelte";
    import { isBusy, visiblePage } from "../stores.js";
    import { verifyEmail } from "../api.js";
    import Button from "./components/Button.svelte";
    import Page from "./Page.svelte";

    export let token;
    let isError = false;

    onMount(() => {
        isBusy.set(true);
        verifyEmail(token)
            .then((resp) => {
                if (!resp.error) {
                    visiblePage.set("/my-graphs/");
                } else {
                    isError = true;
                }
            })
            .finally(() => isBusy.set(false));
    });
</script>

<Page>
    <div class="max-w-lg mx-auto" slot="body">
        <h1 class="text-xl font-bold text-center mt-8 mb-8">Verifying your Address...</h1>
        {#if isError}
            <p class="text-xl text-center">Something went wrong confirming your email address. Try the link again in the email we sent you. If that doesn't work, request another email verification.</p>
            <p class="text-center mt-8">
                <Button
                    text="Go to account"
                    type="button"
                    url="/account/"
                />
            </p>
        {/if}
    </div>
</Page>