import Parameter from "../utils/parameter.js";

export default class OPsat extends Parameter {
    constructor(initialValue, overrides) {
        let args = overrides || {};
        args.name = "OPsat";
        args.isMultiValued = true;
        args.value = initialValue;
        args.unit = Parameter.valueOrDefault(args.unit, "dBm");

        args.minValue = Parameter.valueOrDefault(args.minValue, -99.99);
        args.maxValue = Parameter.valueOrDefault(args.maxValue, 99.99);

        args.isEnabled = Parameter.valueOrDefault(args.isEnabled, false);
        args.isWatched = Parameter.valueOrDefault(args.isWatched, false);

        args.cascadeWatched = Parameter.valueOrDefault(args.cascadeWatched, ["IPsat"]);
        args.cascadeEnabled = Parameter.valueOrDefault(args.cascadeEnabled, ["IPsat", "IP1dB", "OP1dB"]);
        args.cascadeMVEnabled = Parameter.valueOrDefault(args.cascadeMVEnabled, ["IP1dB", "IPsat", "OP1dB"]);
        args.cascadeMV = Parameter.valueOrDefault(args.cascadeMV, []);

        super(args);

        this.cascadeMV = this.mergeCascadeMV([
            {
                name: "OP1dB",
                direction: "above",
                delta: 0.5,
                maxDelta: 10,
                minDelta: 0.25,
                enabled: true,
                cascadeDelta: {
                    OP1dB: "OPsat",
                    IP1dB: "IPsat",
                    IPsat: "IP1dB"
                }
            },
            { name: "IPsat", enabled: true, hidden: true, calculateDelta: 'calculateIPsatDelta' },
            { name: "IP1dB", enabled: true, hidden: true, calculateDelta: 'calculateIP1dBDelta' },
        ], this.cascadeMV);
    }

    calculateIP1dBDelta(comp, multiValue) {
        let op1db = comp.interpolateAtFreqTemp("OP1dB", multiValue.freq, multiValue.temp);
        let opsat = multiValue.value;
        let ipsatDelta = this.calculateIPsatDelta(comp, multiValue);
        return ipsatDelta.minus(opsat.minus(op1db));
    }

    calculateIPsatDelta(comp, multiValue) {
        let gain = comp.interpolateAtFreqTemp("Gain", multiValue.freq, multiValue.temp);
        return gain.times(-1).add(1);
    }

    validate(node) {
        let errors = {};

        let p1db = node.parameters.get("OP1dB");
        this.multiValues.forEach(mv => {
            const p1dbValue = node.interpolateAtFreqTemp(p1db, mv.freq, mv.temp);
            let difference = mv.value.minus(p1dbValue);
            if (difference.lessThan(0.25)) {
                this._validateAddError(errors, ["multiValues", mv.freq, mv.temp], `At this multi-value point, OPsat must be ${p1dbValue.plus(0.25).toFixed(2)}dBm or greater`);
            } else if (difference.greaterThan(10)) {
                this._validateAddError(errors, ["multiValues", mv.freq, mv.temp], `At this multi-value point, OPsat must be ${p1dbValue.plus(10).toFixed(2)}dBm or less`);
            }
        });

        return errors;
    }
}
