<script>
    import { onMount } from "svelte";
    import { authToken, visiblePage, isBusy } from "../stores.js";
    import { listExampleGraphs } from "../api.js";
    import Page from "./Page.svelte";
    import Section from "./components/Section.svelte";

    let choices = [];

    const handleOpen = (item) => {
        visiblePage.set(`/examples/${item.id}/`);
    };

    onMount(() => {
        isBusy.set(true);
        choices = [];
        listExampleGraphs($authToken)
            .then((resp) => {
                choices = resp;
            })
            .finally(() => isBusy.set(false));
    });
</script>

<Page bgColour="gray-100" padding="0">
    <div slot="body" class="">
        <div
            class="bg-white p-4 shadow-b shadow-lg flex flex-row justify-between"
        >
            <h1 class="text-2xl">Examples</h1>
        </div>
        <div class="max-w-3xl mx-auto mt-4">
            <Section>
                <div slot="body">
                    {#if $isBusy}
                        <div class="text-center my-2">Loading...</div>
                    {:else}
                        <div class="pb-3 divide-y divide-gray-200">
                            {#if choices.length}
                                {#each choices as c}
                                    <div
                                        class="flex flex-row justify-between items-center mt-3 pt-3 first:mt-0"
                                    >
                                        <div class="">
                                            <button
                                                class="text-black hover:text-brand"
                                                on:click={() => handleOpen(c)}
                                            >
                                                {#if !c.id}
                                                    <span>
                                                        <i
                                                            class="fa-solid fa-fw fa-folder"
                                                        />
                                                    </span>
                                                {:else}
                                                    <span>
                                                        <i
                                                            class="fa-solid fa-fw fa-diagram-project"
                                                        />
                                                    </span>
                                                {/if}
                                                <span class="ml-2">
                                                    {c.name}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                {/each}
                            {/if}
                        </div>
                    {/if}
                </div>
            </Section>
        </div>
    </div>
</Page>
