import Component from './utils/component.js';
import ProbedParameters from "./utils/probedParameters.js";
import Context from "./utils/context.js";
import iconUrl from '../images/termination.svg';
import probe from "../probe";

export default class Termination extends Component {
    category = "TRM";

    constructor(args) {
        args.defaults = [
            { name: "Device Type", value: "Default", unit: "", choices: ["Default", "TX", "RX"], isEnabled: true, isEnabledEditable: false, isWatched: false },
        ]
        args.name = args.name || "Termination";
        args.summary = args.summary || "Final cascaded data for the chain with calculation totals and report downloads.";
        args.icon = iconUrl;
        super(args);
        this._calculations = new ProbedParameters();
    }

    simulate(inputs, reasons = []) {
        this._inputs = inputs;
        let input = inputs[0];
        let output = new Context(input);

        output.get("Pcomp").isHidden = true;

        let inputNode = this.getInputNode();
        if (!!inputNode) {
            probe([this.getInputNode()], 0, this._calculations);
        }

        this._outputs = [output];
        return this._outputs;
    }

    outPortIds() {
        return [];
    }

    getInputNode() {
        let thisNode = this.findNode();
        if (!thisNode) return;
        let nodesInto = thisNode.findNodesInto();
        while (nodesInto.next()) {
            return nodesInto.value;
        }
    }

}
