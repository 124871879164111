<ul
    class="flex flex-row justify-between items-center text-white bg-black px-3 py-1"
>
    <li class="py-2">
        <span class="">
            &copy; 2021-2023
            <a
                href="https://rfgraph.com/"
                target="_blank"
                rel="noreferrer"
                class="text-white hover:text-brand hover:no-underline"
            >
                RFGraph
            </a>
        </span>
        &middot;
        <a
            href="https://rfgraph.com/support/"
            target="_blank"
            rel="noreferrer"
            class="text-white hover:text-brand hover:no-underline"
        >
            Support
        </a>
        &middot;
        <a
            href="https://rfgraph.com/contact/"
            target="_blank"
            rel="noreferrer"
            class="text-white hover:text-brand hover:no-underline"
        >
            Contact Us
        </a>
    </li>
</ul>
