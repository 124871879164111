<script>
    import { isBusy as isBusyGlobal, visibleModal, visiblePage } from "../../stores.js";

    export let text = "Button";
    export let type = "submit";
    export let theme = "default";
    export let toUpperCase = true;
    export let boldText = true;
    export let size = "default";
    export let icon = null;
    export let iconRight = false;
    export let onClick = null;
    export let url = null;
    export let modal = null;
    export let onOver = null;
    export let onOut = null;
    export let isDisabled = false;
    export let customClass = "";
    export let barPosition = null;
    export let isBusy = false;
    export let upsell = false;
    export let width = "";

    let showSpinner = icon !== null;

    const themes = {
        "danger": "text-black bg-red-500 rounded hover:bg-red-600 disabled:opacity-50 disabled:hover:bg-red-500 disabled:cursor-default",
        "pro": "text-black bg-[#FFD51E] rounded hover:bg-[#D6B513] border border-[#D6B513] disabled:opacity-50 disabled:hover:bg-[#FFD51E] disabled:cursor-default",
        "black": "text-white bg-black rounded hover:bg-[#333333] disabled:bg-gray-600 disabled:hover:bg-gray-600 disabled:cursor-default",
        "white": "text-black bg-white border border-[#c3c3c3] rounded hover:border-black disabled:opacity-50 disabled:hover:border-[#c3c3c3] disabled:cursor-default",
        "anchor": "text-[#009A93] hover:underline disabled:opacity-50 disabled:cursor-default",
        "anchor-white": "text-white hover:text-brand disabled:opacity-50 disabled:cursor-default",
        "anchor-danger": "text-red-500 hover:text-black disabled:opacity-50 disabled:cursor-default text-sm",
        "anchor-brand": "text-brand disabled:opacity-50 disabled:cursor-default",
        "default": "text-black bg-brand rounded hover:bg-[#08b6ad] disabled:opacity-50 disabled:hover:bg-[#c3c3c3] disabled:bg-[#c3c3c3] disabled:cursor-default",
        "dropdown-white": "text-black bg-white hover:bg-gray-300 disabled:hover:bg-white disabled:opacity-50 disabled:cursor-default w-full text-left",
    }
    let themeClass = themes[theme] || theme;

    if (toUpperCase) {
        themeClass += " uppercase";
    }

    if (boldText) {
        themeClass += " font-semibold";
    }

    if (barPosition === "left") {
        themeClass += " rounded-r-none mr-[-1px] hover:z-30";
    } else if (barPosition === "right") {
        themeClass += " rounded-l-none";
    } else if (barPosition === "middle") {
        themeClass += " rounded-none mr-[-1px] hover:z-30";
    }

    let sizeClass;
    if (theme.indexOf("anchor") !== -1) {
        sizeClass = "";
    } else if (size === "default") {
        if (text) {
            sizeClass = "px-3 py-1";
        } else {
            sizeClass = "px-2 py-1";
        }
    } else if (size === "small") {
        sizeClass = "px-2 py-1 text-sm";
    } else {
        sizeClass = size;
    }

    const _onClick = (evt) => {
        if (upsell) {
            visibleModal.set({ name: "upsell" });
            return;
        }
        if (!!url) {
            visiblePage.set(url);
        } else if (!!modal) {
            visibleModal.set({ name: modal });
        } else if (!!onClick) {
            onClick(evt);
        }
    };

    $: isBusyFinal = isBusy || $isBusyGlobal
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<button
    {type}
    class="{themeClass} {sizeClass} {customClass} w-{width} flex-row items-center relative"
    on:click={_onClick}
    on:mouseover={onOver}
    on:mouseout={onOut}
    disabled={isDisabled || isBusyFinal}
>
    {#if !iconRight && !!icon}
        <span class:mr-1={!!text}>
            {#if isBusyFinal}
                {#if showSpinner}
                    <span class="">
                        <i class="fad fa-spinner-third fa-spin" />
                    </span>
                {/if}
            {:else if icon}
                <span class="">
                    <i class={icon} />
                </span>
            {/if}
        </span>
    {/if}
    {#if text}
        <span class="whitespace-nowrap">
            {text}
        </span>
    {/if}
    {#if iconRight && !!icon}
        <span class:ml-1={!!text}>
            {#if isBusyFinal}
                {#if showSpinner}
                    <span class="">
                        <i class="fad fa-spinner-third fa-spin" />
                    </span>
                {/if}
            {:else if icon}
                <span class="">
                    <i class={icon} />
                </span>
            {/if}
        </span>
    {/if}
    {#if upsell}
        <span class="absolute top-[-11px] right-[5px] z-40 text-[0.65rem] bg-[#FFD51E] rounded-xl border border-[#D6B513] px-2" style="line-height: 1rem">
            PRO
        </span>
    {/if}
</button>
