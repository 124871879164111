<script>
    import Decimal from "decimal.js";


    export let name;
    export let label;
    export let choices;
    export let error;
    export let value;
    export let hideLabel = false;
    export let helpText = null;
    export let required = false;
    export let disabled = false;
    export let labelTheme = "text-sm font-medium text-gray-700";
    export let onChange = null;

    const compare = (val1, val2) => {
        if (val1 === val2) return true;
        try {
            val1 = new Decimal(val1);
            val2 = new Decimal(val2);
            return val1.equals(val2);
        } catch {

        }
        return val1 === val2;
    };

    $: extra = choices.some(c => compare(value, c[0])) ? null : value;
</script>

<div>

    <label
        for={name}
        class="block mb-1 {labelTheme}"
        class:text-red-500={!!error}
        class:sr-only={hideLabel}
    >
        {label}
        {#if required}<span class="text-red-500">*</span>{/if}
    </label>
    <div class="">
        <!-- svelte-ignore a11y-no-onchange -->
        <select bind:value={value} on:change={onChange} {disabled} {...$$restProps}>
            {#each choices as choice (choice[0])}
                <option value={choice[0]} disabled={choice[2]}>{choice[1]}</option>
            {/each}
            {#if extra}
                <option value={extra}>{extra}</option>
            {/if}
        </select>
    </div>
    {#if helpText}
        <div class="text-gray-500">
            <small>{helpText}</small>
        </div>
    {/if}
    {#if error}
        <div class="text-red-500">
            <small>{error}</small>
        </div>
    {/if}
</div>
