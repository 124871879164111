<script>
    import { onMount } from "svelte";
    import { authToken, isBusy } from "../stores.js";
    import { listFolders } from "../api.js";
    import Input from "../pages/components/form/Input.svelte";
    import Select from "../pages/components/form/Select.svelte";

    export let initialFolder;
    export let newFolder;
    export let errors;
    export let allowNew = true;
    export let hideLabel = false;

    let chosen = initialFolder;
    let newName = initialFolder;
    let folders = [];

    $: showNew = chosen === "new";
    $: {
        let dirname = chosen;
        if (chosen === "new") {
            dirname = newName;
        }
        if (!dirname.startsWith("/")) {
            dirname = `/${dirname}`;
        }
        if (!dirname.endsWith("/")) {
            dirname = `${dirname}/`;
        }
        newFolder = dirname;
    }

    onMount(() => {
        isBusy.set(true);
        let req = listFolders($authToken);
        req.then((resp) => {
            folders = resp.map((f) => [f.dirname + f.name, f.dirname + f.name]);
            if (allowNew) {
                folders.push(["new", "+ Create New Folder"]);
            }
        }).finally(() => isBusy.set(false));
    });
</script>

<div class="mt-2">
    <Select
        choices={folders}
        bind:value={chosen}
        error={null}
        label="Choose Folder"
        hideLabel={hideLabel}
        name="choose-folder-select"
    />
</div>
{#if showNew}
    <div class="mt-2">
        <Input
            bind:value={newName}
            error={errors.newName}
            label="New Folder"
            name="choose-folder-dirname"
            helpText="The name for your new folder"
            required={true}
        />
    </div>
{/if}
