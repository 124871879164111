import Parameters from "./parameters.js";

export default class Context extends Parameters {

    constructor(data) {
        if (data === undefined) {
            data = [
                { name: "Pout", value: 0, unit: "dBm", isWatched: false, isEnabled: true },
                { name: "Pcomp", value: 0, unit: "dB", isWatched: false, isEnabled: true },
                { name: "Total_Pcomp", value: 0, unit: "dB", isWatched: false, isEnabled: true, isHidden: true },
                { name: "C_Gain", value: 0, unit: "dB", isWatched: false, isEnabled: true },
                { name: "Linear_Gain", value: 0, unit: "dB", isWatched: false, isEnabled: true, isHidden: true },
                { name: "C_OIP2", value: 0, unit: "dBm", isWatched: false, isEnabled: true, cascadeWatched: ["C_IIP2"] },
                { name: "C_OIP3", value: 0, unit: "dBm", isWatched: false, isEnabled: true, cascadeWatched: ["C_IIP3"] },
                { name: "C_OP1dB", value: 0, unit: "dBm", isWatched: false, isEnabled: true, cascadeWatched: ["C_IP1dB"] },
                { name: "C_IIP2", value: 0, unit: "dBm", isWatched: false, isEnabled: true, cascadeWatched: ["C_OIP2"] },
                { name: "C_IIP3", value: 0, unit: "dBm", isWatched: false, isEnabled: true, cascadeWatched: ["C_OIP3"] },
                { name: "C_IP1dB", value: 0, unit: "dBm", isWatched: false, isEnabled: true, cascadeWatched: ["C_OP1dB"] },
                { name: "C_NF", value: 0, unit: "dB", isWatched: false, isEnabled: true },
                { name: "Noise_Pout", value: 0, unit: "dBm", isWatched: false, isEnabled: true },
                { name: "Frequency", value: 1000, unit: "MHz", isWatched: false, isEnabled: true, isHidden: true },
                { name: "Temperature", value: 20, unit: "°C", isWatched: false, isEnabled: true, isHidden: true },
                { name: "Project Type", value: "TX", unit: "", isWatched: false, isEnabled: true, isHidden: true },
                { name: "Noise Floor", value: 0, unit: "dBm", isWatched: false, isEnabled: true, isHidden: true },
                // Used when a splitter branches out. Needed to calculate the Gain of the "group" formed by splitting and combining
                { name: "Splitters", value: [], unit: "", isWatched: false, isEnabled: true, isHidden: true },
            ]
        }
        super(data);
    }

    checkWarnings(comp) {
        let pOutValue = this.get("Pout").getValue();
        let p1Backoff = comp.parameters.get("OP1BackOff");
        let p1db = comp.parameters.get("OP1dB");

        if (comp.getDeviceType() === "RX") {
            pOutValue = pOutValue.add(this.get("Pcomp").getValue());
        }

        let isWarning = false;
        if (!!p1Backoff && !!p1db) {
            // e.g. a Source doesn't have a P1BackOff param
            if (p1Backoff.isEnabled && p1db.isEnabled && pOutValue.gte(p1db.getValue("dBm").minus(p1Backoff.getValue("dB")))) {
                isWarning = true;
            }
        }
        let pOut = this.get("Pout");
        pOut._isWarning = isWarning;
        return isWarning;
    }

    checkDangers(pCompValue) {
        if (pCompValue.gte(1)) {
            this.get("Pcomp")._isDanger = true;
            this.get("Pout")._isDanger = true;
            return true;
        }
        return false;
    }

}
