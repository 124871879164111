<script>
    import { createUpdateSubscriptionPage } from "../api.js";
    import Button from "../pages/components/Button.svelte";
    import { isBusy, authToken } from "../stores.js";
    import Modal from "./Modal.svelte";

    const openBillingPage = (tier) => {
        isBusy.set(true);
        const req = createUpdateSubscriptionPage($authToken, tier);
        req.then(resp => {
            if (resp.error) {
            errors = resp.fields;
            } else {
                window.location = resp.url;
            }
        });
    }

    const handleMonthly = () => {
        openBillingPage("month");
    }

    const handleAnnually = () => {
        openBillingPage("year");
    }
</script>

<Modal
    title="Upgrade to Pro"
    icon="fad fa-circle-star fa-2x"
    iconStyle="--fa-secondary-color: #FFD51E; --fa-primary-opacity: 1; --fa-secondary-opacity: 1"
    confirmText={null}
    cancelText="Cancel"
>
    <p class="text-center">How would you like to pay?</p>
    <div class="flex flex-row gap-2 justify-center mt-4">
        <Button
            text="$9.99 Monthly"
            onClick={handleMonthly}
        />
        <Button
            text="$99 Annually"
            onClick={handleAnnually}
        />
    </div>
</Modal>
