import Decimal from 'decimal.js';
import Component from './utils/component.js';
import Context from "./utils/context.js";
import { convertToUnit } from "./utils/parameter.js";
import iconUrl from '../images/combiner.svg';
import Parameters from './utils/parameters.js';

const LOSS = Decimal.log10(2).times(10);

export default class Combiner extends Component {
    category = "CMB";

    constructor(args) {
        args.defaults = [
            { name: "Size", value: 2, unit: "", precision: 0, choices: [2, 3, 4, 8], isEnabled: true, isEnabledEditable: false, isWatched: false },
            { name: "Gain", value: -0.25, cascadeValue: [{ name: "NF", enabled: true, hidden: false }] },
            { name: "OIP2", value: 98.75 },
            { name: "OIP3", value: 98.75 },
            { name: "OP1dB", value: 97.5 },
            { name: "OPsat", value: 97.75 },
            { name: "IIP2", value: 99 },
            { name: "IIP3", value: 99 },
            { name: "IP1dB", value: 98.75 },
            { name: "IPsat", value: 99 },
            { name: "NF", value: 0.25 },
            { name: "Idc", value: 0, unit: "mA", isEnabled: false, isWatched: false, precision: 0 },
            { name: "DevSply", value: 0, unit: "Vdc", isEnabled: false, isWatched: false },
            { name: "BusSply", value: 0, unit: "Vdc", isEnabled: false, isWatched: false },
            { name: "OP1BackOff", value: 2 },
            { name: "IP1BackOff", value: 0 },
            { name: "Te", value: 290, unit: "°K", isEnabled: false, isWatched: false, isHidden: true },
            { name: "Device Type", value: "Default", unit: "", choices: ["Default", "TX", "RX"], isEnabled: true, isEnabledEditable: false, isWatched: false },
            { name: "Loss@1", value: LOSS, unit: "dB", isEnabled: true, isWatched: false, isEnabledEditable: false },
            { name: "Loss@2", value: LOSS, unit: "dB", isEnabled: true, isWatched: false, isEnabledEditable: false },
        ]
        args.name = args.name || "Combiner";
        args.summary = args.summary || "RF combiner that defaults with theoretical losses for 2, 3, 4 or 8 ways.";
        args.icon = iconUrl;
        super(args);
    }

    defaultContext(incomingContext) {
        // TODO: What should these values be?
        let output = new Context();

        output.set("Frequency", incomingContext.get("Frequency"));
        output.set("Project Type", incomingContext.get("Project Type"));
        output.set("Noise Floor", incomingContext.get("Noise Floor"));
        output.set("Splitters", incomingContext.get("Splitters"));

        output.set("Pout", incomingContext.get("Noise Floor"));
        output.set("Pcomp", 0);
        output.set("C_Gain", -99);
        output.set("Linear_Gain", -99);
        output.set("C_OIP2", 99);
        output.set("C_OIP3", 99);
        output.set("C_OP1dB", 99);
        output.set("C_NF", 0);
        output.set("Noise_Pout", incomingContext.get("Noise Floor"));
        output.set("C_IIP2", 99);
        output.set("C_IIP3", 99);
        output.set("C_IP1dB", 99);

        output._branch = 1;

        return output;
    }

    runSimulation(parameters, inputs) {
        let splitters = {};
        let pendingSplitters = [];
        for (let idx = 0; idx < inputs.length; idx++) {
            let input = inputs[idx];
            if (!input) {
                continue;
            }
            let inputSplitters = input.get("Splitters").getValue();
            if (inputSplitters.length > 0) {
                let mostRecentSplitter = inputSplitters[inputSplitters.length - 1];
                if (!splitters[mostRecentSplitter.key]) {
                    splitters[mostRecentSplitter.key] = { splitter: mostRecentSplitter, inputs: [], inputCount: 0, firstInput: null };
                }
                splitters[mostRecentSplitter.key].inputs.push(input);
                splitters[mostRecentSplitter.key].inputCount++;
                if (splitters[mostRecentSplitter.key].firstInput === null) splitters[mostRecentSplitter.key].firstInput = input;

                for (let splitterIndex = 0; splitterIndex < inputSplitters.length - 1; splitterIndex++) {
                    pendingSplitters.push(inputSplitters[splitterIndex].key);
                }
            } else {
                // TODO: How to cope with input that hasn't come from a splitter?
            }
        }
        let outputs = [];
        let splittersList = Object.entries(splitters);
        let combinerSize = new Decimal(parameters.get("Size").getValue());
        for (let [key, details] of splittersList) {
            let splitterSize = new Decimal(details.splitter.size);
            let inputSize = Math.min(splitterSize, combinerSize);
            if (inputSize > details.inputCount) {
                key = parseInt(key);
                let useDefaults = pendingSplitters.indexOf(key) === -1;
                if (useDefaults) {
                    // Fill in missing splitter outputs using the default context.
                    // So if a combiner sees 2 of the 4 inputs needed from a splitter, it fills the remaining
                    // Only do this if useDefaults is true
                    // Fill in defaults up to the size of the combiner, a 2-way combiner can't combine 3 inputs
                    for (let idx = 0; idx < inputSize; idx++) {
                        if (!details.inputs[idx]) details.inputs[idx] = this.defaultContext(details.firstInput);
                    }
                }
            }
            if (splitterSize.equals(details.inputs.length) || combinerSize.equals(details.inputs.length)) {
                // I have enough information to combine this splitter

                // Include loss on the final combine, all prior combines don't account for loss.
                let includeLoss = splittersList.length === 1;
                let combined = this.combine(parameters, details.inputs, details.splitter, includeLoss, details.firstInput);
                outputs.push(combined);
            } else {
                // Not enough information, so carry these inputs into the next round
                outputs.push(...details.inputs);
            }
        }
        if (outputs.length > 1) {
            // If we haven't managed to combine any splitters then we should fill in missing values using
            // the default context, that should allow us to combine everything
            outputs = this.runSimulation(parameters, outputs);
        }
        return outputs;
    }

    combineParam(parameters, inputs, paramName, includeLoss) {
        let finalValue = new Decimal(0);
        for (let idx = 0; idx < inputs.length; idx++) {
            let input = inputs[idx];
            let param = input.get(paramName);
            let value = param.getValue();
            if (!!input._branch) {
                let branchLoss = parameters.get(`Loss@${input._branch}`).getValue();
                value = value.minus(branchLoss);
            }
            value = convertToUnit(value, "*", "linear");
            // TODO: This 50 value is the characteristic impedance and will probably need to be a comp parameter some point
            value = value.times(50).sqrt();
            finalValue = finalValue.add(value);
        }
        finalValue = finalValue.pow(2).dividedBy(50);
        finalValue = convertToUnit(finalValue, "linear", "*");

        if (includeLoss) {
            let deviceLoss = parameters.get("Gain").getValue().abs();
            finalValue = finalValue.minus(deviceLoss);
        }

        return finalValue;
    }

    combine(parameters, inputs, splitter, includeLoss, firstInput) {
        let output = new Context(firstInput);
        output.get("Splitters").defer(() => firstInput.get("Splitters").getValue().slice(0, -1));

        let combinedValues = ["Pout", "C_OIP2", "C_OIP3", "C_OP1dB", "C_IIP2", "C_IIP3", "C_IP1dB"];
        combinedValues.forEach(paramName => {
            output.get(paramName).defer(() => this.combineParam(parameters, inputs, paramName, includeLoss));
        });

        output.get("Noise_Pout").defer(() => {
            let finalValue = new Decimal(0);

            for (let idx = 0; idx < inputs.length; idx++) {
                let input = inputs[idx];
                let param = input.get("Noise_Pout");
                let value = param.getValue();

                let fromSplitter = splitter.output.get("C_NF").getValue();
                let intoCombiner = input.get("C_NF").getValue();
                let excess = intoCombiner.minus(fromSplitter);
                value = value.minus(excess);

                if (!!input._branch) {
                    let branchLoss = parameters.get(`Loss@${input._branch}`).getValue();
                    value = value.minus(branchLoss);
                }
                value = convertToUnit(value, "*", "linear");
                value = value.times(50).sqrt();
                finalValue = finalValue.add(value);
            }
            finalValue = finalValue.pow(2).dividedBy(50);
            finalValue = convertToUnit(finalValue, "linear", "*");

            if (includeLoss) {
                let deviceLoss = parameters.get("Gain").getValue().abs();
                finalValue = finalValue.minus(deviceLoss);
            }

            let noiseFloor = firstInput.get("Noise Floor").getValue();
            if (finalValue.lessThan(noiseFloor)) {
                finalValue = noiseFloor;
            }
            return finalValue;
        });

        output.get("C_Gain").defer(() => {
            let cGainIntoSplitter = splitter.input.get("C_Gain").getValue();
            let pOutIntoSplitter = splitter.input.get("Pout").getValue();
            return cGainIntoSplitter.add(output.get("Pout").getValue().minus(pOutIntoSplitter));
        });

        output.get("C_NF").defer(() => {
            let pOutIntoSplitter = splitter.input.get("Pout").getValue();
            let noisePoutIntoSplitter = splitter.input.get("Noise_Pout").getValue();
            let cNFIntoSplitter = splitter.input.get("C_NF").getValue();
            let noisePout = output.get("Noise_Pout").getValue();

            let snrOut = output.get("Pout").getValue().minus(noisePout);
            let snrIn = pOutIntoSplitter.minus(noisePoutIntoSplitter);
            let nfEff = snrIn.minus(snrOut);

            return cNFIntoSplitter.plus(nfEff);
        });

        // TODO: We need to calculate Pcomp
        output.get(`Pcomp`).defer(() => 0);

        output.get("Linear_Gain").defer(() => this.calculateLinearGain(parameters, inputs[0], output));
        output.get("Total_Pcomp").defer(() => this.calculateTotalPcomp(parameters, inputs[0], output));

        output.all.forEach(p => p._branch = null);

        return output;
    }

    _simulate(parameters, inputs) {
        for (let idx = 0; idx < inputs.length; idx++) {
            if (!!inputs[idx]) inputs[idx]._branch = idx + 1;
        }
        return this.runSimulation(parameters, inputs);
    }

    * bubbledChanges(param, field, value) {
        yield* super.bubbledChanges(param, field, value);
        if (param.name === "Size" && field === "value") {
            yield* this.bubbledLossChanges(value);
        }
    }

    * bubbledLossChanges(newSize) {
        newSize = parseInt(newSize);
        let splittingLoss = Decimal.log10(newSize).times(10);
        let name, param, idx
        for (idx = 1; idx <= newSize; idx++) {
            name = `Loss@${idx}`;
            param = this.parameters.get(name);
            if (!param) {
                param = Parameters.parameterFactory({
                    name: name,
                    value: splittingLoss,
                    unit: "dB",
                    isEnabled: true,
                    isEnabledEditable: false
                });
                yield [this.parameters.all, "ADDED", param];
            } else {
                yield [param, "value", splittingLoss];
            }
        }
        for (idx = newSize + 1; idx <= 8; idx++) {
            name = `Loss@${idx}`;
            let rank = this.parameters.indexOf(name)
            if (rank !== -1) {
                yield [this.parameters.all, "REMOVED", rank];
            }
        }
    }

    inPortIds() {
        let ids = [];
        let size = this.parameters.get("Size").getValue();
        let idx = 1;
        while (idx <= size) {
            ids.push(`in-${idx}`);
            idx++;
        }
        return ids;
    }

}
