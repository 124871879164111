<script>
    import { buildDisconnectedQueue } from "../../../simulator.js";
    import { probePlotSettings, graphName } from "../../../stores";
    import Select from "../form/Select.svelte";
    import { simplePlot } from "../../../plot.js";
    import Button from "../Button.svelte";

    const PRECISION = parseInt(import.meta.env.VITE_DECIMAL_PRECISION || 2);

    export let source;
    export let node;
    export let components;
    export let calculations;

    const xAxisChoices = [
        ["Frequency", "Frequency (MHz)"],
        ["Temperature", "Temperature (°C)"],
        ["Power", "Source Power (dBm)"],
    ];

    let canvas;
    let trace;
    let done = true;
    let yAxisChoices = [];

    const worker = new Worker(
        new URL("../../../worker/plot.js", import.meta.url),
        {
            type: 'module'
        }
    );
    let plotTabChannel;

    worker.onerror = () => {
        console.error("Something went wrong simulating in the background");
    }

    worker.onmessage = (msg) => {
        if (msg.data.isDone) onDone(msg.data.data);
    }

    const buildMessage = (ranges, xLow, xHigh) => {
        const queue = buildDisconnectedQueue(node.data);
        queue.nodes = {};
        for (const key in queue.parameters) {
            queue.parameters[key] = queue.parameters[key].dump()
        }
        return {
            xHigh: xHigh,
            xLow: xLow,
            xDataPoints: 100,
            xParam: $probePlotSettings.plotXAxis,
            yParam: [$probePlotSettings.plotYAxis],
            stopKey: node.data.key,
            queue: queue,
            ranges: ranges,
            spread: $probePlotSettings.plotXAxis === "Frequency" ? "octave" : "even",
            sourceTemp: source.data.parameters.get("Temperature").getValue("°C").toFixed(0),
            sourceFreq: source.data.parameters.get("Frequency").getValue("MHz").toFixed(PRECISION),
            sourcePower: source.data.parameters.get("Power").getValue("dBm").toFixed(PRECISION),
        }
    }

    const updateData = () => {
        if (done === true && !!$probePlotSettings.plotYAxis) {
            done = false;
            const ranges = calculateMiniPlotXRanges();
            const xLow = ranges[$probePlotSettings.plotXAxis].maxLow;
            const xHigh = ranges[$probePlotSettings.plotXAxis].maxHigh;
            worker.postMessage(buildMessage(ranges, xLow, xHigh));
        }
    }

    const findLabel = (choices, value) => {
        for (let idx = 0; idx < choices.length; idx++) {
            const elem = choices[idx];
            if (elem[0] === value) return elem[1];
        }
    }

    const onDone = (data) => {
        const xLabel = findLabel(xAxisChoices, $probePlotSettings.plotXAxis);
        const yLabel = findLabel(yAxisChoices, $probePlotSettings.plotYAxis);
        trace = {
            type: "scatter",
            name: yLabel,
            mode: "lines",
            x: data.map(d => d.x),
            y: data.map(d => d.y[0]),
            marker: {
                color: "#0ad6cc",
            },
            hovertemplate: `${xLabel}: %{x:.0f}<br>${yLabel}: %{y:.${PRECISION}f}<extra></extra>`,
        }
        done = true;
    }

    const getSensibleStartingRange = startingValue => {
        let startingLow, startingHigh;
        if (startingValue.greaterThan(0)) {
            startingLow = startingValue.times(0.5).round().toNumber();
            startingHigh = startingValue.times(1.5).round().toNumber();
        } else {
            startingHigh = startingValue.times(0.5).round().toNumber();
            startingLow = startingValue.times(1.5).round().toNumber();
        }
        return {low: startingLow, high: startingHigh};
    }

    const getAttrRange = (attr, startingValue) => {
        const {low:startingLow, high:startingHigh} = getSensibleStartingRange(startingValue);
        let actualLow = startingLow, actualHigh = startingHigh;
        for (let c of components) {
            for (let p of c.data.parameters.all) {
                if (p.isMultiValuedEnabled) {
                    for (let mv of p.multiValues) {
                        let v = mv[attr].round().toNumber();
                        if (actualLow === null) actualLow = v
                        else actualLow = Math.min(actualLow, v);
                        if (actualHigh === null) actualHigh = v;
                        else actualHigh = Math.max(actualHigh, v);
                    }
                }
                if (p.isSParamsEnabled) {
                    for (let file of p.sParams) {
                        if (attr === "freq") {
                            for (let mv of file.data) {
                                let v = mv.freq.round().toNumber();
                                if (actualLow === null) actualLow = v
                                else actualLow = Math.min(actualLow, v);
                                if (actualHigh === null) actualHigh = v;
                                else actualHigh = Math.max(actualHigh, v);
                            }
                        } else if (attr === "temp") {
                            let v = file.temp;
                            if (actualLow === null) actualLow = v
                            else actualLow = Math.min(actualLow, v);
                            if (actualHigh === null) actualHigh = v;
                            else actualHigh = Math.max(actualHigh, v);
                        }
                    }
                }
            }
        }
        const low = Math.max(startingLow, actualLow);
        const high = Math.min(startingHigh, actualHigh);
        return {low: low, high: high, maxLow: actualLow, maxHigh: actualHigh};
    }

    const getPowerRange = (sourcePower, bufferAbove, bufferBelow) => {
        let combinerCP1dB;
        for (let i = 0; i < components.length; i++) {
            const comp = components[i];

            if (comp.category === "CMB") {
                combinerCP1dB = comp.data._outputs[0].get("C_OP1dB").getValue("dBm");
            }

            const psat = comp.data.parameters.get("OPsat");
            if (!psat) continue;
            const psatValue = psat.getValue("dBm");
            if (psat.isEnabled && psatValue.lessThan(92)) {
                const linearGain = calculations.get("Linear_Gain").getValue("dB");
                const anchorPoint = psatValue.minus(linearGain);
                const low = anchorPoint.floor().minus(bufferBelow).toNumber();

                if (!!combinerCP1dB && combinerCP1dB.greaterThan(psatValue)) {
                    return {
                        low: low,
                        high: combinerCP1dB.ceil().minus(linearGain).add(bufferAbove).add(2).toNumber()
                    };
                }

                return {
                    low: low,
                    high: anchorPoint.ceil().add(bufferAbove).toNumber()
                };
            }
        }
        return {
            low: sourcePower.minus(bufferBelow).toNumber(),
            high: sourcePower.add(bufferAbove).toNumber()
        };
    }

    const calculateMiniPlotXRanges = () => {
        let freq = source.data.parameters.get("Frequency").getValue();
        let power = source.data.parameters.get("Power").getValue();
        let powerRange = getPowerRange(power, 10, 30);
        powerRange.maxLow = powerRange.low;
        powerRange.maxHigh = powerRange.high;
        return {
            "Frequency": getAttrRange("freq", freq),
            "Power": powerRange,
            "Temperature": {maxLow: -250, maxHigh: 400, low: -40, high: 85},
        }
    }

    const calculateNewTabXRanges = () => {
        let freq = source.data.parameters.get("Frequency").getValue();
        let power = source.data.parameters.get("Power").getValue();
        return {
            "Frequency": getAttrRange("freq", freq),
            "Power": {maxLow: -200, maxHigh: 100, ...getPowerRange(power, 4, 11)},
            "Temperature": {maxLow: -250, maxHigh: 400, low: -40, high: 85},
        }
    }

    const handleOpenInTab = () => {
        if (!!plotTabChannel) {
            plotTabChannel.close();
        }
        plotTabChannel = new BroadcastChannel(`plot-${window.rfgGraphId}`);
        plotTabChannel.onmessage = evt => {
            if (evt.data.type === "initRequest" && evt.data.key === node.data.key) {
                const ranges = calculateNewTabXRanges();
                const xLow = ranges[$probePlotSettings.plotXAxis].low;
                const xHigh = ranges[$probePlotSettings.plotXAxis].high;
                plotTabChannel.postMessage({
                    type:"initResponse",
                    details: buildMessage(ranges, xLow, xHigh),
                    xAxisChoices: xAxisChoices,
                    yAxisChoices: yAxisChoices,
                    graphName: $graphName,
                    nodeName: node.data.name,
                });
            }
        };
        const plotTab = window.open(`/graphs/${window.rfgGraphId}/plot/?stopKey=${node.data.key}`, "_blank");
        window._plotTabs = window._plotTabs || [];
        window._plotTabs.push(plotTab);
        window.onunload = () => {
            window._plotTabs.forEach(tab => {
                if (!!tab && !tab.closed) tab.close();
            });
            window._plotTabs = [];
        };
    }

    $: {
        yAxisChoices = Array.from(node.data.getFirstOutput().getVisible(node.data.getDeviceType())).map(c => [c.name, `${c.name} (${c.unit})`]);
        yAxisChoices = [...yAxisChoices, ["Total_Pcomp", "Total_Pcomp (dB)"], ["Linear_Gain", "Linear_Gain (dB)"]];
        updateData();
    }

    $: {
        if (!!canvas && !!trace) simplePlot(canvas, trace, 317, 150);
    }

</script>

<div class="p-2">
    <div class="flex flex-col gap-2 mb-4">
        <Select name="y-axis"
                label="y Axis"
                error={null}
                choices={[
                    ["", "Choose parameter to plot"],
                    ...yAxisChoices
                ]}
                disabled={!done}
                bind:value={$probePlotSettings.plotYAxis}
                onChange={() => updateData()} />
        <Select name="x-axis"
            label="x Axis"
            error={null}
            choices={xAxisChoices}
            disabled={!done}
            bind:value={$probePlotSettings.plotXAxis}
            onChange={() => updateData()} />
    </div>
    {#if $probePlotSettings.plotYAxis !== ""}
        {#if !done}
            <div class="chart text-center mt-4">
                <i class="fa-duotone fa-spinner-third fa-spin fa-2x"></i>
            </div>
        {/if}
        <div class="chart" class:hidden={!done}>
            <div bind:this={canvas} class="w-full h-full"></div>
        </div>
        <div class="text-right">
            <Button text="Open in Tab" icon="far fa-external-link" onClick={handleOpenInTab}></Button>
        </div>
    {/if}
</div>
