import Decimal from 'decimal.js';

import Parameters from "./parameters.js";
import Context from "./context.js";
import Parameter, { convertToUnit } from "./parameter.js";
import iconUrl from '../../images/badge.svg';
import { convertToMultiValue } from '../../sparams.js';
import { interpolateValue } from '../../multivalues.js';
import { buildDisconnectedQueue, simulateInBackgroundDisconnected, addComponentToQueue, Queue } from "../../simulator.js";

const PRECISION = parseInt(import.meta.env.VITE_DECIMAL_PRECISION || 2);

export default class Component {
    _iconChoices = [];
    _iconNames = [];
    icon = iconUrl;
    category = "BASE";

    constructor(args) {
        this.key = args.key;
        this.location = args.location;
        this.group = args.group;
        this.name = args.name || "Unknown";
        this.icon = args.icon || iconUrl;
        this.parameters = new Parameters(args.defaults);
        this.parameters.merge(args.parameters);
        this.summary = args.summary || "";
        this.notes = args.notes || "";
        this.mirrorKey = args.mirrorKey;

        this._watchedOutputs = [];
        this._outPortIds = this.outPortIds();
        this._inPortIds = this.inPortIds();
        this._outputs = [];
        this._inputs = [];
        this._watchedParameters = Array.from(this.parameters.getWatched(this.getDeviceType()));

        this._isDanger = false;
        this._isWarning = false;

        this._connectedComponentsIn = [];
        this._connectedComponentsOut = [];

        this._calculations = new Parameters([
            {
                name: "Pdc_Dev",
                value: 0,
                unit: "Watts",
                isWatched: (!!args.watchedCalculationNames) ? args.watchedCalculationNames.indexOf("Pdc_Dev") !== -1 : false
            },
            {
                name: "Pdc_Sply",
                value: 0,
                unit: "Watts",
                isWatched: (!!args.watchedCalculationNames) ? args.watchedCalculationNames.indexOf("Pdc_Sply") !== -1 : false
            },
        ]);
        this._watchedCalculations = Array.from(this._calculations.getWatched(this.getDeviceType()));
        this.watchedCalculationNames = args.watchedCalculationNames;

        this.watchedOutputNames = args.watchedOutputNames;

        this._showWatermark = false;

        if (typeof window !== "undefined" && typeof BroadcastChannel !== "undefined") {
            this._plotChannel = new BroadcastChannel(`plot-${window.rfgGraphId}`);
            this._plotChannel.onmessage = evt => this.onPlotChannelMessage(evt);
        }
    }

    copy(key) {
        return new this.constructor({
            key: key,
            location: this.location,
            group: this.group,
            name: this.name,
            icon: this.icon,
            mirrorKey: this.mirrorKey,
            parameters: this.parameters.all,
            summary: this.summary,
            notes: this.notes,
            watchedCalculationNames: this.watchedCalculationNames,
            watchedOutputNames: this.watchedOutputNames,
        });
    }

    *_change(node, param, changes) {
        for (let i = 0; i < changes.length; i++) {
            let field = changes[i][0];
            let value = changes[i][1];

            if (field === "value") {
                yield ["simulate", `Changed ${param.name}`];

                if (param.minValue !== null && value < param.minValue) {
                    param.addWarning("Changed Value!", `${param.name} was automatically changed to ${param.minValue.toFixed(2)} (its minimum value)`, true);
                    value = param.minValue;
                } else if (param.maxValue !== null && value > param.maxValue) {
                    param.addWarning("Changed Value!", `${param.name} was automatically changed to ${param.maxValue.toFixed(2)} (its maximum value)`, true);
                    value = param.maxValue;
                }
            } else if (field === "cascadeValue") {
                let cascadeValue = param.cascadeValue;
                value.forEach(newCv => {
                    cascadeValue.forEach(oldCv => {
                        if (oldCv.name === newCv[0]) {
                            oldCv.enabled = newCv[1];
                        }
                    })
                });
                value = cascadeValue;
            } else if (field === "cascadeMV") {
                value.forEach(newCMv => {
                    param.cascadeMV.forEach(oldCMv => {
                        if (oldCMv.name === newCMv[0]) {
                            oldCMv.enabled = newCMv[1];
                            oldCMv.delta = newCMv[2];
                        }
                    })
                });
                value = param.cascadeMV;
            }

            const oldValue = param[field];
            yield ["set", param, field, value];

            for (const change of this.bubbledChanges(param, field, value, oldValue)) {
                yield ["simulate", `Bubbled Changes`];
                if (change[1] === "ADDED") {
                    yield ["addArrayItem", change[0], -1, change[2]];
                } else if (change[1] === "REMOVED") {
                    yield ["removeArrayItem", change[0], change[2]];
                } else {
                    yield ["set", ...change];
                }
            }

            if (field === "isEnabled") {
                yield ["simulate", `Turned ${param.name} on`];
                if (!value) yield ["set", param, "isWatched", false];

                if (!!param.cascadeEnabled) {
                    let bound = param.cascadeEnabled;
                    for (let idx = 0; idx < bound.length; idx++) {
                        const name = bound[idx];
                        let other = this.parameters.get(name);
                        if (!!other) {
                            yield ["set", other, "isEnabled", value];
                            if (!value) yield ["set", other, "isWatched", false];
                        }
                    }
                }
            } else if (field === "isWatched") {
                if (!!param.cascadeWatched) {
                    let bound = param.cascadeWatched;
                    for (let idx = 0; idx < bound.length; idx++) {
                        const name = bound[idx];
                        let other = this.parameters.get(name);
                        if (!!other) yield ["set", other, "isWatched", value];
                        else {
                            for (let idx = 0; idx < this._outputs.length; idx++) {
                                const output = this._outputs[idx];
                                let outputParam = output.get(name);
                                if (!!outputParam) yield ["set", outputParam, "isWatched", value];
                            }
                        }
                    }
                }

                let watched, dType = this.getDeviceType();
                watched = Array.from(this.parameters.getWatched(dType));
                yield ["set", this, "_watchedParameters", watched];
                if (this._outputs.length > 0) {
                    watched = Array.from(this.combineOutputs(this._outputs));
                    yield ["set", this, "watchedOutputNames", watched.map(p => p.name)];
                    yield ["set", this, "_watchedOutputs", watched];
                }
                watched = Array.from(this._calculations.getWatched(dType));
                yield ["set", this, "watchedCalculationNames", watched.map(p => p.name)];
                yield ["set", this, "_watchedCalculations", watched];
            } else if (field === "value" && param.name === "Size") {
                for (let idx = value; idx < this._outPortIds.length; idx++) {
                    let links = node.findLinksOutOf(this._outPortIds[idx]).iterator;
                    while (links.next()) {
                        yield ["remove", links.value];
                    }
                }
                yield ["set", this, "_outPortIds", this.outPortIds()];

                for (let idx = value; idx < this._inPortIds.length; idx++) {
                    let links = node.findLinksInto(this._inPortIds[idx]).iterator;
                    while (links.next()) {
                        yield ["remove", links.value];
                    }
                }
                yield ["set", this, "_inPortIds", this.inPortIds()];
            } else if (param.name === "Device Type" && field === "value") {
                let type = this.getDeviceType();
                let watched = Array.from(this.parameters.getWatched(type));
                yield ["set", this, "_watchedParameters", watched];
                if (this._outputs.length > 0) {
                    watched = Array.from(this.combineOutputs(this._outputs));
                    yield ["set", this, "watchedOutputNames", watched.map(p => p.name)];
                    yield ["set", this, "_watchedOutputs", watched];
                }
            }
        }
    }

    change(diagram, obj, changes, fromMirror) {
        diagram.startTransaction("set property");

        let node = diagram.findNodeForKey(this.key);
        let simulateReasons = [];

        for (const change of this._change(node, obj, changes)) {
            const type = change[0];

            if (type === "simulate") {
                simulateReasons.push(change[1]);
            } else if (type === "toast") {
                window.rfgShowToast(...change.slice(1));
            } else if (type === "set") {
                diagram.model.setDataProperty(...change.slice(1));
            } else if (type === "addArrayItem") {
                diagram.model.insertArrayItem(...change.slice(1));
            } else if (type === "removeArrayItem") {
                diagram.model.removeArrayItem(...change.slice(1));
            } else if (type === "remove") {
                diagram.remove(change[1]);
            }
        }

        diagram.commitTransaction("set property");

        if (self._isMirrorEnabled && !fromMirror) {
            if (obj instanceof Parameter) {
                let recalculate = obj.isMultiValuedEnabled || obj.isSParamsEnabled;

                this.findMirrors(diagram).each(comp => {
                    let param = comp.data.parameters.get(obj.name);
                    if (recalculate) {
                        let recalcChanges = changes.filter(c => c[0] !== "value");
                        let value;
                        if (obj.isSParamsEnabled) {
                            value = comp.data.calculateUsingSParams(comp.data.getFirstInput(), obj, obj.sParams);
                        } else {
                            value = comp.data.interpolate(comp.data.getFirstInput(), obj, obj.multiValues);
                        }
                        recalcChanges.push(["value", value.toFixed(PRECISION)]);
                        comp.data.change(diagram, param, recalcChanges, true);
                    } else {
                        if (!param) {
                            param = comp.data._calculations.get(obj.name);
                        }
                        if (!param) {
                            let branch = obj._branch || 1;
                            param = comp.data._outputs[branch - 1].get(obj.name);
                        }
                        comp.data.change(diagram, param, changes, true);
                    }
                });
            } else {
                this.findMirrors(diagram).each(comp => {
                    comp.data.change(diagram, comp.data, changes, true);
                });
            }
        }

        if (simulateReasons.length > 0 && node.findTreeRoot().data.category === "SRC") {
            diagram.simulate(node, simulateReasons);
        }
    }

    _simulate(parameters, inputs) {
        let input = inputs[0];
        let output = new Context(input);

        output.get("Pout").defer(() => this.calculatePout(parameters, input, output));
        output.get("Pcomp").defer(() => this.calculatePcomp(parameters, input, output));
        output.get("C_Gain").defer(() => this.calculateC_Gain(parameters, input, output));
        output.get("Linear_Gain").defer(() => this.calculateLinearGain(parameters, input, output));
        output.get("C_NF").defer(() => this.calculateC_NF(parameters, input, output));
        output.get("Noise_Pout").defer(() => this.calculateNoise_Pout(parameters, input, output));
        output.get("C_IP1dB").defer(() => this.calculateC_IP1dB(parameters, input, output));
        output.get("C_IIP3").defer(() => this.calculateC_IIP3(parameters, input, output));
        output.get("C_IIP2").defer(() => this.calculateC_IIP2(parameters, input, output));
        output.get("C_OP1dB").defer(() => this.calculateC_OP1dB(parameters, input, output));
        output.get("C_OIP3").defer(() => this.calculateC_OIP3(parameters, input, output));
        output.get("C_OIP2").defer(() => this.calculateC_OIP2(parameters, input, output));
        output.get("Total_Pcomp").defer(() => this.calculateTotalPcomp(parameters, input, output));

        return [output];
    }

    _runCalculations() {
        this._calculations.get("Pdc_Dev").defer(() => this.calculatePdcDev());
        this._calculations.get("Pdc_Sply").defer(() => this.calculatePdcSply());
    }

    simulate(inputs, reasons = []) {
        this._inputs = inputs;

        if (reasons.indexOf("Changed Frequency") !== -1 || reasons.indexOf("Changed Temperature") !== -1 || reasons.indexOf("ChangedLink") !== -1 || reasons.indexOf("InitialLoad") !== -1) {
            let input = this.getFirstInput();
            // TODO: If params bubble changes, then don't recalculate things
            //       e.g. OP1dB bubbles to calc IP1dB, so don't need to calc IP1dB again
            for (let idx = 0; idx < this.parameters.all.length; idx++) {
                const param = this.parameters.all[idx];
                if (param.isMultiValued && param.isMultiValuedEnabled && param.multiValues.length > 0) {
                    this.change(window.diagram, param, [["value", this.interpolate(input, param, param.multiValues)]]);
                }
                if (param.isSParamsEnabled && param.sParams.length > 0) {
                    this.change(window.diagram, param, [["value", this.calculateUsingSParams(input, param, param.sParams)]]);
                }
            }
        }

        this._outputs = this._simulate(this.parameters, inputs);
        this._runCalculations();

        this._isWarning = this._outputs[0].checkWarnings(this);

        let pComp = this._outputs[0].get("Pcomp").getValue();
        this._isDanger = this._outputs[0].checkDangers(pComp);

        return this._outputs;
    }

    calculateC_Gain(parameters, input, output) {
        let gain = parameters.get("Gain");
        let cGain = input.get("C_Gain").getValue("dB");
        cGain = cGain.plus(gain.getValue("dB"));
        cGain = cGain.minus(output.get("Pcomp").getValue("dB"));
        return cGain;
    }

    calculateC_OP1dB(parameters, input, output) {
        let op1db = parameters.get("OP1dB");
        if (!op1db.isEnabled) {
            // 100 as default value, then converted to linear
            op1db = (new Decimal(10)).pow(10);
        } else {
            op1db = op1db.getValue("linear");
        }

        let gain = parameters.get("Gain").getValue("linear");
        let previousC_OP1dB = input.get("C_OP1dB");

        if (previousC_OP1dB.getValue() === null) {
            return convertToUnit(op1db, "linear", "dBm");
        }
        previousC_OP1dB = previousC_OP1dB.getValue("linear");
        let one = new Decimal(1);
        let linearC_OP1dB = one.dividedBy(one.dividedBy(previousC_OP1dB.times(gain)).plus(one.dividedBy(op1db)));
        return convertToUnit(linearC_OP1dB, "linear", "dBm");
    }

    calculateC_NF(parameters, input, output) {
        let nf = parameters.get("NF");
        if (!nf.isEnabled) {
            // 0 as default value, then converted to linear
            nf = new Decimal(1);
        } else {
            nf = nf.getValue("linear");
        }

        let previousC_NF = input.get("C_NF");
        let previousC_Gain = input.get("C_Gain");

        if (previousC_NF.getValue() === null) {
            return convertToUnit(nf, "linear", "dB");
        }
        previousC_NF = previousC_NF.getValue("linear");
        previousC_Gain = previousC_Gain.getValue("linear");

        let linearC_NF = previousC_NF.add(nf.minus(1).dividedBy(previousC_Gain));
        let C_NF = convertToUnit(linearC_NF, "linear", "dB");
        let pComp = output.get("Pcomp").getValue("dB");
        C_NF = C_NF.add(pComp.times(0.11));
        return C_NF;
    }

    calculateC_OIP3(parameters, input, output) {
        let oip3 = parameters.get("OIP3");
        if (!oip3.isEnabled) {
            // 100 as default value, then converted to linear
            oip3 = (new Decimal(10)).pow(10);
        } else {
            oip3 = oip3.getValue("linear");
        }

        let previousC_OIP3 = input.get("C_OIP3");
        if (previousC_OIP3.getValue() === null) {
            return convertToUnit(oip3, "linear", "dBm");
        }
        previousC_OIP3 = previousC_OIP3.getValue("linear");
        let gain = parameters.get("Gain").getValue("linear");
        let one = new Decimal(1);
        let linearC_OIP3 = one.dividedBy(one.dividedBy(previousC_OIP3.times(gain)).plus(one.dividedBy(oip3)));
        return convertToUnit(linearC_OIP3, "linear", "dBm");
    }

    calculateC_OIP2(parameters, input, output) {
        let oip2 = parameters.get("OIP2");
        if (!oip2.isEnabled) {
            // 100 as default value, then converted to linear
            oip2 = (new Decimal(10)).pow(10);
        } else {
            oip2 = oip2.getValue("linear");
        }

        let previousC_OIP2 = input.get("C_OIP2");
        if (previousC_OIP2.getValue() === null) {
            return convertToUnit(oip2, "linear", "dBm");
        }
        previousC_OIP2 = previousC_OIP2.getValue("linear");
        let gain = parameters.get("Gain").getValue("linear");
        let one = new Decimal(1);
        let fromPrevious = one.dividedBy(previousC_OIP2.times(gain)).sqrt();
        let fromCurrent = one.dividedBy(oip2).sqrt();
        let linearC_OIP2 = one.dividedBy(fromPrevious.plus(fromCurrent).pow(2));
        return convertToUnit(linearC_OIP2, "linear", "dBm");
    }

    calculateC_IP1dB(parameters, input, output) {
        let gain = output.get("C_Gain").getValue();
        return output.get("C_OP1dB").getValue().minus(gain).add(1);
    }

    calculateC_IIP3(parameters, input, output) {
        let gain = output.get("C_Gain").getValue();
        return output.get("C_OIP3").getValue().minus(gain);
    }

    calculateC_IIP2(parameters, input, output) {
        let gain = output.get("C_Gain").getValue();
        return output.get("C_OIP2").getValue().minus(gain);
    }

    calculatePcomp(parameters, input, output) {
        let gain = parameters.get("Gain");
        let pIn = input.get("Pout").getValue();
        let pOut = output.get("Pout").getValue();
        return pIn.plus(gain.getValue()).minus(pOut);
    }

    calculatePout(parameters, input, output) {
        if (parameters.get("OPsat").isEnabled && parameters.get("OP1dB").isEnabled) {
            return this.calculatePoutWithCompression(parameters, input)
        }
        let pIn = input.get("Pout").getValue();
        let gain = parameters.get("Gain").getValue();
        return pIn.plus(gain);
    }

    calculatePoutWithCompression(parameters, input) {
        let pSat = parameters.get("OPsat").getValue("linear");
        let gain = parameters.get("Gain").getValue("linear");
        let S = this.estimatePoutS(parameters);
        let oneOverS = (new Decimal(1)).dividedBy(S);
        let pIn = input.get("Pout").getValue("linear");
        let pOut = pSat.dividedBy(pSat.dividedBy(pIn.times(gain)).pow(S).add(1).pow(oneOverS));
        return convertToUnit(pOut, "linear", "dBm");
    }

    estimatePoutSStarting(parameters) {
        let pSat = parameters.get("OPsat").getValue();
        let p1 = parameters.get("OP1dB").getValue();
        return pSat.minus(p1).pow(-0.544).times(2.8815);
    }

    estimatePoutS(parameters) {
        let pSat = parameters.get("OPsat").getValue("linear");
        let p1 = parameters.get("OP1dB").getValue("linear");
        let S = this.estimatePoutSStarting(parameters)

        let sMin = S.times(0.85);
        let sMax = S.times(1.15);

        let error = this.calculatePoutSError(p1, pSat, S);
        let attempts = 0;
        while (error.abs() >= 0.0005 && attempts < 100) {
            attempts += 1;
            S = sMin.add(sMax).dividedBy(2);
            error = this.calculatePoutSError(p1, pSat, S);
            if (error < 0) {
                sMax = S;
            } else {
                sMin = S;
            }
        }
        return S;
    }

    calculatePoutSError(p1, pSat, S) {
        // error = p1 - (pSat / ((1 + (pSat / (p1 * 1.2589254)) ** S) ** (1 / S)))
        let oneOverS = (new Decimal(1)).dividedBy(S);
        let denom = pSat.dividedBy(p1.times(1.2589254)).pow(S).add(1).pow(oneOverS);
        return p1.minus(pSat.dividedBy(denom));
    }

    calculatePdcDev() {
        let idc = this.parameters.get("Idc");
        let devSply = this.parameters.get("DevSply");
        if (!idc.isEnabled || !devSply.isEnabled) {
            return 0;
        }
        return idc.getValue("A").times(devSply.getValue("Vdc"));
    }

    calculatePdcSply() {
        let idc = this.parameters.get("Idc");
        let busSply = this.parameters.get("BusSply");
        if (!idc.isEnabled || !busSply.isEnabled) {
            return 0;
        }
        return idc.getValue("A").times(busSply.getValue("Vdc"));
    }

    calculateNoise_Pout(parameters, input, output) {
        let noiseFloor = input.get("Noise Floor").getValue();
        let gain = parameters.get("Gain").getValue();
        let thisC_NF = output.get("C_NF").getValue();
        let pComp = output.get("Pcomp").getValue();
        let previousC_NF = input.get("C_NF").getValue();
        let previousNoise_Pout = input.get("Noise_Pout").getValue();

        let Noise_Pout;
        if (previousNoise_Pout === null) {
            let nf = parameters.get("NF").getValue();
            // 0.89 is a fudge factor, getting the pcomp value to line up with AWR
            Noise_Pout = noiseFloor.add(nf).add(gain).minus(pComp.times(0.89));
        } else {
            // We subtract 100% pComp to include the fudge factor, but also remove the extra pcomp added to C_NF
            // Removing 0.11 pcomp from C_NF gives us the small signal value
            Noise_Pout = previousNoise_Pout.add(gain).minus(pComp).add(thisC_NF).minus(previousC_NF);
        }

        if (Noise_Pout.lessThan(noiseFloor)) {
            Noise_Pout = noiseFloor;
        }

        return Noise_Pout;
    }

    calculateLinearGain(parameters, input, output) {
        const source = this.findSource();
        if (!source) {

        }
        let params = new Parameters(source.parameters);
        params.set("Power", source.getFirstOutput().get("Noise Floor").getValue());
        let queue = Queue();
        queue = addComponentToQueue(queue, source, params, self._isMultiValuesEnabled);
        queue = buildDisconnectedQueue(this, queue);
        let results = simulateInBackgroundDisconnected(queue);
        return results[this.key][0].get("C_Gain").getValue();
    }

    calculateTotalPcomp(parameters, input, output) {
        let linearGainValue = output.get("Linear_Gain").getValue();
        let actualGainValue = output.get("C_Gain").getValue();
        return linearGainValue.minus(actualGainValue);
    }

    outPortIds() {
        return ["out"];
    }

    inPortIds() {
        return ["in"];
    }

    outputOnPort(portId) {
        let portIds = this.outPortIds();
        for (let idx = 0; idx < portIds.length; idx++) {
            if (portIds[idx] === portId) {
                return this._outputs[idx];
            }
        }
    }

    * bubbledChanges(param, field, value) {
        if (field === "value") {
            if (!!param.cascadeValue) {
                for (const cascade of param.cascadeValue) {
                    if (cascade.enabled) {
                        yield [this.parameters.get(cascade.name), "value", param[cascade.calculate](this)];
                    }
                }
            }
        }
        if (field === "isMultiValuedEnabled") {
            if (!!param.cascadeMVEnabled) {
                for (const paramName of param.cascadeMVEnabled) {
                    yield [this.parameters.get(paramName), "isMultiValuedEnabled", value];
                }
            }
        }
        if (field === "multiValues") {
            if (!!param.cascadeMV) {
                for (const cascade of param.cascadeMV) {
                    const otherParam = this.parameters.get(cascade.name);
                    const cascadedValues = value.map(mv => {
                        const otherMV = otherParam.multiValues.filter(omv => omv.temp.equals(mv.temp) && omv.freq.equals(mv.freq))[0];
                        let v;
                        if (cascade.enabled || otherMV === undefined) {
                            let delta;
                            if (!!cascade.calculateDelta) {
                                delta = param[cascade.calculateDelta](this, mv);
                            } else {
                                delta = parseFloat(cascade.delta);
                                if (cascade.direction === "above") {
                                    delta = delta * -1;
                                }
                            }
                            v = mv.value.plus(delta).toDecimalPlaces(PRECISION);
                        } else {
                            v = otherMV.value;
                        }
                        return {
                            freq: mv.freq,
                            temp: mv.temp,
                            value: v
                        }
                    });

                    yield [otherParam, "multiValues", cascadedValues];
                    if (cascade.enabled) {
                        yield [otherParam, "value", this.interpolate(this.getFirstInput(), otherParam, cascadedValues)];
                        otherParam.clearWarnings();
                    }
                    if (!!cascade.cascadeDelta) {
                        let delta = parseFloat(cascade.delta);
                        for (const parent in cascade.cascadeDelta) {
                            const child = cascade.cascadeDelta[parent];
                            const parentParam = this.parameters.get(parent);
                            for (const parentCascade of parentParam.cascadeMV) {
                                if (parentCascade.name === child) {
                                    yield [parentCascade, "enabled", cascade.enabled];
                                    yield [parentCascade, "delta", delta];
                                }
                            }
                        }
                    }
                }
            }
        }
        if (param.name === "OP1dB") {
            if (field === "value" && !param.isMultiValuedEnabled) {
                yield* this.bubbledOp1dBChanges(param);
            }
        } else if (param.name === "IP1dB") {
            if (field === "value" && !param.isMultiValuedEnabled) {
                yield* this.bubbledIp1dBChanges(param);
            }
        } else if (param.name === "OPsat") {
            if (field === "value" && !param.isMultiValuedEnabled) {
                yield* this.bubbledOpsatChanges(param);
            }
        } else if (param.name === "IPsat") {
            if (field === "value" && !param.isMultiValuedEnabled) {
                yield* this.bubbledIpsatChanges(param);
            }
        } else if (param.name === "OIP2") {
            if (field === "value") {
                yield* this.bubbledOip2Changes(param);
            }
        } else if (param.name === "OIP3") {
            if (field === "value") {
                yield* this.bubbledOip3Changes(param);
            }
        } else if (param.name === "IIP2") {
            if (field === "value") {
                yield* this.bubbledIip2Changes(param);
            }
        } else if (param.name === "IIP3") {
            if (field === "value") {
                yield* this.bubbledIip3Changes(param);
            }
        } else if (param.name === "OP1BackOff") {
            if (field === "value") {
                yield* this.bubbledOP1BackOffChanges(param);
            }
        } else if (param.name === "IP1BackOff") {
            if (field === "value") {
                yield* this.bubbledIP1BackOffChanges(param);
            }
        } else if (param.name === "Te") {
            if (field === "isEnabled") {
                yield [this.parameters.get("NF"), field, !value];
            }
        } else if (param.name === "NF") {
            if (field === "isEnabled") {
                yield [this.parameters.get("Te"), field, !value];
            }
        } else if (param.name === "Gain" && field === "value") {
            yield* this.bubbledGainChanges(param);
        }
    }

    * bubbledOip2Changes(param) {
        let iip2 = this.parameters.get("IIP2");
        let gain = this.parameters.get("Gain").getValue();
        yield [iip2, "value", param.getValue().minus(gain)];
    }

    * bubbledOip3Changes(param) {
        let iip3 = this.parameters.get("IIP3");
        let gain = this.parameters.get("Gain").getValue();
        yield [iip3, "value", param.getValue().minus(gain)];
    }

    * bubbledIip2Changes(param) {
        let oip2 = this.parameters.get("OIP2");
        let gain = this.parameters.get("Gain").getValue();
        yield [oip2, "value", param.getValue().add(gain)];
    }

    * bubbledIip3Changes(param) {
        let oip3 = this.parameters.get("OIP3");
        let gain = this.parameters.get("Gain").getValue();
        yield [oip3, "value", param.getValue().add(gain)];
    }

    * bubbledOP1BackOffChanges(param) {
        let ip1backoff = this.parameters.get("IP1BackOff");
        yield [ip1backoff, "value", param.getValue().add(1)];
    }

    * bubbledIP1BackOffChanges(param) {
        let op1backoff = this.parameters.get("OP1BackOff");
        yield [op1backoff, "value", param.getValue().minus(1)];
    }

    * bubbledOp1dBChanges(param) {
        let opSat = this.parameters.get("OPsat");
        let opSatValue = opSat.getValue();
        let op1dbValue = param.getValue();
        let difference = opSatValue.minus(op1dbValue);
        if (difference.lessThan(0.25)) {
            let extraSat = (new Decimal(0.25)).minus(difference);
            opSatValue = opSatValue.plus(extraSat);
            if (opSatValue.greaterThan(opSat.maxValue)) {
                yield [opSat, "value", opSat.maxValue];
                opSat.addWarning("Changed Value!", `OPSat was automatically changed to ${opSat.maxValue} so that it is 0.25dBm greater than OP1dB.`, true);
                op1dbValue = new Decimal(opSat.maxValue - 0.25);
                yield [param, "value", op1dbValue];
                param.addWarning("Changed Value!", `OP1dB was automatically changed to ${op1dbValue.toFixed(2)} so that it is 0.25dBm less than OPSat.`, true);
            } else {
                yield [opSat, "value", opSatValue];
                opSat.addWarning("Changed Value!", `OPSat was automatically changed to ${opSatValue.toFixed(2)} so that it is 0.25dBm greater than OP1dB.`, true);
            }
        } else if (difference.greaterThan(10)) {
            let extraSat = (new Decimal(10)).minus(difference);
            opSatValue = opSatValue.plus(extraSat);
            yield [opSat, "value", opSatValue];
            opSat.addWarning("Changed Value!", `OPsat was automatically changed to ${opSatValue.toFixed(2)} so that it is 10dBm greater than OP1dB`, true);
        }

        let ip1dbValue = op1dbValue.minus(this.parameters.get("Gain").getValue()).add(1);
        yield [this.parameters.get("IP1dB"), "value", ip1dbValue];

        let delta = opSatValue.minus(op1dbValue);
        let ipsatValue = ip1dbValue.add(delta);
        yield [this.parameters.get("IPsat"), "value", ipsatValue];
    }

    * bubbledIp1dBChanges(param) {
        let ipSat = this.parameters.get("IPsat");
        let ipSatValue = ipSat.getValue();
        let ip1dbValue = param.getValue();
        let difference = ipSatValue.minus(ip1dbValue);
        if (difference.lessThan(0.25)) {
            let extraSat = (new Decimal(0.25)).minus(difference);
            ipSatValue = ipSatValue.plus(extraSat);
            if (ipSatValue.greaterThan(ipSat.maxValue)) {
                yield [ipSat, "value", ipSat.maxValue];
                ipSat.addWarning("Changed Value!", `IPSat was automatically changed to ${ipSat.maxValue} so that it was 0.25 greater than IP1dB.`, true);
                ip1dbValue = new Decimal(ipSat.maxValue - 0.25);
                yield [param, "value", ip1dbValue];
                param.addWarning("Changed Value!", `IP1dB was automatically changed to ${ip1dbValue.toFixed(2)} so that it was 0.25 less than IPSat.`, true);
            } else {
                yield [ipSat, "value", ipSatValue];
                ipSat.addWarning("Changed Value!", `IPsat was automatically changed to ${ipSatValue.toFixed(2)} so that it is 10dBm greater than IP1dB`, true);
            }
        } else if (difference.greaterThan(10)) {
            let extraSat = (new Decimal(10)).minus(difference);
            ipSatValue = ipSatValue.plus(extraSat);
            ipSat.addWarning("Changed Value!", `IPsat was automatically changed to ${ipSatValue.toFixed(2)} so that it is 10dBm greater than IP1dB`, true);
            yield [ipSat, "value", ipSatValue];
        }

        let op1dbValue = ip1dbValue.add(this.parameters.get("Gain").getValue()).minus(1);
        yield [this.parameters.get("OP1dB"), "value", op1dbValue];

        let delta = ipSatValue.minus(ip1dbValue);
        let opsatValue = op1dbValue.add(delta);
        yield [this.parameters.get("OPsat"), "value", opsatValue];
    }

    * bubbledOpsatChanges(param) {
        let op1db = this.parameters.get("OP1dB");
        let op1dbValue = op1db.getValue();
        let opSatValue = param.getValue();
        let difference = opSatValue.minus(op1dbValue);
        if (difference.lessThan(0.25)) {
            let extraOP1dB = difference.minus(0.25);
            op1dbValue = op1dbValue.plus(extraOP1dB);
            if (op1dbValue.lessThan(op1db.minValue)) {
                yield [op1db, "value", op1db.minValue];
                op1db.addWarning("Changed Value!", `OP1dB was automatically changed to ${op1db.minValue} so that it is 0.25dBm less than OPsat.`, true);
                opSatValue = new Decimal(op1db.minValue + 0.25);
                yield [param, "value", opSatValue];
                param.addWarning("Changed Value!", `OPSat was automatically changed to ${opSatValue.toFixed(2)} so that it is 0.25dBm greater than OP1dB.`, true);
            } else {
                yield [op1db, "value", op1dbValue];
                op1db.addWarning("Changed Value!", `OP1dB was automatically changed to ${op1dbValue.toFixed(2)} so that it is 0.25dBm less than OPsat.`, true);
            }
        } else if (difference.greaterThan(10)) {
            let extraOP1dB = difference.minus(10);
            op1dbValue = op1dbValue.plus(extraOP1dB);
            yield [op1db, "value", op1dbValue];
            op1db.addWarning("Changed Value!", `OP1dB was automatically changed to ${op1dbValue.toFixed(2)} so that it is 10dBm less than OPsat`, true);
        }

        let ip1dbValue = op1dbValue.minus(this.parameters.get("Gain").getValue()).add(1);
        yield [this.parameters.get("IP1dB"), "value", ip1dbValue];

        let delta = opSatValue.minus(op1dbValue);
        let ipsatValue = ip1dbValue.add(delta);
        yield [this.parameters.get("IPsat"), "value", ipsatValue];
    }

    * bubbledIpsatChanges(param) {
        let ip1db = this.parameters.get("IP1dB");
        let ip1dbValue = ip1db.getValue();
        let ipSatValue = param.getValue();
        let difference = ipSatValue.minus(ip1dbValue);
        if (difference.lessThan(0.25)) {
            let extraIP1dB = difference.minus(0.25);
            ip1dbValue = ip1dbValue.plus(extraIP1dB);
            if (ip1dbValue.lessThan(ip1db.minValue)) {
                yield [ip1db, "value", ip1db.minValue];
                ip1db.addWarning("Changed Value!", `IP1dB was automatically changed to ${ip1db.minValue} so that it is 0.25dBm less than IPsat.`, true);
                ipSatValue = new Decimal(ip1db.minValue + 0.25);
                yield [param, "value", ipSatValue];
                param.addWarning("Changed Value!", `IPSat was automatically changed to ${ipSatValue.toFixed(2)} so that it is 0.25dBm greater than IP1dB.`, true);
            } else {
                yield [ip1db, "value", ip1dbValue];
                ip1db.addWarning("Changed Value!", `IP1dB was automatically changed to ${ip1dbValue.toFixed(2)} so that it is 0.25dBm less than IPsat.`, true);
            }
        } else if (difference.greaterThan(10)) {
            let extraIP1dB = difference.minus(10);
            ip1dbValue = ip1dbValue.plus(extraIP1dB);
            yield [ip1db, "value", ip1dbValue];
            ip1db.addWarning("Changed Value!", `IP1dB was automatically changed to ${ip1dbValue.toFixed(2)} so that it is 10dBm less than IPsat`, true);
        }

        let op1dbValue = ip1dbValue.add(this.parameters.get("Gain").getValue()).minus(1);
        yield [this.parameters.get("OP1dB"), "value", op1dbValue];

        let delta = ipSatValue.minus(ip1dbValue);
        let opsatValue = op1dbValue.add(delta);
        yield [this.parameters.get("OPsat"), "value", opsatValue];
    }

    * bubbledGainChanges(param) {
        let gain = param.getValue();
        if (this.getDeviceType() === "TX") {
            let op1db = this.parameters.get("OP1dB");
            if (op1db.isMultiValued) return
            let op1dbValue = op1db.getValue();
            let ip1dbValue = op1dbValue.minus(gain).add(1);
            yield [this.parameters.get("IP1dB"), "value", ip1dbValue];

            let opSatValue = this.parameters.get("OPsat").getValue();
            let delta = opSatValue.minus(op1dbValue);
            let ipsatValue = ip1dbValue.add(delta);
            yield [this.parameters.get("IPsat"), "value", ipsatValue];

            let iip3 = this.parameters.get("IIP3");
            yield [iip3, "value", this.parameters.get("OIP3").getValue().minus(gain)];

            let iip2 = this.parameters.get("IIP2");
            yield [iip2, "value", this.parameters.get("OIP2").getValue().minus(gain)];
        } else {
            let ip1db = this.parameters.get("IP1dB");
            if (ip1db.isMultiValued) return
            let ip1dbValue = ip1db.getValue();
            let op1dbValue = ip1dbValue.add(gain).minus(1);
            yield [this.parameters.get("OP1dB"), "value", op1dbValue];

            let ipSatValue = this.parameters.get("IPsat").getValue();
            let delta = ipSatValue.minus(ip1dbValue);
            let opsatValue = op1dbValue.add(delta);
            yield [this.parameters.get("OPsat"), "value", opsatValue];

            let oip3 = this.parameters.get("OIP3");
            yield [oip3, "value", this.parameters.get("IIP3").getValue().add(gain)];

            let oip2 = this.parameters.get("OIP2");
            yield [oip2, "value", this.parameters.get("IIP2").getValue().add(gain)];
        }
    }

    * combineOutputs(outputs) {
        let deviceType = this.getDeviceType();
        for (let i = 0; i < outputs.length; i++) {
            for (const param of outputs[i].getWatched(deviceType)) {
                yield param;
            }
        }

    }

    getFirstInput() {
        for (let idx = 0; idx < this._inputs.length; idx++) {
            if (!!this._inputs[idx]) return this._inputs[idx];
        }
    }

    getFirstOutput() {
        for (let idx = 0; idx < this._outputs.length; idx++) {
            if (!!this._outputs[idx]) return this._outputs[idx];
        }
    }

    getDeviceType() {
        let dType = this.parameters.get("Device Type");
        if (!dType) {
            // Should only be true for Source components
            dType = this.parameters.get("Project Type");
        }
        dType = dType.getValue();
        if (dType === "Default") {
            if (this._inputs.length > 0) {
                dType = this.getFirstInput().get("Project Type").getValue();
            } else {
                dType = "TX";
            }
        }
        return dType;
    }

    findNode() {
        return window.diagram.findNodeForKey(this.key);
    }

    *findAncestors() {
        for (let idx = 0; idx < this._connectedComponentsIn.length; idx++) {
            const c = this._connectedComponentsIn[idx];
            if (!c) continue;
            yield* c.findAncestors();
            yield c;
        }
    }

    findSource() {
        if (this.category === "SRC") {
            return this;
        }
        for (const c of this.findAncestors()) {
            if (c.category === "SRC") {
                return c;
            }
        }
    }

    calculateUsingSParams(input, parameter, sParamFiles) {
        if (!!input && sParamFiles.length > 0 && self._isMultiValuesEnabled) {
            let freq = input.get("Frequency").getValue("MHz");
            let temp = input.get("Temperature").getValue("°C");
            let multiValues = convertToMultiValue(sParamFiles);
            if (multiValues.length > 0) return interpolateValue(multiValues, temp, freq);
        }
        return parameter.getValue();
    }

    interpolate(input, parameter, multiValues) {
        if (multiValues.length !== 0 && !!input && self._isMultiValuesEnabled) {
            let freq = input.get("Frequency").getValue("MHz");
            let temp = input.get("Temperature").getValue("°C");
            return interpolateValue(multiValues, temp, freq);
        }
        return parameter.getValue();
    }

    interpolateAtFreqTemp(parameter, freq, temp, multiValues) {
        if (typeof parameter === "string") {
            parameter = this.parameters.get(parameter);
        }
        if (multiValues === undefined) {
            multiValues = parameter.multiValues;
        }
        if (multiValues.length > 0) {
            return interpolateValue(multiValues, temp, freq);
        }
        return parameter.getValue();
    }

    broadcastChangeNotification() {
        if (!!this._plotChannel) {
            this._plotChannel.postMessage({ type: "changeNotification", key: this.key });
        }
    }

    broadcastDeleteNotification() {
        if (!!this._plotChannel) {
            this._plotChannel.postMessage({ type: "nodeDeleted", key: this.key });
        }
    }

    broadcastDisconnectedNotification() {
        if (!!this._plotChannel) {
            this._plotChannel.postMessage({ type: "nodeDisconnected", key: this.key });
        }
    }

    broadcastNameChange() {
        if (!!this._plotChannel) {
            this._plotChannel.postMessage({ type: "nodeNameUpdate", key: this.key, name: this.name });
        }
    }

    onPlotChannelMessage(evt) {
        if (evt.data.type === "queueRequest" && evt.data.key === this.key) {
            let source = this.findSource();
            if (!source) return;
            const queue = buildDisconnectedQueue(this);
            queue.nodes = {};
            for (const key in queue.parameters) {
                queue.parameters[key] = queue.parameters[key].dump()
            }
            this._plotChannel.postMessage({
                type: "queueUpdate",
                key: this.key,
                queue: queue,
                sourceTemp: source.parameters.get("Temperature").getValue("°C").toFixed(0),
                sourceFreq: source.parameters.get("Frequency").getValue("MHz").toFixed(PRECISION),
                sourcePower: source.parameters.get("Power").getValue("dBm").toFixed(PRECISION),
            });
        }
    }

    findMirrors(diagram) {
        if (!this.mirrorKey) return new go.List();
        return diagram.findNodesByExample({ mirrorKey: this.mirrorKey, key: k => k !== this.key });
    }
}
