import Parameter from "../utils/parameter.js";

export default class NF extends Parameter {
    constructor(initialValue, overrides) {
        let args = overrides || {};
        args.name = "NF";
        args.isMultiValued = true;
        args.value = initialValue;
        args.unit = Parameter.valueOrDefault(args.unit, "dB");

        args.minValue = Parameter.valueOrDefault(args.minValue, 0);
        args.maxValue = Parameter.valueOrDefault(args.maxValue, 99.99);

        args.isEnabled = Parameter.valueOrDefault(args.isEnabled, true);
        args.isWatched = Parameter.valueOrDefault(args.isWatched, true);

        super(args);
    }
}