import ProbedParameters from "./components/utils/probedParameters.js";
import { getAllNodesToSource } from "./diagram/utils.js";


function probeNode(node, params) {
    let pdcDev = node._calculations.get("Pdc_Dev");
    params.addParam("Total_Pdc_Dev", pdcDev, "Watts");

    let pdcSply = node._calculations.get("Pdc_Sply");
    params.addParam("Total_Pdc_Sply", pdcSply, "Watts");
}

export default function probe(nodes, branch = 0, calcs = null) {
    let probedNode, sourceNode;
    let nodesAreConnected = false;

    calcs = calcs || new ProbedParameters();
    calcs.reset();

    if (nodes.length === 1) {
        probedNode = nodes[0];
        nodes = Array.from(getAllNodesToSource(nodes[0]));
        nodesAreConnected = true;
    }

    nodes.forEach((n) => {
        probeNode(n.data, calcs);
        if (n.data.category === "SRC") sourceNode = n;
    });

    if (nodesAreConnected && !!sourceNode) {
        let output = probedNode.data._outputs[branch];
        if (!!output) {
            let linearGain = calcs.get("Linear_Gain");
            let linearGainValue = output.get("Linear_Gain").getValue();
            linearGain.set(linearGainValue);
            linearGain.isHidden = false;

            let totalPcomp = calcs.get("Total_Pcomp");
            let totalPcompValue = output.get("Total_Pcomp").getValue();
            totalPcomp.set(totalPcompValue);
            totalPcomp.isHidden = false;
        }
    } else {
        calcs.get("Total_Pcomp").isHidden = true;
        calcs.get("Linear_Gain").isHidden = true;
    }

    return {
        nodes: nodes,
        calculations: calcs,
        source: sourceNode,
        probedNode: probedNode
    };
}
